export const WhiteLoader = () => {
   return (
      <div
         className='spinner-border text-white'
         style={{
            width: "15px",
            height: "15px",
            border: "2px solid",
            borderRight: "2px solid transparent",
         }}
         role='status'></div>
   )
}

export const ThemeSpinner = () => {
   return <div className="theme-custom-loader"></div>
}