import React, { useEffect, useState } from 'react'
import errorPage  from '../../Assets/Images/errorImage.webp';
import { useNavigate } from 'react-router';
const ErrorPage = () => {
    const navigate = useNavigate()
    const [time,setTime]=useState(3)
    useEffect(()=>{
        if(time > 1 ){
        setTimeout(() => {
            setTime(time-1)
        }, 1000);
    }else{
             setTimeout(() => {
            navigate("/")
        }, 2000);
    }
    },[time])
    // useEffect(()=>{
    //     setTimeout(() => {
    //         navigate("/")
    //     }, 2000);
    // },[])
    console.log(time)
  return (
    <div>
        <img src={errorPage} alt="" />
        <h1 style={{position:"absolute",top:"6%"}}>Redirecting in {time} seconds ......</h1>
    </div>
  )
}

export default ErrorPage