import React, { useEffect, useState } from "react";
import Sidebar from "../../AppLayout/Sidebar";
import { useFormik } from "formik";
import { nanoid } from "nanoid";
import { addUserSitesApi, getAllUserSites } from "../../Api/Apis";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router";
import { AddSitesValidation } from "../../Utils/Validations";

const AddSites = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const siteFormik = useFormik({
    initialValues: {
      siteList: [
        {
          siteId: nanoid(),
          siteName: "",
          managerName: "",
          managerMail: "",
          address:"",
        },
      ],
    },
    validationSchema: AddSitesValidation,
    onSubmit: async (values) => {
      console.log(values, "site formik");
      // const {}
      try {
        const res = await addUserSitesApi({ data: values?.siteList });
        if (res?.status === 200) {
          toast.success("Sites added successfully", { id: "001" });
          navigate("/");
        }
      } catch (error) {
        if(error && error?.response?.status === 400 && error?.response?.data?.message){
          toast.error(error?.response?.data?.message)
        }else{
          console.log(error);
        }
      }
    },
  });

  const handleChangeSiteValues = (e, i) => {
    const { name, value } = e.target;
    // console.log(name, i, "this needed...", siteFormik.values, value)
    siteFormik.setFieldValue(`siteList.${[i]}.${name}`, value);
  };

  console.log(location, "sites arr console...");

  const handleAddSites = (arr) => {
    arr.push({
      siteId: nanoid(),
      siteName: "",
      managerName: "",
      managerMail: "",
      address:"",
    });
    siteFormik.setFieldValue("siteList", arr);
  };

  const handleRemoveSites = (arr, id) => {
    const arrX = arr.filter((obj) => {
      return obj.siteId !== id;
    });
    siteFormik.setFieldValue("siteList", arrX);
  };

  const getSitesData = async () => {
    try {
      const res = await getAllUserSites();
      if (res.status === 200 || res.status === 201) {
        // setSiteData(res?.data?.data);
        if(!res?.data?.data) return 
        console.log(res?.data?.data, "djksfhsdu");
        let arr=[]
        res?.data?.data?.map((v) => {
          arr?.push({ siteId: v?.id,
            isDefault:v?.isDefault,
            siteName: v.siteName,
            managerName: v.managerName,
            managerMail: v.managerMail,
            address:v?.address,
          })
        });
        siteFormik.setFieldValue("siteList", arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (location?.state?.edit) {
    }
    getSitesData();
  }, [location]);

  console.log("siteformik",siteFormik.errors);
  return (
    <>
      {/* <Sidebar /> */}
      <div className="container-fliud mask">
        <div className="row ms-0 me-0">
          <div className="col-xl-7 col-lg-6 text-center">
            <div className="img_logo">
              <img src={process.env.PUBLIC_URL + "/assets/images/logo.svg"} />
              <img
                src={process.env.PUBLIC_URL + "/assets/images/tcf.svg"}
                className="tcf_logo"
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="add-sites mask2">
              <h2 className="">Add sites to your business</h2>

              {Boolean(siteFormik.values.siteList.length)
                ? siteFormik.values.siteList?.map((el, i) => {
                    // console.log(el, i, "<><><><><><>")
                    return (
                      <React.Fragment key={`sites-${i}`}>
                        <div className="d-grid gap-1 mb-4 d-flex justify-content-between">
                          <label htmlFor="" className="form-label mt-3">
                            Site {i+1} {el?.isDefault && "(Default)"}
                          </label>
                          {!el?.isDefault && siteFormik.values.siteList.length > 1 ? (
                            <button
                              style={{ minWidth: "100px" }}
                              className="btn_custome btn_later mt-4"
                              onClick={() => {
                                handleRemoveSites(
                                  siteFormik.values.siteList,
                                  el?.siteId
                                );
                              }}
                            >
                              Remove
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                        <input
                          className="form-control mt-3"
                          type="text"
                          name={`siteName`}
                          value={el?.siteName}
                          onChange={(e) => {
                            handleChangeSiteValues(e, i);
                          }}
                          id=""
                          placeholder="Enter site name"
                        />
                         {siteFormik?.touched?.siteList?.[i]?.siteName && siteFormik?.errors?.siteList?.[i]?.siteName && <p className="text-danger">{siteFormik?.errors?.siteList?.[i]?.siteName}</p>}
                        <input
                          className="form-control mt-3"
                          type="text"
                          name="managerName"
                          value={el?.managerName}
                          onChange={(e) => {
                            handleChangeSiteValues(e, i);
                          }}
                          id="number"
                          placeholder="Enter site manager name"
                        />
                        {siteFormik?.touched?.siteList?.[i]?.managerName && siteFormik?.errors?.siteList?.[i]?.managerName && <p className="text-danger">{siteFormik?.errors?.siteList?.[i]?.managerName}</p>}
                        <input
                          className="form-control mt-3"
                          type="email"
                          name="managerMail"
                          value={el?.managerMail}
                          onChange={(e) => {
                            !el?.isDefault && handleChangeSiteValues(e, i);
                          }}
                          id="email"
                          disabled={el?.isDefault}
                          placeholder="Enter Site manager email"
                        />
                        {siteFormik?.touched?.siteList?.[i]?.managerMail && siteFormik?.errors?.siteList?.[i]?.managerMail && <p className="text-danger">{siteFormik?.errors?.siteList?.[i]?.managerMail}</p>}
                        <input
                          className="form-control mt-3"
                          type="text"
                          name="address"
                          value={el?.address}
                          onChange={(e) => {
                            handleChangeSiteValues(e, i);
                          }}
                          id="address"
                          placeholder="Enter Site address"
                        />
                        {siteFormik?.touched?.siteList?.[i]?.address && siteFormik?.errors?.siteList?.[i]?.address && <p className="text-danger">{siteFormik?.errors?.siteList?.[i]?.address}</p>}
                      </React.Fragment>
                    );
                  })
                : ""}
              <div className="d-flex justify-content-end">
                <button
                  style={{ minWidth: "100px" }}
                  className="btn_custome mt-4 "
                  onClick={() => {
                    handleAddSites(siteFormik.values.siteList);
                  }}
                >
                  Add more sites
                </button>
              </div>
              <br />
              <div className="d-grid gap-1 mb-4 d-flex justify-content-between">
                <button
                  type="button"
                  style={{ minWidth: "150px" }}
                  className="btn_custome mt-4 me-0"
                  onClick={() => {
                    siteFormik.handleSubmit();
                  }}
                >
                  Continue
                </button>
                <button
                  type="button"
                  style={{ minWidth: "150px" }}
                  className="btn_custome btn_later mt-4"
                  onClick={() => {
                    navigate("/edit-profile", {state:{"newUser":true}});
                  }}
                >
                  Later
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSites;
