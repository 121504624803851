import React from 'react'
import { Route, Routes } from 'react-router'
import Demo from './Pages/Demo'

const UiRouter = () => {
   return (
      // <Routes>
      //    <Route path="/demo" element={<Demo />} />
      // </Routes>
      <>
         This is on hold...
      </>
   )
}

export default UiRouter