import React, {
  useContext, 
  useEffect, useState
} from "react";

import Sidebar from "../../AppLayout/Sidebar";
import Mainsearch from "../../AppLayout/MainSearch";
import CloseBtn from "../../Assets/Images/Group 4292.svg";
import ModalImage1 from "../../Assets/Images/Mask Group 70.png";
import ModalImage2 from "../../Assets/Images/Mask Group 68.png";
import { UserContext } from "../../Contexts/Contexts";
import { getAllPlanApi, getAllSubscriptionPlans, handleCheckoutPlan, handleCustomerBilling } from "../../Api/Apis";
import { Spinner } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { SITE_URL } from "../../Utils/BaseUrl";
import { ThemeSpinner } from "../../Utils/Loaders";
import Default from "../Default"
import BusinessPlanTrailAlert from "../Common/BusinessPlanTrailAlert";
import {toast} from "react-hot-toast";
import OperatingAlert from "../Common/OperatingAlert";
const UpgradePlan = () => {
  const { showTrialAlert } = useContext(UserContext);
  const [allPlan, setAllPlan] = useState([]);
  const [currentPlan, setCurrentPlan] = useState();
  const [planforCheckout, setPlanforCheckout] = useState({});
  const [checkoutLoader, setCheckoutLoader] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const [isUserSubscribed, setIsUserSubscribed] = useState(false)
  const [isHovered, setIsHovered] = useState(0);
  const [loader, setLoader] = useState(true)
  const [isApplePay, setIsApplePay] = useState(false);

  const getAllPlans = async () => {
    try {
      
      const res = await getAllPlanApi();
      if (res.status === 200 || res.status === 201) {
        setIsUserSubscribed(res?.data?.isUserSubscribed)
        setIsApplePay(res?.data?.isApplePay)
        if (res?.data?.isUserSubscribed) {
          let temp = res?.data?.data?.filter(obj => obj.isAlreadySubscribed)
          setAllPlan(temp)
        } else {

          setAllPlan(res?.data?.data);
        }

      }
      setLoader(false)
    } catch (Err) {
      console.log(Err);
      setLoader(false)
    }
  };

  const getCurrentSubscription = async () => {
    try {
      
      const res = await getAllSubscriptionPlans();

      if(res && res.status === 200){
        setCurrentPlan(res.data.currentSubscription)
      }
      setLoader(false)
    } catch (Err) {
      console.log(Err);
      setLoader(false)
    }
  };

  const handleCloseModal = () =>{
    setSearchParams("status","")
  }

  const handleCheckout = async (obj) => {
    try {
      setCheckoutLoader(true)
      const res = await handleCheckoutPlan(
        {
          priceId: obj.id,
          success_url: `${SITE_URL}/upgrade-plan?status=success`,
          cancel_url: `${SITE_URL}/upgrade-plan?status=failed`
        }
      )
      if (res) {
        window.location.href = res.data.url;
      }
      setCheckoutLoader(false)
    } catch (err) {
      console.log(err)
      setCheckoutLoader(false)
    }
  }

  useEffect(() => {
    let search = searchParams.get('status')
    if (search) {
      document.getElementById('payment_modal').click()
    }
    getAllPlans();
    getCurrentSubscription();
  }, []);
  
  useEffect(()=>{
    if(isApplePay){

      const toastId = toast.error('IoS subscription plans do not appear on other types of devices. Please use an Apple device to view your subscription plan and/or upgrade', {
        style: {
          padding: '16px',
          color: '#856404',
          backgroundColor: '#fff3cd',
          width: 'auto',
          maxWidth: '80%',
        },
        duration: Infinity, 
      });


      
      return () => {
        toast.dismiss(toastId);
      };
    }
  },[isApplePay])

  const handleUpdatePlan = async () => {
    try {
      setCheckoutLoader(true)
      const res = await handleCustomerBilling({ return_url: `${SITE_URL}/upgrade-plan` })
      if (res) {
        window.location.href = res.data.url;
      }
      // setCheckoutLoader(false)
    } catch (err) {
      alert('Something Went Wrong')
      setCheckoutLoader(false)
    }
  }

  const handleMouseOver = (id) => {
    setIsHovered(id);
  };

  const handleMouseOut = () => {
    setIsHovered(0);
  }

  return (
    <div className="upgrade">
      <Sidebar />
      <div className="main-content  content-mask" style={{paddingTop:'0rem'}}>
        <div className="sticky-header" style={{paddingTop:'1.5rem'}}> 
      {showTrialAlert && <BusinessPlanTrailAlert/>}
      <OperatingAlert/>
        <Mainsearch />
        </div>
        <div className="d-flex justify-content-between pt-4 mb-3 position-relative">
          {/* <p className="browse">Browse all the ingredients and recipes shared with you</p> */}
          <div className=" d-flex justify-content-between align-items-center my-community-heading  ">
            {/* <h2 className="fw-bolder ps-3 p-20">Start your plan</h2> */}
          </div>
        </div>
        <div className="row">
        {currentPlan  ? (
          <div className="col-lg-4 ms-lg-4">
            <div className="card main-card">  
                <div className="card-body-1" style={{ borderColor: currentPlan?.product_data?.colors?.primary ? currentPlan?.product_data?.colors?.primary : "#42c442" }}>
                  <h5 className="card-title" >Your Current Plan</h5>

                  <div
                    className="small-card-1 mb-4"
                    onMouseOver={() => handleMouseOver(currentPlan.id)}
                    onMouseOut={handleMouseOut}
                    style={{ borderColor: isHovered === currentPlan?.id ? currentPlan?.product_data?.colors?.primary : '#fff', boxShadow:`0 10px 40px ${currentPlan?.product_data?.colors?.secondary}`, cursor:"default" }}
                  >
                    <div className="heading-4 d-flex justify-content-between align-item-center">
                      <h1 style={{ color: currentPlan?.product_data?.colors?.primary ? currentPlan?.product_data?.colors?.primary : "#42c442" }}>{currentPlan?.product_data?.name}</h1>
                      <p style={{ color: currentPlan?.product_data?.colors?.primary ? currentPlan?.product_data?.colors?.primary : "#42c442", borderColor: currentPlan?.product_data?.colors?.primary ? currentPlan?.product_data?.colors?.primary : "#42c442", backgroundColor: currentPlan?.product_data?.colors?.primary ? currentPlan?.product_data?.colors?.secondary : "#42c442" }}>
                        <u>{currentPlan?.amount ? `${currentPlan?.currencySymbol}${currentPlan?.amount}/${currentPlan?.interval_count > 1 ?currentPlan?.interval_count + "":""}${currentPlan?.interval}` :"Free"}</u>
                      </p>
                    </div>
                    <p className="small-card-1-body" >{currentPlan?.product_data?.description}</p>
                  </div>
                </div>
              </div>
          </div>
        ) : null}

      {!isApplePay && isUserSubscribed && currentPlan ? <div className="text-end pt-5 col-lg-2 ms-lg-2">
            <button onClick={() => handleUpdatePlan()}
              disabled={checkoutLoader}
              className="btn_custome mt-4"
              type="button"
            // data-bs-toggle="modal"
            // data-bs-target="#exampleModalsub"
            >
              {checkoutLoader ? <Spinner /> : 'Change Plan'}

            </button>
          </div> : null}

          </div>

        {/* <div>
          <h2 className="ps-3">My plan</h2>
          <div className=" col-lg-4 ms-lg-4">
            <div className="card main-card">
              <div className="card-body-1">
                <h5 className="card-title ">Subscriptions</h5>
                <h6 className="card-subtitle mb-4 ">Choose Your Personal Plan</h6>

                <div
                  className="small-card-1  active mb-4"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal2"
                >
                  <div className="heading-4 d-flex justify-content-between align-item-center">
                    <h1>PERSONAL</h1>
                    <p>
                      <u>£ 50/year</u>
                    </p>
                  </div>
                  <p className="small-card-1-body">Up to 10 analysis/day</p>
                  <p className=" mb-0 pb-0 ">
                    <i className="bi bi-check-circle-fill h1 icon  mb-0"></i>
                  </p>
                </div>
              </div>
            </div>
          </div>


        </div> */}
        <div className="row">
          {loader ?
          <div className="text-center">
          <ThemeSpinner/>
          </div>
          :
          allPlan.length
          ?<>
          {!isUserSubscribed && <h2 className="ps-3">Choose a plan</h2>}
           {!isUserSubscribed && allPlan.map((obj, key) => {
            return <div key={key} className=" col-lg-4 ms-lg-4">
              <div className="card main-card">
                <div className="card-body-1" style={{ borderColor: obj?.product_data?.colors?.primary ? obj?.product_data?.colors?.primary : "#42c442" }}>
                  {!obj.isAlreadySubscribed ? <h5 className="card-title" >Subscriptions</h5> : null}
                  <h6 className="card-subtitle mb-4 ">{obj.isAlreadySubscribed ? 'Your Current Plan' : `Choose Your ${obj?.product_data?.name} Plan`}</h6>

                  <div
                    className="small-card-1 mb-4"
                    data-bs-toggle={obj.isAlreadySubscribed ? "" : "modal"}
                    data-bs-target={obj.isAlreadySubscribed ? "" : "#exampleModal2"}
                    onClick={() => setPlanforCheckout(obj)}
                    onMouseOver={() => handleMouseOver(obj.id)}
                    onMouseOut={handleMouseOut}
                    style={{ borderColor: isHovered === obj?.id ? obj?.product_data?.colors?.primary : '#fff', boxShadow:`0 10px 40px ${obj?.product_data?.colors?.secondary}` }}
                  >
                    <div className="heading-4 d-flex justify-content-between align-item-center">
                      <h1 style={{ color: obj?.product_data?.colors?.primary ? obj?.product_data?.colors?.primary : "#42c442" }}>{obj?.product_data?.name}</h1>
                      <p style={{ color: obj?.product_data?.colors?.primary ? obj?.product_data?.colors?.primary : "#42c442", borderColor: obj?.product_data?.colors?.primary ? obj?.product_data?.colors?.primary : "#42c442", backgroundColor: obj?.product_data?.colors?.primary ? obj?.product_data?.colors?.secondary : "#42c442" }}>
                        <u>{obj?.amount ? `${obj?.currencySymbol}${obj?.amount}/${obj?.interval_count > 1 ?obj?.interval_count + "":""}${obj?.interval}` :"Free"}</u>
                      </p>
                    </div>
                    {/* {obj?.product_data?.features?.map((features, key) => {
                      return <p key={key} className="small-card-1-body">{features?.name}</p>
                    })} */}
                    <p className="small-card-1-body" >{obj?.product_data?.description}</p>
                    {/* <p className=" mb-0 pb-0 ">
                      <i className="bi bi-check-circle-fill h1 icon  mb-0"></i>
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          })}</>
            :
            <Default name='Plans'/>
          }


          {/* ///////////// */}


          {/* <!-- Button trigger modal --> */}
          <button type="button" id="payment_modal" className="d-none btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
            Launch demo modal
          </button>

          {/* <!-- Modal --> */}
          <div class="modal fade" data-bs-backdrop="static" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-sm">
              <div class="modal-content">
                <div class="modal-header border-bottom-0">
                  
                  {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                </div>
                {searchParams.get('status')==='success' ? <><div class="modal-body text-center fs-5">
                  <p><svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="bi bi-check-circle-fill text-success" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                  </svg></p>
                  <h3 className="fw-bolder">Thank you!</h3>
                  <h3 className="fw-bolder">Your plan is <span className="text-success">successfully</span>  activated</h3>
                  {/* <p className="fs-4 px-2 mb-0 py-3">You will be receiving a confirmation email with order details.</p> */}
                </div>
                <div class="modal-footer border-top-0">
                  <button type="button" class={`btn  mx-auto px-5 py-3 btn-success`} data-bs-dismiss="modal" aria-label="Close" style={{borderRadius: "25px"}} onClick={()=>handleCloseModal()}>Done</button>
                </div>
                </>
                :searchParams.get('status')==='failed' ?
                <>
                <div class="modal-body text-center fs-5">
                  <p><svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="bi bi-x-circle-fill text-danger" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                </svg></p>
                  <h3 className="fw-bolder">Oops!</h3>
                  <h3 className="fw-bolder">Your transaction has <span className="text-danger">failed</span></h3>
                  <p className="fs-4 px-2 mb-0 py-3">Please go back and try again later.</p>
                </div>
                <div class="modal-footer border-top-0">
                  <button type="button" class={`btn  mx-auto px-5 py-3 btn-danger`} data-bs-dismiss="modal" aria-label="Close" style={{borderRadius: "25px"}} onClick={()=>handleCloseModal()}>Done</button>
                </div></>: null}
              </div> 
            </div>
          </div>


          {/* ///////////// */}





          {/* {!isApplePay && isUserSubscribed && allPlan.length ? <div className="text-end col-lg-2 ms-lg-2">
            <button onClick={() => handleUpdatePlan()}
              disabled={checkoutLoader}
              className="btn_custome mt-4"
              type="button"
            // data-bs-toggle="modal"
            // data-bs-target="#exampleModalsub"
            >
              {checkoutLoader ? <Spinner /> : 'Change Plan'}

            </button>
          </div> : null} */}

          {/* <div className=" col-lg-4 ms-lg-4">
            <div className="card main-card">
              <div className="card-body-1">
                <h5 className="card-title ">Subscriptions</h5>
                <h6 className="card-subtitle mb-4 ">Choose Your Personal Plan</h6>

                <div
                  className="small-card-1  active mb-4"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal2"
                >
                  <div className="heading-4 d-flex justify-content-between align-item-center">
                    <h1>PERSONAL</h1>
                    <p>
                      <u>£ 50/year</u>
                    </p>
                  </div>
                  <p className="small-card-1-body">Up to 10 analysis/day</p>
                  <p className=" mb-0 pb-0 ">
                    <i className="bi bi-check-circle-fill h1 icon  mb-0"></i>
                  </p>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className=" col-lg-4">
            <div className="card main-card">
              <div className="card-body">
                <h5 className="card-title ">Subscriptions</h5>
                <h6 className="card-subtitle mb-2 ">Choose Your Business Plan</h6>
                <p className="card-text ">Start your 30 days free trial</p>
                <div
                  className="small-card  active mb-4"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal1"
                >
                  <div className="heading d-flex justify-content-between align-item-center">
                    <h1>Business silver</h1>
                    <p>
                      <u>£ 200/year</u>
                    </p>
                  </div>
                  <p className="small-card-body">
                    Suitable for businesses up to 3 sites up to 30 analysis/day
                  </p>
                  <p className=" mb-0 pb-0 ">
                    <i className="bi bi-check-circle-fill h1 icon  mb-0"></i>
                  </p>
                </div>
                <div className="small-card  mb-4">
                  <div className="heading-1 d-flex justify-content-between align-item-center">
                    <h1>Business Gold</h1>
                    <p>
                      <u>£ 379/year</u>
                    </p>
                  </div>
                  <p className="small-card-body">
                    Suitable for businesses up to 7 sites up to 70 analysis/day
                  </p>
                  <p className=" mb-0 pb-0 ">
                    <i className="bi bi-check-circle-fill h1 icon  mb-0"></i>
                  </p>
                </div>
                <div className="small-card  mb-4">
                  <div className="heading-2 d-flex justify-content-between align-item-center">
                    <h1>Business Platinium</h1>
                    <p>
                      <u>£ 600/year</u>
                    </p>
                  </div>
                  <p className="small-card-body">
                    Suitable for businesses up to 20 sites up to 220
                    analysis/day
                  </p>
                  <p className=" mb-0 pb-0 ">
                    <i className="bi bi-check-circle-fill h1 icon  mb-0"></i>
                  </p>
                </div>
                <div className="small-card  mb-4">
                  <div className="heading-3 d-flex justify-content-between align-item-center">
                    <h1>Business Unlimited</h1>
                    <p>
                      <u>£ 999/year</u>
                    </p>
                  </div>
                  <p className="small-card-body">
                    Suitable for businesses up to 50 sites up to 500
                    analysis/day
                  </p>
                  <p className=" mb-0 pb-0 ">
                    <i className="bi bi-check-circle-fill h1 icon  mb-0"></i>
                  </p>
                </div>
              </div>
            </div>
            
           
          </div> */}

        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content pb-4">
            <div className="modal-header border-bottom-0 pb-0">
              <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
              <img className="modal-image-1" src={ModalImage1} />
              <button
                type=""
                className=" border-0 bg-transparent"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {" "}
                <img src={CloseBtn} />
              </button>
            </div>
            <div className="modal-body px-5 pt-3 py-0">
              <h1 className="modal-title  fw-bold p-16  pt-5 fs-5 text-center">
                Upgrade plan ??
              </h1>
              <p className="fw-bolder  p-12 ps-4">
                Are you sure you want to upgrade <br /> your plan into Business
                Platinium?'
              </p>

              <p className="p-10 mb-0 ps-4">
                The prepaid amount will be deducted from your new subscription
                plan amount.
              </p>
            </div>

            <div className=" mx-auto">
              <button className="btn_custome mt-4 modal-submit me-5 ">
                No
              </button>
              <button className="btn_custome mt-4 modal-submit">Yes</button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal2"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content pb-4">
            <div className="modal-header border-bottom-0 pb-0">
              <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
              <img className="modal-image-1" src={ModalImage2} />
              <button
                type=""
                className=" border-0 bg-transparent"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {" "}
                <img src={CloseBtn} />
              </button>
            </div>
            <div className="modal-body px-5 py-0">
              <h1 className="modal-title  fw-bold p-16 pt-5 fs-5 text-center">
                Upgrade plan ??
              </h1>
              <p className="fw-bolder  p-12 ps-4">
                Are you sure you want to Upgrade your plan into {planforCheckout?.product_data?.name} ?
              </p>

              {/* <p className="p-10 mb-0 ps-4">
                The Business silver plan will go into effect with your next
                payment. You may continue using your current plan up until the
                day before your subsequent payment due date.
              </p> */}
            </div>

            <div className=" mx-auto">
              <button className="btn_custome mt-4 modal-submit me-5 " disabled={checkoutLoader} data-bs-dismiss="modal" aria-label="Close">
                No
              </button>
              <button className="btn_custome mt-4 modal-submit" disabled={checkoutLoader} onClick={() => handleCheckout(planforCheckout)}>{checkoutLoader ? <Spinner /> : 'Yes'}</button>
            </div>
          </div>
        </div>
      </div>

      {/* modal start here    */}
      <div
        className="modal fade"
        id="exampleModalsub"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content model-subs">
            <div className="modal-body">
              <div className=" col-lg-12 ms-lg-4">
                <div className="card main-card">
                  <div className="card-body-1">
                    <h5 className="card-title ">Subscriptions</h5>
                    <h6 className="card-subtitle mb-4 ">
                      Choose Your Personal Plan
                    </h6>

                    <div
                      className="small-card-1  active mb-4"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal2"
                    >
                      <div className="heading-4 d-flex justify-content-between align-item-center">
                        <h1>PERSONAL</h1>
                        <p>
                          <u>£ 50/year</u>
                        </p>
                      </div>
                      <p className="small-card-1-body">Up to 10 analysis/day</p>
                      <p className=" mb-0 pb-0 ">
                        <i className="bi bi-check-circle-fill h1 icon  mb-0"></i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-lg-12">
                <div className="card main-card">
                  <div className="card-body">
                    <h5 className="card-title ">Subscriptions</h5>
                    <h6 className="card-subtitle mb-2 ">
                      Choose Your Business Plan
                    </h6>
                    <p className="card-text ">Start your 15 days free trial</p>
                    {Boolean(allPlan?.length) &&
                      allPlan?.map((v, key) => {
                        return (
                          <div
                            key={key}
                            className="small-card  active mb-4"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal1"
                          >
                            <div className="heading d-flex justify-content-between align-item-center">
                              <h1>{v.name}</h1>
                              <p>
                                <u>
                                  £ {v.price}/{v.subscriptionType}
                                </u>
                              </p>
                            </div>
                            <p className="small-card-body">{v.description}</p>
                            <p className=" mb-0 pb-0 ">
                              <i className="bi bi-check-circle-fill h1 icon  mb-0"></i>
                            </p>
                          </div>
                        );
                      })}
                    {/* <div className="small-card  mb-4">
                      <div className="heading-1 d-flex justify-content-between align-item-center">
                        <h1>Business Gold</h1>
                        <p>
                          <u>£ 379/year</u>
                        </p>
                      </div>
                      <p className="small-card-body">
                        Suitable for businesses up to 7 sites up to 70
                        analysis/day
                      </p>
                      <p className=" mb-0 pb-0 ">
                        <i className="bi bi-check-circle-fill h1 icon  mb-0"></i>
                      </p>
                    </div>
                    <div className="small-card  mb-4">
                      <div className="heading-2 d-flex justify-content-between align-item-center">
                        <h1>Business Platinium</h1>
                        <p>
                          <u>£ 600/year</u>
                        </p>
                      </div>
                      <p className="small-card-body">
                        Suitable for businesses up to 20 sites up to 220
                        analysis/day
                      </p>
                      <p className=" mb-0 pb-0 ">
                        <i className="bi bi-check-circle-fill h1 icon  mb-0"></i>
                      </p>
                    </div>
                    <div className="small-card  mb-4">
                      <div className="heading-3 d-flex justify-content-between align-item-center">
                        <h1>Business Unlimited</h1>
                        <p>
                          <u>£ 999/year</u>
                        </p>
                      </div>
                      <p className="small-card-body">
                        Suitable for businesses up to 50 sites up to 500
                        analysis/day
                      </p>
                      <p className=" mb-0 pb-0 ">
                        <i className="bi bi-check-circle-fill h1 icon  mb-0"></i>
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradePlan;
