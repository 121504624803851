import React from 'react'

const BusinessPlanTrailAlert = () => {
    return (
        <div class="alert alert-danger fade show" role="alert">
            You are still on the 15-day free trial, please pay to avoid being downgraded from the business suite.
        </div>
    )
}

export default BusinessPlanTrailAlert