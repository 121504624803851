import React, { useEffect, useState } from 'react'
import { downloadRecipeApi, getAllIngridientsById } from '../../Api/Apis';
import { WhiteLoader } from '../Loader/Loader';
import toast from 'react-hot-toast';
import { BUCKET_URL } from '../../Utils/BaseUrl';
import { chartFooterText } from '../../Utils/HelperFuntions';

const IngredientChartModal = (props) => {
    const { ingredientId, setIngredientId } = props;

    const [loader, setLoader] = useState(true)
    const [ingredientDetails, setIngredientDetails] = useState()
    const [downloadLoader, setDownloadLoader] = useState(false)

    const getIngredientData = async () => {
        try {
            setLoader(true)
            const res = await getAllIngridientsById(ingredientId);

            if (res.status === 200) {

                let tempNut = res?.data?.data?.proximates?.nutrients;
                tempNut = tempNut.filter((el) => el.value !== 0 || "");


                let tempMin = res?.data?.data?.proximates?.minerals;
                tempMin = tempMin.filter((el) => el.value !== 0 || "");

                let tempVit = res?.data?.data?.proximates?.vitamins;
                tempVit = tempVit.filter((el) => el.value !== 0 || "");

                setIngredientDetails({
                    ...res?.data?.data,
                    ['nutrients']: tempNut,
                    ['minerals']: tempMin,
                    ['vitamins']: tempVit
                })

            }

            setLoader(false);
        } catch (error) {
            toast.error(error.message);
            setLoader(false);
        }
    };



    const handleDownloadIngredient = async () => {
        if (downloadLoader) return;
        setDownloadLoader(true)
        try {
            const res = await downloadRecipeApi("", ingredientId);
            if (res.status === 200 || res.status === 201) {
                window.open(`${BUCKET_URL + "/" + res.data.data}`, "_blank");
                setDownloadLoader(false)
            }
        } catch (err) {
            toast.error(err?.response?.data?.message);
            setDownloadLoader(false)
        }
    }

    useEffect(() => {
        if (ingredientId) {
            getIngredientData()
        }
    }, [ingredientId])

    return (
        <div
            data-bs-backdrop="static"
            className="modal fade"
            id="ingredientModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div
                className="modal-dialog  modal-dialog-centered"
                style={{ maxWidth: "440px", width: "100%" }}
            >
                <div
                    className="modal-content "
                    style={{ borderRadius: "0px", border: "1px solid #000" }}
                >
                    <div className="modal-header-list position-relative">
                        <h1
                            className="modal-title-list  mb-0 py-3 "
                            id="exampleModalLabel"
                        >
                            Nutritional Information
                            {downloadLoader ?
                                <span className='ms-4'>
                                    <WhiteLoader />
                                </span>
                                :
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    fill="currentColor"
                                    className="bi bi-download ms-4"
                                    viewBox="0 0 16 16"
                                    style={{ cursor: "pointer" }}
                                    onClick={handleDownloadIngredient}
                                >
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                </svg>
                            }
                            <button
                                id="closeModalBtn"
                                type="button"
                                className="position-absolute rounded-circle  end-0 me-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setIngredientId()}
                            >
                                {" "}
                                <i
                                    className="bi bi-x"
                                ></i>
                            </button>
                        </h1>
                    </div>
                    {loader ?
                        <div className='mx-auto' style={{ padding: "20px" }}>
                            <WhiteLoader color={"black"} />
                        </div>
                        : <div className="modal-body pb-0">
                            {ingredientDetails?.nutrients?.length > 0 ? <>
                                <div className=" heading d-flex justify-content-between align-items-center">
                                    <h6>Nutrition</h6>
                                    <h6>Per 100g</h6>
                                </div>
                                <hr className="m-0 bottom-line" />
                                {ingredientDetails?.nutrients?.map((nutrient) => (
                                    <div className="row pt-3">
                                        <div className="col-5">
                                            <h6 className="fs-5 fw-bold">{nutrient?.name}</h6>
                                        </div>
                                        <div className="col-3">
                                            <p className="fs-5">{nutrient?.unit}</p>
                                        </div>
                                        <div className="col-4">
                                            <h6 className="text-center fs-5 fw-bold">
                                                {nutrient?.value}
                                            </h6>
                                        </div>
                                    </div>
                                ))} </> : ""}


                            {ingredientDetails?.minerals?.length > 0 ? <> <hr className="mb-2 bottom-line" />
                                <div className=" heading d-flex justify-content-between align-items-center">
                                    <h6>Minerals</h6>
                                    <h6>Per 100g</h6>
                                </div>
                                {/* <hr className="my-2 bottom-line" /> */}
                                {ingredientDetails?.minerals?.map((miniral) => (
                                    <div className="row">

                                        <div className="col-5">
                                            <h6 className="fs-5 fw-bold">{miniral?.name}</h6>
                                        </div>
                                        <div className="col-3">
                                            <p className="fs-5">{miniral?.unit}</p>
                                        </div>
                                        <div className="col-4">
                                            <h6 className="text-center fs-5 fw-bold">
                                                {miniral?.value}
                                            </h6>
                                        </div>
                                    </div>
                                ))}</> : ""}

                            {ingredientDetails?.vitamins?.length > 0 ?

                                <><hr className="my-2 bottom-line" />
                                    <div className=" heading d-flex justify-content-between align-items-center">
                                        <h6>Vitamins</h6>
                                        <h6>Per 100g</h6>
                                    </div>
                                    {/* <hr className="my-2 bottom-line" /> */}
                                    {ingredientDetails?.vitamins?.map((vitamin) => (
                                        <div className="row">

                                            <div className="col-5">
                                                <h6 className="fs-5 fw-bold">{vitamin?.name}</h6>
                                            </div>
                                            <div className="col-3">
                                                <p className="fs-5">{vitamin?.unit}</p>
                                            </div>
                                            <div className="col-4">
                                                <h6 className="text-center fs-5 fw-bold">
                                                    {vitamin?.value}
                                                </h6>
                                            </div>
                                        </div>
                                    ))}</> : ""}

                        </div>}
                    <hr className="my-2 bottom-line" />
                    <p
                        className=" text-dark m-0 card-bottom p-10 pt-3 p-2"
                        style={{ borderTop: "3px solid black" }}
                    >
                        {/* % values are based on a 2000 calories diet. Your daily values may be higher or lower depending on your energy needs.<br />
                        A ratio value of 1 is used for volume to weight conversion during some analysis.<br />
                        Some of the ingredients and/or recipes on this database are Crown copyright data drawn from CoFID and licensed under the Open Government Licence v3.0. */}
                    {chartFooterText()}
                    
                    </p>
                </div>
            </div>
        </div>
    )
}

export default IngredientChartModal