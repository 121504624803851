import React, { useContext } from "react";
import { handleLogout } from "../Utils/HelperFuntions";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../Contexts/Contexts";
import person from "../Assets/Images/edit_profile.svg";
// import recipe from "../Assets/Images/bookmark-fill.svg";
// import community from "../Assets/Images/my_community_fill.svg";
// import plan from "../Assets/Images/subscription_plan_fill.svg";
import bussiness from "../Assets/Images/Group 8414.svg";
// import darkperson from '../Assets/Images/edit_profile.svg'
// import darkcommunity from '../Assets/Images/my_community.svg'
import darksite from "../Assets/Images/DarkSites.svg"
// import darkplan from '../Assets/Images/subscription_plan.svg';
// import darksaved from '../Assets/Images/saved_items.svg';
// import saved from '../Assets/Images/saved_items_fill.svg';
import tcfLightSvg from '../Assets/Images/Group -1.svg';
import tcfSvg from '../Assets/Images/Group 10775 (2).svg';
// import shared from '../Assets/Images/shared_recipe_fill.svg';
// import darkshared from '../Assets/Images/shared_recipe.svg';
// import privacy from '../Assets/Images/privacy_policy.svg';
// // import darkprivacy from '../Assets/Images/privacy_tip_FILL0_wght400_GRAD0_opsz24.svg';
// import TandC from '../Assets/Images/termsandconditions.svg';
// // import darkTandC from '../Assets/Images/file-earmark-fill.svg';
// import blog from '../Assets/Images/blogs.svg';
// // import darkblog from '../Assets/Images/blog-solid.svg';
// import darkMyRecipe from '../Assets/Images/my_recipe_fill.svg';
// import myRecipe from '../Assets/Images/my_recipe.svg'



function Sidebar() {
  // const navigate = useNavigate();

  const { userProfileDetails,setSearchData } = useContext(UserContext);
  const userDetails = userProfileDetails?.data?.data
  const location = useLocation();
  const locationForAccountCollapse = ["/edit-profile","/saved-recipes","/myshared-recipes","/communityshared-recipes","/my-community","/upgrade-plan","/my-business-sites","/manage-sites"]

  return (
    //Working on collapsable sidebar...

    // <>
    //   {
    //     toggleSidebar
    //       ?
    //       (
    //         <>
    //           <nav className="navbar navbar-vertical fixed-start navbar-expand-md navbar-light" id="sidebar">
    //             <div className="container-fluid">
    //               {/* Toggler */}
    //               {/* <button className="navbar-toggler d-block" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation"
    //                 onClick={() => { setToggleSidebar(false) }}
    //               >
    //                 <span className="navbar-toggler-icon" />
    //               </button> */}
    //               {/* Brand */}
    //               <a className="navbar-brand" href="/">
    //                 <img className="navbar-brand-img mx-auto" src={process.env.PUBLIC_URL + "/assets/images/logo.svg"} />
    //               </a>
    //               {/* User (xs) */}
    //               {/* <div className="navbar-user d-md-none">
    //         <div className="dropdown">
    //           <a href="#" id="sidebarIcon" className="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    //             <div className="avatar avatar-sm avatar-online">
    //               <img src="./assets/img/avatars/profiles/avatar-1.jpg" className="avatar-img rounded-circle" alt="..." />
    //             </div>
    //           </a>
    //           <div className="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarIcon">
    //             <a href="./profile-posts.html" className="dropdown-item">Profile</a>
    //             <a href="./account-general.html" className="dropdown-item">Settings</a>
    //             <hr className="dropdown-divider" />
    //             <a href="./sign-in.html" className="dropdown-item">Logout</a>
    //           </div>
    //         </div>
    //       </div> */}
    //               {/* Collapse */}
    //               <div className="collapse navbar-collapse" id="sidebarCollapse">

    //                 {/* Navigation */}
    //                 <ul className="navbar-nav">
    //                   <li className="nav-item">
    //                     <Link className="nav-link" to="/"  >
    //                       <i className='active'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="16.959" viewBox="0 0 18 16.959">
    //                         <path id="Path_10716" data-name="Path 10716" d="M72.4,973.8c-.036-.031-.312-.173-.664-.349a3.877,3.877,0,0,0-3.472,0c-.353.176-.628.318-.664.349l-5.221,4.528A4,4,0,0,0,61,981.35v6.85a1.8,1.8,0,0,0,1.8,1.8h4.8a.6.6,0,0,0,.6-.6v-3.6a1.8,1.8,0,1,1,3.6,0v3.6a.6.6,0,0,0,.6.6h4.8a1.8,1.8,0,0,0,1.8-1.8v-6.85a4,4,0,0,0-1.379-3.022Z" transform="translate(-61 -973.041)" fill="#fd3468" />
    //                       </svg></i> Home
    //                     </Link>
    //                   </li>
    //                   <li className="nav-item" >
    //                     <Link className="nav-link" to="/my-recipes" >
    //                       <i><svg xmlns="http://www.w3.org/2000/svg" width="23.481" height="15.727" viewBox="0 0 23.481 15.727">
    //                         <g id="Group_4200" data-name="Group 4200" transform="translate(834.382 -115.482)">
    //                           <path id="Path_9340" data-name="Path 9340" d="M-659.591,182.15h2.074a4.754,4.754,0,0,0,1.8,3.686,4.559,4.559,0,0,0,2.91,1.031,4.6,4.6,0,0,0,3.369-1.376,4.687,4.687,0,0,0,1.406-3.338h2.083c-.088.322-.169.625-.254.927q-1.436,5.077-2.868,10.156a.342.342,0,0,1-.4.305q-3.306-.016-6.612,0a.324.324,0,0,1-.382-.285q-1.526-5.431-3.07-10.857C-659.551,182.327-659.566,182.254-659.591,182.15Zm12.215.765c-.316,2.406-1.353,4.3-3.731,5.233A5.265,5.265,0,0,0-647.376,182.915Zm-6.94,4.5a5.525,5.525,0,0,1-3.851-4.54C-658.4,184.574-656.47,187.037-654.316,187.415Zm2.708,4.842a1.756,1.756,0,0,0,1.516-1.526,1.743,1.743,0,0,0-1.461-1.416c.651.326,1.279.672,1.256,1.486S-650.972,191.949-651.608,192.258Z" transform="translate(-164.952 -62.915)" />
    //                           <path id="Path_9341" data-name="Path 9341" d="M-818.706,276.466h-2.107a.98.98,0,0,0,1.418.072l.265.565-.823.4.013.05c.3,0,.592,0,.888,0a.635.635,0,0,1,.675.636.63.63,0,0,1-.676.661q-5.105,0-10.211,0a.631.631,0,0,1-.68-.658.637.637,0,0,1,.673-.639c.29-.006.581,0,.871-.008.007,0,.013-.008.053-.035l-.841-.4.252-.558a.98.98,0,0,0,1.371-.03,1.139,1.139,0,0,0-.162-.024c-.5,0-.994,0-1.491,0-.449,0-.723-.246-.726-.639a.649.649,0,0,1,.711-.663q4.979,0,9.957-.005a.23.23,0,0,1,.269.206C-818.923,275.727-818.821,276.058-818.706,276.466Zm-8.6.044a.928.928,0,0,0,1.372,0Zm3.261,0a.96.96,0,0,0,1.4,0Zm1.6-.008c.685.409,1.077.4,1.4,0Zm-3.228.007a.934.934,0,0,0,1.377,0Zm5.536,1.027c-.707-.4-1.043-.388-1.425,0Zm-1.656,0c-.631-.41-.944-.405-1.424,0Z" transform="translate(-4.188 -150.714)" />
    //                           <path id="Path_9342" data-name="Path 9342" d="M-828.346,344.307c1.876,0,3.752,0,5.627-.006a.318.318,0,0,1,.375.3,1.093,1.093,0,0,1-.355,1.015,4.026,4.026,0,0,1-1.727.961,13.484,13.484,0,0,1-5.393.493,10.494,10.494,0,0,1-2.737-.59,3.76,3.76,0,0,1-1.458-.872,1.086,1.086,0,0,1-.35-.99.33.33,0,0,1,.39-.32C-832.1,344.312-830.222,344.307-828.346,344.307Z" transform="translate(0 -215.938)" />
    //                           <path id="Path_9343" data-name="Path 9343" d="M-823.008,223.636h-.314c-3.44,0-6.88,0-10.32.007-.284,0-.425-.112-.4-.357a1.776,1.776,0,0,1,.206-.759,2.579,2.579,0,0,1,1.225-.906,9.155,9.155,0,0,1,2.873-.723,14.208,14.208,0,0,1,5.1.313c1.024.268,1.024.268,1.312,1.3C-823.223,222.876-823.119,223.242-823.008,223.636Z" transform="translate(-0.315 -99.393)" />
    //                           <path id="Path_9344" data-name="Path 9344" d="M-541.048,123.393a6.385,6.385,0,0,0-.674.128c-.284.086-.361-.071-.5-.283a1.426,1.426,0,0,1,.009-1.75.739.739,0,0,0,0-.929,1.383,1.383,0,0,1,0-1.744.743.743,0,0,0,0-.93,1.375,1.375,0,0,1-.01-1.722,4.648,4.648,0,0,0,.325-.682l.89.412c-.146.319-.3.619-.419.931a.583.583,0,0,0,0,.384,7.506,7.506,0,0,0,.412.892.5.5,0,0,1-.007.52,8.214,8.214,0,0,0-.4.868.625.625,0,0,0,0,.408c.128.331.3.646.433.975a.446.446,0,0,1,0,.31c-.14.337-.313.66-.45,1a.538.538,0,0,0,0,.359C-541.329,122.816-541.191,123.082-541.048,123.393Z" transform="translate(-275.433)" />
    //                           <path id="Path_9345" data-name="Path 9345" d="M-572.6,143.719l.894.413c-.146.316-.3.612-.417.923a.636.636,0,0,0,0,.414,8.135,8.135,0,0,0,.411.892.459.459,0,0,1,0,.474c-.119.223-.193.471-.327.683a.706.706,0,0,0,.007.829c.035.055.048.124.086.176a1.224,1.224,0,0,1-.122,1.636l-.909-.249a1.13,1.13,0,0,0-.066-1.394.963.963,0,0,1,.007-1.181,1.17,1.17,0,0,0,0-1.485.982.982,0,0,1-.006-1.183A9.628,9.628,0,0,0-572.6,143.719Z" transform="translate(-246.436 -26.648)" />
    //                           <path id="Path_9346" data-name="Path 9346" d="M-510.176,141.8c.124-.271.266-.536.366-.816a.733.733,0,0,0,0-.437,3.045,3.045,0,0,0-.3-.662.781.781,0,0,1,.014-.934,1.359,1.359,0,0,0,0-1.718.807.807,0,0,1-.005-.958c.189-.331.329-.69.5-1.057l.892.413c-.152.33-.305.638-.433.957a.51.51,0,0,0,0,.335,3.342,3.342,0,0,0,.306.661.824.824,0,0,1,0,1.006,1.4,1.4,0,0,0-.018,1.672c.324.548.289.586-.225.958-.33.239-.689.437-1.035.653Z" transform="translate(-305.88 -18.622)" />
    //                           <path id="Path_9347" data-name="Path 9347" d="M-606.581,138.291a7.717,7.717,0,0,1-.648-.585c-.085-.095-.075-.267-.131-.4-.142-.326-.288-.65-.451-.966a.416.416,0,0,1,0-.418c.154-.31.305-.622.433-.942a.533.533,0,0,0,0-.36c-.131-.33-.3-.643-.434-.972a.515.515,0,0,1,0-.356c.192-.454.407-.9.623-1.367l.892.418c-.147.319-.3.609-.413.912a.637.637,0,0,0,0,.413,8.732,8.732,0,0,0,.409.892.47.47,0,0,1,.005.474c-.146.282-.26.58-.408.861a.456.456,0,0,0-.006.472c.144.273.248.566.394.837a.478.478,0,0,1,0,.523C-606.41,137.9-606.481,138.079-606.581,138.291Z" transform="translate(-213.765 -15.52)" />
    //                           <path id="Path_9348" data-name="Path 9348" d="M-475.642,127.83a4.572,4.572,0,0,1-.581,2.224c-.162-.356-.312-.671-.445-.993-.022-.053.025-.143.055-.209.142-.315.3-.623.428-.945a.63.63,0,0,0,0-.414c-.119-.314-.291-.607-.411-.921a.611.611,0,0,1,0-.413c.186-.447.4-.881.616-1.338l.892.413c-.151.328-.3.626-.424.938a.575.575,0,0,0,0,.382c.13.33.293.647.439.971.119.264.092.3-.206.3C-475.386,127.831-475.5,127.83-475.642,127.83Z" transform="translate(-337.573 -8.813)" />
    //                           <path id="Path_9349" data-name="Path 9349" d="M-635.867,115.927c-.159.347-.311.657-.441.975a.445.445,0,0,0,0,.307c.139.336.312.659.448,1a.527.527,0,0,1,.005.36,6.032,6.032,0,0,1-.509.989l-.052-.5h-.7c0-.055-.011-.091,0-.108a.943.943,0,0,0-.046-1.2,1.127,1.127,0,0,1-.016-1.361,6.838,6.838,0,0,0,.413-.865Z" transform="translate(-185.897 -0.037)" />
    //                           <path id="Path_9350" data-name="Path 9350" d="M-505.138,195.742a5.265,5.265,0,0,1-3.731,5.233C-506.491,200.041-505.454,198.149-505.138,195.742Z" transform="translate(-307.19 -75.742)" />
    //                           <path id="Path_9351" data-name="Path 9351" d="M-630.772,199.571c-2.154-.379-4.086-2.842-3.851-4.541A5.525,5.525,0,0,0-630.772,199.571Z" transform="translate(-188.496 -75.07)" />
    //                           <path id="Path_9352" data-name="Path 9352" d="M-517.779,312.4c.637-.309,1.287-.632,1.31-1.455s-.6-1.16-1.255-1.486a1.743,1.743,0,0,1,1.461,1.416A1.756,1.756,0,0,1-517.779,312.4Z" transform="translate(-298.781 -183.053)" />
    //                           <path id="Path_9353" data-name="Path 9353" d="M-783.067,298.7h1.372A.928.928,0,0,1-783.067,298.7Z" transform="translate(-48.426 -172.907)" />
    //                           <path id="Path_9354" data-name="Path 9354" d="M-725.134,298.722h1.4A.96.96,0,0,1-725.134,298.722Z" transform="translate(-103.098 -172.925)" />
    //                           <path id="Path_9355" data-name="Path 9355" d="M-696.754,298.577h1.4C-695.677,298.976-696.068,298.987-696.754,298.577Z" transform="translate(-129.881 -172.789)" />
    //                           <path id="Path_9356" data-name="Path 9356" d="M-754.093,298.7h1.376A.934.934,0,0,1-754.093,298.7Z" transform="translate(-75.769 -172.907)" />
    //                           <path id="Path_9357" data-name="Path 9357" d="M-679.645,312h-1.425C-680.688,311.607-680.352,311.6-679.645,312Z" transform="translate(-144.682 -185.173)" />
    //                           <path id="Path_9358" data-name="Path 9358" d="M-709.045,311.87h-1.424C-709.989,311.465-709.676,311.461-709.045,311.87Z" transform="translate(-116.938 -185.045)" />
    //                         </g>
    //                       </svg></i> My Recipes
    //                     </Link>
    //                   </li>

    //                   <li className="nav-item">
    //                     <Link className="nav-link" to="/shared-recipes">
    //                       <i><svg xmlns="http://www.w3.org/2000/svg" width="20.908" height="17.431" viewBox="0 0 20.908 17.431">
    //                         <g id="Group_4201" data-name="Group 4201" transform="translate(-4978.529 140.166)">
    //                           <path id="Path_10694" data-name="Path 10694" d="M5309.268,40.676a17.426,17.426,0,0,1-1.514,1.063,17.3,17.3,0,0,1-1.711.751.9.9,0,0,1-.168.035,7.213,7.213,0,0,0-4.324-5.676c.133-.181.255-.358.39-.525a.163.163,0,0,1,.152-.011,3.2,3.2,0,0,0,1.415.4,2.992,2.992,0,0,0,1.706-.449,5.265,5.265,0,0,0,.684-.511.242.242,0,0,1,.32-.044,5.559,5.559,0,0,1,2.913,3.943c.048.245.056.5.086.746.01.079.033.157.05.235Z" transform="translate(-309.831 -168.654)" />
    //                           <path id="Path_10695" data-name="Path 10695" d="M4978.529,40.548c.032-.247.057-.5.1-.741a5.56,5.56,0,0,1,2.972-4.191c.146-.08.219-.017.314.07a3.5,3.5,0,0,0,1.671.881,2.968,2.968,0,0,0,2.045-.311c.146-.077.221-.067.306.076a4.93,4.93,0,0,0,.323.434,7.307,7.307,0,0,0-2.919,2.264,7.217,7.217,0,0,0-1.409,3.435c-.332-.118-.65-.218-.959-.345a8.143,8.143,0,0,1-2.308-1.436,1.5,1.5,0,0,0-.132-.094Z" transform="translate(0 -168.568)" />
    //                           <path id="Path_10696" data-name="Path 10696" d="M5085.68,83.028a6.316,6.316,0,0,1,2.161,1.692,6.4,6.4,0,0,1,1.489,3.987.319.319,0,0,1-.095.221,9.6,9.6,0,0,1-11.755.761c-.326-.22-.626-.478-.942-.714a.309.309,0,0,1-.136-.293,6.4,6.4,0,0,1,3.526-5.6c.136-.075.205-.027.3.058a3.994,3.994,0,0,0,2.186,1.093,3.422,3.422,0,0,0,2.335-.52C5085.067,83.514,5085.359,83.266,5085.68,83.028Z" transform="translate(-93.876 -214.079)" />
    //                           <path id="Path_10697" data-name="Path 10697" d="M5150.493-109.2a2.711,2.711,0,0,1-1.723-.963,5.4,5.4,0,0,1-1.567-3.681,3.517,3.517,0,0,1,2.662-3.483,3.56,3.56,0,0,1,4.439,2.887,4.486,4.486,0,0,1-.377,2.5,5.052,5.052,0,0,1-1.667,2.222A2.7,2.7,0,0,1,5150.493-109.2Z" transform="translate(-161.786 -21.797)" />
    //                           <path id="Path_10698" data-name="Path 10698" d="M5044.9-133.225a1.994,1.994,0,0,1-2.145.107,3.949,3.949,0,0,1-1.563-1.729,4.558,4.558,0,0,1-.549-2.21,3.118,3.118,0,0,1,2.857-2.965,3.08,3.08,0,0,1,2.532.978.709.709,0,0,1,.054.061.224.224,0,0,1,.021.053,4.211,4.211,0,0,0-1.652,2.616A5.336,5.336,0,0,0,5044.9-133.225Z" transform="translate(-59.579 -0.13)" />
    //                           <path id="Path_10699" data-name="Path 10699" d="M5295.65-133.334c.9-2.195.723-4.148-1.209-5.747a2.887,2.887,0,0,1,1.092-.817,3.107,3.107,0,0,1,4.318,2.234,3.779,3.779,0,0,1-.3,2.267,4.121,4.121,0,0,1-1.745,2.139A2.008,2.008,0,0,1,5295.65-133.334Z" transform="translate(-303.012 0)" />
    //                         </g>
    //                       </svg></i> Shared Recipes
    //                     </Link>
    //                   </li>

    //                   <li className="nav-item">
    //                     <a className="nav-link" href="#sidebarBasics" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarBasics">
    //                       <i className="bi bi-gear"></i> Account
    //                     </a>
    //                     <div className="collapse " id="sidebarBasics">
    //                       <ul className="nav nav-sm flex-column">
    //                         <li className="nav-item">
    //                           <Link to="/edit-profile" className="nav-link ">
    //                             Edit Profile
    //                           </Link>
    //                         </li>
    //                         <li className="nav-item">
    //                           <Link to="/my-recipes" className="nav-link ">
    //                             My recipes
    //                           </Link>
    //                         </li>
    //                         <li className="nav-item">
    //                           <Link to="/" className="nav-link ">
    //                             My community
    //                           </Link>
    //                         </li>
    //                         <li className="nav-item">
    //                           <Link href="" className="nav-link ">
    //                             Upgrade plan
    //                           </Link>
    //                         </li>
    //                         <li className="nav-item">
    //                           <Link to="/my-business-sites" className="nav-link ">
    //                             My Business Sites
    //                           </Link>
    //                         </li>
    //                       </ul>
    //                     </div>
    //                   </li>

    //                   <li className="nav-item">
    //                     <Link className="nav-link" >
    //                       <i className="bi bi-bell"></i> Notification
    //                     </Link>
    //                   </li>
    //                 </ul>

    //                 <div className="mt-auto" />

    //                 <a href=""><i className="bi bi-box-arrow-right logout" onClick={() => { removeToken() }}></i></a>

    //                 {/* <div className="navbar-user d-none d-md-flex" id="sidebarUser">

    //           <a className="navbar-user-link" data-bs-toggle="offcanvas" href="#sidebarOffcanvasActivity" aria-controls="sidebarOffcanvasActivity">
    //             <span className="icon">
    //               <i className="fe fe-bell" />
    //             </span>
    //           </a>

    //           <div className="dropup">

    //             <a href="#" id="sidebarIconCopy" className="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    //               <div className="avatar avatar-sm avatar-online">
    //                 <img src="./assets/img/avatars/profiles/avatar-1.jpg" className="avatar-img rounded-circle" alt="..." />
    //               </div>
    //             </a>

    //             <div className="dropdown-menu" aria-labelledby="sidebarIconCopy">
    //               <a href="./profile-posts.html" className="dropdown-item">Profile</a>
    //               <a href="./account-general.html" className="dropdown-item">Settings</a>
    //               <hr className="dropdown-divider" />
    //               <a href="./sign-in.html" className="dropdown-item">Logout</a>
    //             </div>
    //           </div>

    //           <a className="navbar-user-link" data-bs-toggle="offcanvas" href="#sidebarOffcanvasSearch" aria-controls="sidebarOffcanvasSearch">
    //             <span className="icon">
    //               <i className="fe fe-search" />
    //             </span>
    //           </a>
    //         </div> */}
    //               </div>
    //             </div>
    //           </nav>
    //         </>
    //       )
    //       :
    //       (
    //         <>
    //           <nav className="navbar navbar-vertical fixed-start navbar-expand-md navbar-small navbar-light" id="sidebar" style={{ width: "6% !important" }}>
    //             <div className="container-fluid">
    //               {/* Toggler */}
    //               <button className="navbar-toggler d-block" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation"
    //                 onClick={() => { setToggleSidebar(true) }}
    //               >
    //                 <span className="navbar-toggler-icon" />
    //               </button>
    //               {/* Brand */}
    //               <a className="navbar-brand" href="/">
    //                 <img className="navbar-brand-img mx-auto" src={process.env.PUBLIC_URL + "/assets/images/logo.svg"} />
    //               </a>
    //               {/* User (xs) */}
    //               {/* <div className="navbar-user d-md-none">
    //         <div className="dropdown">
    //           <a href="#" id="sidebarIcon" className="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    //             <div className="avatar avatar-sm avatar-online">
    //               <img src="./assets/img/avatars/profiles/avatar-1.jpg" className="avatar-img rounded-circle" alt="..." />
    //             </div>
    //           </a>
    //           <div className="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarIcon">
    //             <a href="./profile-posts.html" className="dropdown-item">Profile</a>
    //             <a href="./account-general.html" className="dropdown-item">Settings</a>
    //             <hr className="dropdown-divider" />
    //             <a href="./sign-in.html" className="dropdown-item">Logout</a>
    //           </div>
    //         </div>
    //       </div> */}
    //               {/* Collapse */}
    //               <div className="collapse navbar-collapse" id="sidebarCollapse">
    //                 {/* Navigation */}
    //                 <ul className="navbar-nav">
    //                   <li className="nav-item">
    //                     <Link className="nav-link" to="/"  >
    //                       <i className='active'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="16.959" viewBox="0 0 18 16.959">
    //                         <path id="Path_10716" data-name="Path 10716" d="M72.4,973.8c-.036-.031-.312-.173-.664-.349a3.877,3.877,0,0,0-3.472,0c-.353.176-.628.318-.664.349l-5.221,4.528A4,4,0,0,0,61,981.35v6.85a1.8,1.8,0,0,0,1.8,1.8h4.8a.6.6,0,0,0,.6-.6v-3.6a1.8,1.8,0,1,1,3.6,0v3.6a.6.6,0,0,0,.6.6h4.8a1.8,1.8,0,0,0,1.8-1.8v-6.85a4,4,0,0,0-1.379-3.022Z" transform="translate(-61 -973.041)" fill="#fd3468" />
    //                       </svg></i>
    //                     </Link>
    //                   </li>
    //                   <li className="nav-item" >
    //                     <Link className="nav-link" to="/my-recipes" >
    //                       <i><svg xmlns="http://www.w3.org/2000/svg" width="23.481" height="15.727" viewBox="0 0 23.481 15.727">
    //                         <g id="Group_4200" data-name="Group 4200" transform="translate(834.382 -115.482)">
    //                           <path id="Path_9340" data-name="Path 9340" d="M-659.591,182.15h2.074a4.754,4.754,0,0,0,1.8,3.686,4.559,4.559,0,0,0,2.91,1.031,4.6,4.6,0,0,0,3.369-1.376,4.687,4.687,0,0,0,1.406-3.338h2.083c-.088.322-.169.625-.254.927q-1.436,5.077-2.868,10.156a.342.342,0,0,1-.4.305q-3.306-.016-6.612,0a.324.324,0,0,1-.382-.285q-1.526-5.431-3.07-10.857C-659.551,182.327-659.566,182.254-659.591,182.15Zm12.215.765c-.316,2.406-1.353,4.3-3.731,5.233A5.265,5.265,0,0,0-647.376,182.915Zm-6.94,4.5a5.525,5.525,0,0,1-3.851-4.54C-658.4,184.574-656.47,187.037-654.316,187.415Zm2.708,4.842a1.756,1.756,0,0,0,1.516-1.526,1.743,1.743,0,0,0-1.461-1.416c.651.326,1.279.672,1.256,1.486S-650.972,191.949-651.608,192.258Z" transform="translate(-164.952 -62.915)" />
    //                           <path id="Path_9341" data-name="Path 9341" d="M-818.706,276.466h-2.107a.98.98,0,0,0,1.418.072l.265.565-.823.4.013.05c.3,0,.592,0,.888,0a.635.635,0,0,1,.675.636.63.63,0,0,1-.676.661q-5.105,0-10.211,0a.631.631,0,0,1-.68-.658.637.637,0,0,1,.673-.639c.29-.006.581,0,.871-.008.007,0,.013-.008.053-.035l-.841-.4.252-.558a.98.98,0,0,0,1.371-.03,1.139,1.139,0,0,0-.162-.024c-.5,0-.994,0-1.491,0-.449,0-.723-.246-.726-.639a.649.649,0,0,1,.711-.663q4.979,0,9.957-.005a.23.23,0,0,1,.269.206C-818.923,275.727-818.821,276.058-818.706,276.466Zm-8.6.044a.928.928,0,0,0,1.372,0Zm3.261,0a.96.96,0,0,0,1.4,0Zm1.6-.008c.685.409,1.077.4,1.4,0Zm-3.228.007a.934.934,0,0,0,1.377,0Zm5.536,1.027c-.707-.4-1.043-.388-1.425,0Zm-1.656,0c-.631-.41-.944-.405-1.424,0Z" transform="translate(-4.188 -150.714)" />
    //                           <path id="Path_9342" data-name="Path 9342" d="M-828.346,344.307c1.876,0,3.752,0,5.627-.006a.318.318,0,0,1,.375.3,1.093,1.093,0,0,1-.355,1.015,4.026,4.026,0,0,1-1.727.961,13.484,13.484,0,0,1-5.393.493,10.494,10.494,0,0,1-2.737-.59,3.76,3.76,0,0,1-1.458-.872,1.086,1.086,0,0,1-.35-.99.33.33,0,0,1,.39-.32C-832.1,344.312-830.222,344.307-828.346,344.307Z" transform="translate(0 -215.938)" />
    //                           <path id="Path_9343" data-name="Path 9343" d="M-823.008,223.636h-.314c-3.44,0-6.88,0-10.32.007-.284,0-.425-.112-.4-.357a1.776,1.776,0,0,1,.206-.759,2.579,2.579,0,0,1,1.225-.906,9.155,9.155,0,0,1,2.873-.723,14.208,14.208,0,0,1,5.1.313c1.024.268,1.024.268,1.312,1.3C-823.223,222.876-823.119,223.242-823.008,223.636Z" transform="translate(-0.315 -99.393)" />
    //                           <path id="Path_9344" data-name="Path 9344" d="M-541.048,123.393a6.385,6.385,0,0,0-.674.128c-.284.086-.361-.071-.5-.283a1.426,1.426,0,0,1,.009-1.75.739.739,0,0,0,0-.929,1.383,1.383,0,0,1,0-1.744.743.743,0,0,0,0-.93,1.375,1.375,0,0,1-.01-1.722,4.648,4.648,0,0,0,.325-.682l.89.412c-.146.319-.3.619-.419.931a.583.583,0,0,0,0,.384,7.506,7.506,0,0,0,.412.892.5.5,0,0,1-.007.52,8.214,8.214,0,0,0-.4.868.625.625,0,0,0,0,.408c.128.331.3.646.433.975a.446.446,0,0,1,0,.31c-.14.337-.313.66-.45,1a.538.538,0,0,0,0,.359C-541.329,122.816-541.191,123.082-541.048,123.393Z" transform="translate(-275.433)" />
    //                           <path id="Path_9345" data-name="Path 9345" d="M-572.6,143.719l.894.413c-.146.316-.3.612-.417.923a.636.636,0,0,0,0,.414,8.135,8.135,0,0,0,.411.892.459.459,0,0,1,0,.474c-.119.223-.193.471-.327.683a.706.706,0,0,0,.007.829c.035.055.048.124.086.176a1.224,1.224,0,0,1-.122,1.636l-.909-.249a1.13,1.13,0,0,0-.066-1.394.963.963,0,0,1,.007-1.181,1.17,1.17,0,0,0,0-1.485.982.982,0,0,1-.006-1.183A9.628,9.628,0,0,0-572.6,143.719Z" transform="translate(-246.436 -26.648)" />
    //                           <path id="Path_9346" data-name="Path 9346" d="M-510.176,141.8c.124-.271.266-.536.366-.816a.733.733,0,0,0,0-.437,3.045,3.045,0,0,0-.3-.662.781.781,0,0,1,.014-.934,1.359,1.359,0,0,0,0-1.718.807.807,0,0,1-.005-.958c.189-.331.329-.69.5-1.057l.892.413c-.152.33-.305.638-.433.957a.51.51,0,0,0,0,.335,3.342,3.342,0,0,0,.306.661.824.824,0,0,1,0,1.006,1.4,1.4,0,0,0-.018,1.672c.324.548.289.586-.225.958-.33.239-.689.437-1.035.653Z" transform="translate(-305.88 -18.622)" />
    //                           <path id="Path_9347" data-name="Path 9347" d="M-606.581,138.291a7.717,7.717,0,0,1-.648-.585c-.085-.095-.075-.267-.131-.4-.142-.326-.288-.65-.451-.966a.416.416,0,0,1,0-.418c.154-.31.305-.622.433-.942a.533.533,0,0,0,0-.36c-.131-.33-.3-.643-.434-.972a.515.515,0,0,1,0-.356c.192-.454.407-.9.623-1.367l.892.418c-.147.319-.3.609-.413.912a.637.637,0,0,0,0,.413,8.732,8.732,0,0,0,.409.892.47.47,0,0,1,.005.474c-.146.282-.26.58-.408.861a.456.456,0,0,0-.006.472c.144.273.248.566.394.837a.478.478,0,0,1,0,.523C-606.41,137.9-606.481,138.079-606.581,138.291Z" transform="translate(-213.765 -15.52)" />
    //                           <path id="Path_9348" data-name="Path 9348" d="M-475.642,127.83a4.572,4.572,0,0,1-.581,2.224c-.162-.356-.312-.671-.445-.993-.022-.053.025-.143.055-.209.142-.315.3-.623.428-.945a.63.63,0,0,0,0-.414c-.119-.314-.291-.607-.411-.921a.611.611,0,0,1,0-.413c.186-.447.4-.881.616-1.338l.892.413c-.151.328-.3.626-.424.938a.575.575,0,0,0,0,.382c.13.33.293.647.439.971.119.264.092.3-.206.3C-475.386,127.831-475.5,127.83-475.642,127.83Z" transform="translate(-337.573 -8.813)" />
    //                           <path id="Path_9349" data-name="Path 9349" d="M-635.867,115.927c-.159.347-.311.657-.441.975a.445.445,0,0,0,0,.307c.139.336.312.659.448,1a.527.527,0,0,1,.005.36,6.032,6.032,0,0,1-.509.989l-.052-.5h-.7c0-.055-.011-.091,0-.108a.943.943,0,0,0-.046-1.2,1.127,1.127,0,0,1-.016-1.361,6.838,6.838,0,0,0,.413-.865Z" transform="translate(-185.897 -0.037)" />
    //                           <path id="Path_9350" data-name="Path 9350" d="M-505.138,195.742a5.265,5.265,0,0,1-3.731,5.233C-506.491,200.041-505.454,198.149-505.138,195.742Z" transform="translate(-307.19 -75.742)" />
    //                           <path id="Path_9351" data-name="Path 9351" d="M-630.772,199.571c-2.154-.379-4.086-2.842-3.851-4.541A5.525,5.525,0,0,0-630.772,199.571Z" transform="translate(-188.496 -75.07)" />
    //                           <path id="Path_9352" data-name="Path 9352" d="M-517.779,312.4c.637-.309,1.287-.632,1.31-1.455s-.6-1.16-1.255-1.486a1.743,1.743,0,0,1,1.461,1.416A1.756,1.756,0,0,1-517.779,312.4Z" transform="translate(-298.781 -183.053)" />
    //                           <path id="Path_9353" data-name="Path 9353" d="M-783.067,298.7h1.372A.928.928,0,0,1-783.067,298.7Z" transform="translate(-48.426 -172.907)" />
    //                           <path id="Path_9354" data-name="Path 9354" d="M-725.134,298.722h1.4A.96.96,0,0,1-725.134,298.722Z" transform="translate(-103.098 -172.925)" />
    //                           <path id="Path_9355" data-name="Path 9355" d="M-696.754,298.577h1.4C-695.677,298.976-696.068,298.987-696.754,298.577Z" transform="translate(-129.881 -172.789)" />
    //                           <path id="Path_9356" data-name="Path 9356" d="M-754.093,298.7h1.376A.934.934,0,0,1-754.093,298.7Z" transform="translate(-75.769 -172.907)" />
    //                           <path id="Path_9357" data-name="Path 9357" d="M-679.645,312h-1.425C-680.688,311.607-680.352,311.6-679.645,312Z" transform="translate(-144.682 -185.173)" />
    //                           <path id="Path_9358" data-name="Path 9358" d="M-709.045,311.87h-1.424C-709.989,311.465-709.676,311.461-709.045,311.87Z" transform="translate(-116.938 -185.045)" />
    //                         </g>
    //                       </svg></i>
    //                     </Link>
    //                   </li>

    //                   <li className="nav-item">
    //                     <Link className="nav-link" to="/shared-recipes">
    //                       <i><svg xmlns="http://www.w3.org/2000/svg" width="20.908" height="17.431" viewBox="0 0 20.908 17.431">
    //                         <g id="Group_4201" data-name="Group 4201" transform="translate(-4978.529 140.166)">
    //                           <path id="Path_10694" data-name="Path 10694" d="M5309.268,40.676a17.426,17.426,0,0,1-1.514,1.063,17.3,17.3,0,0,1-1.711.751.9.9,0,0,1-.168.035,7.213,7.213,0,0,0-4.324-5.676c.133-.181.255-.358.39-.525a.163.163,0,0,1,.152-.011,3.2,3.2,0,0,0,1.415.4,2.992,2.992,0,0,0,1.706-.449,5.265,5.265,0,0,0,.684-.511.242.242,0,0,1,.32-.044,5.559,5.559,0,0,1,2.913,3.943c.048.245.056.5.086.746.01.079.033.157.05.235Z" transform="translate(-309.831 -168.654)" />
    //                           <path id="Path_10695" data-name="Path 10695" d="M4978.529,40.548c.032-.247.057-.5.1-.741a5.56,5.56,0,0,1,2.972-4.191c.146-.08.219-.017.314.07a3.5,3.5,0,0,0,1.671.881,2.968,2.968,0,0,0,2.045-.311c.146-.077.221-.067.306.076a4.93,4.93,0,0,0,.323.434,7.307,7.307,0,0,0-2.919,2.264,7.217,7.217,0,0,0-1.409,3.435c-.332-.118-.65-.218-.959-.345a8.143,8.143,0,0,1-2.308-1.436,1.5,1.5,0,0,0-.132-.094Z" transform="translate(0 -168.568)" />
    //                           <path id="Path_10696" data-name="Path 10696" d="M5085.68,83.028a6.316,6.316,0,0,1,2.161,1.692,6.4,6.4,0,0,1,1.489,3.987.319.319,0,0,1-.095.221,9.6,9.6,0,0,1-11.755.761c-.326-.22-.626-.478-.942-.714a.309.309,0,0,1-.136-.293,6.4,6.4,0,0,1,3.526-5.6c.136-.075.205-.027.3.058a3.994,3.994,0,0,0,2.186,1.093,3.422,3.422,0,0,0,2.335-.52C5085.067,83.514,5085.359,83.266,5085.68,83.028Z" transform="translate(-93.876 -214.079)" />
    //                           <path id="Path_10697" data-name="Path 10697" d="M5150.493-109.2a2.711,2.711,0,0,1-1.723-.963,5.4,5.4,0,0,1-1.567-3.681,3.517,3.517,0,0,1,2.662-3.483,3.56,3.56,0,0,1,4.439,2.887,4.486,4.486,0,0,1-.377,2.5,5.052,5.052,0,0,1-1.667,2.222A2.7,2.7,0,0,1,5150.493-109.2Z" transform="translate(-161.786 -21.797)" />
    //                           <path id="Path_10698" data-name="Path 10698" d="M5044.9-133.225a1.994,1.994,0,0,1-2.145.107,3.949,3.949,0,0,1-1.563-1.729,4.558,4.558,0,0,1-.549-2.21,3.118,3.118,0,0,1,2.857-2.965,3.08,3.08,0,0,1,2.532.978.709.709,0,0,1,.054.061.224.224,0,0,1,.021.053,4.211,4.211,0,0,0-1.652,2.616A5.336,5.336,0,0,0,5044.9-133.225Z" transform="translate(-59.579 -0.13)" />
    //                           <path id="Path_10699" data-name="Path 10699" d="M5295.65-133.334c.9-2.195.723-4.148-1.209-5.747a2.887,2.887,0,0,1,1.092-.817,3.107,3.107,0,0,1,4.318,2.234,3.779,3.779,0,0,1-.3,2.267,4.121,4.121,0,0,1-1.745,2.139A2.008,2.008,0,0,1,5295.65-133.334Z" transform="translate(-303.012 0)" />
    //                         </g>
    //                       </svg></i>
    //                     </Link>
    //                   </li>

    //                   {/* <li className="nav-item">
    //                     <a className="nav-link" href="#sidebarBasics" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarBasics">
    //                       <i className="bi bi-gear"></i>
    //                     </a>
    //                     <div className="collapse " id="sidebarBasics">
    //                       <ul className="nav nav-sm flex-column">
    //                         <li className="nav-item">
    //                           <Link to="/edit-profile" className="nav-link ">
    //                             Edit Profile
    //                           </Link>
    //                         </li>
    //                         <li className="nav-item">
    //                           <Link to="/my-recipes" className="nav-link ">
    //                             My recipes
    //                           </Link>
    //                         </li>
    //                         <li className="nav-item">
    //                           <Link to="/" className="nav-link ">
    //                             My community
    //                           </Link>
    //                         </li>
    //                         <li className="nav-item">
    //                           <Link href="" className="nav-link ">
    //                             Upgrade plan
    //                           </Link>
    //                         </li>
    //                         <li className="nav-item">
    //                           <Link to="/my-business-sites" className="nav-link ">
    //                             My Business Sites
    //                           </Link>
    //                         </li>
    //                       </ul>
    //                     </div>
    //                   </li> */}

    //                   <li className="nav-item">
    //                     <Link className="nav-link" >
    //                       <i className="bi bi-bell"></i>
    //                     </Link>
    //                   </li>
    //                 </ul>

    //                 <div className="mt-auto" />

    //                 <a href=""><i className="bi bi-box-arrow-right logout" onClick={() => { removeToken() }}></i></a>

    //                 {/* <div className="navbar-user d-none d-md-flex" id="sidebarUser">

    //           <a className="navbar-user-link" data-bs-toggle="offcanvas" href="#sidebarOffcanvasActivity" aria-controls="sidebarOffcanvasActivity">
    //             <span className="icon">
    //               <i className="fe fe-bell" />
    //             </span>
    //           </a>

    //           <div className="dropup">

    //             <a href="#" id="sidebarIconCopy" className="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    //               <div className="avatar avatar-sm avatar-online">
    //                 <img src="./assets/img/avatars/profiles/avatar-1.jpg" className="avatar-img rounded-circle" alt="..." />
    //               </div>
    //             </a>

    //             <div className="dropdown-menu" aria-labelledby="sidebarIconCopy">
    //               <a href="./profile-posts.html" className="dropdown-item">Profile</a>
    //               <a href="./account-general.html" className="dropdown-item">Settings</a>
    //               <hr className="dropdown-divider" />
    //               <a href="./sign-in.html" className="dropdown-item">Logout</a>
    //             </div>
    //           </div>

    //           <a className="navbar-user-link" data-bs-toggle="offcanvas" href="#sidebarOffcanvasSearch" aria-controls="sidebarOffcanvasSearch">
    //             <span className="icon">
    //               <i className="fe fe-search" />
    //             </span>
    //           </a>
    //         </div> */}
    //               </div>
    //             </div>
    //           </nav>
    //         </>
    //       )
    //   }
    // </>

    <>
      <nav
        className="navbar navbar-vertical fixed-start navbar-expand-md navbar-light"
        id="sidebar" style={{overflow:"hidden"}}
      >
        <div className="container-fluid">
          {/* Toggler */}
          {/* <button className="navbar-toggler d-block" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation"
                    onClick={() => { setToggleSidebar(false) }}
                  >
                    <span className="navbar-toggler-icon" />
                  </button> */}
          {/* Brand */}
          <a className="navbar-brand" href="/">
            <img
              className="navbar-brand-img mx-auto"
              src={process.env.PUBLIC_URL + "/assets/images/logo.svg"}
            />
          </a>
          {/* User (xs) */}
          {/* <div className="navbar-user d-md-none">
            <div className="dropdown">
              <a href="#" id="sidebarIcon" className="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div className="avatar avatar-sm avatar-online">
                  <img src="./assets/img/avatars/profiles/avatar-1.jpg" className="avatar-img rounded-circle" alt="..." />
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarIcon">
                <a href="./profile-posts.html" className="dropdown-item">Profile</a>
                <a href="./account-general.html" className="dropdown-item">Settings</a>
                <hr className="dropdown-divider" />
                <a href="./sign-in.html" className="dropdown-item">Logout</a>
              </div>
            </div>
          </div> */}
          {/* Collapse */}
          <div className="collapse navbar-collapse" id="sidebarCollapse">
            {/* Navigation */}
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link onClick={()=>setSearchData([])} className="nav-link" to="/">
                  <i className={location.pathname === "/" || location.pathname === "/add-ingredients" ? "active" : ""}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="16.959"
                      viewBox="0 0 18 16.959"
                    >
                      <path
                        id="Path_10716"
                        data-name="Path 10716"
                        d="M72.4,973.8c-.036-.031-.312-.173-.664-.349a3.877,3.877,0,0,0-3.472,0c-.353.176-.628.318-.664.349l-5.221,4.528A4,4,0,0,0,61,981.35v6.85a1.8,1.8,0,0,0,1.8,1.8h4.8a.6.6,0,0,0,.6-.6v-3.6a1.8,1.8,0,1,1,3.6,0v3.6a.6.6,0,0,0,.6.6h4.8a1.8,1.8,0,0,0,1.8-1.8v-6.85a4,4,0,0,0-1.379-3.022Z"
                        transform="translate(-61 -973.041)"

                        fill={location.pathname === "/" || location.pathname === "/add-ingredients" ? "#fd3468" : ""}

                      />
                    </svg>
                  </i>{" "}
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={()=>setSearchData([])} className="nav-link" to="/my-recipes">
                <i className={location.pathname === "/my-recipes" ? "active" : ""}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
              <path fill={location.pathname === "/my-recipes" ? "#fd3468":""} d="M40-360q0-109 91-174.5T340-600q118 0 209 65.5T640-360H40Zm0 160v-80h600v80H40ZM80-40q-17 0-28.5-11.5T40-80v-40h600v40q0 17-11.5 28.5T600-40H80Zm640 0v-320q0-114-78-197T451-668l-11-92h200v-160h80v160h200l-65 648q-3 31-25.5 51.5T776-40h-56Z"/>
              </svg>
              </i>
              {" "}My Recipes
                {/* <img src={ location.pathname === "/my-recipes" ? darkMyRecipe  : myRecipe } className="me-4" /><span className="">My Recipes</span> */}
                  
                </Link>
              </li>

              



              <li className="nav-item">
                <Link onClick={()=>setSearchData([])}  className="nav-link" to="/shared-recipes">
                  <i className={location.pathname === "/shared-recipes" && "active"}>
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20.908"
                      height="17.431"
                      viewBox="0 0 20.908 17.431"
                    >
                      <g
                        id="Group_4201"
                        data-name="Group 4201"
                        transform="translate(-4978.529 140.166)"
                      >
                        <path
                          id="Path_10694"
                          data-name="Path 10694"
                          d="M5309.268,40.676a17.426,17.426,0,0,1-1.514,1.063,17.3,17.3,0,0,1-1.711.751.9.9,0,0,1-.168.035,7.213,7.213,0,0,0-4.324-5.676c.133-.181.255-.358.39-.525a.163.163,0,0,1,.152-.011,3.2,3.2,0,0,0,1.415.4,2.992,2.992,0,0,0,1.706-.449,5.265,5.265,0,0,0,.684-.511.242.242,0,0,1,.32-.044,5.559,5.559,0,0,1,2.913,3.943c.048.245.056.5.086.746.01.079.033.157.05.235Z"
                          transform="translate(-309.831 -168.654)"
                          fill={location.pathname === "/shared-recipes" ? "#fd3468" : ""}
                        />
                        <path
                          id="Path_10695"
                          data-name="Path 10695"Account
                          d="M4978.529,40.548c.032-.247.057-.5.1-.741a5.56,5.56,0,0,1,2.972-4.191c.146-.08.219-.017.314.07a3.5,3.5,0,0,0,1.671.881,2.968,2.968,0,0,0,2.045-.311c.146-.077.221-.067.306.076a4.93,4.93,0,0,0,.323.434,7.307,7.307,0,0,0-2.919,2.264,7.217,7.217,0,0,0-1.409,3.435c-.332-.118-.65-.218-.959-.345a8.143,8.143,0,0,1-2.308-1.436,1.5,1.5,0,0,0-.132-.094Z"
                          transform="translate(0 -168.568)"
                          fill={location.pathname === "/shared-recipes" ? "#fd3468" : ""}
                        />
                        <path
                          id="Path_10696"
                          data-name="Path 10696"
                          d="M5085.68,83.028a6.316,6.316,0,0,1,2.161,1.692,6.4,6.4,0,0,1,1.489,3.987.319.319,0,0,1-.095.221,9.6,9.6,0,0,1-11.755.761c-.326-.22-.626-.478-.942-.714a.309.309,0,0,1-.136-.293,6.4,6.4,0,0,1,3.526-5.6c.136-.075.205-.027.3.058a3.994,3.994,0,0,0,2.186,1.093,3.422,3.422,0,0,0,2.335-.52C5085.067,83.514,5085.359,83.266,5085.68,83.028Z"
                          transform="translate(-93.876 -214.079)"
                          fill={location.pathname === "/shared-recipes" ? "#fd3468" : ""}
                        />
                        <path
                          id="Path_10697"
                          data-name="Path 10697"
                          d="M5150.493-109.2a2.711,2.711,0,0,1-1.723-.963,5.4,5.4,0,0,1-1.567-3.681,3.517,3.517,0,0,1,2.662-3.483,3.56,3.56,0,0,1,4.439,2.887,4.486,4.486,0,0,1-.377,2.5,5.052,5.052,0,0,1-1.667,2.222A2.7,2.7,0,0,1,5150.493-109.2Z"
                          transform="translate(-161.786 -2Account1.797)"
                          fill={location.pathname === "/shared-recipes" ? "#fd3468" : ""}
                        />
                        <path
                          id="Path_10698"
                          data-name="Path 10698"
                          d="M5044.9-133.225a1.994,1.994,0,0,1-2.145.107,3.949,3.949,0,0,1-1.563-1.729,4.558,4.558,0,0,1-.549-2.21,3.118,3.118,0,0,1,2.857-2.965,3.08,3.08,0,0,1,2.532.978.709.709,0,0,1,.054.061.224.224,0,0,1,.021.053,4.211,4.211,0,0,0-1.652,2.616A5.336,5.336,0,0,0,5044.9-133.225Z"
                          transform="translate(-59.579 -0.13)"
                          fill={location.pathname === "/shared-recipes" ? "#fd3468" : ""}
                        />
                        <path
                          id="Path_10699"
                          data-name="Path 10699"
                          d="M5295.65-133.334c.9-2.195.723-4.148-1.209-5.747a2.887,2.887,0,0,1,1.092-.817,3.107,3.107,0,0,1,4.318,2.234,3.779,3.779,0,0,1-.3,2.267,4.121,4.121,0,0,1-1.745,2.139A2.008,2.008,0,0,1,5295.65-133.334Z"
                          transform="translate(-303.012 0)"
                          fill={location.pathname === "/shared-recipes" ? "#fd3468" : ""}
                        />
                      </g>
                    </svg> */}
                    <img src={location.pathname === "/shared-recipes" ? tcfSvg:tcfLightSvg} alt="" />
                  </i>{" "}
                  Shared Recipes
                </Link>
              </li>
              
              <li className="nav-item">
                <a
                  className="nav-link text-center"
                  href="#sidebarBasics"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarBasics"

                >
                  {/* <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" className=" -3"> 
                  <path  d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Z"/>
                  </svg> Account */}
                  {/* <i className=""></i> */}
                <img src={person } className=" pe-3"  style={{paddingLeft: "17px"}}/><span className="ps-4"> Account</span>

                </a>
                <div className={`collapse ${locationForAccountCollapse.includes(location.pathname) ? 'show':""}`} id="sidebarBasics">
                  <ul className="nav nav-sm flex-column" style={{
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: "14px"
                  }}>
                    {userDetails?.siteId === 0 && <li className="nav-item ">
                      <Link
                        onClick={()=>setSearchData([])}
                        to="/edit-profile"
                        state={{ viewMode: false }}
                        className="nav-link "
                      >
                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" className="me-4"> 
                        <path fill={location.pathname === '/edit-profile' ? "#fd3468" :""} d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Z"/>
                        </svg>
                        {" "}Edit Profile
                        {/* <img src={ location.pathname === "/edit-profile" ? person : darkperson} className="me-4" /><span className=""> Edit Profile</span> */}
                      </Link>
                    </li> }
                   {userDetails?.isManager &&  <li className="nav-item">
                      <Link to="/manage-sites" className="nav-link ">
                      <svg xmlns="http://www.w3.org/2000/svg" height="16" width="24"  class="bi bi-brilliance me-4" viewBox="0 0 16 16">
                      <path fill={location.pathname === "/manage-sites" ? "#fd3468" :""} d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16M1 8a7 7 0 0 0 7 7 3.5 3.5 0 1 0 0-7 3.5 3.5 0 1 1 0-7 7 7 0 0 0-7 7"/>
                   </svg>
                      {" "}Manage Sites
                      </Link>
                    </li>}

                    <li className="nav-item">
                      <Link to="/saved-recipes" className="nav-link ">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" className="me-4">
                    <path fill={location.pathname === '/saved-recipes' ? "#fd3468" :""} d="M200-120v-640q0-33 23.5-56.5T280-840h400q33 0 56.5 23.5T760-760v640L480-240 200-120Z"/>
                    </svg>
                      {" "}Saved Items
                        {/* <img src={location.pathname === "/saved-recipes" ? saved : darksaved} className="me-4" /><span className="">Saved Items</span> */}
                      </Link>
                    </li>
                    <li className="nav-item">
                <Link  onClick={()=>setSearchData([])} className="nav-link" to="/communityshared-recipes">
                {/* <img src={(location.pathname === "/myshared-recipes" || location.pathname === "/communityshared-recipes") ? shared : darkshared} className="me-4" />  */}
                {/* <span className="">Shared Recipe</span> */}
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" className="me-4">
                <path fill={['/communityshared-recipes',"/myshared-recipes"].includes(location.pathname) ? "#fd3468" :""} d="M0-240v-63q0-43 44-70t116-27q13 0 25 .5t23 2.5q-14 21-21 44t-7 48v65H0Zm240 0v-65q0-32 17.5-58.5T307-410q32-20 76.5-30t96.5-10q53 0 97.5 10t76.5 30q32 20 49 46.5t17 58.5v65H240Zm540 0v-65q0-26-6.5-49T754-397q11-2 22.5-2.5t23.5-.5q72 0 116 26.5t44 70.5v63H780ZM160-440q-33 0-56.5-23.5T80-520q0-34 23.5-57t56.5-23q34 0 57 23t23 57q0 33-23 56.5T160-440Zm640 0q-33 0-56.5-23.5T720-520q0-34 23.5-57t56.5-23q34 0 57 23t23 57q0 33-23 56.5T800-440Zm-320-40q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T600-600q0 50-34.5 85T480-480Z"/>
                </svg>{" "}Shared Recipes
                </Link>
              </li>
                    <li className="nav-item">
                      <Link to="/my-community" className="nav-link ">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" className="me-4">
                      <path fill={location.pathname === '/my-community' ? "#fd3468" :""} d="M500-482q29-32 44.5-73t15.5-85q0-44-15.5-85T500-798q60 8 100 53t40 105q0 60-40 105t-100 53Zm220 322v-120q0-36-16-68.5T662-406q51 18 94.5 46.5T800-280v120h-80Zm80-280v-80h-80v-80h80v-80h80v80h80v80h-80v80h-80Zm-480-40q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM0-160v-112q0-34 17.5-62.5T64-378q62-31 126-46.5T320-440q66 0 130 15.5T576-378q29 15 46.5 43.5T640-272v112H0Z"/>
                      </svg>
                      {" "}My community
                        {/* <img src={location.pathname === "/my-community" ? community : darkcommunity} className="me-4" /><span className=""> My community</span> */}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="https://thecaloriefinder.com/blog/" target="_blank" className="nav-link ">
                        {/* <img src={blog } className="me-4" /><span className=""> The Blogs</span> */}
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 512 512" className="me-4">
                          <path d="M192 32c0 17.7 14.3 32 32 32c123.7 0 224 100.3 224 224c0 17.7 14.3 32 32 32s32-14.3 32-32C512 128.9 383.1 0 224 0c-17.7 0-32 14.3-32 32zm0 96c0 17.7 14.3 32 32 32c70.7 0 128 57.3 128 128c0 17.7 14.3 32 32 32s32-14.3 32-32c0-106-86-192-192-192c-17.7 0-32 14.3-32 32zM96 144c0-26.5-21.5-48-48-48S0 117.5 0 144V368c0 79.5 64.5 144 144 144s144-64.5 144-144s-64.5-144-144-144H128v96h16c26.5 0 48 21.5 48 48s-21.5 48-48 48s-48-21.5-48-48V144z"/>
                        </svg>{" "}The Blog
                      </Link>
                    </li>
                   {((userDetails?.id==userDetails?.currentSite?.userId && userDetails?.currentSite?.isDefault) || !userDetails?.currentSite) &&   <li className="nav-item">
                      <Link to="/upgrade-plan" className="nav-link ">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" className="me-4">
                    <path fill={location.pathname === '/upgrade-plan' ? "#fd3468" :""} d="M880-720v480q0 33-23.5 56.5T800-160H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720Zm-720 80h640v-80H160v80Zm0 160v240h640v-240H160Zm0 240v-480 480Z"/>
                    </svg>{" "}Subscription plan
                        {/* <img src={location.pathname === "/upgrade-plan" ? plan : darkplan} className="me-4" /><span className="">Subscriptions plan</span> */}
                      </Link>
                    </li> }
                    <li className="nav-item">
                      <Link to="https://thecaloriefinder.com/term-and-conditions/" className="nav-link " target="_blank">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" className="me-4">
                      <path d="M240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520h200L520-800v200Z"/>
                      </svg>
                      {" "}Terms & Conditions
                        {/* <img src={TandC} className="me-4" /><span className="">Terms & Condition </span> */}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="https://thecaloriefinder.com/privacy-policy/" className="nav-link" target="_blank">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" className="me-4">
                        <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-139-35-229.5-159.5T160-516v-244l320-120 320 120v244q0 152-90.5 276.5T480-80Z"/>
                      </svg>
                      {" "}Privacy Policy
                        {/* <img src={privacy} className="me-4" /><span className="">Privacy Policy </span> */}
                      </Link>
                    </li>
                    {userProfileDetails?.data?.data?.accountType ===
                      "personal" ? (
                      ""
                    ) : (
                      <li className="nav-item">
                        <Link to="/my-business-sites" className="nav-link ">
                          <img src={location.pathname === "/my-business-sites" ? bussiness:darksite} className="me-4" /><span className="">My Business Sites</span>
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </li>

              {/* <li className="nav-item">
                <Link className="nav-link" >
                  <i className="bi bi-bell"></i> Notification
                </Link>
              </li> */}
            </ul>

              <div className="mt-auto p-4">
                {userProfileDetails?.data?.data?.playStoreLink && <a href={userProfileDetails?.data?.data?.playStoreLink} target="_blank"><img src="https://calorie-finder.s3.eu-west-2.amazonaws.com/calorie/assets/google.png" className="img-fluid" alt="Google Play Store"/></a>}
                {userProfileDetails?.data?.data?.appleStoreLink && <a href={userProfileDetails?.data?.data?.appleStoreLink} target="_blank"><img src="https://calorie-finder.s3.eu-west-2.amazonaws.com/calorie/assets/apple.png" className="img-fluid mt-2"  alt="Apple App Store"/></a>}
              </div>

            <div className="mt-auto" />

            <a style={{ cursor: "pointer" }} onClick={handleLogout} >

              <span className="logout" style={{ width: "120px" }}>  <i
                className="bi bi-box-arrow-right "
              // onClick={() => {
              //   removeToken();
              // }}
              ></i> <span className="logoutText">Log out</span> </span>
            </a>

            {/* <div className="navbar-user d-none d-md-flex" id="sidebarUser">
          
              <a className="navbar-user-link" data-bs-toggle="offcanvas" href="#sidebarOffcanvasActivity" aria-controls="sidebarOffcanvasActivity">
                <span className="icon">
                  <i className="fe fe-bell" />
                </span>
              </a>
             
              <div className="dropup">
        
                <a href="#" id="sidebarIconCopy" className="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <div className="avatar avatar-sm avatar-online">
                    <img src="./assets/img/avatars/profiles/avatar-1.jpg" className="avatar-img rounded-circle" alt="..." />
                  </div>
                </a>
           
                <div className="dropdown-menu" aria-labelledby="sidebarIconCopy">
                  <a href="./profile-posts.html" className="dropdown-item">Profile</a>
                  <a href="./account-general.html" className="dropdown-item">Settings</a>
                  <hr className="dropdown-divider" />
                  <a href="./sign-in.html" className="dropdown-item">Logout</a>
                </div>
              </div>
             
              <a className="navbar-user-link" data-bs-toggle="offcanvas" href="#sidebarOffcanvasSearch" aria-controls="sidebarOffcanvasSearch">
                <span className="icon">
                  <i className="fe fe-search" />
                </span>
              </a>
            </div> */}
          </div>
        </div>
      </nav>
    </>
  );
}

export default Sidebar;
