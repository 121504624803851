import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../Contexts/Contexts'
import { assignProfilePicBucketUrl } from '../Utils/HelperFuntions'
import ProfilePicdemo from "../Assets/Images/person.png"
import { useLocation, useNavigate } from 'react-router'
import { allRecipesForHome, allSharedRecipeApi, getAllMyRecipes, getAllTcfSharedRecipe } from '../Api/Apis'
import { useHistory } from 'react-router-dom';
import {debounce} from 'lodash'
const MainSearch = () => {

  const { userProfileDetails  ,setPCount,searchData, setSearchData,setSearchInpt } = useContext(UserContext)
  const userDetails = userProfileDetails?.data?.data
  const navigate = useNavigate()
  const location = useLocation();
  // const [search,setSearch]=useState();

  const LIMIT = 30;
  
  const handleSearch = (e)=>{

    getSearchData(e.target.value);
  }
  const debouncedOnChange = debounce(handleSearch, 500);

  const getHeadings = (path)=>{
    if(path === "/add-ingredients"){
      return <h1> {location?.state?.edit ? "Modify" :"Add"} Ingredient</h1>
    }else if(path === "/view-recipes"){
      return <h1><span style={{cursor:"pointer"}} onClick={() => navigate(-1)}><i class="bi bi-caret-left-fill"></i></span> Recipe Details</h1>
    }else if(path ==="/edit-profile"){
      return <h1>Profile</h1>
    }else if(path==="/my-community"){
      return <h1>Community</h1>
    }else if(path ==="/upgrade-plan"){
      return <h1>Plans</h1>
    }else if(path === "/saved-recipes"){
      return <h1>Saved Items</h1>
    }else if(path==="/my-business-sites"){
      return <h1>Business Sites</h1>
    }else if(path === "/my-recipes"){
      return <h1>My Recipes</h1>
    }else if(path === "/myshared-recipes"){
      return <h1>My Shared Recipe</h1>
    }else if(path==="/manage-sites"){
      return <h1>Invitations</h1>
    }else{
      return false
    }
  }

  const getSearchData =async(data)=>{
    if(location.pathname === "/"){
      try {
        const res = await allRecipesForHome(data,0,LIMIT);
        if (res?.status === 200) {
          setSearchData(res?.data?.data);
          setPCount(Math.ceil(res?.data?.totalLength / LIMIT))
        }
        setSearchInpt(data)

      } catch (error) {
        // setSearchData((v) => ({ ...v, loading: false }));`
        console.log(error)
      }
    }else if(location.pathname === "/shared-recipes"){
      const res = await getAllTcfSharedRecipe(data,0,LIMIT)
      if(res.status === 200 || res.status === 201 ){
        setSearchData(res?.data)
        setPCount(Math.ceil(res?.data?.totalLength / LIMIT))
      }
    }
  }
  // useEffect(()=>{
  //   getSearchData(search)
  // },[search])
  return (
    <>
      <div className="d-flex justify-content-between">
        <div style={{ width: "-webkit-fill-available" }}>
          {
            getHeadings(location.pathname) ==false ? <div className="main-search pe-4">
            <input
              type="text"
              className="form-control pt-3 pb-3 ps-5"
              name=""
              id=""
              // value={search}
              onChange={debouncedOnChange}
              // onChange={handleSearch}
              placeholder={location.pathname === "/"?"Search the database":"Search The CalorieFinder"}
            />
            <img
              className="serach-svg"
              src="./assets/images/Search.svg"
              alt=""
              srcSet=""
            />
          </div> : getHeadings(location.pathname)
          }
         
        </div>
        {userDetails?.siteId === 0 &&  <div className="" onClick={() => { navigate("/edit-profile" ,{state:{viewMode:true}}) }} style={{ "cursor": "pointer" }}>
          <div className='border-profile-pic'>
            <div className="avatar avatar-md">
              <img
                src={`${userProfileDetails?.data?.data?.profilePic ? assignProfilePicBucketUrl(userProfileDetails?.data?.data?.profilePic) : ""}`}
                className="avatar-img rounded-circle"
                alt="..."
                onError={(e) => {
                  e.target.src = ProfilePicdemo
                  e.onError = null
                }}
              />
            </div>
          </div>
        </div>}
      </div>
    </>
  )
}

export default MainSearch
