import axios from "axios";
import { API_BASE_URL } from "../Utils/BaseUrl";
import { getToken } from "../Utils/HelperFuntions";

axios.interceptors.response.use((response) => response, (error) => {
   // console.log(error, "OOOOOOOOOOOoo")
   if ((error?.response?.data?.status === 401) || error?.response?.status === 401) {

      if(error?.response?.data?.status !== -1){

         localStorage.clear()
         sessionStorage.clear()
         // console.log("interceptor rannnnnnnnn")
         window.location.href = "/sign-in"
      }
   } else {
      throw error
   }
});

export const postApiWithoutToken = (path, data) => {
   return axios.post(API_BASE_URL + path, data, {
      headers: {
         "Content-Type": "application/json",
      },
   });
};

export const putApiWithoutToken = (path, data) => {
   return axios.put(API_BASE_URL + path, data, {
      headers: {
         "Content-Type": "application/json",
      },
   });
};

export const postApiWithToken = (path, data) => {
   const token = getToken()

   return axios.post(API_BASE_URL + path, data, {
      headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${token}`,
      },
   });
};

export const getApiWithToken = (path) => {
   const token = getToken()
   // console.log(token, "tokennnnnnnnnnn");

   return axios.get(API_BASE_URL + path, {
      headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${token}`,
      },
   });
};

export const imgUploadApi = (path, data) => {
   const token = getToken()

   return axios.post(API_BASE_URL + path, data, {
      headers: {
         "Content-Type": "multipart/form-data",
         Authorization: `Bearer ${token}`,
      },
   });
};

export const getApiWithoutToken = (path) => {
   return axios.get(API_BASE_URL + path, {
      headers: {
         "Content-Type": "application/json",
      },
   });
};

export const putApiWithToken = (path, data) => {
   const token = getToken()

   return axios.put(API_BASE_URL + path, data, {
      headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${token}`,
      },
   });
};

export const deleteApi = (path, data) => {
   const token = getToken()
   return axios.delete(API_BASE_URL + path, {
      data: data,
      headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${token}`,
      }
   });
};
