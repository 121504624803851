import React, { useContext, useEffect, useRef, useState } from "react";
import AppLayout from "../../AppLayout/AppLayout";
import { useLocation, useNavigate } from "react-router";
import {
  allRecipesForHome,
  downloadRecipeApi,
  getAllIngridientsById,
  getAllPendingIngredients,
  getRecipieByIdApi,
  saveInggredientApi,
  savedRecipieApi,
  shareIngredientApi,
  shareRecipeApi,
} from "../../Api/Apis";
import { ThemeSpinner } from "../../Utils/Loaders";
// import moment from "moment";
import ProfilePicDemo from "../../Assets/Images/Group 10775.png";
import { BUCKET_URL } from "../../Utils/BaseUrl";
// import chart_image from "../../Assets/Images/person.png";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import Default from "../Default";
// import { saveAs } from "file-saver";
import { UserContext } from "../../Contexts/Contexts";
import ReactPaginate from "react-paginate";
import { WhiteLoader } from "../Loader/Loader";
// import EdirBtn from "../../Assets/Images/";
import CommunityShareModal from "../Common/CommunityShareModal";
import { chartFooterText, getChartNutrientsValue } from "../../Utils/HelperFuntions";
const Home = () => {
  const [allRecipes, setAllRecipes] = useState({ data: null, loading: true });
  const [singleIngredient, setSingleIngredient] = useState();
  const [singleRecipe, setSingleRecipe] = useState();
  const [borderId, setBorderId] = useState();
  const [quantity, setQuantity] = useState();
  const [toggle, setToggle] = useState(false);
  const { searchData, pCount,searchInpt,setSearchInpt } = useContext(UserContext);
  const [sortData, setSortData] = useState([]);
  const [pendingIngredients, setPendingIngredients] = useState([]);
  const [isRecipe, setIsRecipe] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [calorieServing, setCalorieServing] = useState();
  const limit = 30;
  const [downloadLoader, setDownloadLoader] = useState(false)
  const [proximates, setProximates] = useState({
    minerals: [],
    vitamins: [],
    nutrients: [],
  });
  const [recipeServing,setRecipeServing] = useState("")
  const [recipeNote,setRecipeNote] = useState("")
  const [currentPage, setCurrentPage] = useState(0);
  const [ingredientDetails, setIngredientDetails] = useState()
  const [communityShareLoader,setCommunityShareLoader] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  const scrollRef = useRef(null)

  const fetchAllRecipes = async (search, page, limit) => {
    try {
      setAllRecipes((v) => ({ ...v, loading: true }));
      
      const res = await allRecipesForHome(search, page, limit);
      if (res?.status === 200) {
        setAllRecipes((v) => ({ ...v, data: res?.data?.data, loading: false }));
                setPageCount(Math.ceil(res?.data?.totalLength / limit));
      }
    } catch (error) {
      console.log(error,'fetch all recipes error')
      setAllRecipes((v) => ({ ...v, loading: false }));
    }
  };
  const handlePageClick = (e) => {
    // setLoading(true);
    handleScrollToTop();
    const selectedPage = e.selected;
    setCurrentPage(selectedPage);
    fetchAllRecipes("", selectedPage, limit);
  };

  const handleSaveRecipe = async () => {
    if (singleRecipe?.isSaved === true) {
      let data = {
        recipeId: singleRecipe?.id,
        isSaved: false,
      };
      try {
        const res = await savedRecipieApi(data);
        if (res.status === 200 || res.status === 201) {
          toast.success(res?.data?.message);
          // fetchAllRecipes("", currentPage, limit);
          handleIngredients(singleRecipe?.id);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      let data = {
        recipeId: singleRecipe?.id,
        isSaved: true,
      };
      try {
        const res = await savedRecipieApi(data);
        if (res.status === 200 || res.status === 201) {
          toast.success(res?.data?.message);
          // fetchAllRecipes("", currentPage, limit);
          handleIngredients(singleRecipe?.id);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleShareRecipie = async () => {
    if (singleRecipe) {
      try {
        setCommunityShareLoader(true)
        const res = await shareRecipeApi({ recipeId: singleRecipe.id });
        if (res.status === 200 || res.status === 201) {
          toast.success(res?.data?.message);
          document.getElementById("confirmModal").click();
          handleIngredients(singleRecipe?.id);
        }
        setCommunityShareLoader(false)

      } catch (err) {
        console.log(err);
        setCommunityShareLoader(false)
        toast.error(err.response.data.message);
        document.getElementById("confirmModal").click();
      }
    }
  };
  const handleDownloadRecipe = async () => {
    // const token = localStorage.getItem("token");
    // const headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    // fetch(
    //   `${API_BASE_URL}/recipeAndIngredient/generateRecipesAndIngredientsPdf?recipeId=${singleRecipe.id}&ingredientId=`,
    //   {
    //     headers: headers,
    //   }
    // )
    //   .then((response) =>  console.log(response,"responseeeeeee"))
    //   .then((blob) => {
    //     // Save the blob as a file using FileSaver
    //     // saveAs(blob, "data.pdf");
    //   })
    //   .catch((error) => {
    //     console.error("Error downloading the CSV file:", error);
    //   });

    setDownloadLoader(true)
    try {
      const res = await downloadRecipeApi(singleRecipe.id, "");
      if (res.status === 200 || res.status === 201) {
        window.open(`${BUCKET_URL + "/" + res.data.data}`, "_blank");
        setDownloadLoader(false)
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
      setDownloadLoader(false)
    }

  };
  const handleIngredients = async (id, status) => {
    document.getElementById(`${borderId}`)?.removeAttribute("class");
    document.getElementById(`${borderId}`)?.setAttribute("class", `food-div`);
    setBorderId(`card${id}`);
    setToggle(true);
    document
      .getElementById(`card${id}`)
      .setAttribute("class", ` borderCard food-div`);

    if (status) {
      try {
        const res = await getAllIngridientsById(id);
        if (res.status === 200 || res.status === 201) {
          // console.log(res.data.data, "DAHHDRFD");
          setIsRecipe(false);
          setSingleIngredient(res?.data?.data);
          setSingleRecipe();
          setCalorieServing(res?.data?.data?.caloriePerServing);
          setProximates({
            vitamins: res?.data?.data?.proximates?.vitamins,
            minerals: res?.data?.data?.proximates?.minerals,
            nutrients: res?.data?.data?.proximates?.nutrients,
          });
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const res = await getRecipieByIdApi(id);
        if (res.status === 200 || res.status === 201) {
          setSingleRecipe(res?.data?.data);
          setQuantity(res.data.data.totalQuantity);
          setSingleIngredient();
          setIngredientDetails(res?.data?.data?.ingredientDetails)
          setCalorieServing(res.data.data.caloriePerServing);
          setIsRecipe(true);

          setCalorieServing(res.data.data.caloriePerServing);
          // console.log(res.data.data.proximatesDetails, "DFADSFAFDSAF");
          setProximates({
            vitamins: res?.data?.data?.proximatesDetails?.vitamins,
            minerals: res?.data?.data?.proximatesDetails?.minerals,
            nutrients: res?.data?.data?.proximatesDetails?.nutrients,
          });
          setRecipeServing(res?.data?.data?.servingSize)
          setRecipeNote(res?.data?.data?.note)
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const  getItemsCombinedLength = (arr)=> {
    if(!arr) return;
    const result = arr.reduce((accumulator, currentObj) => {
        accumulator += (currentObj.recipes.length || 0) + (currentObj.ingredients.length || 0);
        return accumulator;
    }, 0); 

    return result;
}

  const handleChartViewToggle = () => {
    document.getElementById(`${borderId}`)?.removeAttribute("class");
    document.getElementById(`${borderId}`)?.setAttribute("class", `food-div`);
    setToggle(false);
  };
  const getAllPneding = async () => {
    try {
      const res = await getAllPendingIngredients();

      if (res?.status === 200 || res.status === 201) {
        setPendingIngredients(res?.data?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const handleShareIngredient = async () => {
  //   if (singleIngredient) {
  //     try {
  //       const res = await shareIngredientApi({
  //         ingredientId: singleIngredient.id,
  //       });
  //       if (res.status === 200 || res.status === 201) {
  //         toast.success(res?.data?.message);
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // };

  const handleIngredientSave = async () => {
    if (singleIngredient?.isSaved === true) {
      let data = {
        ingredientId: singleIngredient?.id,
        isSaved: false,
      };
      try {
        const res = await saveInggredientApi(data);
        if (res.status === 200 || res.status === 201) {
          toast.success(res.data.message);
          handleIngredients(
            singleIngredient?.id,
            singleIngredient?.ingredientStatus
          );
        }
        console.log(res);
      } catch (err) {
        console.log(err);
      }
    } else {
      let data = {
        ingredientId: singleIngredient?.id,
        isSaved: true,
      };
      try {
        const res = await saveInggredientApi(data);
        if (res.status === 200 || res.status === 201) {
          toast.success(res.data.message);
          handleIngredients(
            singleIngredient?.id,
            singleIngredient?.ingredientStatus
          );
        }
        console.log(res);
      } catch (err) {
        console.log(err);
      }
    }
  };
  const getSortedData = (name) => {
    if (name === "new") {
      let d = allRecipes?.data
        ?.map((v) => {

          // return v.ingredients?.map((l) => {
          //   return l;
          // });
          let d = v?.ingredients?.concat(v?.recipes);

          return d;
        })
        .flat();

      const sortedData = d?.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setSortData(sortedData);

    } else {
      let d = allRecipes?.data
        ?.map((v) => {
          // return v.ingredients?.map((l) => {
          //   return l;
          // });
          let d = v?.ingredients?.concat(v?.recipes);
          return d;
        })
        .flat();

      const sortedData = d?.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );

      // return sortedData;
      setSortData(sortedData);
    }
  };

  const handleSorting = (e) => {
    if (e.target.value === "new") {
      getSortedData("new");
    } else {
      getSortedData("old");
    }
  };


  const getValueD = (d) => {
    console.log(d, "DDDDDD")
  }
  const handleDownloadIngredient = async () => {
    // const token = localStorage.getItem("token");
    // const headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    // fetch(
    //   `${API_BASE_URL}/recipeAndIngredient/generateRecipesAndIngredientsPdf?recipeId=&ingredientId=${singleIngredient?.id}`,
    //   {
    //     headers: headers,
    //   }
    // )
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     // Save the blob as a file using FileSaver
    //     saveAs(blob, "data.pdf");
    //   })
    //   .catch((error) => {
    //     console.error("Error downloading the CSV file:", error);
    //   });
    // const res = await downloadRecipeApi("", singleIngredient.id);
    setDownloadLoader(true)
    try {
      const res = await downloadRecipeApi("", singleIngredient.id);
      if (res.status === 200 || res.status === 201) {
        setDownloadLoader(false);
        window.open(`${BUCKET_URL + "/" + res.data.data}`, "_blank");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
      setDownloadLoader(false)
    }

  };

  const handleImageClick = async (id) => {
    navigate("add-ingredients", { state: { ingredientId: id, edit: true } });
  };
  // console.log(singleIngredient?.proximates, "DDIDIDIDIDIDI");
  // let f = singleIngredient?.proximates?.nutrients?.filter((v) => v.value !== 0);
  // console.log(f, "ASDGAHREBGEWTERD");
  useEffect(() => {
    setSearchInpt();
    fetchAllRecipes("", currentPage, limit);
    getAllPneding();
  }, []);
  useEffect(() => {

    if (searchData.length) {
      if (toggle) {
        setToggle(false)
      }
      setAllRecipes((v) => ({ ...v, data: searchData, loading: false }));
      setPageCount(pCount);
    } else {
      if(location.pathname !== "/")
      fetchAllRecipes("", currentPage, limit);
    }
  }, [searchData]);



  const z = (arr) => {
    if (arr?.length !== 0) {
      arr.map((el, i) => {
        console.log(el, i, "LLL");
      });
    }
  };

  const handleScrollToTop = () => {
   return scrollRef?.current?.scrollIntoView({
      // behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  useEffect(() => {
    getSortedData("new");
  }, [allRecipes]);
  // z(allRecipes?.data)

  return (
    <>
      <AppLayout>
        <div ref={scrollRef} className="d-flex justify-content-between pt-4 mb-3">
          <div className="all-svg">
            <h3
              className="ingredient mb-0"
              onClick={() => {
                navigate(`/add-ingredients`);
              }}
              style={{ cursor: "pointer", border: "1px solid #fe557f" }}
            >
              <span className="plus-sy">+</span> Add a new ingredient
            </h3>
            {toggle && (
              <div className="all-svg  my-3 justify-content-around">
                {singleIngredient ? (
                  <>
                    {
                      singleIngredient.ingredientStatus !== "pending" ? <>
                        {
                          downloadLoader ? <div style={{ boxShadow: "0px 3px 6px #00000029", backgroundColor: "white", padding: "6px 8px", marginLeft: "5px", borderRadius: "10px" }}> <WhiteLoader color="black" /></div> : <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            fill="currentColor"
                            className="bi bi-download"
                            viewBox="0 0 16 16"
                            onClick={handleDownloadIngredient}
                          >
                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                          </svg>

                        }


                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          // fill="currentColor"
                          fill={
                            singleIngredient?.isSaved ? "#fd3468" : "currentColor"
                          }
                          class="bi bi-bookmark-plus-fill"
                          viewBox="0 0 16 16"
                          onClick={handleIngredientSave}
                          style={{ cursor: "pointer" }}
                        >
                          <path
                            fill-rule="evenodd"
                            d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zm6.5-11a.5.5 0 0 0-1 0V6H6a.5.5 0 0 0 0 1h1.5v1.5a.5.5 0 0 0 1 0V7H10a.5.5 0 0 0 0-1H8.5V4.5z"
                          />
                        </svg>
                      </> : ""
                    }


                  </>
                ) : (
                singleRecipe &&  <>
                    {" "}
                    {singleRecipe?.isMyRecipe && <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 20.908 17.431"
                      style={{ cursor: "pointer" }}
                      fill={singleRecipe?.isShared ? "#fd3468" : "currentColor"}
                      // onClick={handleShareRecipie}
                      data-bs-toggle={singleRecipe?.isShared ? "" : "modal"}
                      data-bs-target={
                        singleRecipe?.isShared ? "" : "#confirmModal"
                      }
                    >
                      <g
                        id="Group_4201"
                        data-name="Group 4201"
                        transform="translate(-4978.529 140.166)"
                      >
                        <path
                          id="Path_10694"
                          data-name="Path 10694"
                          d="M5309.268,40.676a17.426,17.426,0,0,1-1.514,1.063,17.3,17.3,0,0,1-1.711.751.9.9,0,0,1-.168.035,7.213,7.213,0,0,0-4.324-5.676c.133-.181.255-.358.39-.525a.163.163,0,0,1,.152-.011,3.2,3.2,0,0,0,1.415.4,2.992,2.992,0,0,0,1.706-.449,5.265,5.265,0,0,0,.684-.511.242.242,0,0,1,.32-.044,5.559,5.559,0,0,1,2.913,3.943c.048.245.056.5.086.746.01.079.033.157.05.235Z"
                          transform="translate(-309.831 -168.654)"
                        />
                        <path
                          id="Path_10695"
                          data-name="Path 10695"
                          d="M4978.529,40.548c.032-.247.057-.5.1-.741a5.56,5.56,0,0,1,2.972-4.191c.146-.08.219-.017.314.07a3.5,3.5,0,0,0,1.671.881,2.968,2.968,0,0,0,2.045-.311c.146-.077.221-.067.306.076a4.93,4.93,0,0,0,.323.434,7.307,7.307,0,0,0-2.919,2.264,7.217,7.217,0,0,0-1.409,3.435c-.332-.118-.65-.218-.959-.345a8.143,8.143,0,0,1-2.308-1.436,1.5,1.5,0,0,0-.132-.094Z"
                          transform="translate(0 -168.568)"
                        />
                        <path
                          id="Path_10696"
                          data-name="Path 10696"
                          d="M5085.68,83.028a6.316,6.316,0,0,1,2.161,1.692,6.4,6.4,0,0,1,1.489,3.987.319.319,0,0,1-.095.221,9.6,9.6,0,0,1-11.755.761c-.326-.22-.626-.478-.942-.714a.309.309,0,0,1-.136-.293,6.4,6.4,0,0,1,3.526-5.6c.136-.075.205-.027.3.058a3.994,3.994,0,0,0,2.186,1.093,3.422,3.422,0,0,0,2.335-.52C5085.067,83.514,5085.359,83.266,5085.68,83.028Z"
                          transform="translate(-93.876 -214.079)"
                        />
                        <path
                          id="Path_10697"
                          data-name="Path 10697"
                          d="M5150.493-109.2a2.711,2.711,0,0,1-1.723-.963,5.4,5.4,0,0,1-1.567-3.681,3.517,3.517,0,0,1,2.662-3.483,3.56,3.56,0,0,1,4.439,2.887,4.486,4.486,0,0,1-.377,2.5,5.052,5.052,0,0,1-1.667,2.222A2.7,2.7,0,0,1,5150.493-109.2Z"
                          transform="translate(-161.786 -21.797)"
                        />
                        <path
                          id="Path_10698"
                          data-name="Path 10698"
                          d="M5044.9-133.225a1.994,1.994,0,0,1-2.145.107,3.949,3.949,0,0,1-1.563-1.729,4.558,4.558,0,0,1-.549-2.21,3.118,3.118,0,0,1,2.857-2.965,3.08,3.08,0,0,1,2.532.978.709.709,0,0,1,.054.061.224.224,0,0,1,.021.053,4.211,4.211,0,0,0-1.652,2.616A5.336,5.336,0,0,0,5044.9-133.225Z"
                          transform="translate(-59.579 -0.13)"
                        />
                        <path
                          id="Path_10699"
                          data-name="Path 10699"
                          d="M5295.65-133.334c.9-2.195.723-4.148-1.209-5.747a2.887,2.887,0,0,1,1.092-.817,3.107,3.107,0,0,1,4.318,2.234,3.779,3.779,0,0,1-.3,2.267,4.121,4.121,0,0,1-1.745,2.139A2.008,2.008,0,0,1,5295.65-133.334Z"
                          transform="translate(-303.012 0)"
                        />
                      </g>
                    </svg>}
                    {
                      downloadLoader ? <div style={{ boxShadow: "0px 3px 6px #00000029", backgroundColor: "white", padding: "6px 8px", marginLeft: "5px", borderRadius: "10px" }}> <WhiteLoader color="black" /></div> :
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          fill="currentColor"
                          className="bi bi-download"
                          viewBox="0 0 16 16"
                          style={{ cursor: "pointer" }}
                          onClick={handleDownloadRecipe}
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>

                    }

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill={singleRecipe?.isSaved ? "#fd3468" : "currentColor"}
                      className="bi bi-bookmark-plus-fill"
                      viewBox="0 0 16 16"
                      onClick={handleSaveRecipe}
                      style={{ cursor: "pointer" }}
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zm6.5-11a.5.5 0 0 0-1 0V6H6a.5.5 0 0 0 0 1h1.5v1.5a.5.5 0 0 0 1 0V7H10a.5.5 0 0 0 0-1H8.5V4.5z"
                      />
                    </svg>
                  </>
                )}
              </div>
            )}
          </div>
          <div>
            <h4>
              Sort by
              <select
                className="ms-3"
                onChange={handleSorting}
                name=""
                id=""
                style={{ border: "none", fontWeight: "700" }}
              >
                <option value="new">Newest</option>
                <option value="old">Oldest</option>
              </select>
            </h4>
          </div>
        </div>

        {allRecipes?.loading ?

            <div className="text-center">
              <ThemeSpinner />
            </div>

        :
        getItemsCombinedLength(allRecipes?.data) > 0 ? (
          <>
            <div className="row ">
              <div
                className={`col-xl-${toggle ? 7 : 12} col-lg-${toggle ? 6 : 12
                  } `}
                style={{}}
              >
                <div className="row">
                  {/* {Boolean(pendingIngredients?.length) && 
                  <div className="  ">
                    <h1 className="mb-0 bg-white p-3" style={{color: "rgb(255, 0, 0)"}}>Ingredient 'pending' approval</h1>
                  </div>} */}
 
                  {!searchInpt && Boolean(pendingIngredients?.length) &&
                  <div className="row">
                  <h1 className="mb-0  p-3" style={{color: "#fbdfa3",fontWeight:"bolder"}}>New Ingredient pending approval</h1>
                    { pendingIngredients?.map((v, i) => {
                      return (
                        <React.Fragment key={`${i}-recipes`}>
                          <div
                            className={`col-xl-${toggle ? 6 : 4} col-lg-${toggle ? 12 : 6
                              } mt-3 `}
                          >


                            <div className="food-div" id={`card${v.id}`}>
                              <svg onClick={() => handleImageClick(v.id)} className=" position-absolute " style={{ right: "0px", top: "1px", zIndex: "999999", cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="46" height="31" viewBox="0 0 46 31">
                                <defs>
                                  <linearGradient id="linear-gradient" x1="0.1" x2="1.083" y2="1.314" gradientUnits="objectBoundingBox">
                                    <stop offset="0" stop-color="#ff93ab" />
                                    <stop offset="1" stop-color="#fd3468" />
                                  </linearGradient>
                                </defs>
                                <g id="Component_81_20" data-name="Component 81 – 20" transform="translate(0.5 0.5)">
                                  <path id="Rectangle_4607" data-name="Rectangle 4607" d="M0,0H34A11,11,0,0,1,45,11V30a0,0,0,0,1,0,0H15A15,15,0,0,1,0,15V0A0,0,0,0,1,0,0Z" stroke="rgba(0,0,0,0)" stroke-width="1" fill="url(#linear-gradient)" />
                                  <text id="Edit" transform="translate(11 7)" fill="#fff" font-size="12" font-family="SegoeUI-Semibold, Segoe UI" font-weight="600"><tspan x="0" y="13">Edit</tspan></text>
                                </g>
                              </svg>

                              {/* <button className="edit-btn border rounded px-2 py-1">edit</button> */}
                              <div className="image-div ratio ratio-4x3">

                                <img
                                  className=" img-fluid"
                                  src={
                                    v.thumbnailImage
                                      ? BUCKET_URL + "/" + v.thumbnailImage
                                      : ""
                                  }
                                  width="100%"
                                  height="100%"
                                  alt=""
                                  onError={(e) => {
                                    e.target.src = ProfilePicDemo;
                                    e.error = null;
                                  }}


                                />
                              </div>

                              <div className="d-flex justify-content-between mt-3">
                                <p
                                  className="ingredients"
                                // style={{ cursor: "pointer" }}
                                >
                                  {v.ingredientStatus ? "Ingredient" : "Recipe"}
                                </p>



                                <div className="d-flex">
                                  <small style={{fontWeight: "600" }} className=" d-flex flex-column">
                                    <p className="m-0 p-10"><span> Created on:</span> <span>{v.dateTime}</span></p>
                                    {/* {v?.userDetails?.userName && <p className="m-0 p-10"><span> Created by:</span> <span>{v?.userDetails?.userName}</span></p>} */}

                                  </small>

                                  <p className="shared-greens">
                                    {/* <i class="bi bi-dot shared"></i> Shared */}
                                    <i
                                      style={{
                                        color: "#f7bb39",
                                        // left: "105px",
                                        position: "absolute",
                                        fontSize: "16px",
                                        top: "0",
                                      }}
                                      class="bi bi-clock"
                                    ></i>
                                  </p>
                                </div>
                              </div>
                              {/* <small style={{ color: "#FBAD06" }}>
                                Uploaded on: {v.dateTime}
                                 </small> */}
                              <h1 className="m-0" style={{minHeight:"60px"}}>{v.name}</h1>
                              <button
                                type="button"
                                // data-bs-toggle="modal"
                                // data-bs-target="#myModal"
                                onClick={() =>
                                  handleIngredients(v.id, v?.ingredientStatus)
                                }
                                className="mt-3 btn-view-chart w-100"
                              >
                                View Chart
                              </button>
                              {/* <button type="button" class="btn btn-primary" > Open modalv </button> */}
                            </div>
                          </div>

                        </React.Fragment>
                      );
                    })}
                    </div>
                     }
                  {sortData?.map((v, i) => {

                    return (
                      <React.Fragment key={`${i}-recipes`}>
                        <div
                          className={`col-xl-${toggle ? 6 : 4} col-lg-${toggle ? 12 : 6
                            } mt-3`}
                        >
                          <div className="food-div" id={`card${v.id}`}>
                            <div className="image-div ratio ratio-4x3" style={{cursor: !v.ingredientStatus ?"pointer":""}}  onClick={() => !v.ingredientStatus ? navigate("/view-recipes", { state: { id: v?.id } }) : {}}>
                              <img
                                className=" img-fluid"
                                src={
                                  v.thumbnailImage
                                    ? BUCKET_URL + "/" + v.thumbnailImage
                                    : ""
                                }
                                width="100%"
                                height="100%"
                                alt=""
                                onError={(e) => {
                                  e.target.src = ProfilePicDemo;
                                  e.error = null;
                                }}
                              // style={{ cursor: "pointer" }}
                              // onClick={() => handleImageClick(v.id)}
                              />
                            </div>

                            <div className="d-flex justify-content-between mt-3">
                              <p
                                className="ingredients" >
                                {v.ingredientStatus ? "Ingredient" : "Recipe"}
                              </p>
                          <small style={{ color: "#000", fontWeight: "600" }} className=" d-flex flex-column">
                                <p className="m-0 p-10"><span> Created on:</span> <span>{v.dateTime}</span></p>
                                {!v?.ingredientStatus &&  v?.usersDetails?.userName && <p className="m-0 p-10">
                                  <span> Created by:</span> <span>{v?.usersDetails?.userName}</span></p>}
                              </small>


                              {/* <p className="shared-green">
                                  <i class="bi bi-dot shared"></i> Shared */}
                              {/* <i
                                    style={{
                                      color: "#EBAA48",
                                      left: "-40px",
                                      position: "absolute",
                                      fontSize: "24px",
                                      top: "0",
                                    }}
                                    class="bi bi-clock"
                                  ></i> */}

                              {/* </p> */}
                            </div>

                            <h1 className="card_heading mt-0 mb-0" style={{minHeight:"60px"}}>{v.name}</h1>
                            <button
                              type="button"
                              // data-bs-toggle="modal"
                              // data-bs-target="#myModal"
                              // onClick={() =>
                              //   handleIngredients(v.id, v?.ingredientStatus)
                              // }
                              onClick={() => !v.ingredientStatus ? navigate("/view-recipes", { state: { id: v?.id } }) : handleIngredients(v.id, v?.ingredientStatus)}
                              className="mt-3 btn-view-chart w-100"
                            >
                              View Chart
                            </button>
                            {/* <button type="button" class="btn btn-primary" > Open modalv </button> */}
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                  
                </div>
              </div>

              {toggle && (
                <div className="col-xxl-4  col-xl-4 col-lg-6 mt-3 ">
                  <div class="card  mb-0">
                    <div class="card-body   p-0"><i
                          onClick={handleChartViewToggle}
                          class="bi bi-x position-absolute me-3 border rounded-circle mt-1 px-1 bg-dark text-white end-0"
                        ></i>
                    {isRecipe && <div className="p-3 border-bottom"> 
                       {recipeServing?<p className="p-14 mb-2 fw-bolder">Portion size : <span>{recipeServing ? recipeServing + " g" :""}</span></p>
                      : <p className="p-14 mb-2 fw-bolder">No of Portion : <span>{singleRecipe?.noOfPortion}</span></p>}
                       {recipeNote && 
                      //  <p className="p-14 mb-0 pe-5 ">
                      //   <b className="fw-bolder">Recipe note :
                      //   </b> <span>{recipeNote}</span>
                      //   </p>
                      <>
                          <div className="mb-3 fw-semibold d-flex " style={{ color: "#212529" }}>

                            <span className="fw-bolder" style={{ width: "9%"}}> Note: </span>
                            <span style={{ width: "91%" }} dangerouslySetInnerHTML={{ __html: recipeNote.replace(/\n/g, '<br />') }} />

                          </div>
                      
                      </>
                        }

                      </div>}
                      {isRecipe && Boolean(ingredientDetails?.length) && <div className="modal_chart_list mx-2 bg-white mb-3 ">
                        
                        
                        {Boolean(ingredientDetails?.length) &&
                          ingredientDetails?.map((v) => {
                            console.log(v, "vallluuueeee");
                            return (
                              <div className="row mb-4 mt-4">
                                <div className="col-auto">
                                  <img
                                    src={BUCKET_URL + "/" + v.frontImage}
                                    style={{
                                      width: "28px",
                                      height: "28px",
                                      borderRadius: "50%",
                                    }}
                                    onError={(e) => {
                                      e.target.src = ProfilePicDemo;
                                      e.error = null;
                                    }}
                                  />
                                </div>
                                <div className="col-7">
                                  <h6 className=" p-14 fw-bold"> {v.name}</h6>
                                  <p className="p-10 m-0">Note: {v.notes}</p>
                                </div>
                                <div className="col-auto">
                                  <p className="m-0 p-12">
                                    {v?.quantity + " " + v?.quantityUnit}
                                  </p>
                                </div>

                                {/* <div className="col-auto">
                              <i
                                class="bi bi-caret-right icon-color"
                                type="button"
                              ></i>
                            </div> */}
                              </div>
                            );
                          })}
                      </div>}
                      <div className="modal-header-list position-relative ">
                        <h1
                          class="modal-title-list  mb-0 py-3 "
                          id="exampleModalLabel"
                        >
                          Nutritional Information
                          <button
                            type="button"
                            className="position-absolute rounded-circle  end-0 me-2"
                          >
                            {" "}
                            <i
                              onClick={handleChartViewToggle}
                              className="bi bi-x"
                            ></i>
                          </button>
                        </h1>
                      </div>

                      <div class=" p-3 " style={{ border: "1px solid #000" }}>
                        {singleRecipe && (
                          <div
                            className=" heading d-flex justify-content-around align-items-center"
                            style={{ borderBottom: "9px solid black" }}
                          >
                            <h6 className="p-1">Serving size </h6>
                            <h6 className="p-12">
                              {" "}
                              (
                              {singleRecipe?.servingSize?.data
                                ? singleRecipe?.servingSize?.data
                                : singleRecipe?.servingSize}
                              )
                            </h6>
                          </div>
                        )}

                        <div
                          className=" heading d-flex justify-content-around align-items-center "
                          style={{ borderBottom: "5px solid black" }}
                        >
                          <h6 className="p-17 fw-bold">Calories per serving</h6>
                          <h1 className="p-17 fw-boldest">{parseInt(calorieServing)}</h1>
                        </div>

                        {
                          Boolean(proximates?.nutrients?.length) && <>
                            <div className="row align-item-center pt-1">
                              <h6 className={`col-${isRecipe ? 2 : 4}`}>
                                Nutrients
                              </h6>
                              <h6 className={`col-${isRecipe ? 2 : 4}`} align='center'>Unit</h6>
                              <h6 className={`col-${isRecipe ? 2 : 4}`}>
                                per 100g{" "}
                              </h6>
                              {isRecipe && (
                                <>
                                  <h6 className="col-2">per {quantity}</h6>
                                  <h6 className="col-2">serving </h6>
                                  <h6 className="col-2">NRV </h6>
                                </>
                              )}
                            </div>
                            <hr className="m-0 bottom-line" /></>
                        }
                        <div className="disable-scroller_chart">
                          {Boolean(proximates?.nutrients?.length) &&
                            proximates?.nutrients
                              ?.filter((v) => v.value !== 0)
                              ?.map((v) => {
                                return (
                                  <div className="row gx-0 pt-3">
                                    <div
                                      className={`col-${isRecipe ? 2 : 4
                                        } text-start`}
                                    >
                                      <h6 className=" fs-5 fw-bold">
                                        {v?.link ? (
                                          <Link to={v?.link} target="_blank">
                                            {v.name}
                                          </Link>
                                        ) : (
                                          <span>{v.name}</span>
                                        )}
                                      </h6>
                                    </div>
                                    <div className={`col-${isRecipe ? 2 : 4}`}>
                                      <p className="fs-5" align='center'>{v.unit}</p>
                                    </div>
                                    <div className={`col-${isRecipe ? 2 : 4}`}>
                                      <h6 className="text-start fs-5 fw-bold">
                                      {getChartNutrientsValue(v?.hundredValue,v?.value,v?.name)}
                                      </h6>
                                    </div>
                                    {Boolean(v?.hasOwnProperty('nrvPercentage')) && (
                                      <>
                                        <div className="col-2">
                                          <h6 className="text-start fs-5 fw-bold">
                                            {getChartNutrientsValue("",v?.value,v?.name)}
                                          </h6>
                                        </div>
                                        <div className="col-2">
                                          <h6 className="text-start fs-5 fw-bold">
                                            {v?.nrvValue > 0
                                              ? v?.nrvPercentage + "%"
                                              : ""}
                                          </h6>
                                        </div>
                                        <div className="col-2">
                                          <h6 className="text-start fs-5 fw-bold">
                                            {v?.nrvValue > 0 ? v?.nrvValue : ""}
                                          </h6>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                );
                              })}
                          {
                            Boolean(proximates?.minerals?.length) && <><hr className="m-0 bottom-line" />
                              <div className="row align-item-center pt-1">
                                <h6 className={`col-${isRecipe ? 2 : 4}`}>
                                  Minerals
                                </h6>
                                <h6 className={`col-${isRecipe ? 2 : 4}`}>Unit</h6>
                                <h6 className={`col-${isRecipe ? 2 : 4}`}>
                                  per 100g{" "}
                                </h6>
                                {isRecipe && (
                                  <>
                                    <h6 className="col-2">per {quantity}</h6>
                                    <h6 className="col-2">serving </h6>
                                    <h6 className="col-2">NRV </h6>
                                  </>
                                )}
                              </div>
                              <hr className="m-0 bottom-line" />
                            </>
                          }

                          {Boolean(proximates?.minerals?.length) &&
                            proximates?.minerals
                              ?.filter((v) => v.value !== 0)
                              ?.map((v) => {
                                // console.log(first)
                                return (
                                  <div className="row  gx-0 pt-3">
                                    <div
                                      className={`col-${isRecipe ? 2 : 4
                                        } text-start`}
                                    >
                                      <h6 className=" fs-5 fw-bold">
                                        {v?.link ? (
                                          <Link to={v?.link} target="_blank">
                                            {v.name}
                                          </Link>
                                        ) : (
                                          <span>{v.name}</span>
                                        )}
                                      </h6>
                                    </div>
                                    <div className={`col-${isRecipe ? 2 : 4}`}>
                                      <p className="fs-5">{v.unit}</p>
                                    </div>
                                    <div className={`col-${isRecipe ? 2 : 4}`}>
                                      <h6 className="text-start fs-5 fw-bold">
                                        {getChartNutrientsValue("",v?.value,v?.name)}
                                      </h6>
                                    </div>
                                    {Boolean(v?.hasOwnProperty('nrvPercentage')) && (
                                      <>
                                        <div className="col-2">
                                          <h6 className="text-start fs-5 fw-bold">
                                            {getChartNutrientsValue("",v?.value,v?.name)}
                                          </h6>
                                        </div>
                                        <div className="col-2">
                                          <h6 className="text-start fs-5 fw-bold">
                                            {v?.nrvValue > 0
                                              ? v?.nrvPercentage + "%"
                                              : ""}
                                          </h6>
                                        </div>
                                        <div className="col-2">
                                          <h6 className="text-start fs-5 fw-bold">
                                            {v?.nrvValue > 0 ? v?.nrvValue : ""}
                                          </h6>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                );
                              })}
                          {
                            Boolean(proximates?.vitamins?.length) && <><hr className="m-0 bottom-line" />
                              <div className="row align-item-center pt-1">
                                <h6 className={`col-${isRecipe ? 2 : 4}`}>
                                  Vitamins
                                </h6>
                                <h6 className={`col-${isRecipe ? 2 : 4}`}>Unit</h6>
                                <h6 className={`col-${isRecipe ? 2 : 4}`}>
                                  per 100g{" "}
                                </h6>
                                {isRecipe && (
                                  <>
                                    <h6 className="col-2">per {quantity}</h6>
                                    <h6 className="col-2">serving </h6>
                                    <h6 className="col-2">NRV </h6>
                                  </>
                                )}
                              </div>
                              <hr className="m-0 bottom-line" />
                            </>
                          }

                          {Boolean(proximates?.vitamins?.length) &&
                            proximates?.vitamins
                              ?.filter((v) => v.value !== 0)
                              ?.map((v) => {
                                return (
                                  <div className="row gx-0 pt-3">
                                    <div
                                      className={`col-${isRecipe ? 2 : 4
                                        } text-start`}
                                    >
                                      <h6 className=" fs-5 fw-bold">
                                        {v?.link ? (
                                          <Link to={v?.link} target="_blank">
                                            {v.name}
                                          </Link>
                                        ) : (
                                          <span>{v.name}</span>
                                        )}
                                      </h6>
                                    </div>
                                    <div className={`col-${isRecipe ? 2 : 4}`}>
                                      <p className="fs-5">{v.unit}</p>
                                    </div>
                                    <div className={`col-${isRecipe ? 2 : 4}`}>
                                      <h6 className="text-start fs-5 fw-bold">
                                        {getChartNutrientsValue("",v?.value,v?.name)}
                                      </h6>
                                    </div>
                                    {Boolean(v?.hasOwnProperty('nrvPercentage')) && (
                                      <>
                                        <div className="col-2">
                                          <h6 className="text-start fs-5 fw-bold">
                                            {getChartNutrientsValue("",v?.value,v?.name)}
                                          </h6>
                                        </div>
                                        <div className="col-2">
                                          <h6 className="text-start fs-5 fw-bold">
                                            {v?.nrvValue > 0
                                              ? v?.nrvPercentage + "%"
                                              : ""}
                                          </h6>
                                        </div>
                                        <div className="col-2">
                                          <h6 className="text-start fs-5 fw-bold">
                                            {v?.nrvValue > 0 ? v?.nrvValue : ""}
                                          </h6>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                );
                              })}
                        </div>
                        <p
                          className=" text-dark m-0 card-bottom p-10 pt-3"
                          style={{ borderTop: "3px solid black" }}
                        >
                        {/* % values are based on a 2000 calories diet. Your daily values may be higher or lower depending on your energy needs.<br/>
                        A ratio value of 1 is used for volume to weight conversion during some analysis.<br/>
                        Some of the ingredients and/or recipes on this database are Crown copyright data drawn from CoFID and licensed under the Open Government Licence v3.0. */}
                        {chartFooterText()}

                      </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        ) :  (
          // <div className="text-center">
          //   <ThemeSpinner />
          // </div>
          <Default name="Ingredient"/>
        ) }
        <div className="d-flex justify-content-center mt-5">

        <ReactPaginate
          nextLabel="Next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="< Previous"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
        </div>
      </AppLayout>

      {/* modal start here  */}
      <CommunityShareModal communityShareLoader={communityShareLoader} handleShareRecipie={handleShareRecipie}/>
    </>
  );
};

export default Home;
