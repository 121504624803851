import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import MainSearch from "../../AppLayout/MainSearch";
import AppLayout from "../../AppLayout/AppLayout";
// import image from "../../Assets/Images/person.png"
import image from "../../Assets/Images/person.png";
import { saveAs } from "file-saver";
import CloseBtn from '../../Assets/Images/Group 4292.svg';
// import icon from "../../Assets/Images/escape.svg"
import Sidebar from "../../AppLayout/Sidebar";
import { useLocation, useNavigate } from "react-router";
import { useFormik } from "formik";
import { API_BASE_URL, BUCKET_URL } from "../../Utils/BaseUrl";
import { UserContext } from "../../Contexts/Contexts";
import {
  downloadRecipeApi,
  fileUploadApi,
  getAllIngredeintsApi,
  getAllIngridientsById,
  getRecipieByIdApi,
  savedRecipieApi,
  shareRecipeApi,
  unShareRecipeApi,
  updateRecepies,
} from "../../Api/Apis";
import { toast } from "react-hot-toast";
import ProfilePicDemo from "../../Assets/Images/Group 10775.png";
import { WhiteLoader } from "../Loader/Loader";
import { Link } from "react-router-dom";
import { capitalizeString } from "../../Utils/HelperFuntions";
import { ThemeSpinner } from "../../Utils/Loaders";
import RecipeChart from "../Common/RecipeChart";
import { recipeValidation } from "../../Utils/Validations";
import Select from 'react-select';

const ViewRecipe = () => {
  const location = useLocation();
  console.log(location.state, "valuessss");
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState();
  const [ingredientData, setIngredientData] = useState();
  const [proximates, setProximates] = useState({
    minerals: [],
    nutrients: [],
    vitamins: [],
  });
  const [errorMsg, setErrorMsg] = useState("")
  const [borderId, setBorderId] = useState();
  const [show, setShow] = useState(true);
  const [saved, setSaved] = useState(false)
  const [ingredientToggle, setIngredientToggle] = useState(false);
  const [render, setRender] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [chartData, setChartData] = useState();
  const [downloadLoader , setDownloadLoader]=useState(false);
  const [ml, setMl] = useState("g")
  const [calorieServing, setCalorieServing] = useState()
  const [btnShow, setbtnShow] = useState(false);
  const [quantity, setQuantity] = useState();
  const [delIngredientId, setDelIngredientId] = useState()
  const [isShared, setIsShared] = useState()
  const [outerValues,setOuterValues] = useState({name:"",note:""})
  const [loader,setLoader] = useState(false)
  const [shareLoader, setShareLoader] = useState(false)
  const [isMyRecipe,setIsMyRecipe] =useState(false)
  const [isLoadingIngredients,setIsLoadingIngredients] = useState(false)
  const fileInputRef = useRef(null);

    // const [imgSize, setImgSize] = useState({
  //   frontImage: { name: "", size: "" },
  //   backImage: { name: "", size: "" },
  //   thumbnailImage: { name: "", size: "" },
  // });
  const [modalData, setModalData] = useState({
    id: "",
    quantity: "",
    quantityUnit: "g",
    notes: "",
    name: "",
    thumbnailImage: "",
  });
  const [quantityError,setQuantityError] = useState(false)
  const [noteError,setNoteError] = useState(false)
  const [isRecipe, setIsRecipe] = useState(true);
  const { values,errors, setFieldValue, setValues, handleSubmit, handleChange } =
    useFormik({
      initialValues: {
        name: "",
        note: "",
        id: "",
        isPublished: false,
        thumbnailImage: "",
        servingSize: "",
        ingredientIds: [],
        noOfPortion:""
      },
    validationSchema: recipeValidation,
      onSubmit: async (val) => {
        console.log(val, "ASDFSDFADSGDSG");
        const stringifiedObjects = val?.ingredientIds?.map((obj) =>
          obj
        );
        // let d = {
        //   ...val,
        //   servingSize: { data: val.servingSize + ml }
        // }
        let d ;
        // if(ingredientToggle){
           d = {
            ...val,
            ingredientIds:JSON.stringify(stringifiedObjects)
        }
        // }else{
        // d ={
        //  ...val,
        //  ingredientIds:JSON.stringify([])
        // }
        // }

        console.log(d,"ADSFDHKAFDJASFJDFJDS" )
        const payload={...d,noOfPortion:d?.noOfPortion?Number(d?.noOfPortion):0}
        setLoader(true)
        const res = await updateRecepies(payload);
        if (res.status === 200 || res.status === 201) {
          toast.success("Recipe Updated Successfully");
          navigate("/my-recipes");
          // if (ingredientToggle) {
          //   navigate("/my-recipes");
          // }
          document.getElementById("exampleModalRecipe").click();
          setIngredientToggle(false);
          getRecipieById(val?.id)
          setbtnShow(false)
          setShow(true);
          setIsEdit(false)
        }
        setLoader(false)
      },
    });

    const handleChangeInput = (e) =>{
      const {name,value} = e.target;
      if(name === 'noOfPortion'){
        setFieldValue('noOfPortion', value);
        setFieldValue('servingSize', '');
      }else{
        setFieldValue('noOfPortion', '');
        setFieldValue('servingSize', value);
      }
    }

    const handleCloseChart = () => {
      setChartData();
      // setBorderId()
      // setToggle(false);
    };

  const handleNote = (e) => {
    let {value} = e.target;

    const maxWords = 500;
      const wordCount = value.split(/\s+/).length;
      let validation = wordCount <= maxWords;
     if(!validation) setNoteError(true)
     if(noteError && validation) setNoteError(false)
    // if (e.target.value?.length > 500) {
    //   e.preventDefault();
    // } else {
      setModalData({ ...modalData, notes: value });
    // }
  };
  const handleRecipeImgaeCros =()=>{
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    setFieldValue("thumbnailImage","")
  }
  const handleSaveRecipe = async () => {
    if (saved) {
      let data = {
        recipeId: location?.state?.id,
        isSaved: false,
      };
      try {
        const res = await savedRecipieApi(data);
        if (res.status === 200 || res.status === 201) {
          toast.success(capitalizeString(res?.data?.message));
          getRecipieById(location?.state?.id)
        }
        console.log(res, "responffffff");
      } catch (err) {
        console.log(err);
      }
    } else {
      let data = {
        recipeId: location?.state?.id,
        isSaved: true,
      };
      try {
        const res = await savedRecipieApi(data);
        if (res.status === 200 || res.status === 201) {
          toast.success(capitalizeString(res?.data?.message));
          getRecipieById(location?.state?.id)
        }
        console.log(res, "responffffff");
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleShareRecipie = async () => {
    if (location?.state?.id) {
      try {
        setShareLoader(true)
        const payload = { recipeId: location?.state?.id }
        const res = isShared ? await unShareRecipeApi(payload) : await shareRecipeApi(payload);
        if (res.status === 200 || res.status === 201) {
          toast.success(capitalizeString(res?.data?.message));
          document.getElementById("confirmModalshare").click()
          if(isShared){
            setIsShared(false)
          }else{
            setIsShared(true)
          }
        }
        setShareLoader(false)

      } catch (err) {
        console.log(err);
        setShareLoader(false)
        toast.error(capitalizeString(err.response?.data?.message), { id: "001" })
        document.getElementById("confirmModalshare").click()
      }
    }
  };
  const handleDownloadRecipe = async () => {
    // const token = localStorage.getItem("token");
    // const headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    // fetch(
    //   `${API_BASE_URL}/recipeAndIngredient/generateRecipesAndIngredientsPdf?recipeId=${location?.state?.id}&ingredientId=`,
    //   {
    //     headers: headers,
    //   }
    // )
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     // Save the blob as a file using FileSaver
    //     saveAs(blob, "data.pdf");
    //   })
    //   .catch((error) => {
    //     console.error("Error downloading the CSV file:", error);
    //   });
    setDownloadLoader(true);
    try {
      const res = await downloadRecipeApi(location?.state?.id, "");
      if (res.status === 200 || res.status === 201) {
        setDownloadLoader(false);
        window.open(`${BUCKET_URL + "/" + res.data.data}`, "_blank");
      }
    } catch (err) {
      toast.error(capitalizeString(err?.response?.data?.message))
      setDownloadLoader(false)
    }

  };
  const handleUpload = async (event) => {
    const logoImg = event.target.files[0];
    const imgFile = new FormData();
    let setVal = {
      name: logoImg.name,
      size: (logoImg.size / 1024 / 1024).toFixed(2),
    };

    const toastLoader = toast.loading("Uploading...");

    if (!logoImg) {
      toast.dismiss(toastLoader);
      return;
    }

    imgFile.append("file", logoImg);
    imgFile.append("type", "recipe");

    if (logoImg.size > 15000000) {
      toast.error("Please upload file less than 15 mb");
      toast.dismiss(toastLoader);
      return;
    }

    const res = await fileUploadApi(imgFile);
    if (res.status === 200) {
      setFieldValue("thumbnailImage", res?.data?.filePath || res?.data?.data);
      toast.dismiss(toastLoader);
      toast.success(capitalizeString(res?.data?.message), { id: "001" });
    }
  };
  console.log(values, "dsfkdslauehdsfhads");

  console.log(proximates, "proxiixiixixi")
  const getCheckChart = () => {
    if (proximates?.minerals?.length || proximates?.vitamins?.length || proximates?.nutrients?.length) {
      return true;
    } else {
      return false;
    }
  }
  const handleViewChart = (e) => {
    // e.preventDefault()
    setChartData()
    setIsRecipe(true);
    getRecipieById(location?.state?.id)
  }
  const getRecipieById = async (id) => {
    try {
      const res = await getRecipieByIdApi(id);
      if (res.status === 200 || res.status === 201) {
        let data = res?.data?.data;
        console.log(data, "DATAAAATEAT");
        setValues({
          name: data?.name,
          note: data?.note,
          id: location?.state?.id,
          isPublished: data.isPublished,
          thumbnailImage: data?.thumbnailImage,
          servingSize: data?.servingSize?.data
            ? data?.servingSize?.data
            : data?.servingSize,
          ingredientIds: data?.ingredientDetails,
          noOfPortion:data?.noOfPortion
        });
        setOuterValues({name:data?.name,note:data?.note})
        setSaved(data.isSaved);
        setIsMyRecipe(data?.isMyRecipe)
        setIsShared(data.isShared);
        setCalorieServing(data.caloriePerServing)
        setProximates({
          minerals: data?.proximatesDetails?.minerals,
          vitamins: data?.proximatesDetails?.vitamins,
          nutrients: data?.proximatesDetails?.nutrients,
        });
        setChartData(res?.data?.data)
        setUserDetails(data.usersDetails);
        setQuantity(data.totalQuantity)
      }
      console.log(res, "huisfnkjds");
    } catch (err) {
      console.log(err);
    }
  };

  console.log(proximates, "JJJJJJJJDJD")
  const handleClosee =()=>{
    setModalData({id:"",quantity: "",
    quantityUnit: "",
    notes: "",
    name: "",
    thumbnailImage: ""})
  }
  const handleNotesChange = (e) => {
    // if (e.target.value?.length > 500) {
    //   e.preventDefault();
    // } else {
      setFieldValue("note", e.target.value);
    // }
  };
  console.log(values.servingSize, "ADSFTREGHDS");
  const handleIngredients = (e) => {
    e.preventDefault();
    if (!modalData.id) {
      setErrorMsg('Please select Ingredient .')
    } else if(!modalData.quantity){
      setQuantityError(true)
    }else if(noteError){
      return;
    }
    else{

      if (modalData) {
        setbtnShow(true)
        setFieldValue("ingredientIds", [...values.ingredientIds, modalData]);
        document.getElementById("ingredientModalcloseBtn").click();
        setModalData({ id: "", quantity: "", notes: "", quantityUnit: "" });
        setShow(false);
      }
    }

    // console.log(modalData,"modlld" , d)
  };
  const getIngredientsData = async () => {
    try {
      setIsLoadingIngredients(true)
      const res = await getAllIngredeintsApi();
      if (res.status === 200 || res.status === 201) {
        console.log(res, "FUFUFUUFUUFUFFFUFU")
        setIngredientData(res?.data?.data);
      }
      setIsLoadingIngredients(false)
      console.log(res, "ADSGHRETEWFF");
    } catch (err) {
      console.log(err);
      setIsLoadingIngredients(false)
    }
  };

  const customNoOptionsMessage = () => {
    return isLoadingIngredients ? 'Loading...' : 'No options available';
  };

  const handleIngredeintSelect = (e) => {


    // let id = e.target.value;
    let id = e.value;
    console.log(id, "kadsfjld", ingredientData);
    let d = ingredientData?.find((v) => v.id == id);
    console.log(d, "filtereed");
    if (d) {
      setModalData({
        ...modalData,
        id: id,
        name: d?.name,
        thumbnailImage: d?.thumbnailImage,
      });
    }
    setErrorMsg("")
  };

  const handleDeleteIngredinet = (id) => {
    setDelIngredientId(id);
  };
  const confirmDeleteIngredient = (id) => {
    document.getElementById("confirmModal").click()
    values.ingredientIds?.splice(id, 1);
    setIngredientToggle(true);
    handleSubmit()
    setRender(!render);
    setShow(false)
  }
  const handleChart = async (id) => {
    console.log(id, "JJDDDDDDDDD")
    document.getElementById(`${borderId}`)?.removeAttribute("class");
    document.getElementById(`${borderId}`)?.setAttribute("class", `Card   col-12 rounded p-4 mb-5  mx-auto`);
    setBorderId(`card${id}`);
    setIsRecipe(false);
    document
      .getElementById(`card${id}`)
      .setAttribute("class", ` borderCard Card   col-12 rounded p-4 mb-5  mx-auto`);
    try {
      const res = await getAllIngridientsById(id);
      console.log(res, "rsponcgidfj");
      if (res.status === 200 || res.status === 201) {
        setChartData(res?.data?.data);
        setProximates({
          nutrients: res.data.data.proximates.nutrients,
          vitamins: res.data.data.proximates.vitamins,
          minerals: res.data.data.proximates.minerals,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const checkIngredients = () => {
    let d = Boolean(proximates?.nutrients?.length) || Boolean(proximates?.vitamins?.length) || Boolean(proximates?.minerals?.length)
    console.log(d, "DKDKDKKDDKKDKDKDD")
    return d;
  }
  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      // return
      // if(isEdit){
      //   getRecipieById(location?.state?.id);
      //   setIsEdit(false)
      // }else{
      //   event.preventDefault()
      // }
    }
  }, []);
  // console.log(updateId,"update id")
  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);
  console.log(values.ingredientIds, "dataIngredient");
  //   useEffect(() => {
  //     if (location?.state?.values) {
  //       setFieldValue("data", location?.state?.values);
  //     }
  //   }, [location]);
  useState(() => {
    getRecipieById(location?.state?.id);
  }, [location]);
  useEffect(() => {
    getIngredientsData();
  }, []);
  return (
    <AppLayout>
      <div className="d-flex justify-content-between pt-4 mb-3 position-relative"></div>
      <div className=" mt-3">
  {    values?.id  ?<div
          className="food-div border  "
          style={{ backgroundColor: "#FAFAFA" }}
        >
          <div className="row gx-0 overflow-auto">
            <div className="col-lg-5 left  col-12 ps-4  ">
              <div
                className="row"
                style={{ flexWrap: "nowrap", cursor: "pointer" }}
              >
                <h1>{outerValues?.name} </h1>
                {values?.id && isMyRecipe && (
                  <i
                    data-bs-target="#exampleModalRecipe"
                    data-bs-toggle="modal"
                    class="bi bi-pen-fill pe-2 text-danger"
                    onClick={() => setIsEdit(true)}
                  ></i>
                )}
              </div>
              <div className="d-flex justify-content-between mb-3">
                <p className="fw-bold">
                  Created by:{" "}
                  {userDetails?.userName}
                </p>
            {values?.servingSize ? <p className="fw-bold">Portion size: {values?.servingSize} g </p>
                :<p className="fw-bold">No of Portion: {values?.noOfPortion}</p>}
              </div>

              <div className="mb-3 fw-semibold d-flex " style={{ color: "#212529" }}>
            
             <span  className="fw-bolder">Note: &nbsp; </span> 
              
          <span style={{width:"90%"}} dangerouslySetInnerHTML={{ __html: outerValues?.note.replace(/\n/g, '<br />') }} />
            
          </div>

              <div className="create-recipe-image mb-3">
                <img
                  src={BUCKET_URL + "/" + values?.thumbnailImage}
                  className="img-fluid"
                  width="100%"
                  height="100%"
                  objectFit="contain"
                  onError={(e) => {
                    e.target.src = ProfilePicDemo;
                    e.error = null;
                  }}
                />
              </div>
      {isMyRecipe && <div className="mb-4">
                You can search and 'add ingredients ' to your meal < lbr /> from
                the section given below
              </div>}

              {values?.ingredientIds?.map((v, i) => {
                console.log(v, "resdmfsdkuasdfj");
                return (
                  <div
                    className="Card col-12 rounded p-md-4  p-2 mb-5  mx-auto"
                    style={{ boxShadow: "0px 0px 1px 1px #000" }}
                    onClick={() => handleChart(v.id)}
                    id={`card${v.id}`}
                  >
                    <div className="row g-0 ">
                      <div
                        className="col-2 me-2"
                        style={{ height: "38px", width: "38px" }}
                      >
                        <img
                          src={BUCKET_URL + "/" + v?.thumbnailImage}
                          className="rounded-circle "
                          alt="..."
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          onError={(e) => {
                            e.target.src = ProfilePicDemo;
                            e.error = null;
                          }}
                        />
                      </div>
                      <div className="col-9 ps-2  ">
                        <div className="row g-0">
                          <div className="col-md-8 col-6"> <h3 className="m-0 p-14 ">{v?.name}</h3></div>
                          <div className="col-md-4 col-6  text-end"> <span className=""> {v?.quantity + " " + (v?.quantityUnit ? v?.quantityUnit : "g")} </span>
                          </div>
                        </div>
                      </div>
                      {isMyRecipe && <div
                        data-bs-target="#confirmModal"
                        data-bs-toggle="modal"
                        onClick={(e) =>{
                          e.stopPropagation();
                           handleDeleteIngredinet(i)}
                          }
                        className="col-1  ms-md-auto m-0"
                        style={{cursor:"pointer"}}
                      >
                        <i className="bi bi-trash3  p-1 rounded-2 border"></i>
                      </div>}
                    </div>
                    {/* <div className="overflow-auto">Note :{v.notes}</div> */}
                    {v?.notes && <div className="overflow-auto d-flex ">
                      <span >Note: &nbsp;  </span> 
                      <span style={{width:"90%"}} dangerouslySetInnerHTML={{ __html: v?.notes.replace(/\n/g, '<br />') }} />
                    </div>}
                  </div>
                );
              })}

              {/* <div
                className="Card   col-12 rounded p-4 mb-5  mx-auto"
                style={{ boxShadow: "0px 0px 1px 1px #000" }}
              >
                <div className="row gx-0 ">
                  <div
                    className="col-2 me-2"
                    style={{ height: "38px", width: "38px" }}
                  >
                    <img
                      src="./assets/img/avatars/profiles/avatar-1.jpg"
                      className=" rounded-circle"
                      alt="..."
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div className="col-9 ps-2 d-flex align-items-center ">
                    <h3 className="m-0 p-14 ">Potato Mash with Butter</h3>
                    <span className="ms-4"> 250 g</span>
                  </div>
                  <div className="col-auto  ">
                    <i className="bi bi-trash3 h1 p-1 rounded-2 border"></i>
                  </div>
                </div>
                <div>
                  Note : Lorem ipsum dolor sit amet, consectetur <br />{" "}
                  adipiscing elit, sed do
                </div>
              </div> */}
              <div className="d-flex justify-content-between">

                {/* {Boolean(values.ingredientIds?.length) && show && <button */}
                {show && <button
                    type="button"
                    className=" submit-buton btn py-3 p-14 pt-3 mx-2"
                    onClick={handleViewChart}
                  >
                    View Chart
                  </button>
                }

                {isMyRecipe &&  <button
                  type="button"
                  className="submit-buton btn py-3 p-14 pt-3 mx-2"
                  data-bs-target="#addIngredientModal"
                  data-bs-toggle="modal"
                  onClick={() => setIngredientToggle(true)}
                >
                  Add Ingredient
                </button>}
              </div>
              {Boolean(values?.ingredientIds?.length) && btnShow && (
                <div className=" row py-3 p-14 pt-3 mx-2">
                  <button
                    type="button"
                    className=" btn_custome py-3 p-14 pt-3 mx-2"
                    onClick={handleSubmit}
                    disabled={loader}
                  >
                    {loader ? <WhiteLoader/> :"Confirm Recipe"}
                  </button>
                </div>
              )}
            </div>

            {/* { Boolean(values.ingredientIds?.length) && ( */}
              <div className="col-lg-5 col-md-4 col-12 mx-auto px-lg-5 px-md-2">
               <div className="all-svg">
              {isMyRecipe && <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 20.908 17.431"
                    style={{ cursor: "pointer" }}
                    // onClick={handleShareRecipie}
                    fill={isShared ? "#fd3468" : "currentColor"}
                    // data-bs-toggle={isShared ? "" : "modal"}
                    // data-bs-target={isShared ? "" : "#confirmModalshare"}
                    data-bs-toggle="modal"
                    data-bs-target="#confirmModalshare"
                  >
                    <g
                      id="Group_4201"
                      data-name="Group 4201"
                      transform="translate(-4978.529 140.166)"
                    >
                      <path
                        id="Path_10694"
                        data-name="Path 10694"
                        d="M5309.268,40.676a17.426,17.426,0,0,1-1.514,1.063,17.3,17.3,0,0,1-1.711.751.9.9,0,0,1-.168.035,7.213,7.213,0,0,0-4.324-5.676c.133-.181.255-.358.39-.525a.163.163,0,0,1,.152-.011,3.2,3.2,0,0,0,1.415.4,2.992,2.992,0,0,0,1.706-.449,5.265,5.265,0,0,0,.684-.511.242.242,0,0,1,.32-.044,5.559,5.559,0,0,1,2.913,3.943c.048.245.056.5.086.746.01.079.033.157.05.235Z"
                        transform="translate(-309.831 -168.654)"
                      />
                      <path
                        id="Path_10695"
                        data-name="Path 10695"
                        d="M4978.529,40.548c.032-.247.057-.5.1-.741a5.56,5.56,0,0,1,2.972-4.191c.146-.08.219-.017.314.07a3.5,3.5,0,0,0,1.671.881,2.968,2.968,0,0,0,2.045-.311c.146-.077.221-.067.306.076a4.93,4.93,0,0,0,.323.434,7.307,7.307,0,0,0-2.919,2.264,7.217,7.217,0,0,0-1.409,3.435c-.332-.118-.65-.218-.959-.345a8.143,8.143,0,0,1-2.308-1.436,1.5,1.5,0,0,0-.132-.094Z"
                        transform="translate(0 -168.568)"
                      />
                      <path
                        id="Path_10696"
                        data-name="Path 10696"
                        d="M5085.68,83.028a6.316,6.316,0,0,1,2.161,1.692,6.4,6.4,0,0,1,1.489,3.987.319.319,0,0,1-.095.221,9.6,9.6,0,0,1-11.755.761c-.326-.22-.626-.478-.942-.714a.309.309,0,0,1-.136-.293,6.4,6.4,0,0,1,3.526-5.6c.136-.075.205-.027.3.058a3.994,3.994,0,0,0,2.186,1.093,3.422,3.422,0,0,0,2.335-.52C5085.067,83.514,5085.359,83.266,5085.68,83.028Z"
                        transform="translate(-93.876 -214.079)"
                      />
                      <path
                        id="Path_10697"
                        data-name="Path 10697"
                        d="M5150.493-109.2a2.711,2.711,0,0,1-1.723-.963,5.4,5.4,0,0,1-1.567-3.681,3.517,3.517,0,0,1,2.662-3.483,3.56,3.56,0,0,1,4.439,2.887,4.486,4.486,0,0,1-.377,2.5,5.052,5.052,0,0,1-1.667,2.222A2.7,2.7,0,0,1,5150.493-109.2Z"
                        transform="translate(-161.786 -21.797)"
                      />
                      <path
                        id="Path_10698"
                        data-name="Path 10698"
                        d="M5044.9-133.225a1.994,1.994,0,0,1-2.145.107,3.949,3.949,0,0,1-1.563-1.729,4.558,4.558,0,0,1-.549-2.21,3.118,3.118,0,0,1,2.857-2.965,3.08,3.08,0,0,1,2.532.978.709.709,0,0,1,.054.061.224.224,0,0,1,.021.053,4.211,4.211,0,0,0-1.652,2.616A5.336,5.336,0,0,0,5044.9-133.225Z"
                        transform="translate(-59.579 -0.13)"
                      />
                      <path
                        id="Path_10699"
                        data-name="Path 10699"
                        d="M5295.65-133.334c.9-2.195.723-4.148-1.209-5.747a2.887,2.887,0,0,1,1.092-.817,3.107,3.107,0,0,1,4.318,2.234,3.779,3.779,0,0,1-.3,2.267,4.121,4.121,0,0,1-1.745,2.139A2.008,2.008,0,0,1,5295.65-133.334Z"
                        transform="translate(-303.012 0)"
                      />
                    </g>
                  </svg>}
                  {
                    downloadLoader ? <div style={{ boxShadow: "0px 3px 6px #00000029", backgroundColor: "white", padding: "6px 8px", marginLeft: "5px", borderRadius: "10px" }}> <WhiteLoader color="black !important" /></div> : 
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="bi bi-download"
                      viewBox="0 0 16 16"
                      style={{ cursor: "pointer" }}
                      onClick={handleDownloadRecipe}
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                  }

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill={saved ? "#fd3468" : "currentColor"}
                    className="bi bi-bookmark-plus-fill"
                    viewBox="0 0 16 16"
                    onClick={handleSaveRecipe}
                    style={{ cursor: "pointer" }}
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zm6.5-11a.5.5 0 0 0-1 0V6H6a.5.5 0 0 0 0 1h1.5v1.5a.5.5 0 0 0 1 0V7H10a.5.5 0 0 0 0-1H8.5V4.5z"
                    />
                  </svg>
                </div>
                <div class="card  mt-3" style={{ width: "30rem", height: "0" }}>
                  {chartData && <RecipeChart 
                    chartDetails={chartData}
                    handleCloseChart={() => handleCloseChart()}
                  />}
                </div>
              </div>
            {/* )} */}
          </div>
        </div>:<div className="text-center">
            <ThemeSpinner />
          </div>}
      </div>

      {/* recipe modal start here  */}

      <div
        className="modal fade"
        id="addIngredientModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"

      >
        <form>
          <div className="modal-dialog modal-dialog-centered ">
            <div className="modal-content">
              <div className="modal-body">
                {/* <a href="" className="close-img" data-bs-dismiss="modal">
                  <img src="./assets/images/close.svg" alt="" srcset="" />
                </a>
                <div className="text-center">
                  <img src="./assets/images/bkimg.PNG" width="45" alt="" />
                </div> */}
                <div style={{ display: "flex", justifyContent: "end", cursor: "pointer" }}>
                  <button
                    type="button"
                    className="border-0 bg-transparent"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleClosee}
                    id='ingredientModalcloseBtn'
                  // onClick={()=>getRecipieById(location.state.id)}
                  >
                    {" "}
                    <img src={CloseBtn} />
                  </button>
                </div>
                <h4 className="text-center">Add Ingredients </h4>

                <div className="position-relative mt-4">
                  {/* <select
                    className="form-control"
                    type="text"
                    name="name"
                    value={modalData.id}
                    onChange={handleIngredeintSelect}
                    // value={createRecepieFormik?.values.name}

                    id="name"
                    placeholder="Breakfast Recipe 1"
                  >
                    <option value="" hidden>
                      -
                    </option>
                    {ingredientData?.map((v) => {
                      return <option value={v.id}>{v.name}</option>;
                    })}
                  </select> */}
                   <Select
                    value={{label:modalData.name,value:modalData.name}}
                    onChange={handleIngredeintSelect}
                    options={ingredientData?.map((v) => ({label:v.name,value:v.id}))}
                    placeholder='search and add ingredients from the list below'
                    noOptionsMessage={customNoOptionsMessage}

                  />
                  <label htmlFor="name" className="input-label">
                    Select Ingredient
                  </label>
                  <p className="text-danger">{errorMsg}</p>
                </div>

                <div className="row mt-4">
                  <div className="col-5">
                    <select value={modalData.quantityUnit} onChange={(e) =>
                      setModalData({ ...modalData, quantityUnit: e.target.value })
                    } className="form-select" name="" id="">
                      {/* <option value="" hidden>-</option> */}
                      {/* <option value="ml">ml</option> */}
                      <option selected value="g">g</option>
                    </select>
                  </div>
                  <div className="col-7">
                  <input
                    className="form-control"
                    type="text"
                    onKeyPress={(event) => {
                      if (!/^\d*\.?\d*$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    name="servingSize"
                    value={modalData.quantity}
                    onChange={(e) => {
                      if(quantityError)
                      setQuantityError(false)
                      setModalData({ ...modalData, quantity: e.target.value });
                    }}
                    id=""
                    placeholder="Add Weight"
                  />
           {quantityError && <p className="text-danger px-2 pt-2">
                    Quantity Required 
                  </p>}
                  </div>
                </div>

                <label className="form-label mt-3" htmlFor="add">
                  Add ingredient note.
                </label>
                <textarea
                  className="form-control"
                  name="note"
                  //   onChange={(e) => {
                  //     globalHandleChange(e, createRecepieFormik);
                  //   }}
                  //   value={createRecepieFormik.values.note || ""}
                  value={modalData.notes}
                  // onChange={(e) => setModalData({ ...modalData, notes: e.target.value })}
                  onChange={handleNote}
                  id=""
                  cols="30"
                  rows="3"
                />
                {noteError && <p className="text-danger">Notes here can have a maximum of 500 words.</p>}
                <div className="text-center">
                  <button
                    className="btn_custome mt-4"
                    type="button"
                    onClick={handleIngredients}
                  >
                    Add Ingredient
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* recipie modal update   */}

      <div
        className="modal fade"
        id="exampleModalRecipe"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <form>
          <div className="modal-dialog modal-dialog-centered newrecipe">
            <div className="modal-content">
              <div className="modal-body">

                {/* <a href="" className="close-img" data-bs-dismiss="modal">
                  <img src="./assets/images/close.svg" alt="" srcset="" />
                </a>
                <div className="text-center">
                  <img src="./assets/images/bkimg.PNG" width="45" alt="" />
                </div> */}
                <div style={{ display: "flex", justifyContent: "end", cursor: "pointer" }}>
                  <button
                    type="button"
                    className="border-0 bg-transparent"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => getRecipieById(location.state.id)}
                  >
                    {" "}
                    <img src={CloseBtn} />
                  </button>
                </div>
                <h2 className="text-center  ">Modify this Recipe</h2>


                <div className="d-flex justify-content-between">
                  <label
                    className="upload text-danger"
                    style={{ fontSize: ".8rem" }}
                  >
                    Upload Recipe image Here
                    <input
                      type="file"
                      name="thumbnailImage"
                      ref={fileInputRef}

                      // onChange={(e) => {
                      //   handleFileUpload(e, createRecepieFormik, setImgSize, "recipe");
                      // }}
                      onChange={handleUpload}
                      accept="image/*"
                      style={{ display: "none" }}
                    />
                  </label>
                </div>
                {/* {createRecepieFormik?.values?.thumbnailImage && ( */}
                <div className="d-flex justify-content-between after-upload-img mt-2">
                  <div className="me-3">
                    <img
                      // src={`${assignProfilePicBucketUrl(
                      //   createRecepieFormik.values.thumbnailImage
                      // )}`}
                      src={BUCKET_URL + "/" + values.thumbnailImage}
                      width="44"
                      alt=""
                    />
                  </div>
                  {
                    console.log(values.thumbnailImage , "imgaeeggeee")
                  }
                  <div style={{ textAlign: "left", width: "100%" }}>
                    <h4>{values.thumbnailImage}</h4>
                    {/* <h4>{imgSize?.thumbnailImage?.name || "Image Name"}</h4>
                      <h5>
                        {imgSize?.thumbnailImage?.size || "Image Size in"} MB
                      </h5> */}
                  </div>
                  {values.thumbnailImage && <div>
                    {/* <a href="">X</a> */}
                    <i className="bi bi-x" onClick={handleRecipeImgaeCros} style={{ cursor: "pointer" }}></i>
                  </div>}
                </div>
                {/* )} */}

                <div className="position-relative mt-4">
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    id="name"
                    placeholder="Breakfast Recipe 1"
                  />
                  <label htmlFor="name" className="input-label">
                    Enter Recipe Name
                  </label>
                </div>
                <p className="text-danger px-2 pt-2">
                    {errors?.name}
                  </p>
                <div className="row mt-4">
                  <div className="col-4">
                    {/* <select value={ml} className="form-select" name="" id="">
                      <option value="g">g</option>
                    </select> */}
                     <label className="form-label mt-3" htmlFor="add">
                    No. of Portion
                    </label>
                    <input
                      className="form-control px-2"
                      type="text"
                      name="noOfPortion"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={handleChangeInput}
                      value={values.noOfPortion || ""}
                      placeholder="Portion No."
                    />
                  </div>
                  <div className="col-1 d-flex align-items-end pb-3 ">
                    <span>or</span>
                  </div>
                  <div className="col-4">
                  <label className="form-label mt-3" htmlFor="add">
                     Portion Size
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="servingSize"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={handleChangeInput}
                      value={values.servingSize}
                      id=""
                      placeholder="Add Weight"
                    />
                  </div>
                  <div className="col-2 d-flex align-items-end">
                    <span className="fw-bolder pb-3">grams</span>
                  </div>
                </div>
                <p className="text-danger px-2 pt-2">
                    {errors?.servingSize || errors?.noOfPortion}
                  </p>

                <label className="form-label mt-3" htmlFor="add">
                  Add recipe note.
                </label>
                <textarea
                  className="form-control"
                  name="note"
                  onChange={handleNotesChange}
                  value={values.note}
                  id=""
                  cols="30"
                  rows="3"
                  // placeholder="Enter Notes here Max 500 words."
                ></textarea>
                {errors?.note &&  <p className="text-danger px-2 pt-2">
                    {errors?.note}
                  </p>}
                <div>
                  <input
                    value={values.isPublished}
                    checked={values.isPublished}
                    onChange={(e) => 
                      setFieldValue("isPublished", e.target.checked)
                    }
                    type="checkbox"
                    name=""
                    id=""
                  />{" "}
                  Share this recipe publicly on The CalorieFinder
                </div>
                <div className="text-center">
                  <button
                    className="btn_custome mt-4"
                    type="button"
                    onClick={handleSubmit}
                    disabled={loader}
                  >
                    {loader ? <WhiteLoader/> : "Update Recipe"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* confirm modal start heree  */}

      <div
        className="modal fade"
        id="confirmModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content pb-4">
            <div className="modal-header border-bottom-0 pb-0">
              <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
              <img className="modal-image-1" />
              <button
                type=""
                className=" border-0 bg-transparent"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {" "}
                <img src={CloseBtn} />
              </button>
            </div>
            <div className="modal-body px-5 pt-3 py-0">
              <h1 className="modal-title  fw-bold p-16  pt-5 fs-5 text-center">
                Are you sure you want to remove this Ingredient ?
              </h1>
            </div>

            <div className=" mx-auto">
              <button className="btn_custome mt-4 modal-submit me-5 " data-bs-dismiss="modal"
                aria-label="Close">
                No
              </button>
              <button
                className="btn_custome mt-4 modal-submit"
                onClick={() => confirmDeleteIngredient(delIngredientId)}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>



      {/* shre conrim  */}

      <div
        className="modal fade"
        id="confirmModalshare"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content pb-4">
            <div className="modal-header border-bottom-0 pb-0">
              <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
              <img className="modal-image-1" />
              <button
                type=""
                className=" border-0 bg-transparent"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {" "}
                <img src={CloseBtn} />
              </button>
            </div>
            <div className="modal-body px-5 pt-3 py-0">
              <h1 className="modal-title  fw-bold p-16  pt-5 fs-5 text-center">
                {`Are you sure want to ${isShared ? "un-share" :"share"} recipe to community ?`}
              </h1>
            </div>

            <div className=" mx-auto">
              <button
                className="btn_custome mt-4 modal-submit me-5 "
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                No
              </button>
              <button
                disabled={shareLoader}
                className="btn_custome mt-4 modal-submit"
                onClick={handleShareRecipie}
              >
               {shareLoader ? <WhiteLoader/> :"Yes"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default ViewRecipe;
