import React from 'react'
import MainRouter from './MainRouter/MainRouter'
import UserContextState from './Contexts/UserContextState'
import AuthContextState from './Contexts/AuthContextState'

const App = () => {
  return (
    <>
      <UserContextState>
        <MainRouter />
      </UserContextState>
    </>
  )
}

export default App