import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import firebase from "../../../../Utils/Firebase"
import OtpInput from 'react-otp-input'
import { AuthContext, UserContext } from '../../../../Contexts/Contexts'
import { WhiteLoader } from '../../../../Utils/Loaders'
import { signUpApi } from '../../../../Api/Apis'
import { getToken, resendOtpWithFirebase, setToken } from '../../../../Utils/HelperFuntions'
import toast from 'react-hot-toast'

const SignUpOtp = () => {
   const TimerSecondsValue = 30
   const { verificationId, setVerificationId } = useContext(AuthContext)
   const { setIsLoggedIn } = useContext(UserContext)
   const [code, setCode] = useState('');
   const [loader, setLoader] = useState(false);
   const [timer, setTimer] = useState(0);

   const historyState = useLocation()
   const navigate = useNavigate()

   console.log(historyState, "history state at otp", verificationId)

   const handleSetCode = (e) => {
      setCode(e)
   }

   const handleVerifyCode = async () => {
      setLoader(true)
      const credential = firebase.auth.PhoneAuthProvider.credential(historyState?.state?.verificationId, code);

      await firebase
         .auth()
         .signInWithCredential(credential)
         .then((userCredential) => {
            // User successfully authenticated
            const user = userCredential.user;
            // console.log('User authenticated:', user?.multiFactor?.user?.accessToken);
            // console.log('User authenticated:', user?._delegate?.accessToken);
            handleSignUp({ token: user?.multiFactor?.user?.accessToken, loginType: "manual", accountType: historyState?.state?.role === "business" ? "business":"personal",planName:historyState?.state?.planType ,email:historyState?.state?.email})
         })
         .catch((error) => {
            toast.error("Invalid OTP", { id: "001" })

            setLoader(false)
            // Handle error
            console.error('Error verifying code:', error);
         });
   };

   const handleSignUp = async (payload) => {
      try {
         const res = await signUpApi(payload)
         if (res?.status === 200) {
            if(historyState?.state?.role === "business"){
               alert(`Your 15 days trial is successfully activated.`)
            }
            setToken(res?.data?.token)
            if (getToken() !== "") {
               historyState?.state?.role === "business" ? navigate("/add-sites") : navigate("/edit-profile", {state:{"newUser":true}})
            }
            setLoader(false);
            setIsLoggedIn(true)
         }
      } catch (error) {
         console.log(error)
      }
   }

   const handleResendOTP = () => {
      resendOtpWithFirebase(historyState?.state?.phone)
      setTimer(TimerSecondsValue);
   };

   const setTimeOnFirstMount = () => {
      setTimer(TimerSecondsValue)
   }

   useEffect(() => {
      if (timer === 0) {
         setTimeOnFirstMount()
      }
   }, [])

   useEffect(() => {
      let intervalId;
      if (timer > 0) {
         intervalId = setInterval(() => {
            setTimer(timer - 1);
         }, 1000);
      }

      return () => {
         clearInterval(intervalId);
      };
   }, [timer]);

   return (
      <>
         <div className="container-fliud mask">
            <div className="row ms-0 me-0">
               <div className="col-xl-7 col-lg-6 text-center">
                  <div className="img_logo">
                     <img src={process.env.PUBLIC_URL + "/assets/images/logo.svg"} />
                     <img
                        src={process.env.PUBLIC_URL + "/assets/images/tcf.svg"}
                        className="tcf_logo"
                     />
                  </div>
               </div>
               <div className="col-xl-5 col-lg-6">
                  <div className="login-welcome otp-welcome mask2">
                     <h2 className="text-center">Verify your OTP</h2>
                     <p className="text-center mt-5">
                        We will send you a <b> One Time Password </b><br /> on this mobile number.
                     </p>
                     <p className="text-center mt-2">
                        Send OTP to <b>{historyState?.state?.phone}</b>
                     </p>
                     <label htmlFor="full" className="form-label mt-5">
                        Verification Code
                     </label>
                     <OtpInput
                        // value={otp}
                        onChange={(e) => { handleSetCode(e) }}
                        numInputs={6}
                        containerStyle={`otp-field`}
                        shouldAutoFocus
                        inputStyle={{
                           margin: "10px",
                           width: "2.375rem",
                           height: "2.375rem",
                           borderRadius: "0.313rem",
                           textAlign: "center",
                           border: "1px solid rgba(0,0,0,0.3)",
                           display: "flex",
                           justifyContent: "space-around",
                        }}
                        errorStyle={{ border: "solid 1px red" }}
                        // hasErrored={isWrongOtp}
                        value={code}
                        isInputNum
                        // renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                     />
                     <p className="dont_rev_otp mt-4 text-align-left">
                        Didn't receive the OTP?{" "}
                        {timer === 0 ? (
                           // <button onClick={handleResendOTP} className="btn_custome mt-4 otp-submit">Resend OTP</button>
                           <a className="ms-3" onClick={handleResendOTP} style={{ "cursor": "pointer" }}>Resend OTP</a>
                        ) : (
                           <a className="ms-3">Resend OTP in {timer} seconds</a>
                        )}
                     </p>
                     {
                        console.log(((timer > 0) && (code.length <= 5)), "KKKKK", code.length)
                     }
                     <button
                        className="btn_custome mt-4 otp-submit"
                        onClick={() => { handleVerifyCode() }}
                        disabled={((timer > 0) && (code.length <= 5)) ? true : false}
                     >
                        {loader ? (<><WhiteLoader /></>) : "Submit"}
                     </button>
                  </div>
               </div>
            </div>
         </div>
         <div id="recaptcha-container"></div>
      </>
   )
}

export default SignUpOtp
