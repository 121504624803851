import React, { useEffect, useState } from "react";
import AppLayout from "../../AppLayout/AppLayout";
import {
  downloadRecipeApi,
  getAllIngridientsById,
  getAllSavedIngredientsAndRecipeApi,
  getRecipieByIdApi,
  getSavedIngredientApi,
  getSavedRecipeApi,
  saveInggredientApi,
  savedRecipieApi,
} from "../../Api/Apis";
import { BUCKET_URL } from "../../Utils/BaseUrl";
import Default from "../Default";
import ProfilePicDemo from "../../Assets/Images/Group 10775.png";
import { Link, useNavigate } from "react-router-dom";
import chart_image from "../../Assets/Images/person.png";
import RecipeChart from "../Common/RecipeChart";
import { ThemeSpinner } from "../../Utils/Loaders";
import { WhiteLoader } from "../Loader/Loader";
import toast from "react-hot-toast";

const SavedRecipe = () => {
  const [savedRecipe, setSavedRecipe] = useState([]);
  const [savedIngredient, setSavedIngredient] = useState([]);
  const [recipeChartData, setRecipeChartData] = useState();
  const [ingredientChartData, setIngredinetChartData] = useState();
  const [chartDetails, setChartDetails] = useState();
  const [isRecipe, setIsRecipe] = useState(false);
  const [toggle,setToogle]=useState(false);
  const [quantity, setQuantity] = useState();
  const [loader,setLoader] = useState(true)
  const [downloadLoader,setDownloadLoader] = useState(false)
  const [saved,setSaved] = useState()
  const [proximates, setProximates] = useState({
    minerals: [],
    vitamins: [],
    nutrients: [],
  });
  const [currentPage,setCurrentPage] = useState(1)
  const [cardIndex,setCardIndex] = useState()

  const LIMIT = 30;
  
  const navigate = useNavigate()

  const getAllSavedItems = async() =>{
    try{
      setLoader(true)
      const res =  await getAllSavedIngredientsAndRecipeApi("",currentPage,LIMIT)
      if (res.status === 200 || res.status === 201) {
        setSavedRecipe(res?.data?.data);

      }
      setLoader(false)
    }catch(err){
      setLoader(false)
      console.log(err)
    }
  }

  const handleRecipeChart = async (id) => {
    const res = await getRecipieByIdApi(id);
    if (res.status === 200 || res.status === 201) {
      setRecipeChartData(res.data.data);
      setChartDetails(res.data.data);
      setIngredinetChartData()
      setSaved(true)
      setQuantity(res.data.data.totalQuantity);
      setIsRecipe(true);
      setToogle(true)
      setProximates({
        vitamins: res?.data?.data?.proximatesDetails?.vitamins,
        minerals: res?.data?.data?.proximatesDetails?.minerals,
        nutrients: res?.data?.data?.proximatesDetails?.nutrients,
      });
    }
  };

  const handleSaveRecipeOrIngredients = async () => {
    let data = chartDetails?.ingredientStatus ?
    {ingredientId:chartDetails?.id,
    isSaved : false
  }
    :{
      recipeId: chartDetails?.id,
      isSaved: false,
    };
    try {
      const res =chartDetails?.ingredientStatus ? await saveInggredientApi(data) :  await savedRecipieApi(data);
      if (res.status === 200 || res.status === 201) {
        toast.success(res?.data?.message);
        handleCloseChart()
        getAllSavedItems()
        setSaved(!chartDetails?.isSaved)

      }
    } catch (err) {
      console.log(err);
    }
};

const handleDownloadRecipe = async () => {
  if(!chartDetails?.id)
  return
  setDownloadLoader(true)
  try {
    const res = chartDetails?.ingredientStatus ? await downloadRecipeApi("",chartDetails?.id) :await downloadRecipeApi(chartDetails?.id, "");
    if (res.status === 200 || res.status === 201) {
      window.open(`${BUCKET_URL + "/" + res.data.data}`, "_blank");
      setDownloadLoader(false)
    }
  } catch (err) {
    toast.error(err?.response?.data?.message);
    setDownloadLoader(false)
  }

};

  const handleIngredinetChart = async (id,index) => {
    setCardIndex(index)
    const res = await getAllIngridientsById(id);
    if (res.status === 200 || res.status === 201) {
      setIngredinetChartData(res.data.data);
      let temp = JSON.parse(JSON.stringify(res?.data?.data))
      temp['proximatesDetails'] = temp['proximates'] 
      setChartDetails(temp);
      setSaved(true)
      setRecipeChartData()
      setToogle(true)
      setIsRecipe(false);
      setProximates({
        vitamins: res?.data?.data?.proximates?.vitamins,
        minerals: res?.data?.data?.proximates?.minerals,
        nutrients: res?.data?.data?.proximates?.nutrients,
      });
    }
  };
  const handleCloseChart = () => {
    setCardIndex()
    setChartDetails();
    setToogle(false)
  };
  useEffect(() => {
    getAllSavedItems();
  }, []);
  return (
    <AppLayout>
      <div className="">
      <div className="all-svg mb-4">
            {chartDetails && (
              <>
                {" "}
                {downloadLoader ? (
                  <div className="text-dark"
                    style={{
                      boxShadow: "0px 3px 6px #00000029",
                      backgroundColor: "white",
                      padding: "6px 8px",
                      marginLeft: "5px",
                      borderRadius: "10px",
                    }}
                  >
                    {/* {" "} */}

                    <WhiteLoader color="black" />
                  </div>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-download"
                    viewBox="0 0 16 16"
                    style={{ cursor: "pointer" }}
                    onClick={handleDownloadRecipe}
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                )}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill={saved ? "#fd3468" : "currentColor"}
                  className="bi bi-bookmark-plus-fill"
                  viewBox="0 0 16 16"
                  onClick={handleSaveRecipeOrIngredients}
                  style={{ cursor: "pointer" }}
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zm6.5-11a.5.5 0 0 0-1 0V6H6a.5.5 0 0 0 0 1h1.5v1.5a.5.5 0 0 0 1 0V7H10a.5.5 0 0 0 0-1H8.5V4.5z"
                  />
                </svg>
              </>
            )}
          </div>
      <div className="row">
        <div className={` col-xl-${toggle ? 7 : 12} col-lg-${toggle ? 6 : 12
                  } disable-scroller`}>
                     <div className="row">
          {loader ? 
          <div className="text-center">
          <ThemeSpinner/>
          </div>
          :Boolean(savedRecipe?.length) ?
          savedRecipe?.map((v,index) => {
            return (
              <div className={`col-xl-${toggle ? 6 : 4} col-lg-${toggle ? 12 : 6
              } mt-3`}>
                <div className={`food-div ${index === cardIndex ? "borderCard":""}`}>
                  <div className="image-div ratio ratio-4x3" style={{cursor:v?.isRecipe ?"pointer":"default"}} onClick={()=>v?.isRecipe  ? navigate("/view-recipes", { state: { id:v?.recipeId} }) : {}}>
                    <img
                      className=" img-fluid"
                      src={`${BUCKET_URL}/${v?.isRecipe ? v?.recipesDetails?.thumbnailImage : v?.ingredientDetails?.thumbnailImage}`}
                      width="100%"
                      height="100%"
                      alt=""
                      onError={(e) => {
                        e.target.src = ProfilePicDemo;
                        e.error = null;
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <p className="ingredients">{v?.isRecipe ? "Recipe" :"Ingredient"}</p>

                    <small
                      style={{ color: "#000", fontWeight: "600" }}
                      className=" d-flex flex-column"
                    >
                      <p className="m-0 p-10">
                        <span> Created on:</span> <span>{v.dateTime}</span>
                      </p>
                      {v?.isRecipe ? <p className="m-0 p-10">
                        <span> Created by:</span>{" "}
                        <span>

                            {v?.userDetails?.userName}
                        </span>
                      </p>:null}
                    </small>
                  </div>

                  <h1 style={{minHeight:"60px"}}>{v?.isRecipe ? v?.recipesDetails?.name : v?.ingredientDetails?.name}</h1>
                  <button
                    onClick={() => v?.isRecipe ? navigate("/view-recipes", { state: { id: v?.recipesDetails?.id } }) : handleIngredinetChart(v?.ingredientId,index)}
                    className="mt-3 btn-view-chart w-100"
                  >
                    View Chart
                  </button>
                </div>
              </div>
            );
          })
          :<Default name = 'Saved Items' subHeading="Save items to get started" showSubHeading/>
        }
            </div>
  
        </div>

        {chartDetails && (
          <div className="col-xxl-4  col-xl-4 col-lg-6 mt-3 ">
            <RecipeChart chartDetails={chartDetails} isRecipe={isRecipe} handleCloseChart={()=>handleCloseChart()}/>
          </div>
        )}
      </div>
      </div>
    </AppLayout>
  );
};

export default SavedRecipe;
