import React from 'react'
import CloseButton from "../../Assets/Images/Group 4292.svg";

const AccountDeletedByAdminModal = () => {
  return (
    <div
    className="modal fade"
    id="deleteModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog  modal-dialog-centered">
      <div className="modal-content pb-4">
        <div className="modal-header border-bottom-0 pb-0">
          <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
          <img className="modal-image-1" />
          <button
            type=""
            className=" border-0 bg-transparent"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            {" "}
            <img src={CloseButton} />
          </button>
        </div>
        <div className="modal-body px-5 pt-3 py-0">
          <h1 className="modal-title pb-3 fw-bold p-16 fs-5 text-center">
            Account Deleted
          </h1>

          <p className="p-10 mb-0 ps-4">
            Your Account has been Deleted!!. Please Contact Admin.
          </p>
        </div>

        <div className=" mx-auto">
          <button
            className="btn_custome mt-4 modal-submit me-5 "
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default AccountDeletedByAdminModal