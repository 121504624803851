import React, { useEffect, useState } from "react";
import AppLayout from "../../AppLayout/AppLayout";
import { useFormik } from "formik";
import {
  globalHandleChange,
  handleFileUpload,
  handleMultiFileUpload,
  urlRegex,
} from "../../Utils/HelperFuntions";
import ProfilePicDemo from "../../Assets/Images/Group 10775.png";
import {
  addIngredients,
  getAllIngridientsById,
  getAllIngridientsUpdateById,
  getProximates,
  multiFileUpload,
  updateIngredientApi,
  updateIngredients,
} from "../../Api/Apis";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router";
import { BUCKET_URL } from "../../Utils/BaseUrl";
import { Link } from "react-router-dom";
import { values } from "lodash";

const AddIngredients = () => {
  const [showModal, setShowModal] = useState(false);
  const [render, setRender] =useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [togglePre, setTogglePre] = useState(false);
  // const [proximate, setProximate] = useState();
  const [errorMsg, setErrorMsg] = useState({
    name: "",
    unit: "",
  });
  const [images, setImages] = useState([]);
  const [imgSize, setImgSize] = useState({
    frontImage: { name: "", size: "" },
    backImage: { name: "", size: "" },
    thumbnailImage: { name: "", size: "" },
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [linkData, setLinkData] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const formikAddIngredients = useFormik({
    initialValues: {
      name: "",
      ingredientQuantity: 100,
      ingredientUnit: "g",
      caloriePerUnit: "",
      referenceLink: [],
      referenceImages: [],
      brandName:"",
      frontImage: "",
      backImage: "",
      thumbnailImage: "",
      proximates: {},
    },
    validationSchema: "",
    onSubmit: async (values) => {
      let z = [];
      const formdata = new FormData();
      // imageFiles?.forEach((file) => { formdata.append("file", file); z.push(file) })
      formdata.append("file", imageFiles);
      try {
        const multi_upload = {
          folderName: "ingredientsImages",
          file: formdata,
        };
        const mineralsStringified = values.proximates.minerals?.map((mineral) =>
          JSON.stringify(mineral)
        );

        // Create an array of stringified vitamin objects
        const vitaminsStringified = values.proximates.vitamins?.map((vitamin) =>
          JSON.stringify(vitamin)
        );

        // Create an array of stringified nutrient objects
        const nutrientsStringified = values.proximates.nutrients?.map(
          (nutrient) => JSON.stringify(nutrient)
        );

        // Create a new proximates object with the stringified arrays
        const proximatesStringified = {
          minerals: mineralsStringified,
          vitamins: vitaminsStringified,
          nutrients: nutrientsStringified,
        };

        const newData = {
          ...values,
          // proximates: proximatesStringified,
          proximates: {
            minerals: values?.proximates?.minerals?.filter(
              (l) => l.value !== 0
            ),
            vitamins: values?.proximates?.vitamins?.filter(
              (l) => l.value !== 0
            ),
            nutrients: values?.proximates?.nutrients?.filter(
              (l) => l.value !== 0
            ),
          },
          id: location?.state?.ingredientId,
        };
        console.log(values.proximates, "DFASDFDSF", newData);
        // const res = await multiFileUpload({ folderName: "ingredientsImages", file: formdata })
        // const res = await multiFileUpload({ folderName: "ingredientsImages", file: formdata })
        // console.log(res, "ersssssssssssssssssssssss")

        const res1 =
          location?.state?.edit === true
            ? await updateIngredientApi(newData)
            : await addIngredients(newData);
        if (res1.status === 200 || res1.status === 201) {
          toast.success(res1.data.message);
          document.getElementById("exampleModal").click();
          formikAddIngredients.resetForm();
          navigate("/");
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const handlerr = (id) => {
    formikAddIngredients?.values?.referenceLink?.splice(id, 1);
    setRender(!render);
  }

  const handleLinks = () => {
    if(!linkData || !urlRegex.test(linkData)) return
    formikAddIngredients.setFieldValue("referenceLink", [
      ...formikAddIngredients.values.referenceLink,
      linkData,
    ]);
    setLinkData("");
  };

  const handleMultiImage =(id)=>{
    images?.splice(id,1);
    setRender(!render);
  }
  console.log(formikAddIngredients?.values?.referenceLink, "referenderlinkkki");
  const getIngredeintDataById = async (id) => {
    try {
      const res = await getAllIngridientsUpdateById(id);
      if (res.status === 200 || res.status === 201) {
        let data = res.data.data;
        formikAddIngredients.setValues({
          name: data?.name,
          ingredientQuantity: data?.ingredientQuantity,
          ingredientUnit: data?.ingredientUnit,
          caloriePerUnit: data?.caloriePerUnit,
          brandName: data?.brandName,
          referenceLink: data?.referenceLink,
          referenceImages: data?.referenceImages,
          frontImage: data?.frontImage,
          backImage: data?.backImage,
          thumbnailImage: data?.thumbnailImage,
          proximates: data?.proximates,
          // proximates: proximatesJSON,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const openImagePreview = (image) => {
    setSelectedImage(image);
  };
  const getProximatesData = async () => {
    const res = await getProximates();
    if (res.status === 200 || res.status === 201) {
      // setProximate(res?.data?.data);
      formikAddIngredients?.setFieldValue("proximates", res.data.data);
    }
  };

  const handleIngredientsData = (e, name) => {
    if (e.target.value.toString().split("")?.length > 5) return;
    formikAddIngredients.setFieldValue(name, Number(e.target.value));
  };
  console.log(formikAddIngredients.values.proximates, "prodiiiciiicidiiisi");
  const handleIngredientName = (e) => {
    formikAddIngredients.setFieldValue("name", e.target.value);
    setErrorMsg({
      ...errorMsg,
      name: "",
    });
  };
  const handleCaloriePerUnit = (e) => {
    formikAddIngredients.setFieldValue("caloriePerUnit", String(e.target.value));
    setErrorMsg({
      ...errorMsg,
      unit: "",
    });
  };
  const handleShow = () => {
    if (!formikAddIngredients.values.name) {
      setErrorMsg({ ...errorMsg, name: "Ingredient name required." });
      setTogglePre(false);
    } else if (!formikAddIngredients.values.caloriePerUnit) {
      setErrorMsg({ ...errorMsg, unit: "Calorie per unit is required." });
      setTogglePre(false);
    } else {
      setTogglePre(true);
    }
  };

  const handleFrontClick  =(name)=>{
    formikAddIngredients.setFieldValue(name,"")
  }
  console.log(formikAddIngredients.values.proximates, "JFFFFFFF");
  const checkPreiew = () => {
    if (formikAddIngredients.values.proximates) {
      let nutrient = formikAddIngredients.values?.proximates?.nutrients?.filter(
        (l) => l.value != 0
      );
      let vitamin = formikAddIngredients.values?.proximates?.vitamins?.filter(
        (l) => l.value != 0
      );
      let mineral = formikAddIngredients.values?.proximates?.minerals?.filter(
        (l) => l.value != 0
      );
      console.log(nutrient, vitamin, mineral, "IOIOIIOIIO");
      if (
        Boolean(nutrient?.length) ||
        Boolean(vitamin?.length) ||
        Boolean(mineral?.length)
      ) {
        return true;
      } else {
        return false;
      }
    }
  };
  // console.log(checkPreiew(),"checkpreeeeed")
  useEffect(() => {
    if (location?.state?.ingredientId && location?.state?.edit) {
      getIngredeintDataById(location?.state?.ingredientId);
    }
    else{
      getProximatesData();
    }
  }, [location]);

  // useEffect(() => {
  //   getProximatesData();
  // }, []);

  useEffect(() => {
    let imgfnc = [],
      fileReaders = [];
    let isCancel = false;

    imageFiles?.forEach((file) => {
      const fileReader = new FileReader();

      fileReaders.push(fileReader);
      fileReader.onload = (e) => {
        let { result } = e.target;
        if (result) {
          imgfnc = [...imgfnc, { result, file: file?.name, size: file?.size }];
        }

        if (imgfnc?.length === imageFiles?.length && !isCancel) {
          setImages(imgfnc);
        }
      };
      fileReader.readAsDataURL(file);
    });
    if (!formikAddIngredients.values?.length) {
      setImages([]);
    }

    return () => {
      isCancel = true;
      fileReaders.forEach((fileReader) => {
        if (fileReader.readyState === 1) {
          fileReader.abort();
        }
      });
    };
  }, [imageFiles]);

  useEffect(() => {
    if (
      formikAddIngredients.values.name &&
      formikAddIngredients.values.caloriePerUnit &&
      checkPreiew()
    ) {
      setTogglePre(true);
    } else {
      setTogglePre(false);
    }
  }, [
    formikAddIngredients?.values?.name,
    formikAddIngredients?.values?.caloriePerUnit,
    formikAddIngredients.values.proximates,
  ]);

  // const handleNutrientsValue = (e,name,i) => {
  //   // console.log(`proximates.Nutrients.${i}.${name}`, "SADFDSAFDSAFDSFDSA",e.target.value);
  //   formikAddIngredients.setFieldValue(`proximates.Nutrients.${i}.${name}`,e.target.value)
  // };

  return (
    <AppLayout>
      <div className="d-flex justify-content-between pt-4">
        <div>
               {/* <h3 className="ingredient" style={{ cursor: "pointer", border: "1px solid #fe557f" }}>
                  <span className="plus-sy">+</span> Add New Ingredient
               </h3> */}
            </div>
        {/* <div>
            <h4>
              Sort by
              <select
                name=""
                id=""
                style={{ border: "none", fontWeight: "700" }}
              >
                <option value="">Newest</option>
                <option value="">Oldest</option>
              </select>
            </h4>
          </div> */}
      </div>

      <div className="row">
        <div className="col-md-5">
          <h3>Image Thumbnail</h3>
          <div className="d-flex justify-content-between after-upload-img">
            <div className="me-3">
              <img
                // src={`${assignProfilePicBucketUrl(
                //   formikAddIngredients.values.thumbnailImage
                // )}`}
                src={
                  BUCKET_URL + "/" + formikAddIngredients?.values.thumbnailImage
                }
                width="44"
                alt=""
                onError={(e) => {
                  e.target.src = ProfilePicDemo;
                  e.error = null;
                }}
              />
            </div>
          {formikAddIngredients?.values?.thumbnailImage && <div>
            <i className="bi bi-x" onClick={()=>handleFrontClick("thumbnailImage")} style={{ cursor: "pointer" }}></i>
          </div>}
          </div>
          <div className="d-flex justify-content-between">
            <label className="upload">
              Upload product image here
              <input
                type="file"
                name="thumbnailImage"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) => {
                  handleFileUpload(
                    e,
                    formikAddIngredients,
                    setImgSize,
                    "ingredients"
                  );
                }}
              />
            </label>
            <p className="p-10">upto 11MB</p>
          </div>
          <div className="position-relative mt-2">
            <input
              className="form-control"
              type="text"
              name="name"
              value={formikAddIngredients.values.name}
              onChange={handleIngredientName}
              id="name"
              placeholder="Enter Ingredient Name"
            />
            <label htmlFor="name" className="input-label">
              Name
            </label>
            <p className="text-danger">{errorMsg.name}</p>
          </div>

          <div className=" mt-4">
            <input
              className="form-control"
              type="text"
              disabled
              // name="ingredientQuantity"
              // value={formikAddIngredients.values.ingredientQuantity}
              // onChange={(e) => {
              //   globalHandleChange(e, formikAddIngredients);
              // }}
              value='Information per 100g or 100ml'
              // id="name"
              // placeholder="Enter Ingredient Information"
            />
            {/* <label htmlFor="name" >
              Information per 100g or 100ml
            </label> */}
          </div>

          <div className="d-flex justify-content-between mt-3">
            <label className="upload">
            Upload front of pack image here
              <input
                type="file"
                name="frontImage"
                onChange={(e) => {
                  handleFileUpload(
                    e,
                    formikAddIngredients,
                    setImgSize,
                    "ingredients"
                  );
                }}
                accept="image/*"
                style={{ display: "none" }}
              />
            </label>
            <p className="p-10">upto 11MB</p>
          </div>

          <div className="d-flex justify-content-between">
            <label className="upload">
            Upload back of pack image here
              <input
                type="file"
                name="backImage"
                onChange={(e) => {
                  handleFileUpload(
                    e,
                    formikAddIngredients,
                    setImgSize,
                    "ingredients"
                  );
                }}
                accept="image/*"
                style={{ display: "none" }}
              />
            </label>
            <p className="p-10">upto 11MB</p>
          </div>

          {formikAddIngredients?.values?.frontImage && (
            <div className="d-flex justify-content-between after-upload-img">
              <div className="me-3">
                <img
                  // src={`${assignProfilePicBucketUrl(
                  //   formikAddIngredients.values.frontImage
                  // )}`}
                  src={
                    BUCKET_URL + "/" + formikAddIngredients?.values.frontImage
                  }
                  width="44"
                  alt=""
                  onError={(e) => {
                    e.target.src = ProfilePicDemo;
                    e.error = null;
                  }}
                />
              </div>
              <div style={{ textAlign: "left", width: "100%" }}>
                <h4>{imgSize?.frontImage?.name || "Image Name"}</h4>
                <h5>{imgSize?.frontImage?.size || "Image Size in"} MB</h5>
              </div>
              <div>
                <i className="bi bi-x" onClick={()=>handleFrontClick("frontImage")} style={{ cursor: "pointer" }}></i>
              </div>
            </div>
          )}
          {formikAddIngredients?.values?.backImage && (
            <div className="d-flex justify-content-between after-upload-img mt-3">
              <div className="me-3">
                <img
                  // src={`${assignProfilePicBucketUrl(
                  //   formikAddIngredients.values.backImage
                  // )}`}
                  src={
                    BUCKET_URL + "/" + formikAddIngredients?.values.backImage
                  }
                  width="44"
                  alt=""
                  onError={(e) => {
                    e.target.src = ProfilePicDemo;
                    e.error = null;
                  }}
                />
              </div>
              <div style={{ textAlign: "left", width: "100%" }}>
                <h4>{imgSize?.backImage?.name || "Image Name"}</h4>
                <h5>{imgSize?.backImage?.size || "Image Size in"} MB</h5>
              </div>
              <div>
                <i className="bi bi-x" onClick={()=>handleFrontClick("backImage")} style={{ cursor: "pointer" }}></i>
              </div>
            </div>
          )}

          <div className="position-relative mt-4">
            <input
              className="form-control"
              type="number"
              name="caloriePerUnit"
              // disabled
              value={formikAddIngredients.values.caloriePerUnit}
              onChange={handleCaloriePerUnit}
              id="name"
              placeholder="Enter calories per 100g or 100 ml"
            />
            <label htmlFor="name" className="input-label">
              Calories per 100g or 100ml
            </label>
            <p className="text-danger">{errorMsg?.unit}</p>
          </div>

          <div className="position-relative mt-4">
            <textarea
              name="brandName"
                value={formikAddIngredients?.values?.brandName}
                onChange={(e) => {
                  globalHandleChange(e, formikAddIngredients);
                }}
              className="form-control"
              id=""
              style={{ outline: "transparent", overflow: "hidden" }}
              placeholder="Walmart, Tesco, Carrefour, Amazon..."
            ></textarea>
            <label htmlFor="name" className="input-label">
              Reference Brand or Brand Name
            </label>
          </div>

          <label className="form-label mt-3" htmlFor="paste">
            Refrence/proof (paste link here)
          </label>

          <div className="d-flex align-items-center">
            <input
              className="form-control me-3"
              type="text"
              name=""
              value={linkData}
              onChange={(e) => setLinkData(e.target.value)}
              id="paste "
              placeholder="Enter link here"
            />
            <span onClick={handleLinks} className="plus-sy" style={!urlRegex.test(linkData) ? {background:'gray'}: {cursor:"pointer"}}>
              +
            </span>
          </div>
          {linkData?.length && !urlRegex.test(linkData)? <p className="text-danger mt-2">Invalid Link</p>: null}
          <div className="d-flex flex-column mb-4">
          {
            formikAddIngredients?.values?.referenceLink?.map((v,i)=>{
              return <div className="d-flex"> <a style={{ marginLeft: "10px", whitespace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth:"180px" }} href={v?.split("")[0] == "h" ? v : `https://${v}`} target="_blank" rel="noreferrer noopener">{v}</a> <span style={{ cursor: "pointer" }} className="ms-2" ><i onClick={() => handlerr(i)}  className="bi bi-x-circle"></i></span></div>
            })
          }
          </div>
          <label htmlFor="referenceImages" className="upload-photog">
            Upload photographs
          </label>
          <input
            id={"referenceImages"}
            type="file"
            name="referenceImages"
            accept="image/*"
            multiple
            onChange={(e) => {
              handleMultiFileUpload(
                e,
                imageFiles,
                setImageFiles,
                formikAddIngredients
              );
            }}
            style={{ display: "none" }}
          />

          {images &&
            images?.map((el, i) => {
              return (
                <React.Fragment key={`key-${i}`}>
                  <div className="d-flex justify-content-between after-upload-img mt-3">
                    <div className="me-3">
                      <img src={el?.result} width="44" alt="" />
                    </div>
                    <div style={{ textAlign: "left", width: "100%" }}>
                      <h4>{el?.file}</h4>
                      <h5>{(el?.size / 1024 / 1024)?.toFixed(2)} MB</h5>
                    </div>
                    <div>
                      <i className="bi bi-x" onClick={()=>handleMultiImage(i)} style={{ cursor: "pointer" }}></i>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-5">
          <div
            className="table-responsive"
            style={{ height: "534px", overflowY: "auto" }}
          >
            <table className="table table-borderlessj">
              <tbody>
                <tr>
                  <th>
                    Proximates <i className="bi bi-arrow-down"></i>
                  </th>
                  <th></th>
                  <th></th>
                </tr>
                <tr>
                  <td className="fw-bold">Nutrient</td>
                  <td></td>
                  <td className="fw-bold">per 100g</td>
                </tr>
                {Boolean(
                  formikAddIngredients?.values?.proximates?.nutrients?.length
                ) &&
                  formikAddIngredients?.values?.proximates?.nutrients?.map(
                    (v, i) => {
                      return (
                        <tr>
                          <td>{v?.name}</td>
                          <td>
                            <b>{v?.unit}</b>
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              min={0}
                              name={`proximates.nutrients.${i}.value`}
                              value={
                                formikAddIngredients?.values?.proximates
                                  ?.nutrients?.[i]?.value
                              }
                              onChange={(e) =>
                                handleIngredientsData(
                                  e,
                                  `proximates.nutrients.${i}.value`
                                )
                              }
                              max="999"
                            ></input>
                          </td>
                        </tr>
                      );
                    }
                  )}

                <tr>
                  <td className="fw-bold">Vitamins</td>
                  <td></td>
                  <td className="fw-bold">per 100g</td>
                </tr>
                {Boolean(
                  formikAddIngredients?.values?.proximates?.vitamins?.length
                ) &&
                  formikAddIngredients?.values?.proximates?.vitamins?.map(
                    (v, i) => {
                      return (
                        <tr>
                          <td>{v?.name}</td>
                          <td>
                            <b>{v?.unit}</b>
                          </td>
                          <td>
                            <input
                              type="number"
                              min={0}
                              className="form-control"
                              name={`proximates.vitamins.${i}.value`}
                              value={
                                formikAddIngredients?.values?.proximates
                                  ?.vitamins?.[i]?.value
                              }
                              // onChange={formikAddIngredients.handleChange}
                              onChange={(e) =>
                                handleIngredientsData(
                                  e,
                                  `proximates.vitamins.${i}.value`
                                )
                              }
                            ></input>
                          </td>
                        </tr>
                      );
                    }
                  )}

                <tr>
                  <td className="fw-bold">Minerals</td>
                  <td></td>
                  <td className="fw-bold">per 100g</td>
                </tr>
                {Boolean(
                  formikAddIngredients?.values?.proximates?.minerals?.length
                ) &&
                  formikAddIngredients?.values?.proximates?.minerals?.map(
                    (v, i) => {
                      return (
                        <tr>
                          <td>{v?.name}</td>
                          <td>
                            <b>{v?.unit}</b>
                          </td>
                          <td>
                            <input
                              type="number"
                              min={0}
                              className="form-control"
                              name={`proximates.minerals.${i}.value`}
                              value={
                                formikAddIngredients?.values?.proximates
                                  ?.minerals?.[i]?.value
                              }
                              // onChange={formikAddIngredients.handleChange}
                              onChange={(e) =>
                                handleIngredientsData(
                                  e,
                                  `proximates.minerals.${i}.value`
                                )
                              }
                            ></input>
                          </td>
                        </tr>
                      );
                    }
                  )}
              </tbody>
            </table>
          </div>

          <button
            className="btn_custome mt-5 otp-submit"
            data-bs-toggle={togglePre ? "modal" : ""}
            data-bs-target={togglePre ? "#exampleModal" : ""}
            onClick={handleShow}
          >
            Preview
          </button>
        </div>
        <div className="col-md-1"></div>
      </div>

      {/* React bootstrap modal... */}

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="col-xl-12 col-lg-12 mt-3">
                <div class="card  mb-0">
                  <div class="card-body   p-0">
                    {/* <div className="modal_chart_list mx-2">
                          <div className="row mb-4 mt-4">
                            <div className="col-auto">
                              <img
                                src={chart_image}
                                style={{
                                  width: "28px",
                                  height: "28px",
                                  borderRadius: "50%",
                                }}
                              />
                            </div>
                            <div className="col-7">
                              <h6 className=" p-14 fw-bold">
                                Potato Mash with Butter
                              </h6>
                              <p className="p-10 m-0">
                                Note: Lorem Browse all the ingredients Browse all
                                the ingredients{" "}
                              </p>
                            </div>
                            <div className="col-auto">
                              <p className="m-0 p-12">120 g</p>
                            </div>
    
                            <div className="col-auto">
                              <i
                                class="bi bi-caret-right icon-color"
                                type="button"
                              ></i>
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col-auto">
                              <img
                                src={chart_image}
                                style={{
                                  width: "28px",
                                  height: "28px",
                                  borderRadius: "50%",
                                }}
                              />
                            </div>
                            <div className="col-7">
                              <h6 className=" p-14 fw-bold">Blueberry Pancake</h6>
                            </div>
                            <div className="col-auto">
                              <p className="p-12 m-0">100 g</p>
                            </div>
                            <div className="col-auto">
                              <i
                                class="bi bi-caret-right icon-color "
                                type="button"
                              ></i>
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col-auto">
                              <img
                                src={chart_image}
                                style={{
                                  width: "28px",
                                  height: "28px",
                                  borderRadius: "50%",
                                }}
                              />
                            </div>
                            <div className="col-7">
                              <h6 className=" p-14 fw-bold">
                                Potato Mash with Butter
                              </h6>
                            </div>
                            <div className="col-auto">
                              <p className="p-12">180 g</p>
                            </div>
                            <div className="col-auto">
                              <i
                                class="bi bi-caret-right icon-color"
                                type="button"
                              ></i>
                            </div>
                          </div>
                        </div> */}
                    <div className="modal-header-list">
                      <h1
                        class="modal-title-list  mb-0 py-3 "
                        id="exampleModalLabel"
                      >
                        Nutritional Information
                      </h1>
                    </div>

                    <div class=" p-3 " style={{ border: "1px solid #000" }}>
                      {/* <div
                        className=" heading d-flex justify-content-around align-items-center"
                        style={{ borderBottom: "9px solid black" }}
                      >
                        <h6 className="p-1">Serving size </h6>
                        <h6 className="p-12">(199g)</h6>
                      </div>
                      <div
                        className=" heading d-flex justify-content-around align-items-center "
                        style={{ borderBottom: "5px solid black" }}
                      >
                        <h6 className="p-17 fw-bold">Calories per serving</h6>
                        <h1 className="p-17 fw-boldest">142</h1>
                      </div> */}

                      {Boolean(
                        formikAddIngredients?.values?.proximates?.nutrients?.filter((v) => v.value !== 0)?.length
                      ) && (
                        <>
                          <div className="row align-item-center">
                            <h6 className="col">Nutrients</h6>
                            <h6 className="col">Unit</h6>
                            <h6 className="col">per 100g </h6>
                          </div>
                          <hr className="m-0 bottom-line" />
                        </>
                      )}
                      {Boolean(
                        formikAddIngredients?.values?.proximates?.nutrients
                          ?.length
                      ) &&
                        formikAddIngredients?.values?.proximates?.nutrients
                          ?.filter((v) => v.value !== 0)
                          ?.map((v) => {
                            return (
                              <div className="row  pt-3">
                                <div className="col text-start">
                                  <h6 className=" fs-5 fw-bold">
                                    <span>{v.name}</span>
                                  </h6>
                                </div>
                                <div className="col">
                                  <p className="fs-5">{v.unit}</p>
                                </div>
                                <div className="col">
                                  <h6 className="text-start fs-5 fw-bold">
                                    {v?.value
                                      ? v.value?.toString()?.split(".")
                                          ?.length > 1
                                        ? Number(v?.value)?.toFixed(2)
                                        : v?.value
                                      : ""}
                                  </h6>
                                </div>
                              </div>
                            );
                          })}
                      {Boolean(
                        formikAddIngredients?.values?.proximates?.minerals?.filter((v) => v.value !== 0)?.length
                      ) && (
                        <>
                          <hr className="m-0 bottom-line" />
                          <div className="row align-item-center">
                            <h6 className="col">Minerals</h6>
                            <h6 className="col">Unit</h6>
                            <h6 className="col">per 100g </h6>
                          </div>
                          <hr className="m-0 bottom-line" />
                        </>
                      )}

                      {Boolean(
                        formikAddIngredients?.values?.proximates?.minerals
                          ?.length
                      ) &&
                        formikAddIngredients?.values?.proximates?.minerals
                          ?.filter((v) => v.value !== 0)
                          ?.map((v) => {
                            return (
                              <div className="row  pt-3">
                                <div className="col text-start">
                                  <h6 className=" fs-5 fw-bold">
                                    <span>{v.name}</span>
                                  </h6>
                                </div>
                                <div className="col">
                                  <p className="fs-5">{v.unit}</p>
                                </div>
                                <div className="col">
                                  <h6 className="text-start fs-5 fw-bold">
                                    {v?.value
                                      ? v.value?.toString()?.split(".")
                                          ?.length > 1
                                        ? Number(v?.value)?.toFixed(2)
                                        : v?.value
                                      : ""}
                                  </h6>
                                </div>
                              </div>
                            );
                          })}
                      {Boolean(
                        formikAddIngredients?.values?.proximates?.vitamins?.filter((v) => v.value !== 0)?.length
                      ) && (
                        <>
                          <hr className="m-0 bottom-line" />
                          <div className="row align-item-center">
                            <h6 className="col">Vitamins</h6>
                            <h6 className="col">Unit</h6>
                            <h6 className="col">per 100g </h6>
                          </div>
                          <hr className="m-0 bottom-line" />
                        </>
                      )}

                      {Boolean(
                        formikAddIngredients?.values?.proximates?.vitamins
                          ?.length
                      ) &&
                        formikAddIngredients?.values?.proximates?.vitamins
                          ?.filter((v) => v.value !== 0)
                          ?.map((v) => {
                            return (
                              <div className="row  pt-3">
                                <div className="col text-start">
                                  <h6 className=" fs-5 fw-bold">
                                    <span>{v.name}</span>
                                  </h6>
                                </div>
                                <div className="col">
                                  <p className="fs-5">{v.unit}</p>
                                </div>
                                <div className="col">
                                  <h6 className="text-start fs-5 fw-bold">
                                    {v?.value
                                      ? v.value?.toString()?.split(".")
                                          ?.length > 1
                                        ? Number(v?.value)?.toFixed(2)
                                        : v?.value
                                      : ""}
                                  </h6>
                                </div>
                              </div>
                            );
                          })}
                      {/* <div className="row  pt-3">
                          <div className="col text-start">
                            <h6 className=" fs-5 fw-bold">
                              <Link>Energy</Link>
                            </h6>
                          </div>
                          <div className="col">
                            <p className="fs-5">KJ</p>
                          </div>
                          <div className="col">
                            <h6 className="text-start fs-5 fw-bold">295</h6>
                          </div>
                          <div className="col">
                            <h6 className="text-start fs-5 fw-bold">25%</h6>
                          </div>
                          <div className="col">
                            <h6 className="text-start fs-5 fw-bold">295</h6>
                          </div>
                        </div> */}
                      {/* <div className="row pt-3">
                            <div className="col">
                              <h6 className="text-start fs-5 fw-bold">
                                <Link>Fat of which</Link>
                              </h6>
                            </div>
                            <div className="col">
                              <p className="fs-5">KJ</p>
                            </div>
                            <div className="col">
                              <h6 className="text-start fs-5 fw-bold">295</h6>
                            </div>
                            <div className="col">
                              <h6 className="text-start fs-5 fw-bold">25%</h6>
                            </div>
                            <div className="col">
                              <h6 className="text-start fs-5 fw-bold">295</h6>
                            </div>
                          </div>
                          <div className="row pt-3">
                            <div className="col">
                              <h6 className="text-start fs-5 fw-bold">
                                <Link>Saturates</Link>{" "}
                              </h6>
                            </div>
                            <div className="col">
                              <p className="fs-5">KJ</p>
                            </div>
                            <div className="col">
                              <h6 className="text-start fs-5 fw-bold">295</h6>
                            </div>
                            <div className="col">
                              <h6 className="text-start fs-5 fw-bold">25%</h6>
                            </div>
                            <div className="col">
                              <h6 className="text-start fs-5 fw-bold">295</h6>
                            </div>
                          </div>
                          <div className="row pt-3">
                            <div className="col">
                              <h6 className="text-start fs-5 fw-bold">
                                <Link>Carbohydrate Of which</Link>
                              </h6>
                            </div>
                            <div className="col">
                              <p className="fs-5">KJ</p>
                            </div>
                            <div className="col">
                              <h6 className="text-start fs-5 fw-bold">295</h6>
                            </div>
                            <div className="col">
                              <h6 className="text-start fs-5 fw-bold">25%</h6>
                            </div>
                            <div className="col">
                              <h6 className="text-start fs-5 fw-bold">295</h6>
                            </div>
                          </div>
                          <div className="row pt-3">
                            <div className="col">
                              <h6 className="text-start fs-5 fw-bold">
                                <Link>Sugars</Link>
                              </h6>
                            </div>
                            <div className="col">
                              <p className="fs-5">KJ</p>
                            </div>
                            <div className="col">
                              <h6 className="text-start fs-5 fw-bold">295</h6>
                            </div>
                            <div className="col">
                              <h6 className="text-start fs-5 fw-bold">25%</h6>
                            </div>
                            <div className="col">
                              <h6 className="text-start fs-5 fw-bold">295</h6>
                            </div>
                          </div>
                          <div className="row pt-3">
                            <div className="col">
                              <h6 className="text-start fs-5 fw-bold">Fibre</h6>
                            </div>
                            <div className="col">
                              <p className="fs-5">KJ</p>
                            </div>
                            <div className="col">
                              <h6 className="text-start fs-5 fw-bold">295</h6>
                            </div>
                            <div className="col">
                              <h6 className="text-start fs-5 fw-bold">25%</h6>
                            </div>
                            <div className="col">
                              <h6 className="text-start fs-5 fw-bold">295</h6>
                            </div>
                          </div>
                          <div className="row pt-3">
                            <div className="col">
                              <h6 className="text-start fs-5 fw-bold">
                                <Link>Protein</Link>
                              </h6>
                            </div>
                            <div className="col">
                              <p className="fs-5">KJ</p>
                            </div>
                            <div className="col">
                              <h6 className="text-start fs-5 fw-bold">295</h6>
                            </div>
                            <div className="col">
                              <h6 className="text-start fs-5 fw-bold">25%</h6>
                            </div>
                            <div className="col">
                              <h6 className="text-start fs-5 fw-bold">295</h6>
                            </div>
                          </div> */}
                      {/* <p
                          className=" text-dark m-0 card-bottom p-10 pt-3"
                          style={{ borderTop: "3px solid black" }}
                        >
                          * Please change text to "% values are based on a 2000 calories diet. Your daily values may be higher or lower depending on your energy needs."
                        </p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "inline-block",
                  float: "right",
                  marginTop: "10px",
                }}
              >
                <button
                  className="btn_custome mt-1 otp-submit float"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    formikAddIngredients.handleSubmit();
                  }}
                >
                  {location?.state?.edit ? "Update" : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default AddIngredients;
