import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { ThemeSpinner } from '../../Utils/Loaders';
import { putApiWithToken } from '../../Api/AxiosMethods';

const UnsubscribeEmail = () => {
    const [searchParams] = useSearchParams();

    const token = searchParams.get('code');
    const [loader, setLoader] = useState(true)
    const [success, setSuccess] = useState(false)
    const [isSubscribe, setIsSubscribe] = useState(false)

    const unsubscribe = async (status) => {
        try {
            setLoader(true)
            setIsSubscribe(status)
            const res = await putApiWithToken('/users/unsubscribe-notifications', { token, status })
            if (res && res.status === 200) {
                console.log(res.data, ' response hai ye')
                setSuccess(true)
                setLoader(false)
            }
        } catch (err) {
            setSuccess(false)
            setLoader(false)
        }
    }

    useEffect(() => {
        if (token) {
            unsubscribe(false)
        }
    }, [token])

    return (
        <>
            {
                (loader && token) ?
                    <div className='text-center'>

                        <ThemeSpinner />
                    </div>
                    :
                    success ?
                        <div class="container mt-4">
                            <div class="alert alert-success">
                                {isSubscribe ?
                                    <>
                                        <h2 class="alert-heading">Subscribed Successfully</h2>
                                        <p>You have subscribed to our mailing list. You can close this browser window.</p>
                                    </>
                                    : <>
                                        <h2 class="alert-heading">Unsubscribed Successfully</h2>
                                        <p>You have been unsubscribed from our mailing list. You can close this browser window.
                                            <br />
                                            To join the mailing list again, <span className='text-primary' style={{ cursor: "pointer" }} onClick={() => unsubscribe(true)}>Click Here.</span></p>
                                    </>}
                            </div>
                        </div>
                        :
                        <div class="container mt-4">
                            <div class="alert alert-danger">
                                <h2 class="alert-heading">{isSubscribe ? "Email Subscription" : "Unsubscribe"} Failed!!</h2>
                                <p>It seems that Token is Invalid.</p>
                            </div>
                        </div>
            }
            {/* {!loader && <div className='container fw-bolder'>

                <a href="/"> <i class="bi bi-arrow-left" />  Go to Home Page</a>
            </div>} */}
        </>
    )
}

export default UnsubscribeEmail