import React, { useContext, useEffect, useRef, useState } from "react";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import { UserContext } from "../../Contexts/Contexts";
import { useFormik } from "formik";
import { checkUserNameApi, deleteUserAccount, updateUserProfileApi } from "../../Api/Apis";
import {
  assignProfilePicBucketUrl,
  globalHandleChange,
  handleFileUpload,
  handleLogout,
} from "../../Utils/HelperFuntions";
import { toast } from "react-hot-toast";
import Flatpickr from "react-flatpickr";
import { WhiteLoader } from "../../Utils/Loaders";
import AppLayout from "../../AppLayout/AppLayout";
import { useLocation, useNavigate } from "react-router";
// import { CloseButton } from "react-bootstrap";
import CloseButton from "../../Assets/Images/Group 4292.svg";
import { debounce } from "lodash";
import moment from "moment";

const UserProfile = () => {
  // const { setIsLoggedIn } = useContext(UserContext);
  const { userProfileDetails, fetchUserDetails, setIsLoggedIn } =
    useContext(UserContext);
  const [dobS, setDobS] = useState();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [nameValid,setNameValid]=useState(false);
  const [profilePicError,setProfilePicError] = useState('')
  const location = useLocation();

  // let toggle = ;
  const [toggle, setToggle] = useState(location?.state?.viewMode);
  const profilePicRef = useRef();

  const userProfileFormik = useFormik({
    initialValues: {
      fullName: "",
      userName: "",
      email: "",
      bio: "",
      address: "",
      professionalExperience: "",
      pastExperience: "",
      profilePic: "",
      education: "",
      industry:"",
      dob: "",
      phone: "",
    },
    validationSchema: "",
    onSubmit: async (values) => {
      if(!values.profilePic){
        setProfilePicError('Profile Image is Required')
        return
      }
      let d = {
        ...values,
        dob: values?.dob?.[0] ? moment(values?.dob?.[0]).format("YYYY-MM-DD 00:00") :"",
      };
      setLoader(true);
      try {
        const res = await updateUserProfileApi(d);
        if (res?.status === 200) {
          toast.success(`${res?.data?.message}`, { id: "001" });
          setLoader(false);
          fetchUserDetails();
        }
        console.log(res, "profile update formik");
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    },
  });

  const handleSearch = async (e)=>{
    // console.log(e,"eeeeeeeeee")
    const res = await checkUserNameApi({"userName":e});
    // console.log(res, "rspnnnnnnsn");
    if(res.data.status == 1){
      setNameValid(true)
    }else{
      setNameValid(false);
    }
  }

  const debouncedOnChange = debounce(handleSearch, 1000); 

  const handleuserName =(e)=>{
   userProfileFormik?.setFieldValue("userName",e.target.value);
   if(e.target.value?.length > 6){
    // setNameValid(true);
    debouncedOnChange(e.target.value)
   }else{
    setNameValid(false)
   }
  }

  const handleDeleteAccount = async () => {
    try {
      const res = await deleteUserAccount();
      if (res.status === 200 || res.status === 201) {
        console.log(res, "FDSAFJDSFJDSJFDJS");
        document.getElementById("deleteModal").click();
        setIsLoggedIn(false);
        
        handleLogout();
        // navigate("/log-in");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handlePhoneChange = (e, value, country, formattedValue) => {
    console.log(e, value, country, formattedValue, "handle");
    userProfileFormik.setFieldValue("phone", "+"+e);

  };

  const handleChangeDob = (e) => {
    // console.log(e,"errriirrrii")
    setDobS(e);
    // userProfileFormik.setFieldValue("dob", e.join(""))
    userProfileFormik.setFieldValue("dob", e);
  };

  const getSelectDate = (date) => {
    return [date];
  };
// console.log(userProfileDetails.data.data.isNew,"userdear")
  // console.log(getSelectDate("Tue Aug 15 2023 00:00:00 GMT+0530 (India Standard Time)") , "detailsssssss")
  useEffect(() => {
    if (Boolean(userProfileDetails?.data?.data)) {
      userProfileFormik.setFieldValue(
        "fullName",
        userProfileDetails?.data?.data?.firstName ?  `${
          userProfileDetails?.data?.data?.firstName +
          " " +
          userProfileDetails?.data?.data?.lastName  
        }` : ""
      );
      userProfileFormik.setFieldValue(
        "email",
        `${userProfileDetails?.data?.data?.email}`
      );
      userProfileFormik.setFieldValue(
        "userName",
        `${userProfileDetails?.data?.data?.userName}`
      );
      userProfileFormik.setFieldValue(
        "bio",
        `${userProfileDetails?.data?.data?.bio}`
      );
      userProfileFormik.setFieldValue(
        "dob",
        `${userProfileDetails?.data?.data?.dob}`
      );
      userProfileFormik.setFieldValue(
        "address",
        `${userProfileDetails?.data?.data?.address}`
      );
      userProfileFormik.setFieldValue(
        "pastExperience",
        `${userProfileDetails?.data?.data?.pastExperience}`
      );
      userProfileFormik.setFieldValue(
        "professionalExperience",
        `${userProfileDetails?.data?.data?.professionalExperience}`
      );
      userProfileFormik.setFieldValue(
        "education",
        `${userProfileDetails?.data?.data?.education}`
      );
      userProfileFormik.setFieldValue(
        "industry",
        `${userProfileDetails?.data?.data?.industry || ""}`
      );
      userProfileFormik.setFieldValue(
        "profilePic",
        `${userProfileDetails?.data?.data?.profilePic}`
      );
      userProfileFormik.setFieldValue(
        "phone",
        userProfileDetails?.data?.data?.phoneNumber
          ? `${userProfileDetails?.data?.data?.phoneNumber}`
          : ""
      );
      setDobS(new Date(userProfileDetails?.data?.data?.dob));
    }
  }, [userProfileDetails]);

  return (
    <AppLayout>
      <div className="row mt-5">
        <div className="col-xl-5 col-lg-12">
          <div className="dp-side">
            <h2>
              {" "}
              {
                userProfileDetails?.data?.data?.isNew ? "Add profile" :   `${toggle ? "My" : "Edit "} Profile`
              }
             &nbsp;
              {toggle && (
                <i
                  onClick={() => setToggle(false)}
                  style={{ cursor: "pointer" }}
                  class="bi bi-pen-fill pe-2"
                ></i>
              )}
            </h2>
            <form>
              <label
                className="upload upload-profile"
                style={{
                  backgroundSize: "cover",
                  backgroundImage: `${
                    userProfileDetails?.loadig ? (
                      <div className="text-center">
                        <WhiteLoader />
                      </div>
                    ) : userProfileFormik.values.profilePic !== "" ? (
                      `url(${assignProfilePicBucketUrl(
                        userProfileFormik.values.profilePic
                      )})`
                    ) : (
                      `url(${assignProfilePicBucketUrl(
                        userProfileDetails?.data?.data?.profilePic
                      )})`
                    )
                  }`,
                }}
              >
                {userProfileDetails?.data?.data?.profilePic === "" ? (
                  <>
                    {!toggle && (
                      <>
                        <i
                          className="bi bi-camera"
                          style={{ marginBottom: "-10px" }}
                        ></i>
                        <small>Click to change photo</small>
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}
                 {!toggle && (
                  <div className="edit-pen" style={{ position: "relative" }}>
                    <i
                      className="bi bi-pencil-fill cursor-pointer"
                      style={{ position: "absolute" }}
                    ></i>
                  </div>
                )}
                <input
                  type="file"
                  disabled={toggle}
                  name="profilePic"
                  accept="image/png, image/gif, image/jpeg , image/jpg"
                  onChange={(e) => {setProfilePicError('');
                    handleFileUpload(e, userProfileFormik, "", "profilePic");
                  }}
                  // style={{ display: "none" }}
                  hidden={true}
                />
                
              </label>
              <div>
                {profilePicError
               && (
                <p className="text-danger mt-2">{profilePicError}</p>
              )}
              </div>
              <label htmlFor="user" className="form-label mt-4">
                User Name
              </label>
              <input
                className="form-control"
                type="text"
                disabled={toggle}
                style={{color:nameValid ? "darkgreen":"red"}}
                name="userName"
                value={userProfileFormik.values.userName}
                // onChange={(e) => {
                //   globalHandleChange(e, userProfileFormik);
                // }}
                onKeyDown={(e)=>{
                  if(e.key === " "){
                    e.preventDefault()
                  }
                }}
                onChange={handleuserName}
                id="user"
                placeholder="jhon_"
              />
              <label htmlFor="user" className="form-label mt-4">
                Email
              </label>
              <input
                className="form-control"
                type="email"
                disabled
                name="email"
                value={userProfileFormik.values.email}
                onChange={(e) => {
                  globalHandleChange(e, userProfileFormik);
                }}
                id="user"
                placeholder="example@gmail.com"
              />
              <label htmlFor="full" className="form-label mt-4">
                Enter your full name
              </label>
              <input
                className="form-control"
                type="text"
                name="fullName"
                disabled={toggle}
                value={userProfileFormik.values.fullName}
                onChange={(e) => {
                  globalHandleChange(e, userProfileFormik);
                }}
                id="full"
                placeholder="Jhon Doe"
              />
              <label htmlFor="" className="form-label mt-4">
                Enter your mobile number
              </label>
              <div className="">
                <PhoneInput
                  country={"gb"}
                  disabled={toggle}
                  value={userProfileFormik.values.phone}
                  inputClass="phone-input"
                  onChange={(e, value, country, formattedValue) => {
                    handlePhoneChange(e, value, country, formattedValue);
                  }}
                />
              </div>
              <label htmlFor="bio" className="form-label mt-4">
                Bio
              </label>
              <textarea
                name="bio"
                id=""
                cols="30"
                rows="4"
                disabled={toggle}
                className="form-control"
                value={userProfileFormik.values.bio}
                onChange={(e) => {
                  globalHandleChange(e, userProfileFormik);
                }}
                // defaultValue=""
              />
              {/* </textarea> */}
            </form>
            {!toggle && (
              <>
                <h2 className="mt-5 pt-5">Delete Account</h2>
                <hr />
                <p className="p-3">
                  When you delete your The CalorieFinder account, you won't be
                  able to retrieve the content or information that you've shared on The CalorieFinder.
                </p>
                <button
                  className="btn btn-secondary"
                  data-bs-target="#deleteModal"
                  data-bs-toggle="modal"
                >
                  Delete Account
                </button>
              </>
            )}
          </div>
        </div>
        <div className="col-xl-1"></div>
        <div className="col-xl-5 col-lg-12">
          <label className="form-label" htmlFor="">
            Enter current town or city
          </label>
          <div className="position-relative">
            <input
              className="form-control"
              type="text"
              disabled={toggle}
              name="address"
              value={userProfileFormik.values.address}
              onChange={(e) => {
                globalHandleChange(e, userProfileFormik);
              }}
              id=""
              placeholder="Add my location..."
            />
            <img className="nav-img" src="./assets/images/nav.svg" alt="" />
          </div>
          {/* <label className="form-label mt-4" htmlFor="location">
            Years of professional experience
          </label>  
          <input
            type="text"
            className="form-control  "
            disabled={toggle}
            name="professionalExperience"
            id=""
            value={userProfileFormik?.values.professionalExperience}
            onChange={(e) => {
              globalHandleChange(e, userProfileFormik);
            }}
          /> */}
          {/* <select
            disabled={toggle}
            className="form-select"
            name="professionalExperience"
            id=""
            onChange={(e) => {
              globalHandleChange(e, userProfileFormik);
            }}
          >
            <option
              value="none"
              selected="selected"
              hidden
            >{`${userProfileFormik.values.professionalExperience}`}</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </select> */}
          <label className="form-label mt-4" htmlFor="location">
            Industry
          </label>
          <input
            type="text"
            className="form-control  "
            disabled={toggle}
            name="industry"
            id=""
            value={userProfileFormik?.values?.industry}
            onChange={(e) => {
              globalHandleChange(e, userProfileFormik);
            }}
            placeholder="Please enter your profession or industry "
          />
          {/* <select
            disabled={toggle}
            className="form-select"
            name="pastExperience"
            id=""
            onChange={(e) => {
              globalHandleChange(e, userProfileFormik);
            }}
          >
            <option
              value="none"
              selected="selected"
              hidden
            >{`${userProfileFormik.values.pastExperience}`}</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </select> */}
          {/* <label className="form-label mt-4" htmlFor="edu">
            Education
          </label>
          <input
            type="text "
            name="education"
            id="edu"
            className="form-control"
            disabled={toggle}
            value={userProfileFormik?.values?.education}
            onChange={(e) => {
              globalHandleChange(e, userProfileFormik);
            }}
            placeholder="Please enter highest educational achievement and year of graduation"
          /> */}

          {/* <select
            disabled={toggle}
            className="form-select"
            name="education"
            id="edu"
            onChange={(e) => {
              globalHandleChange(e, userProfileFormik);
            }}
          >
            <option value="graduation">Graduation</option>
            <option value="intermediate">Intermediate</option>
            <option value="matric">Matriculation</option>
          </select> */}
          <label className="form-label mt-4" htmlFor="">
          Date of Birth
          </label>
          {/* <input className="form-control" type="text" name="" id="" placeholder="DD-MM-YYYY" /> */}
          {/* <DatePicker /> */}
          <Flatpickr
            className="form-control cursor-pointer"
            options={{ dateFormat: "F j, Y", maxDate: new Date() }}
            altInput={true}
            name="birthDate"
            disabled={toggle}
            enableTime={false}
            value={dobS || getSelectDate(userProfileFormik.values.dob)}
            // value={moment(userProfileFormik.values.dob, 'YY YY-MM-DD')?._i}
            onChange={(event) => {
              handleChangeDob(event);
            }}
            placeholder="Select Date"
          />
          {!toggle && (
            <button
              className="btn_custome mt-4 otp-submit"
              onClick={() => {
                userProfileFormik.handleSubmit();
              }}
            >
              {loader ? <WhiteLoader /> : "Save"}
            </button>
          )}
        </div>
        <div className="col-xl-1"></div>
      </div>

      {/* deletemodal code start here  */}

      <div
        className="modal fade"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content pb-4">
            <div className="modal-header border-bottom-0 pb-0">
              <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
              <img className="modal-image-1" />
              <button
                type=""
                className=" border-0 bg-transparent"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {" "}
                <img src={CloseButton} />
              </button>
            </div>
            <div className="modal-body px-5 pt-3 py-0">
              <h1 className="modal-title  fw-bold p-16  pt-5 fs-5 text-center">
                Are you sure you want to delete your account ?
              </h1>

              <p className="p-10 mb-0 ps-4">
                When you delete your The CalorieFinder account, you won't be
                able to retrieve the content or information that you've shared on The CalorieFinder.
              </p>
            </div>

            <div className=" mx-auto">
              <button
                className="btn_custome mt-4 modal-submit me-5 "
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                No
              </button>
              <button
                className="btn_custome mt-4 modal-submit"
                onClick={handleDeleteAccount}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default UserProfile;
