import React, { useState } from 'react'
import { AuthContext } from './Contexts';

const AuthContextState = (props) => {

   const [verificationId, setVerificationId] = useState('');

   return (
      <AuthContext.Provider
         value={{
            verificationId,
            setVerificationId,
         }}
      >
         {props.children}
      </AuthContext.Provider>
   )
}

export default AuthContextState