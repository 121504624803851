import React, { useContext, useEffect, useState } from "react";
import AppLayout from "../../AppLayout/AppLayout";
import { WhiteLoader } from "../Loader/Loader";
import { ThemeSpinner } from "../../Utils/Loaders";
import { UserContext } from "../../Contexts/Contexts";
import { getSitesList, switchSite } from "../../Api/Apis";
import { toast } from 'react-hot-toast'

const ManageSites = () => {
  const { userProfileDetails, setUserProfileDetails } = useContext(UserContext);
  const [siteList, setSiteList] = useState({ loading: true, data: [] })
  const [switchMySite, setSwitchMySite] = useState({ loading: false, id: null })

  const userDetails = userProfileDetails?.data?.data


  const getSites = async () => {
    try {
      const res = await getSitesList();
      if (res.status === 200) {
        // toast.success(res.data.message);
        setSiteList({ loading: false, data: res?.data?.data })
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  }

  useEffect(() => {
    getSites()
  }, [])

  const handleSwitchSite = async (siteId) => {
    setSwitchMySite({ loading: true, id: siteId })
    try {
      const res = await switchSite({ siteId });
      if (res.status === 200 && res?.data?.data) {
        toast.success(res.data.message);
        setUserProfileDetails((v) => ({ ...v, data: res?.data }))
        setSwitchMySite({ loading: false, id: null })
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  }

  console.log(userDetails?.accountType,"hello");


  return (
    <AppLayout>
      <div className="">
        {siteList.loading ? <div className="d-flex align-items-center justify-content-center"><ThemeSpinner /></div>  : <>
          <div className="">
            <div className=" d-flex justify-content-end align-items-center my-community-heading mt-4 ">
              <div className="overflow-auto">
               {userDetails?.accountType!="business" && userDetails?.siteId!==0 && <h3
                  className="ingredient mb-0"
                  onClick={() => userDetails?.siteId !== 0 && !switchMySite.loading && handleSwitchSite(0)}
                  type="button">
                  <span className="d-flex gap-2 edit-site text-capitalize">
                    {switchMySite.loading && switchMySite.id === 0 ? <WhiteLoader color="red" /> : <>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-brilliance" viewBox="0 0 16 16">
                        <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16M1 8a7 7 0 0 0 7 7 3.5 3.5 0 1 0 0-7 3.5 3.5 0 1 1 0-7 7 7 0 0 0-7 7" />
                      </svg>
                    </>}
                  {userDetails?.siteId === 0 ? "Active" : "Switch"} {userDetails?.accountType} Account
                  </span>
                </h3>}  
              </div>
            </div>
            {siteList?.data?.length > 0 ?
              <div className="">
                <div>
                  <div className="row col-12 row-cols-lg-4 row-cols-md-3" >
                    {
                      Boolean(siteList?.data) ?
                        siteList?.data?.filter((data) => data?.userId !== userDetails?.id)?.map((v, i) => {
                          return (
                            <div class="food-div mb-5 mx-2">
                              <div
                                onClick={() => userDetails?.siteId !== v?.id && !switchMySite.loading && handleSwitchSite(v?.id)}
                                className={`edit-site px-lg-5`}
                                style={{ cursor: `${userDetails?.siteId === v?.id ? "default" : "pointer"}` }}
                              >
                                {switchMySite.loading && switchMySite.id == v.id ? <WhiteLoader color="red" /> : <span  className={`${userDetails?.siteId === v?.id && "ingredients w-auto"}`}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-brilliance" viewBox="0 0 16 16">
                                    <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16M1 8a7 7 0 0 0 7 7 3.5 3.5 0 1 0 0-7 3.5 3.5 0 1 1 0-7 7 7 0 0 0-7 7" />
                                  </svg>
                                  <span className={`px-2`}>{userDetails?.siteId === v?.id ? "Active" : "Switch"} </span>
                                </span>}
                              </div>
                              <div className="d-flex gap-2">
                                <div>Site name:</div>
                                <div>{v?.siteName}</div>
                              </div>
                              <div className="d-flex gap-2">
                                <div>Site address:</div>
                                <div>{v?.address}</div>
                              </div>
                            </div>
                          );
                        })
                        : null
                    }

                  </div></div>
              </div>
            : <div className="d-flex align-items-center justify-content-center edit-site">No Invitation</div>}
          </div>
        </>}

      </div>
    </AppLayout>
  );
};

export default ManageSites;
