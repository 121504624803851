import { deleteApi, getApiWithToken, getApiWithoutToken, imgUploadApi, postApiWithToken, postApiWithoutToken, putApiWithToken } from "./AxiosMethods";

export const fileUploadApi = (payload) => {
   return imgUploadApi(`/users/fileUpload`, payload);
}

export const multiFileUpload = (payload) => {
   return imgUploadApi(`/users/uploadMultiFile`, payload)
}

export const signUpApi = (payload) => {
   return postApiWithToken(`/users/signUp`, payload)
}

export const logInApi = (payload) => {
   return postApiWithToken(`/users/logInAndsignUp`, payload)
}

export const getUserDetailsApi = () => {
   return getApiWithToken(`/users/getUserByAuth`)
}

export const updateUserProfileApi = (payload) => {
   return putApiWithToken(`/users/updateProfile`, payload)
}

export const addUserSitesApi = (payload) => {
   return postApiWithToken(`/userSites/addUserSites`, payload)
}

export const getAllUserSites = () => {
   return getApiWithToken(`/userSites/getAllUserSites`)
}

export const updateUserSitesById = (payload) => {
   return putApiWithToken(`/userSites/updateUserSitesById`, payload)
}

export const deleteUserSitesById = (id) => {
   return deleteApi(`/users/deleteUserSitesById?id=${id}`)
}

export const getAllInvitePeople = () => {
   return getApiWithToken(`/users/getAllInvitePeople`)
}

export const inviteUsers = (payload) => {
   return postApiWithToken(`/users/inviteUsers`, payload)
}

export const getAllMyCommunityMembers = () => {
   return getApiWithToken(`/users/getAllMyCommunityMembers`)
}

export const acceptInvitation = () => {
   return postApiWithToken(`/users/acceptInvitation`)
}

export const addIngredients = (payload) => {
   return postApiWithToken(`/recipeAndIngredient/addIngredients`, payload)
}

export const getAllIngridientsById = (id) => {
   return getApiWithToken(`/recipeAndIngredient/getIngredientsById?id=${id}`)
}

export const updateIngredients = () => {
   return putApiWithToken(`/users/updateIngredients`)
}

export const updateIngredientApi = (payload)=>{
   return putApiWithToken('/recipeAndIngredient/updateIngredients',payload)
}
export const deleteIngrdientsById = (id) => {
   return deleteApi(`/users/deleteIngredients?id=${id}`)
}

export const addRecepies = (payload) => {
   return postApiWithToken(`/recipeAndIngredient/addRecipes`, payload)
}

export const updateRecipieApi = (payload)=>{
   return postApiWithToken(`/recipeAndIngredient/updateRecipesById`,payload);
}
export const getRecipieByIdApi=(id)=>{
   if(!id) return ;
   return getApiWithToken(`/recipeAndIngredient/getRecipeById?id=${id}`)
}
export const allRecipesForHome = (search="",page=0,limit=10) => {
   // return getApiWithToken(`/recipeAndIngredient/getAllRecipesAndIngredientsForHomePage?search=${p?.search || ""}`)
   return getApiWithToken(`/recipeAndIngredient/getAllRecipesAndIngredientsForHomePage?search=${search}&page=${page}&limit=${limit}`)
}

export const getAllMyRecipes = (search="",page=0,limit=10) => {
   return getApiWithToken(`/recipeAndIngredient/getAllMyRecipes?page=${page}&limit=${limit}`)
}

export const getAllIngredeintsApi =(search="",page=0,limit=10000)=>{
   return getApiWithToken(`/recipeAndIngredient/getAllIngredients?search${search}=&page=${page}&limit=${limit}`)
}
export const updateRecepies = (payload) => {
   return postApiWithToken(`/recipeAndIngredient/updateRecipesById`,payload)
}

export const deleteRecepiesBbyId = (id) => {
   return deleteApi(`/users/deleteRecepies?id=${id}`)
}


export const getProximates = ()=>{
   return getApiWithToken(`/users/getAllProximatesByUser`)
}

export const checkCredsApi =(phone,email)=>{
   const payload={phoneNumber:phone,email:email}
   return postApiWithoutToken(`/users/checkPhoneNumber`,payload)
}

export const deleteUserAccount = ()=>{
   return deleteApi(`/users/deleteUserAccount`)
}

export const savedRecipieApi =(payload)=>{
   return putApiWithToken(`/recipeAndIngredient/savedOrUnSavedRecipes`,payload)
}

export const shareRecipeApi =(payload)=>{
   return postApiWithToken(`/users/shareRecipes`,payload);
}
export const unShareRecipeApi =(payload)=>{
   return putApiWithToken(`/users/unShareRecipes`,payload);
}
export const shareIngredientApi =(payload)=>{
   return postApiWithToken(`/users/shareingredients`,payload);
}
export const saveInggredientApi =(payload)=>{
   return putApiWithToken(`/recipeAndIngredient/savedOrUnSavedIngredients`,payload);
}

export const allSharedRecipeApi=(search="",page=0,limit=10)=>{
   return getApiWithToken(`/users/getAllShareRecipesAndIngredients?search=${search}&page=${page}&limit=${limit}`);
}

export const getAllTcfSharedRecipe = (search="",page=0,limit=10) =>{
   return getApiWithToken(`/recipeAndIngredient/getAllTcfSharedRecipes?search=${search}&page=${page}&limit=${limit}`);
}

export const  getSavedRecipeApi =(page=0,limit=10)=>{
   return getApiWithToken(`/recipeAndIngredient/getAllSavedRecipes?page=${page}&limit=${limit}`)
}

export const getAllPendingIngredients = (page=0,limit=10)=>{
   return getApiWithToken(`/recipeAndIngredient/getAllPendingIngredients?page=${page}&limit=${limit}`)
}

export const downloadRecipeApi =(recipeId="",ingredeintId="")=>{
   return getApiWithToken(`/recipeAndIngredient/generateRecipesAndIngredientsPdf?recipeId=${recipeId}&ingredientId=${ingredeintId}`)
}

export const getSavedIngredientApi =()=>{
   return getApiWithToken(`/recipeAndIngredient/getAllSavedIngredients`);
}

export const getAllSavedIngredientsAndRecipeApi =(search="",page=0,limit=10)=>{
   return getApiWithToken(`/recipeAndIngredient/getAllSavedIngredientsAndRecipes?search=${search}&page=${page}&limit=${limit}`);
}

export const getPendingInvitesApi =()=>{
   return getApiWithToken(`/users/getAllMyInvites`)
}

export const acceptInviteApi =(payload)=>{
   return postApiWithToken(`/users/acceptInvitation`,payload);
}

export const removeFromCommunityApi =(payload)=>{
   return putApiWithToken(`/users/removeMembersFromCommunity`,payload);
}

export const getAllIngridientsUpdateById =(id)=>{
   if(!id) return;
   return getApiWithToken(`/recipeAndIngredient/getIngredientsByIdForUpdate?id=${id}`)
}

export const getMySharedRecipeApi =(search="",page=0,limit=10)=>{
   return getApiWithToken(`/users/getAllMyShareRecipes?search=${search}&page=${page}&limit=${limit}`)
}

export const checkLimitApi =()=>{
   return getApiWithToken(`/recipeAndIngredient/getRecipeLimit`);
}

export const checkUserNameApi =(payload)=>{
   return postApiWithToken(`/users/checkUserName` ,payload);
}

export const getSubscriptionDetailApi =()=>{
   return getApiWithToken(`/`)
}

export const getAllSubscriptionPlans = () =>{
   return getApiWithToken('/users/getAllSubcriptionPlans')
}

export const getAllPlanApi =()=>{
   // return getApiWithToken(`/users/getAllSubcriptionPlans`);
   return getApiWithToken(`/payment/get-plans`);
}

export const getBusinessPlansApi =()=>{
   // return getApiWithToken(`/users/getAllSubcriptionPlans`);
   return getApiWithoutToken(`/payment/get-business-plans`);
}

export const handleCheckoutPlan = (payload) =>{
   return postApiWithToken(`/payment/initiate-payment`,payload)
}

export const handleCustomerBilling = (payload) =>{
   return postApiWithToken(`/payment/session-customer-portal`,payload)
}

export const getSitesList = () => {
   return getApiWithToken(`/users/listOfSites`)
}

export const switchSite =(payload)=>{
   return putApiWithToken(`/users/switchSite`,payload);
}