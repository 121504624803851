import React,{useContext} from 'react'
import Sidebar from './Sidebar'
import MainSearch from './MainSearch'
import BusinessPlanTrailAlert from '../Pages/Common/BusinessPlanTrailAlert'
import OperatingAlert from '../Pages/Common/OperatingAlert'

import { UserContext } from "../Contexts/Contexts";

const AppLayout = (props) => {
  const { showTrialAlert,userProfileDetails } = useContext(UserContext);
  const userDetails = userProfileDetails?.data?.data
  console.log("userDetails", userDetails);
  

   return (
      <>
         <Sidebar />
         <div class="main-content mask content-mask"  style={{paddingTop:'0rem'}} >
            <div className='sticky-header'  style={{paddingTop:'1.5rem'}}>
            <div className='mask' style={{backgroundImage:'url(../../assets/images/Mask Group 57@2x.png);'}}>
          {showTrialAlert && ((userDetails?.id==userDetails?.currentSite?.userId && userDetails?.currentSite?.isDefault) || !userDetails?.currentSite) && <BusinessPlanTrailAlert/>}
            <OperatingAlert/>
            <MainSearch />
            </div>
            </div>
            <div className=''>
            {props.children}
            </div>
         </div>
      </>
   )
}

export default AppLayout