import React from 'react'
import { WhiteLoader } from '../Loader/Loader';
import CloseBtn from "../../Assets/Images/Group 4292.svg";

const CommunityShareModal = (props) => {
    const {communityShareLoader,handleShareRecipie} = props;
    
  return (
    <div
    className="modal fade"
    id="confirmModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog  modal-dialog-centered">
      <div className="modal-content pb-4">
        <div className="modal-header border-bottom-0 pb-0">
          <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
          <img className="modal-image-1" />
          <button
            type=""
            className=" border-0 bg-transparent"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            {" "}
            <img src={CloseBtn} />
          </button>
        </div>
        <div className="modal-body px-5 pt-3 py-0">
          <h1 className="modal-title  fw-bold p-16  pt-5 fs-5 text-center">
            Are you sure want to share recipe to community ??
          </h1>
        </div>

        <div className=" mx-auto">
          <button
            className="btn_custome mt-4 modal-submit me-5 "
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            No
          </button>
          <button
            disabled={communityShareLoader}
            className="btn_custome mt-4 modal-submit"
            onClick={handleShareRecipie}
          >
            {communityShareLoader ? <WhiteLoader/> :"Yes"}
          </button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default CommunityShareModal