import React, { useCallback, useEffect, useRef, useState,useContext } from "react";
import Sidebar from "../../AppLayout/Sidebar";
import MainSearch from "../../AppLayout/MainSearch";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
// import { Link } from "react-router-dom";
// import chart_image from "../../Assets/Images/person.png";
import CloseBtn from "../../Assets/Images/Group 4292.svg";
import ModalImage1 from '../../Assets/Images/Mask Group 70.png'
import ModalImage2 from '../../Assets/Images/Mask Group 68.png'
import {
  assignProfilePicBucketUrl,
  globalHandleChange,
  handleFileUpload,
} from "../../Utils/HelperFuntions";
import {
  addRecepies,
  checkLimitApi,
  downloadRecipeApi,
  getAllMyRecipes,
  getAllSubscriptionPlans,
  getRecipieByIdApi,
  handleCustomerBilling,
  savedRecipieApi,
  shareRecipeApi,
  updateRecipieApi,
} from "../../Api/Apis";
import { saveAs } from "file-saver";
import { toast } from "react-hot-toast";
import { ThemeSpinner } from "../../Utils/Loaders";
import { API_BASE_URL, BUCKET_URL, SITE_URL } from "../../Utils/BaseUrl";
import ProfilePicDemo from "../../Assets/Images/Group 10775.png";
import Default from "../Default";
import { recipeValidation } from "../../Utils/Validations";
import { WhiteLoader } from "../Loader/Loader";
import RecipeChart from "../Common/RecipeChart";
import CommunityShareModal from "../Common/CommunityShareModal";
import ReactPaginate from "react-paginate";
import { Spinner } from "react-bootstrap";
import BusinessPlanTrailAlert from "../Common/BusinessPlanTrailAlert";
import { UserContext } from "../../Contexts/Contexts";
import OperatingAlert from "../Common/OperatingAlert";
const MyRecipes = () => {
  const [userRecipes, setUSerRecipes] = useState({ data: [1], loading: true });
  const [sideDetailsClicked, setSideDetailsClicked] = useState(true);
  // const [updateId, setUpdateId] = useState("");
  const [sortData, setSortData] = useState([]);
  const [isAvail, setIsAvail] = useState(null);
  const [borderId, setBorderId] = useState();
  const [chartDetails, setChartDetails] = useState();
  const [ml, setMl] = useState("g");
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [saved, setSaved] = useState();
  const navigate = useNavigate();
  const [imgSize, setImgSize] = useState({
    frontImage: { name: "", size: "" },
    backImage: { name: "", size: "" },
    thumbnailImage: { name: "", size: "" },
  });
  const [loader,setLoader ] = useState(false)
  const [communityShareLoader,setCommunityShareLoader] = useState()
  const fileInputRef = useRef(null);
  const [pageCount, setPageCount] = useState(1);
  const [currentPlan,setCurrentPlan] = useState({})
  const [isAlreadySubscribed,setIsAlreadySubscribed] = useState(false)
  const [checkoutLoader,setCheckoutLoader] = useState(false)

  const { showTrialAlert, setShowTrialAlert } = useContext(UserContext);
  

  const LIMIT = 30;

  const createRecepieFormik = useFormik({
    initialValues: {
      name: "",
      note: "",
      isPublished: false,
      thumbnailImage: "",
      servingSize: "",
      ingredientIds: JSON.stringify([]),
      noOfPortion:"1"
    },
    validationSchema: recipeValidation,
    onSubmit: async (values) => {
      // let d = {
      //   ...values,
      //   servingSize: { data: values?.servingSize?.data + ml },
      // };
      // console.log(d, "DKJD");
      // document.getElementById("exampleModalRecipe").click();
      try {
        setLoader(true)
        const payload = {...values,noOfPortion:values?.noOfPortion?Number(values?.noOfPortion):0}
        const res = await addRecepies(payload);
        if (res?.status === 200) {
          console.log(res, "ASDFGFDSGDFAFDSAF");
          toast.success(res?.data?.message, { id: "001" });
          createRecepieFormik.resetForm();
          document.getElementById("exampleModalRecipe").click();
          // getAllUserRecipes();
          navigate("/view-recipes", { state: { id: res?.data?.data?.id } });
          // if (Boolean(res?.data?.length)) {
          // }
          // setUpdateId("")
        }
        let onBusinessTrial = res?.data?.onBusinessTrial
        if(onBusinessTrial!== null && onBusinessTrial!== undefined){
          setShowTrialAlert(onBusinessTrial)
        }
        setLoader(false)
      } catch (error) {
        let onBusinessTrial = error?.response?.data?.onBusinessTrial
        if(onBusinessTrial!== null && onBusinessTrial!== undefined){
          setShowTrialAlert(onBusinessTrial)
        }
        console.log(error?.response?.data,'error me aagya')
        setLoader(false)
        toast(error?.response?.data?.message, {
          icon: "❗",
        });
      }
    },
  });

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    getAllUserRecipes("",selectedPage)
  };

  const handleMl = (e) => {
    setMl(e.target.value);
  };

  const handleClosee = () => {
    createRecepieFormik.setValues({
      name: "",
      note: "",
      isPublished: false,
      thumbnailImage: "",
      servingSize: "",
      ingredientIds: [],
    });
    createRecepieFormik.setErrors({});
  };
  console.log(createRecepieFormik.errors, "DASFSGADFFDSAFDAS");
  const getAllUserRecipes = async (search, page) => {
    try {
      setUSerRecipes((v) => ({ ...v, loading: true }));
      const res = await getAllMyRecipes(search, page,LIMIT);

      if (res?.status === 200) {
        setUSerRecipes({ ...userRecipes, data: res.data.data, loading: false });
        setPageCount(Math.ceil(res?.data?.totalLength / LIMIT));
      }
    } catch (error) {
      setUSerRecipes((v) => ({ ...v, loading: false }));
      console.log(error);
      // toast.error("Cannot get user recipes", { id: "001" })
    }
  };

  const handleNotes = (e) => {
    // if (e.target.value?.length > 500) {
    //   e.preventDefault();
    // } else {
      // }
      createRecepieFormik.setFieldValue("note", e.target.value);
  };
  const handleViewRecipeChart = async (id) => {
    document.getElementById(`${borderId}`)?.removeAttribute("class");
    document.getElementById(`${borderId}`)?.setAttribute("class", `food-div`);
    setBorderId(`card${id}`);
    document
      .getElementById(`card${id}`)
      .setAttribute("class", ` borderCard food-div`);
    try {
      const res = await getRecipieByIdApi(id);
      if (res.status === 200 || res.status === 201) {
        setChartDetails(res?.data?.data);
        console.log(res?.data?.data?.isSaved, "savedddeddedded");
        setSaved(res?.data?.data?.isSaved);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdateRecipie = async (id) => {
    //  setUpdateId(id);
    try {
      const res = await getRecipieByIdApi(id);
      if (res.status === 200 || res.status === 201) {
        let data = res.data.data;
        createRecepieFormik.setValues({
          name: data.name,
          note: data.note,
          thumbnailImage: data.thumbnailImage,
          servingSize: data.servingSize,
          ingredientIds: [],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleChange = (e) => {
    const {name,value} = e.target;
    if(name === 'noOfPortion'){
      createRecepieFormik.setFieldValue('noOfPortion', value);
      createRecepieFormik.setFieldValue('servingSize', '');
    }else{
      createRecepieFormik.setFieldValue('noOfPortion', '');
      createRecepieFormik.setFieldValue('servingSize', value);
    }
  };

  const handleThumbinailImage = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    createRecepieFormik.setFieldValue("thumbnailImage", "");
  }
  const handleSaveRecipe = async () => {
    if (saved) {
      let data = {
        recipeId: chartDetails?.id,
        isSaved: false,
      };
      try {
        const res = await savedRecipieApi(data);
        if (res.status === 200 || res.status === 201) {
          toast.success(res?.data?.message);
          handleViewRecipeChart(chartDetails?.id);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      let data = {
        recipeId: chartDetails?.id,
        isSaved: true,
      };
      try {
        const res = await savedRecipieApi(data);
        if (res.status === 200 || res.status === 201) {
          toast.success(res?.data?.message);
          handleViewRecipeChart(chartDetails?.id);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleShareRecipie = async () => {
    if (chartDetails) {
      try {
        setCommunityShareLoader(true)
        const res = await shareRecipeApi({ recipeId: chartDetails.id });
        if (res.status === 200 || res.status === 201) {
          toast.success(res?.data?.message);
          setChartDetails({...chartDetails,isShared:true})
          document.getElementById("confirmModal").click();
        }
        setCommunityShareLoader(false)

      } catch (err) {
        console.log(err);
        setCommunityShareLoader(false)
        toast.error(err.response?.data?.message);
        document.getElementById("confirmModal").click();
      }
    }
  };
  const handleDownloadRecipe = async () => {
    // const token = localStorage.getItem("token");
    // const headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    // fetch(
    //   `${API_BASE_URL}/recipeAndIngredient/generateRecipesAndIngredientsPdf?recipeId=${chartDetails.id}&ingredientId=`,
    //   {
    //     headers: headers,
    //   }
    // )
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     // Save the blob as a file using FileSaver
    //     saveAs(blob, "data.pdf");
    //   })
    //   .catch((error) => {
    //     console.error("Error downloading the CSV file:", error);
    //   });
    setDownloadLoader(true);
    try {
      const res = await downloadRecipeApi(chartDetails.id, "");
      // const res = await downloadRecipeApi("") ;
      if (res.status === 200 || res.status === 201) {
        setDownloadLoader(false);
        window.open(`${BUCKET_URL + "/" + res.data.data}`, "_blank");
      }
    } catch (err) {
      toast.error("Server Error");
      setDownloadLoader(false);
    }
  };
  const getSortedData = (name) => {
    if (name === "new") {
      let d = userRecipes?.data?.map((v) => {
        return v;
      });
      const sortedData = d?.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setSortData(sortedData);
    } else {
      let d = userRecipes?.data?.map((v) => {
        return v;
      });

      const sortedData = d?.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
      // return sortedData;
      setSortData(sortedData);
    }
  };

  const getSubscriptionPlan = async () => {
    try {
      const res = await getAllSubscriptionPlans();
      if (res.status === 200 || res.status === 201) {
        setCurrentPlan(res.data.currentSubscription)
        setIsAlreadySubscribed(res.data.isAlreadySubscribed)
      }
    } catch (Err) {
      console.log(Err);
    }
  };

  const handleUpdatePlan = async () => {
    try {
      setCheckoutLoader(true)
      const res = await handleCustomerBilling({ return_url: `${SITE_URL}/my-recipes` })
      if (res) {
        window.location.href = res.data.url;
      }
      // setCheckoutLoader(false)
    } catch (err) {
      alert('Something Went Wrong')
      setCheckoutLoader(false)
    }
  }

  const CheckLimit = async () => {
    try {
      const res = await checkLimitApi();
      console.log(
        res?.data?.limitUsed === res?.data?.totalLimit,
        "respiiiiiiooppp"
      );
      if (res?.data?.limitUsed === res?.data?.totalLimit) {
        getSubscriptionPlan();
        setIsAvail(true);
      } else {
        setIsAvail(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChartView = () => {
    setChartDetails();
    // setBorderId()
  };

  const handleCloseChart = () =>{
    setChartDetails();
    // setToggle
  }
  const handleSorting = (e) => {
    if (e.target.value === "new") {
      getSortedData("new");
    } else {
      getSortedData("old");
    }
  };

  console.log(sortData, "sortdddtata");
  useEffect(() => {
    getSortedData("new");
  }, [userRecipes]);
  useEffect(() => {
    getAllUserRecipes();
    CheckLimit();
  }, []);

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      // setUpdateId("");
      createRecepieFormik.resetForm();
    }
  }, []);
  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  return (
    <>
      <Sidebar />
      <div className="main-content mask content-mask" style={{paddingTop:'0rem'}}>
        <div className="sticky-header" style={{paddingTop:'1.5rem'}}> 
      {showTrialAlert && <BusinessPlanTrailAlert/>}
      <OperatingAlert/>
        <MainSearch />
        </div>
        <div className="
         pt-4 mb-3 position-relative d-flex justify-content-between align-items-center">
          <div className="all-svg mb-4">
            <button
              className="ingredient mb-0 "
              style={{ border: "1px solid #fe557f" }}
              data-bs-toggle="modal"
              disabled={isAvail === null}
              data-bs-target={
                isAvail ? "#exampleModalsub" : "#exampleModalRecipe"
              }
            >
              <span className="plus-sy">+</span> <b>Create Recipe</b>
            </button>
            {chartDetails && (
              <>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 20.908 17.431"
                  style={{ cursor: "pointer" }}
                  // onClick={handleShareRecipie}
                  fill={chartDetails?.isShared ? "#fd3468" : "currentColor"}
                  data-bs-toggle={chartDetails?.isShared ? "" : "modal"}
                  data-bs-target={chartDetails?.isShared ? "" : "#confirmModal"}
                >
                  <g
                    id="Group_4201"
                    data-name="Group 4201"
                    transform="translate(-4978.529 140.166)"
                  >
                    <path
                      id="Path_10694"
                      data-name="Path 10694"
                      d="M5309.268,40.676a17.426,17.426,0,0,1-1.514,1.063,17.3,17.3,0,0,1-1.711.751.9.9,0,0,1-.168.035,7.213,7.213,0,0,0-4.324-5.676c.133-.181.255-.358.39-.525a.163.163,0,0,1,.152-.011,3.2,3.2,0,0,0,1.415.4,2.992,2.992,0,0,0,1.706-.449,5.265,5.265,0,0,0,.684-.511.242.242,0,0,1,.32-.044,5.559,5.559,0,0,1,2.913,3.943c.048.245.056.5.086.746.01.079.033.157.05.235Z"
                      transform="translate(-309.831 -168.654)"
                    />
                    <path
                      id="Path_10695"
                      data-name="Path 10695"
                      d="M4978.529,40.548c.032-.247.057-.5.1-.741a5.56,5.56,0,0,1,2.972-4.191c.146-.08.219-.017.314.07a3.5,3.5,0,0,0,1.671.881,2.968,2.968,0,0,0,2.045-.311c.146-.077.221-.067.306.076a4.93,4.93,0,0,0,.323.434,7.307,7.307,0,0,0-2.919,2.264,7.217,7.217,0,0,0-1.409,3.435c-.332-.118-.65-.218-.959-.345a8.143,8.143,0,0,1-2.308-1.436,1.5,1.5,0,0,0-.132-.094Z"
                      transform="translate(0 -168.568)"
                    />
                    <path
                      id="Path_10696"
                      data-name="Path 10696"
                      d="M5085.68,83.028a6.316,6.316,0,0,1,2.161,1.692,6.4,6.4,0,0,1,1.489,3.987.319.319,0,0,1-.095.221,9.6,9.6,0,0,1-11.755.761c-.326-.22-.626-.478-.942-.714a.309.309,0,0,1-.136-.293,6.4,6.4,0,0,1,3.526-5.6c.136-.075.205-.027.3.058a3.994,3.994,0,0,0,2.186,1.093,3.422,3.422,0,0,0,2.335-.52C5085.067,83.514,5085.359,83.266,5085.68,83.028Z"
                      transform="translate(-93.876 -214.079)"
                    />
                    <path
                      id="Path_10697"
                      data-name="Path 10697"
                      d="M5150.493-109.2a2.711,2.711,0,0,1-1.723-.963,5.4,5.4,0,0,1-1.567-3.681,3.517,3.517,0,0,1,2.662-3.483,3.56,3.56,0,0,1,4.439,2.887,4.486,4.486,0,0,1-.377,2.5,5.052,5.052,0,0,1-1.667,2.222A2.7,2.7,0,0,1,5150.493-109.2Z"
                      transform="translate(-161.786 -21.797)"
                    />
                    <path
                      id="Path_10698"
                      data-name="Path 10698"
                      d="M5044.9-133.225a1.994,1.994,0,0,1-2.145.107,3.949,3.949,0,0,1-1.563-1.729,4.558,4.558,0,0,1-.549-2.21,3.118,3.118,0,0,1,2.857-2.965,3.08,3.08,0,0,1,2.532.978.709.709,0,0,1,.054.061.224.224,0,0,1,.021.053,4.211,4.211,0,0,0-1.652,2.616A5.336,5.336,0,0,0,5044.9-133.225Z"
                      transform="translate(-59.579 -0.13)"
                    />
                    <path
                      id="Path_10699"
                      data-name="Path 10699"
                      d="M5295.65-133.334c.9-2.195.723-4.148-1.209-5.747a2.887,2.887,0,0,1,1.092-.817,3.107,3.107,0,0,1,4.318,2.234,3.779,3.779,0,0,1-.3,2.267,4.121,4.121,0,0,1-1.745,2.139A2.008,2.008,0,0,1,5295.65-133.334Z"
                      transform="translate(-303.012 0)"
                    />
                  </g>
                </svg>
                {downloadLoader ? (
                  <div
                    style={{
                      boxShadow: "0px 3px 6px #00000029",
                      backgroundColor: "white",
                      padding: "6px 8px",
                      marginLeft: "5px",
                      borderRadius: "10px",
                    }}
                  >
                    {" "}
                    <WhiteLoader color="black !important" />
                  </div>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-download"
                    viewBox="0 0 16 16"
                    style={{ cursor: "pointer" }}
                    onClick={handleDownloadRecipe}
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                )}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill={saved ? "#fd3468" : "currentColor"}
                  className="bi bi-bookmark-plus-fill"
                  viewBox="0 0 16 16"
                  onClick={handleSaveRecipe}
                  style={{ cursor: "pointer" }}
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zm6.5-11a.5.5 0 0 0-1 0V6H6a.5.5 0 0 0 0 1h1.5v1.5a.5.5 0 0 0 1 0V7H10a.5.5 0 0 0 0-1H8.5V4.5z"
                  />
                </svg>
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  className="bi bi-share-fill"
                  viewBox="0 0 16 16"
                  style={{cursor:"pointer"}}
                >
                  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                </svg> */}
              </>
            )}
          </div>

          <div>
            <h4>
              Sort by
              <select
                name=""
                className="ms-2"
                onChange={handleSorting}
                id=""
                style={{ border: "none", fontWeight: "700" }}
              >
                <option value="new">Newest</option>
                <option value="old">Oldest</option>
              </select>
            </h4>
          </div>
        </div>
        <div className="row">
          <div
            className={`col-xl-${chartDetails ? 8 : 12} col-lg-${chartDetails ? 6 : 12
              } disable-scroller`}
            style={{}}
          >
            <div className="row">
              {Boolean(userRecipes?.data.length) &&
                userRecipes?.loading === false ? (
                sortData?.map((el, i) => {
                  return (
                    <React.Fragment key={`recipe-${i}`}>
                      <div
                        className={`col-xl-${chartDetails ? 6 : 4} col-lg-${chartDetails ? 12 : 6
                          } mt-3`}
                      >
                        <div className="food-div" id={`card${el.id}`}>
                          <div className="ratio ratio-4x3" style={{cursor:"pointer"}}>
                            <img
                              src={BUCKET_URL + "/" + el.thumbnailImage}
                              width="100%"
                              alt=""
                              className="img-fluid"
                              onError={(e) => {
                                e.target.src = ProfilePicDemo;
                                e.error = null;
                              }}
                              onClick={() =>
                                navigate("/view-recipes", {
                                  state: { id: el.id },
                                })
                              }
                            />
                          </div>

                          <div className="d-flex justify-content-between mt-3">
                            <p
                              className="ingredients"
                              onClick={() => handleUpdateRecipie(el.id)}
                            >
                              Recipe
                            </p>

                            <div className="d-flex">
                              <small
                                style={{ color: "#000", fontWeight: "600" }}
                                className=" d-flex flex-column"
                              >
                                <p className="m-0 p-10">
                                  <span> Created on:</span>{" "}
                                  <span>{el.dateTime}</span>
                                </p>
                                <p className="m-0 p-10">
                                  <span> Created by:</span>{" "}
                                  <span>{el?.usersDetails?.userName}
                                    {/* {el?.usersDetails?.firstName +
                                      " " +
                                      el?.usersDetails?.lastName} */}
                                  </span>
                                </p>
                              </small>
                            </div>
                          </div>

                          <h1 style={{minHeight:"60px"}}>{el?.name}</h1>
                          <button
                            className="mt-3 btn-view-chart w-100"
                            // data-bs-toggle="modal"
                            // data-bs-target="#exampleModal"
                            // onClick={() => handleViewRecipeChart(el.id)}
                            onClick={() => navigate("/view-recipes", {
                              state: { id: el.id },
                            })}
                          >
                            View Chart
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })
              ) : userRecipes?.loading ? (
                <div className="text-center">
                  <ThemeSpinner />
                </div>
              ) : (
                // <div className="text-center">No data found</div>
                <Default name="My Recipes" subHeading="Add recipes to get started" showSubHeading/>
              )}
            </div>
          {pageCount >1 && 
          <div className="d-flex justify-content-center mt-5">
            <ReactPaginate
              nextLabel="Next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel="< Previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
            </div>}
          </div>
          {chartDetails && (
            <div className="col-xl-4 col-lg-6 mt-3">
              {/* <div class="card bg-transparent border-0 mb-0">
                <div class="card-body  position-relative  p-0">
                  <div className="modal_chart_list mx-2 bg-white mb-3 ">
                    <i
                      onClick={handleChartView}
                      class="bi bi-x position-absolute me-3 border rounded-circle mt-1 px-1 bg-dark text-white end-0"
                    ></i>
                    {Boolean(chartDetails?.ingredientDetails?.length) &&
                      chartDetails?.ingredientDetails?.map((v) => {
                        console.log(v, "vallluuueeee");
                        return (
                          <div className="row mb-4 mt-4">
                            <div className="col-auto">
                              <img
                                src={BUCKET_URL + "/" + v.frontImage}
                                style={{
                                  width: "28px",
                                  height: "28px",
                                  borderRadius: "50%",
                                }}
                                onError={(e) => {
                                  e.target.src = ProfilePicDemo;
                                  e.error = null;
                                }}
                              />
                            </div>
                            <div className="col-7">
                              <h6 className=" p-14 fw-bold"> {v.name}</h6>
                              <p className="p-10 m-0">Note: {v.notes}</p>
                            </div>
                            <div className="col-auto">
                              <p className="m-0 p-12">
                                {v?.quantity + " " + v?.quantityUnit}
                              </p>
                            </div>

                            <div className="col-auto">
                              <i
                                class="bi bi-caret-right icon-color"
                                type="button"
                              ></i>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="modal-header-list position-relative w-100">
                    <h1
                      class="modal-title-list  mb-0 py-3 "
                      id="exampleModalLabel"
                    >
                      Nutritional Information
                    </h1>
                  </div>

                  <div class="bg-white p-3 " style={{ border: "1px solid #000" }}>
                    <div
                      className=" heading d-flex justify-content-around align-items-center"
                      style={{ borderBottom: "9px solid black" }}
                    >
                      <h6 className="p-1">Serving size </h6>
                      <h6 className="p-12">
                        {
                          console.log(chartDetails, "chrrrrrt")
                        }
                        (
                        {chartDetails?.servingSize?.data
                          ? chartDetails?.servingSize?.data
                          : chartDetails?.servingSize}
                        )
                      </h6>
                    </div>
                    <div
                      className=" heading d-flex justify-content-around align-items-center "
                      style={{ borderBottom: "5px solid black" }}
                    >
                      <h6 className="p-17 fw-bold">Calories per serving</h6>
                      <h1 className="p-17 fw-boldest">
                        {chartDetails?.caloriePerServing}
                      </h1>
                    </div>
                    {
                      Boolean(chartDetails?.proximatesDetails?.nutrients?.length) && <>
                        <div className="row align-item-center">
                          <h6 className="col-2">Nutrients</h6>
                          <h6 className="col-2">Unit</h6>
                          <h6 className="col-2">Per 100g</h6>
                          <h6 className="col-2">
                            per {chartDetails?.totalQuantity}{" "}
                          </h6>
                          <h6 className="col-2">serving </h6>
                          <h6 className="col-2">NRV </h6>
                        </div>
                        <hr className="m-0 bottom-line" />
                      </>
                    }

                    <div className="disable-scroller_chart">
                      {console.log(chartDetails, "cjradkede")}
                      {chartDetails?.proximatesDetails?.nutrients?.map((v) => {
                        return (
                          <div className="row  pt-3">
                            <div className="col-2 text-start">
                              <h6 className=" fs-5 fw-bold">
                                {v?.link ? (
                                  <Link to={v?.link} target="_blank">
                                    {v.name}
                                  </Link>
                                ) : (
                                  <span>{v.name}</span>
                                )}
                              </h6>
                            </div>
                            <div className="col-2">
                              <p className="fs-5">{v?.unit}</p>
                            </div>
                            <div className="col-2">
                              <h6 className="text-start fs-5 fw-bold">
                                {v?.hundredValue?.toString()?.split(".")
                                  ?.length > 1
                                  ? Number(v?.hundredValue)?.toFixed(2)
                                  : v?.hundredValue}
                              </h6>
                            </div>
                            <div className="col-2">
                              <h6 className="text-start fs-5 fw-bold">
                                {v?.value?.toString()?.split(".")?.length > 1
                                  ? Number(v?.value)?.toFixed(2)
                                  : v?.value}
                              </h6>
                            </div>
                            <div className="col-2">
                              <h6 className="text-start fs-5 fw-bold">
                                {v?.nrvValue > 0 ? v?.nrvPercentage + "%" : ""}
                              </h6>
                            </div>
                            <div className="col-2">
                              <h6 className="text-start fs-5 fw-bold">
                                {v?.nrvValue > 0 ? v?.nrvValue : ""}
                              </h6>
                            </div>
                          </div>
                        );
                      })}

                      {
                        Boolean(chartDetails?.proximatesDetails?.vitamins?.length) && <>
                          <hr className="m-0 bottom-line" />
                          <div className="row align-item-center">
                            <h6 className="col-2">Vitamins</h6>
                            <h6 className="col-2">Unit</h6>
                            <h6 className="col-2">Per 100g</h6>
                            <h6 className="col-2">
                              per {chartDetails?.totalQuantity}{" "}
                            </h6>
                            <h6 className="col-2">serving </h6>
                            <h6 className="col-2">NRV </h6>
                          </div>
                          <hr className="m-0 bottom-line" />
                        </>
                      }

                      {chartDetails?.proximatesDetails?.vitamins?.map((v) => {
                        return (
                          <div className="row  pt-3">
                            <div className="col-2 text-start">
                              <h6 className=" fs-5 fw-bold">
                                {v?.link ? (
                                  <Link to={v?.link} target="_blank">
                                    {v.name}
                                  </Link>
                                ) : (
                                  <span>{v.name}</span>
                                )}
                              </h6>
                            </div>
                            <div className="col-2">
                              <p className="fs-5">{v?.unit}</p>
                            </div>
                            <div className="col-2">
                              <p className="fs-5">
                                {v?.hundredValue?.toString()?.split(".")
                                  ?.length > 1
                                  ? Number(v?.hundredValue)?.toFixed(2)
                                  : v?.hundredValue}
                              </p>
                            </div>
                            <div className="col-2">
                              <h6 className="text-start fs-5 fw-bold">
                                {v?.value?.toString()?.split(".")?.length > 1
                                  ? Number(v?.value)?.toFixed(2)
                                  : v?.value}
                              </h6>
                            </div>
                            <div className="col-2">
                              <h6 className="text-start fs-5 fw-bold">
                                {v?.nrvValue > 0 ? v?.nrvPercentage + "%" : ""}
                              </h6>
                            </div>
                            <div className="col-2">
                              <h6 className="text-start fs-5 fw-bold">
                                {v?.nrvValue > 0 ? v?.nrvValue : ""}
                              </h6>
                            </div>
                          </div>
                        );
                      })}
                      {
                        Boolean(chartDetails?.proximatesDetails?.minerals?.length) && <>
                          <hr className="m-0 bottom-line" />
                          <div className="row align-item-center">
                            <h6 className="col-2">Minerals</h6>
                            <h6 className="col-2">Unit</h6>
                            <h6 className="col-2">Per 100g</h6>
                            <h6 className="col-2">
                              per {chartDetails?.totalQuantity}{" "}
                            </h6>
                            <h6 className="col-2">serving </h6>
                            <h6 className="col-2">NRV </h6>
                          </div>
                          <hr className="m-0 bottom-line" />
                        </>
                      }

                      {chartDetails?.proximatesDetails?.minerals?.map((v) => {
                        return (
                          <div className="row  pt-3">
                            <div className="col-2 text-start">
                              <h6 className=" fs-5 fw-bold">
                                {v?.link ? (
                                  <Link to={v?.link} target="_blank">
                                    {v.name}
                                  </Link>
                                ) : (
                                  <span>{v.name}</span>
                                )}
                              </h6>
                            </div>
                            <div className="col-2">
                              <p className="fs-5">{v?.unit}</p>
                            </div>
                            <div className="col-2">
                              <h6 className="text-start fs-5 fw-bold">
                                {v?.value?.toString()?.split(".")?.length > 1
                                  ? Number(v?.value)?.toFixed(2)
                                  : v?.value}
                              </h6>
                            </div>
                            <div className="col-2">
                              <h6 className="text-start fs-5 fw-bold">
                                {v?.value?.toString()?.split(".")?.length > 1
                                  ? Number(v?.value)?.toFixed(2)
                                  : v?.value}
                              </h6>
                            </div>
                            <div className="col-2">
                              <h6 className="text-start fs-5 fw-bold">
                                {v?.nrvValue > 0 ? v?.nrvPercentage + "%" : ""}
                              </h6>
                            </div>
                            <div className="col-2">
                              <h6 className="text-start fs-5 fw-bold">
                                {v?.nrvValue > 0 ? v?.nrvValue : ""}
                              </h6>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <p
                      className=" text-dark m-0 card-bottom p-10 pt-3"
                      style={{ borderTop: "3px solid black" }}
                    >
                      % values are based on a 2000
                      calories diet. Your daily values may be higher or lower
                      depending on your energy needs.
                    </p>
                    <p
                      className=" text-dark m-0  p-10 "
                     
                    >
                      A ratio value of 1 is used for volume to weight conversion during some analysis.

                    </p>
                    <p
                      className=" text-dark m-0  p-10"
                     
                    >
                      Some of the ingredients and/or recipes on this database are Crown copyright data drawn from CoFID and licensed under the Open Government Licence v3.0.

                    </p>
                  </div>
                </div>
              </div> */}
              <RecipeChart chartDetails={chartDetails} handleCloseChart={()=>handleCloseChart()}/>
            </div>
          )}

          {/* <div className="col-xl-4 col-lg-6 mt-3">
                  <div className="food-div">
                     <img src="./assets/images/breack-fast.jpg" width="100%" alt="" />
                     <div className="d-flex justify-content-between mt-3">
                        <p className="ingredients">Ingredients</p>
                        <p className="shared-green"><i className="bi bi-dot"></i> Shared</p>
                     </div>
                     <h1>Potato Mash with Butter</h1>
                     <button className="mt-3 btn-view-chart w-100">View Chart</button>
                  </div>
               </div> */}
        </div>
      </div>

      {/* chart modal  */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="modal-content">
                <h2 className="bg-dark text-white p-3 mb-0">
                  Nutritional Information
                </h2>
                <div className="modal-body p-2 rounded-0">
                  <div
                    className="table-responsive"
                    style={{ height: "520px", overflowY: "auto" }}
                  >
                    <table className="table table-borderlessj">
                      <thead>
                        <tr>
                          <th className="pt-0 pb-0 border-0 text-black">
                            <b>Nutrients</b>
                          </th>
                          <th className="pt-0 pb-0 border-0 text-black"></th>
                          <th className="pt-0 pb-0 border-0 text-black">
                            <b>per 100g</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <hr /> */}
                        <tr>
                          <td colSpan="3">
                            <hr />
                          </td>{" "}
                        </tr>
                        <tr>
                          <td>
                            <a href="">
                              <i className="bi bi-box-arrow-up-right me-1"></i>
                            </a>
                            <b>Protein</b>
                          </td>
                          <td>g</td>
                          <td>
                            <b>PER 100G</b>
                          </td>
                        </tr>

                        <tr>
                          <td colSpan="3">
                            <hr />
                          </td>{" "}
                        </tr>
                        <tr>
                          <td>
                            <a href="">
                              <i className="bi bi-box-arrow-up-right me-1"></i>
                            </a>
                            <b>Minerals</b>
                          </td>
                          <td>g</td>
                          <td>
                            <b>PER 100G</b>
                          </td>
                        </tr>

                        <tr>
                          <td colspan="3">
                            <hr style={{ border: "2px solid #000" }} />
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <b>Vitamins:D</b>
                          </td>
                          <td>g</td>
                          <td>
                            <b>0.58</b>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Riboflavin(B2)</b>
                          </td>
                          <td>g</td>
                          <td>
                            <b>0.58</b>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Cobalamin(B12)</b>
                          </td>
                          <td>g</td>
                          <td>
                            <b>0.58</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "inline-block",
                  float: "right",
                  marginTop: "10px",
                }}
              ></div>
            </div>
          </div>
        </div>{" "}
        0
      </div>

      {/* ------------------- */}

      {/* Create a new recipe popup code start here */}
      <div
        className="modal fade"
        id="exampleModalRecipe"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered newrecipe">
          <div className="modal-content">
            <form onSubmit={createRecepieFormik.handleSubmit}>
              <div className="modal-body">
                <a
                  href=""
                  onClick={handleClosee}
                  className="close-img"
                  data-bs-dismiss="modal"
                >
                  <img src={CloseBtn} alt="" srcset="" />
                </a>
                <div className="text-center">
                  <img src="./assets/images/bkimg.PNG" width="45" alt="" />
                </div>
                <h2 className="text-center">{"Create a new recipe"}</h2>
                <div className="d-flex justify-content-between">
                  <label
                    className="upload text-danger"
                    style={{ fontSize: ".8rem" }}
                  >
                    Upload Recipe image Here
                    <input
                      type="file"
                      ref={fileInputRef}
                      name="thumbnailImage"
                      onChange={(e) => {
                        handleFileUpload(
                          e,
                          createRecepieFormik,
                          setImgSize,
                          "recipe"
                        );
                      }}
                      accept="image/*"
                      style={{ display: "none" }}
                    />
                  </label>
                </div>
                {createRecepieFormik?.values?.thumbnailImage && (
                  <div className="d-flex justify-content-around after-upload-img mt-2">
                    <div className="me-3">
                      <img
                        // src={`${assignProfilePicBucketUrl(
                        //   createRecepieFormik.values.thumbnailImage
                        // )}`}
                        src={
                          BUCKET_URL +
                          "/" +
                          createRecepieFormik?.values.thumbnailImage
                        }
                        width="44"
                        onError={(e) => {
                          e.target.src = ProfilePicDemo;
                          e.error = null;
                        }}
                        alt=""
                      />
                    </div>
                    <div
                      style={{
                        textAlign: "left",
                        width: "100%",
                        overflowWrap: "anywhere",
                      }}
                    >
                      <p className="fw-bold">
                        {imgSize?.thumbnailImage?.name || "Image Name"}
                      </p>
                      <h5>
                        {imgSize?.thumbnailImage?.size || "Image Size in"} MB
                      </h5>
                    </div>
                    <div>
                      {/* <a href="">X</a> */}
                      <i className="bi bi-x" onClick={handleThumbinailImage} style={{ cursor: "pointer" }}></i>
                    </div>
                  </div>
                )}

                <div className="position-relative mt-4">
                  <label htmlFor="name" className="input-label">
                    Enter Recipe Name
                  </label>
                  <textarea
                    className="form-control px-2"
                    type="text"
                    name="name"
                    rows="2"
                    value={createRecepieFormik?.values.name}
                    onChange={(e) => {
                      globalHandleChange(e, createRecepieFormik);
                    }}
                    id="name"
                    placeholder="Enter Recipe name"
                  />

                  <p className="text-danger px-2">
                    {createRecepieFormik?.errors?.name}
                  </p>
                </div>

                <div className="row mt-4">
                  {/* <div className="col-5">
                    <select
                      onChange={handleMl}
                      value={ml}
                      className="form-select"
                      name=""
                      id=""
                    >
                    
                      <option value="g">g</option>
                    </select>
                  </div> */}
                  <div className="col-4">
                    <label className="form-label mt-3" htmlFor="add">
                      No. of Portion
                    </label>
                    <input
                      className="form-control px-2"
                      type="text"
                      name="noOfPortion"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={createRecepieFormik.values.noOfPortion || ""}
                      placeholder="Portion No."
                    />
                  </div>
                  <div className="col-1 d-flex align-items-end pb-3 ">
                    <span>or</span>
                  </div>
                  <div className="col-4">
                    <label className="form-label mt-3" htmlFor="add">
                      Portion Size
                    </label>
                    <input
                      className="form-control px-2"
                      type="text"
                      name="servingSize"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={createRecepieFormik.values.servingSize || ""}
                      id=""
                      placeholder="Add Weight"
                    />
                  </div>
                  <div className="col-2 d-flex align-items-end">
                    <span className="fw-bolder pb-3">grams</span>
                  </div>
                  <p className="text-danger px-2 pt-2">
                    {createRecepieFormik?.errors?.servingSize || createRecepieFormik?.errors?.noOfPortion}
                  </p>
                </div>

                <label className="form-label mt-3" htmlFor="add">
                  Add recipe note
                </label>
                <textarea
                  className="form-control mb-2"
                  name="note"
                  // onChange={(e) => {
                  //   globalHandleChange(e, createRecepieFormik);
                  // }}
                  onChange={handleNotes}
                  value={createRecepieFormik.values.note || ""}
                  id=""
                  cols="30"
                  rows="3"
                >
                  Enter Notes here Max 500 words.
                </textarea>
               {createRecepieFormik?.errors?.note &&  <p className="text-danger px-2 pt-2">
                    {createRecepieFormik?.errors?.note}
                  </p>}
                <div>
                  <input
                    value={createRecepieFormik.values.isPublished}
                    onChange={() =>
                      createRecepieFormik.setFieldValue(
                        "isPublished",
                        !createRecepieFormik.values.isPublished
                      )
                    }
                    type="checkbox"
                    name=""
                    id=""
                  />{" "}
                  Share this recipe publicly on The CalorieFinder
                </div>
                <div className="text-center">
                  <button className="btn_custome mt-4" type="submit" disabled={loader}>
                    {loader ? <WhiteLoader/> : "Create Recipe"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>


      {/* *********************** community share modal************** */}
      <CommunityShareModal communityShareLoader={communityShareLoader} handleShareRecipie={handleShareRecipie}/>


      {/* business modal start herer  */}
      <div
        class="modal fade"
        id="exampleModalsub"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content model-subs">
            <div className="modal-body">
              <div className=" col-lg-12 ms-lg-4">
                <div class="card main-card">
                    
                  <div class="card-body-1" style={{ borderColor:currentPlan?.product_data?.colors?.primary}}>
                    {/* <h5 class="card-title ">Subscriptions</h5> */}
                    <h6 class="card-subtitle mb-4 ">
                     Your Plan
                    </h6>

                    <div
                      className="small-card-1 mb-4"
                      // style={{boxShadow:currentPlan?.product_data?.colors?.secondary}}
                      style={{ boxShadow: `0 10px 40px ${currentPlan?.product_data?.colors?.secondary}` }}
                      // data-bs-toggle="modal"
                      // data-bs-target="#exampleModal2"
                    >
                      <div className="heading-4 d-flex justify-content-between align-item-center">
                      <h1 style={{ color: currentPlan?.product_data?.colors?.primary ? currentPlan?.product_data?.colors?.primary : "#42c442" }}>
                        {currentPlan?.product_data?.name}
                        </h1>
                        {!!isAlreadySubscribed && <p style={{ borderColor: currentPlan?.product_data?.colors?.primary ? currentPlan?.product_data?.colors?.primary : "#42c442", backgroundColor: currentPlan?.product_data?.colors?.primary ? currentPlan?.product_data?.colors?.secondary : "#42c442"}}>
                        <u style={{ color: currentPlan?.product_data?.colors?.primary ? currentPlan?.product_data?.colors?.primary : "#42c442", }}>
                        {isAlreadySubscribed ? currentPlan?.amount ? `${currentPlan?.currencySymbol} ${currentPlan?.amount}/${currentPlan?.interval}` :"Free" : "Trial"}
                      </u>
                        </p>}
                      </div>
                      <div>

                      
                      </div>
                      <div>

                      </div>
                    <p className="small-card-1-body">
                      {currentPlan?.product_data?.description}
                    </p>
      
                    </div>
                  </div>
                </div>
                <div className="text-center">
                    <h3>
                    You have reached the maximum daily allowance for your current plan. Please continue tomorrow or upgrade to unlock more daily analysis
                    </h3>
                </div>
              </div>

              <div className="text-center">
              <button
                data-bs-dismiss={isAlreadySubscribed ? "" :"modal"}
                aria-label={isAlreadySubscribed ? "" :"Close"}
                className="btn_custome mt-4"
                type="button"
                onClick={()=>isAlreadySubscribed ? handleUpdatePlan()  :navigate('/upgrade-plan')}
              >
                {checkoutLoader ? <Spinner/> : "Change Plan"}
              </button>
            </div>

              {/* <div className=" col-lg-12">
                <div class="card main-card">
                  <div class="card-body">
                    <h5 class="card-title ">Subscriptions</h5>
                    <h6 class="card-subtitle mb-2 ">
                      Choose Your Business Plan
                    </h6>
                    <p class="card-text ">Start your 30 days free trial</p>
                    <div
                      className="small-card  active mb-4"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal1"
                    >
                      <div className="heading d-flex justify-content-between align-item-center">
                        <h1>Business silver</h1>
                        <p>
                          <u>£ 200/year</u>
                        </p>
                      </div>
                      <p className="small-card-body">
                        Suitable for businesses up to 3 sites up to 30
                        analysis/day
                      </p>
                      <p className=" mb-0 pb-0 ">
                        <i class="bi bi-check-circle-fill h1 icon  mb-0"></i>
                      </p>
                    </div>
                    <div className="small-card  mb-4">
                      <div className="heading-1 d-flex justify-content-between align-item-center">
                        <h1>Business Gold</h1>
                        <p>
                          <u>£ 379/year</u>
                        </p>
                      </div>
                      <p className="small-card-body">
                        Suitable for businesses up to 7 sites up to 70
                        analysis/day
                      </p>
                      <p className=" mb-0 pb-0 ">
                        <i class="bi bi-check-circle-fill h1 icon  mb-0"></i>
                      </p>
                    </div>
                    <div className="small-card  mb-4">
                      <div className="heading-2 d-flex justify-content-between align-item-center">
                        <h1>Business Platinium</h1>
                        <p>
                          <u>£ 600/year</u>
                        </p>
                      </div>
                      <p className="small-card-body">
                        Suitable for businesses up to 20 sites up to 220
                        analysis/day
                      </p>
                      <p className=" mb-0 pb-0 ">
                        <i class="bi bi-check-circle-fill h1 icon  mb-0"></i>
                      </p>
                    </div>
                    <div className="small-card  mb-4">
                      <div className="heading-3 d-flex justify-content-between align-item-center">
                        <h1>Business Unlimited</h1>
                        <p>
                          <u>£ 999/year</u>
                        </p>
                      </div>
                      <p className="small-card-body">
                        Suitable for businesses up to 50 sites up to 500
                        analysis/day
                      </p>
                      <p className=" mb-0 pb-0 ">
                        <i class="bi bi-check-circle-fill h1 icon  mb-0"></i>
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* upgrade modal  */}

      <div className="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content pb-4">
            <div className="modal-header border-bottom-0 pb-0">
              <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
              <img className='modal-image-1' src={ModalImage1} />
              <button type="" className=" border-0 bg-transparent" data-bs-dismiss="modal" aria-label="Close"> <img src={CloseBtn} /></button>
            </div>
            <div className="modal-body px-5 pt-3 py-0">
              <h1 className="modal-title  fw-bold p-16  pt-5 fs-5 text-center" >Upgrade plan ??</h1>
              <p className='fw-bolder  p-12 ps-4'>Are you sure you want to upgrade your plan into Business Platinium?</p>

              <p className='p-10 mb-0 ps-4'>The prepaid amount will be deducted from your new subscription plan amount.</p>

            </div>

            <div className=" mx-auto">
              <button className="btn_custome mt-4 modal-submit me-5 ">No</button>
              <button className="btn_custome mt-4 modal-submit">Yes</button>
            </div>
          </div>
        </div>
      </div>
      {/* modal-2 */}
      <div className="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content pb-4">
            <div className="modal-header border-bottom-0 pb-0">
              <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
              <img className='modal-image-1' src={ModalImage2} />
              <button type="" className=" border-0 bg-transparent" data-bs-dismiss="modal" aria-label="Close"> <img src={CloseBtn} /></button>
            </div>
            <div className="modal-body px-5 py-0">
              <h1 className="modal-title  fw-bold p-16 pt-5 fs-5 text-center" >Upgrade plan ??</h1>
              <p className='fw-bolder  p-12 ps-4'>Are you sure you want to Downgrade <br /> your plan into Business silver ?'</p>

              <p className='p-10 mb-0 ps-4'>The Business silver plan will go into effect with your next payment. You may continue using your current plan up until the day before your subsequent payment due date.</p>

            </div>

            <div className=" mx-auto">
              <button className="btn_custome mt-4 modal-submit me-5 ">No</button>
              <button className="btn_custome mt-4 modal-submit">Yes</button>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default MyRecipes;
