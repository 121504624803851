import * as yup from "yup"

export const AddSitesValidation = yup.object({
   siteList: yup.array()
      .of(
         yup.object().shape({
            siteName: yup.string().required("Site name is required"),
            managerName:  yup.string().required("Manager name is required"),
            managerMail:  yup.string().email("Email must be vaild").required("Manager email is required"),
            address:yup.string().required("Site address is required"),
         })
      )
      .required('Required'),
})

export const addSiteValidate = yup.object().shape({
   name: yup.string().required("Site name is required"),
   nameManager:  yup.string().required("Manager name is required"),
   mailManager:  yup.string().email("Email must be vaild").required("Manager email is required"),
   address:yup.string().required("Site address is required"),

})

export const logInFormikValidation = yup.object({
   phone: yup.string().required("Phone number can't be empty")
      .min(6, "Please enter a valid phone number")
      .max(15, "Please enter a valid phone number"),
})

export const signUpFormikValidation = yup.object({
   phone: yup.string().required("Phone number can't be empty")
      .min(6, "Please enter a valid phone number")
      .max(15, "Please enter a valid phone number"),
      email: yup.string()
      .email('Invalid email address')
      .required("Email can't be empty"),
   });
// export const recipeValidation = yup.object({
//    name: yup.string().required("Recipe name is required"),
// })

export const recipeValidation = yup.object().shape({
   name: yup.string().required('Recipe name is required'),
   note: yup.string().test('maxWords', 'Notes here can have a maximum of 1000 words.', function(value){
      if (!value) {
        return true; // If the value is empty, consider it valid
      }
      const maxWords = 1000;
      const wordCount = value.split(/\s+/).length;
      return wordCount <= maxWords;
    }),
   noOfPortion: yup.string().test('oneOfTwo', 'You must enter either no of portion or serving size', function(value) {
     const servingSize = this.parent.servingSize;
     return Boolean(value) || Boolean(servingSize);
   }),
   servingSize: yup
    .string()
    .test('oneOfTwo', 'You must enter either no of portion or serving size', function (value) {
      const noOfPortion = this.parent.noOfPortion;
      return value || noOfPortion;
    })
    .test('minServingSize', 'Serving size cannot be smaller than 10g', function (value) {
      if (!value) return true; // Allow empty values
      const parsedValue = parseInt(value, 10);
      return !isNaN(parsedValue) && parsedValue > 9;
    }),
 });