import React from 'react'
import { Navigate } from 'react-router-dom'
import { isAuth } from '../Utils/HelperFuntions'

const PublicRoute = ({ children }) => {

   const auth = isAuth()

   return !auth ? children : <Navigate to='/' />
}

export default PublicRoute