import { toast } from "react-hot-toast";
import { fileUploadApi } from "../Api/Apis";
import firebase from "./Firebase"
import { BUCKET_URL } from "./BaseUrl";
import { nanoid } from 'nanoid'

export const getToken = () => sessionStorage.getItem("token")
export const setToken = (token) => sessionStorage.setItem("token", token)
export const removeToken = () => sessionStorage.removeItem("token")
export const isAuth = () => Boolean(getToken())

export const handleFileUpload = async (event, formik, setImgSize,type) => {
   console.log(event.target.files[0]?.name?.split(".")[event.target.files[0]?.name?.split(".")?.length-1] , "JJJJJJJJJJJJJjjj")
   if(event.target.files[0]?.name?.split(".")[event.target.files[0]?.name?.split(".")?.length-1] == "png" ||event.target.files[0]?.name?.split(".")[event.target.files[0]?.name?.split(".")?.length-1]=="jpg"||event.target.files[0]?.name?.split(".")[event.target.files[0]?.name?.split(".")?.length-1]=="jpeg"){

   const logoImg = event.target.files[0];
   const imgFile = new FormData();
   let setVal = { name: logoImg.name, size: ((logoImg.size / 1024) / 1024).toFixed(2) }

   const toastLoader = toast.loading("Uploading...")

   if (!logoImg) {
      toast.dismiss(toastLoader)
      return
   }

   imgFile.append("file", logoImg);
   imgFile.append("type",type)

   if (logoImg.size > 15000000) {
      toast.error("Please upload file less than 15 mb");
      toast.dismiss(toastLoader)
      return
   }

   const func = async () => {
      try {
         if (logoImg.name.match(/\.(jpg|jpeg|png|jfif|webp|JPG|JPEG|PNG|JFIF|WEBP)$/)) {
            const res = await fileUploadApi(imgFile);
            if (res.status === 200) {
               console.log(formik.values, "inside the helper function", event.target.name, res)
               formik.setFieldValue(event.target.name, res?.data?.filePath || res?.data?.data);
               toast.dismiss(toastLoader)
               toast.success(res?.data?.message, { id: "001" });
            }
         } else {
            toast.dismiss(toastLoader)

            toast.error("Unsupported Image Format", { id: "err01" });
         }
      } catch (error) {
         toast.dismiss(toastLoader)
      }
   }

   switch (event.target.name) {
      case "frontImage": {
         setImgSize((v) => ({ ...v, frontImage: setVal }))
         func()
         break
      }
      case "backImage": {
         setImgSize((v) => ({ ...v, backImage: setVal }))
         func()
         break
      }
      case "thumbnailImage": {
         setImgSize((v) => ({ ...v, thumbnailImage: setVal }))
         func()
         break
      }
      default: func()
   }     
}
else{
   toast.error("Invalid file type " , {id:"003"})
}
};

export const globalHandleChange = (e, formik) => {
   e.preventDefault()
   formik.setFieldValue(e.target.name, e.target.value)
}

export const resendOtpWithFirebase = (phone) => {
   // console.log("sign up formik...", values)

   const phoneNumberWithPrefix = `${phone}`; // Modify the country prefix as needed

   const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
      callback: (response) => {
         // reCAPTCHA callback
         // console.log(response, "recaptcha passed...")
      },
   });
   firebase
      .auth()
      .signInWithPhoneNumber(phoneNumberWithPrefix, appVerifier)
      .then((confirmationResult) => {
         // setLoader(false)
         console.log("confirmation result")
      })
      .catch((error) => {
         // Handle error
         console.error('Error sending verification code:', error);
      });
}

// export const assignBucketUrl = (path) => {
//    return encodeURI(path?.startsWith("upload/") ? BUCKET_URL + "/" + path : path)
// }

export const assignProfilePicBucketUrl = (path) => {
   return encodeURI(path?.startsWith("profilePic/") ? BUCKET_URL + "/" + path : path)
}

export const handleMultiFileUpload = (e, imageFiles, setImageFiles, formik) => {
   let { files } = e.target;

   for (let i = 0; i < files.length; i++) {
      const file = files[i];
      file.id = nanoid();
      // if (file.type.match(imageTypeRegex)) {
      imageFiles = [...imageFiles, file].sort((a, b) => a.size - b.size);
      // }

      if (imageFiles.length <= 10) {
         setImageFiles([...imageFiles]);
      } else {
         toast.error("Images shouldn't be more than 10", { id: "err801" });
      }
   }
   // imageFiles?.length < 6 && toast(`Please add minimum 6 images`, { icon: "📸", id: "09" });
   // if (imageFiles.length >= 6) {
   //   document.getElementById("photos").disabled = true;
   //   document
   //     .getElementById("addBtn1")
   //     .setAttribute("class", "changeFileBtn btn text-white py-1 h-100 rounded-2 bg-secondary");
   //   document.getElementById("addBtn1").setAttribute("style", " cursor :inherit");
   // }
   formik.setFieldValue("referenceImages", imageFiles);
};

export const handleFileRemoveForPickDrop = (imageFiles, setImageFiles, i, formik, inputRef) => {
   inputRef.current.value = null;

   const name = imageFiles[i]?.id;

   if (imageFiles.length < 10) {
      document.getElementById("photos").disabled = false;
      document.getElementById("addBtn1").setAttribute("class", "changeFileBtn btn text-white py-1 h-100 rounded-2");
      document.getElementById("addBtn1").setAttribute("style", "cursor: pointer");
   }

   const fltr = imageFiles.filter((el, i) => {
      return el.id !== name;
   });

   if (fltr.length > 0) {
      formik.setFieldValue("photos", fltr);
      return setImageFiles([...fltr]);
   } else {
      formik.setFieldValue("photos", "");
      return setImageFiles([...fltr]);
   }
};

export const urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[A-Za-z0-9-]+\.[A-Za-z]{2,}(\S*)$/;

export const capitalizeString = (inputString) => {
  return inputString
    .split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export const isValidEmail = (email) => {
   const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
   return emailRegex.test(email);
 }

 export const chartFooterText = () =>{
   return <span>
   For nutrition labelling on foods, 2000 calories a day is used as a general guide. refs <a target="_blank" href="https://www.fda.gov/food/nutrition-facts-label/how-understand-and-use-nutrition-facts-label#:~:text=2%2C000%20calories%20a%20day%20is%20used%20as%20a%20general%20guide%20for%20nutrition%20advice">[1]</a>, <a href="https://www.eufic.org/en/understanding-science/article/dietary-reference-values-a-reference-for-whom#:~:text=These%20RIs%20are%20consistent%20with%20dietary%20advice%20for%20the%20general%20population%20and%20have%20been%20set%20for%20labelling%20purposes" target="_blank">[2]</a>, <a href="https://www.gov.uk/government/publications/calorie-labelling-in-the-out-of-home-sector/calorie-labelling-in-the-out-of-home-sector-implementation-guidance#:~:text=display%20the%20statement%20that%20%E2%80%98adults%20need%20around%202000%20kcal%20a%20day" target="_blank">[3]</a>.<br/>
   % values are based on a 2000 calories diet.<a href="https://www.eufic.org/en/understanding-science/article/dietary-reference-values-a-reference-for-whom#:~:text=These%20RIs%20are%20consistent%20with%20dietary%20advice%20for%20the%20general%20population%20and%20have%20been%20set%20for%20labelling%20purposes" target="_blank">[2]</a>. Your daily values may be higher or lower depending on your energy needs.<br/>
   A ratio value of 1 is used for volume to weight conversion during some analysis.<br/>
   Some of the ingredients and/or recipes on this database are Crown copyright data drawn from CoFID and licensed under the Open Government Licence v3.0.
   </span>
 }

 export const getChartNutrientsValue = (hundredValue, value, name) => {
   return hundredValue
   ?  name?.toLowerCase()!=="energy" && hundredValue 
     ?.toString()
     ?.split(".")?.length > 1
     ? Number(hundredValue)?.toFixed(1)
     : parseInt(hundredValue)
   : value 
     ? name?.toLowerCase()!=="energy" && value?.toString()?.split(".")
       ?.length > 1
       ? Number(value)?.toFixed(1)
       : parseInt(value)
     : ""
 }

 export const handleLogout = () => {
   removeToken();
   // navigate("/log-in");
   window.location = '/log-in'
 }