import React, { useContext, useEffect, useState } from "react";
import AppLayout from "../../AppLayout/AppLayout";
import {
  downloadRecipeApi,
  getAllTcfSharedRecipe,
  getRecipieByIdApi,
  inviteUsers,
  savedRecipieApi,
} from "../../Api/Apis";
import ModalImage from "../../Assets/Images/Group 3611 (1).svg";
import { BUCKET_URL } from "../../Utils/BaseUrl";
import Default from "../Default";
import ProfilePicDemo from "../../Assets/Images/Group 10775.png";
import { toast } from "react-hot-toast";
import CloseBtn from "../../Assets/Images/Group 4292.svg";
// import { Link } from "react-router-dom";
// import chart_image from "../../Assets/Images/person.png";
import { UserContext } from "../../Contexts/Contexts";
import { isArray } from "lodash";
import { ThemeSpinner } from "../../Utils/Loaders";
import { WhiteLoader } from "../Loader/Loader";
import ReactPaginate from "react-paginate";
import RecipeChart from "../Common/RecipeChart";
import { useNavigate } from "react-router";

const SharedRecipes = () => {
  const [sharedRecipe, setSharedRecipe] = useState();
  const [email, setEmail] = useState("");
  const [borderId, setBorderId] = useState();
  const [toggle, setToggle] = useState(false);
  const [chartDetails, setChartDetails] = useState();
  const [saved, setSaved] = useState();
  const { searchData, pCount } = useContext(UserContext);
  const [loader, setLoader] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [limit, setLimit] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);
  const [downloadLoader, setDownloadLoader] = useState(false)

  const navigate = useNavigate()

  const getAllSharedRecipie = async (search, page, limit) => {
    try {
      setLoader(true);
      const res = await getAllTcfSharedRecipe(search, page, limit);
      if (res.status === 200 || res.status === 201) {
        setSharedRecipe(res.data.data);
        setPageCount(Math.ceil(res?.data?.totalLength / limit));
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  const handleSaveRecipe = async () => {
      let data = {
        recipeId: chartDetails?.id,
        isSaved: !chartDetails?.isSaved,
      };
      try {
        const res = await savedRecipieApi(data);
        if (res.status === 200 || res.status === 201) {
          toast.success(res?.data?.message);
          // fetchAllRecipes("", currentPage, limit);
          // handleIngredients(singleRecipe?.id);
          setSaved(!chartDetails?.isSaved)
        }
      } catch (err) {
        console.log(err);
      }
  };

  const handleDownloadRecipe = async () => {
    if(!chartDetails?.id)
    return
    setDownloadLoader(true)
    try {
      const res = await downloadRecipeApi(chartDetails?.id, "");
      if (res.status === 200 || res.status === 201) {
        window.open(`${BUCKET_URL + "/" + res.data.data}`, "_blank");
        setDownloadLoader(false)
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
      setDownloadLoader(false)
    }

  };

  const handlePageClick = (e) => {
    // setLoading(true);
    const selectedPage = e.selected;
    setCurrentPage(selectedPage);
    getAllSharedRecipie("", selectedPage, limit);
  };

  const getRecipeList = (data) => {
    if (isArray(data)) {
      return data;
    } else {
      return data?.data?.data;
    }
  };

  const handleViewRecipeChart = async (id) => {
    document.getElementById(`${borderId}`)?.removeAttribute("class");
    document.getElementById(`${borderId}`)?.setAttribute("class", `food-div`);
    setBorderId(`card${id}`);
    document
      .getElementById(`card${id}`)
      .setAttribute("class", ` borderCard food-div`);
    try {
      const res = await getRecipieByIdApi(id);
      if (res.status === 200 || res.status === 201) {
        setChartDetails(res?.data?.data);
        setSaved(res?.data?.data?.isSaved);
        setToggle(true);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleCloseChart = () => {
    setChartDetails();
    // setBorderId()
    setToggle(false);
  };

  const handleInvite = async () => {
    if (email) {
      try {
        const res = await inviteUsers({ email: email });
        if (res.status === 200 || res.status === 201) {
          toast.success(res.data.message);
          document.getElementById("exampleModal").click();
          setEmail("");
          // getAllMyCommunity();
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    getAllSharedRecipie("", currentPage, limit);
  }, []);
  useEffect(() => {

    if (searchData && searchData.length) {
      handleCloseChart()
      setSharedRecipe((v) => ({ ...v, data: searchData, loading: false }));
      setPageCount(pCount);
    } 
    // else {
    // console.log('else ke ander')

    //   getAllSharedRecipie("", currentPage, limit);
    // }
  }, [searchData]);

  return (
    <AppLayout>
      <div className="d-flex justify-content-between align-items-center pt-4 mb-3 position-relative">
      <div className="all-svg mb-4">
            {chartDetails && (
              <>
                {" "}
                {downloadLoader ? (
                  <div className="text-dark"
                    style={{
                      boxShadow: "0px 3px 6px #00000029",
                      backgroundColor: "white",
                      padding: "6px 8px",
                      marginLeft: "5px",
                      borderRadius: "10px",
                    }}
                  >
                    {/* {" "} */}

                    <WhiteLoader color="black" />
                  </div>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-download"
                    viewBox="0 0 16 16"
                    style={{ cursor: "pointer" }}
                    onClick={handleDownloadRecipe}
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                )}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill={saved ? "#fd3468" : "currentColor"}
                  // fill={false ? "#fd3468" : "currentColor"}
                  className="bi bi-bookmark-plus-fill"
                  viewBox="0 0 16 16"
                  onClick={handleSaveRecipe}
                  style={{ cursor: "pointer" }}
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zm6.5-11a.5.5 0 0 0-1 0V6H6a.5.5 0 0 0 0 1h1.5v1.5a.5.5 0 0 0 1 0V7H10a.5.5 0 0 0 0-1H8.5V4.5z"
                  />
                </svg>
              </>
            )}
          </div>
        <div>
          <h4>
            Sort by
            <select
              name=""
              class="ms-2"
              id=""
              style={{ border: "none", fontWeight: "700" }}
            >
              <option value="">Newest</option>
              <option value="">Oldest</option>
            </select>
          </h4>
        </div>
      </div>
      <div className="row">
      <div className={`col-xl-${toggle ? 7 : 12} col-lg-${toggle ? 6 : 12} disable-scroller`}>
    <div className="row">
      {!loader && getRecipeList(sharedRecipe)?.length ? (
       getRecipeList(sharedRecipe)?.map((v) => (
            <div className={`col-xl-${toggle ? 6 : 4} col-lg-${toggle ? 12 : 6} mt-3`} key={v.id}>
              <div className="food-div" id={`card${v.id}`}>
                <div className="image-div ratio ratio-4x3" style={{cursor:"pointer"}}  onClick={() => navigate("/view-recipes", {  state: { id: v.id }})}>
                  <img
                    src={BUCKET_URL + "/" + v?.thumbnailImage}
                    width="100%"
                    alt=""
                    onError={(e) => {
                      e.target.src = ProfilePicDemo;
                      e.error = null;
                    }}
                  />
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <p className="ingredients">
                    {v.ingredientStatus ? "Ingredient" : "Recipe"}
                  </p>
                  <small style={{ color: "#000", fontWeight: "600" }} className="d-flex flex-column">
                    <p className="m-0 p-10"><span> Created on:</span> <span>{v.dateTime}</span></p>
                    {v?.usersDetails?.userName && <p className="m-0 p-10"><span> Created by:</span> <span>{v?.usersDetails?.userName}</span></p>}
                  </small>
                </div>
                <h1 style={{minHeight:"60px"}}>{v?.name}</h1>
                <button 
                // onClick={() => handleViewRecipeChart(v.id)} 
                onClick={() => navigate("/view-recipes", { state: { id: v?.id } })}
                className="mt-3 btn-view-chart w-100">
                  View Chart
                </button>
              </div>
            </div>
          ))
      ) : loader ? (
        <div className="text-center">
          <ThemeSpinner />
        </div>
      ) : (
        <Default name="My Shared Recipes"/>

      )}
    </div>
          {pageCount > 1 && (
          <div className="d-flex justify-content-center mt-5">

            <ReactPaginate
              nextLabel="Next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel="< Previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              // renderOnZeroPageCount={null}
            />
          </div>
          )}
    </div>

        {chartDetails && (
          // (
          //     <div className="col-xxl-4  col-xl-4 col-lg-6 mt-3 ">
          //       <div class="card  mb-0">
          //         <div class="card-body  position-relative  p-0">
          //           <div className="modal_chart_list mx-2   ">

          //             {/* <i
          //               onClick={handleChartView}
          //               class="bi bi-x position-absolute me-3 border rounded-circle mt-1 px-1 bg-dark text-white end-0"
          //             ></i> */}
          //             {/* {Boolean(chartDetails?.ingredientDetails?.length) &&
          //               chartDetails?.ingredientDetails?.map((v) => {
          //                 console.log(v, "vallluuueeee");
          //                 return (
          //                   <div className="row mb-4 mt-4">
          //                     <div className="col-auto">
          //                       <img
          //                         src={BUCKET_URL + "/" + v.frontImage}
          //                         style={{
          //                           width: "28px",
          //                           height: "28px",
          //                           borderRadius: "50%",
          //                         }}
          //                         onError={(e) => {
          //                           e.target.src = ProfilePicDemo;
          //                           e.error = null;
          //                         }}
          //                       />
          //                     </div>
          //                     <div className="col-7">
          //                       <h6 className=" p-14 fw-bold"> {v.name}</h6>
          //                       <p className="p-10 m-0">Note: {v.notes}</p>
          //                     </div>
          //                     <div className="col-auto">
          //                       <p className="m-0 p-12">
          //                         {v?.quantity + " " + v?.quantityUnit}
          //                       </p>
          //                     </div>

          //                     <div className="col-auto">
          //                       <i
          //                         class="bi bi-caret-right icon-color"
          //                         type="button"
          //                       ></i>
          //                     </div>
          //                   </div>
          //                 );
          //               })} */}
          //             {/* <div className="row mb-4">
          //               <div className="col-auto">
          //                 <img
          //                   src={chart_image}
          //                   style={{
          //                     width: "28px",
          //                     height: "28px",
          //                     borderRadius: "50%",
          //                   }}
          //                 />
          //               </div>
          //               <div className="col-7">
          //                 <h6 className=" p-14 fw-bold">Blueberry Pancake</h6>
          //               </div>
          //               <div className="col-auto">
          //                 <p className="p-12 m-0">100 g</p>
          //               </div>
          //               <div className="col-auto">
          //                 <i
          //                   class="bi bi-caret-right icon-color "
          //                   type="button"
          //                 ></i>
          //               </div>
          //             </div>
          //             <div className="row mb-4">
          //               <div className="col-auto">
          //                 <img
          //                   src={chart_image}
          //                   style={{
          //                     width: "28px",
          //                     height: "28px",
          //                     borderRadius: "50%",
          //                   }}
          //                 />
          //               </div>
          //               <div className="col-7">
          //                 <h6 className=" p-14 fw-bold">
          //                   Potato Mash with Butter
          //                 </h6>
          //               </div>
          //               <div className="col-auto">
          //                 <p className="p-12">180 g</p>
          //               </div>
          //               <div className="col-auto">
          //                 <i
          //                   class="bi bi-caret-right icon-color"
          //                   type="button"
          //                 ></i>
          //               </div>
          //             </div> */}
          //           </div>
          //           <div className="modal-header-list position-relative w-100 ">
          //             <h1
          //               className="modal-title-list  mb-0 py-3 modal"
          //               id="viewDetailsModal"
          //             >
          //               Nutritional Information
          //             </h1>
          //           </div>

          //           <div class=" p-3 " style={{ border: "1px solid #000" }}>
          //             <div
          //               className=" heading d-flex justify-content-around align-items-center"
          //               style={{ borderBottom: "9px solid black" }}
          //             >
          //               <h6 className="p-1">Serving size </h6>
          //               <h6 className="p-12">
          //                 (
          //                 {chartDetails?.servingSize?.data
          //                   ? chartDetails?.servingSize?.data
          //                   : chartDetails?.servingSize}
          //                 )
          //               </h6>
          //             </div>
          //             <div
          //               className=" heading d-flex justify-content-around align-items-center "
          //               style={{ borderBottom: "5px solid black" }}
          //             >
          //               <h6 className="p-17 fw-bold">Calories per serving</h6>
          //               <h1 className="p-17 fw-boldest">
          //                 {chartDetails?.caloriePerServing}
          //               </h1>
          //             </div>
          //            {
          //             Boolean(chartDetails?.proximatesDetails?.nutrients?.length) && <>
          //                <div className="row align-item-center">
          //               <h6 className="col-2">Nutrients</h6>
          //               <h6 className="col-2">Unit</h6>
          //               <h6 className="col-2">Per 100g</h6>
          //               <h6 className="col-2">
          //                 per {chartDetails?.totalQuantity}{" "}
          //               </h6>
          //               <h6 className="col-2">serving </h6>
          //               <h6 className="col-2">NRV </h6>
          //             </div>
          //             <hr className="m-0 bottom-line" />
          //             </>
          //            }

          //             <div className="disable-scroller_chart">
          //               {console.log(chartDetails, "cjradkede")}
          //               {chartDetails?.proximatesDetails?.nutrients?.map((v) => {
          //                 return (
          //                   <div className="row  pt-3">
          //                     <div className="col-2 text-start">
          //                       <h6 className=" fs-5 fw-bold">
          //                         {v?.link ? (
          //                           <Link to={v?.link} target="_blank">
          //                             {v.name}
          //                           </Link>
          //                         ) : (
          //                           <span>{v.name}</span>
          //                         )}
          //                       </h6>
          //                     </div>
          //                     <div className="col-2">
          //                       <p className="fs-5">{v?.unit}</p>
          //                     </div>
          //                     <div className="col-2">
          //                       <h6 className="text-start fs-5 fw-bold">
          //                         {v?.hundredValue?.toString()?.split(".")
          //                           ?.length > 1
          //                           ? Number(v?.hundredValue)?.toFixed(2)
          //                           : v?.hundredValue}
          //                       </h6>
          //                     </div>
          //                     <div className="col-2">
          //                       <h6 className="text-start fs-5 fw-bold">
          //                         {v?.value?.toString()?.split(".")?.length > 1
          //                           ? Number(v?.value)?.toFixed(2)
          //                           : v?.value}
          //                       </h6>
          //                     </div>
          //                     <div className="col-2">
          //                       <h6 className="text-start fs-5 fw-bold">
          //                         {v?.nrvValue > 0 ? v?.nrvPercentage + "%" : ""}
          //                       </h6>
          //                     </div>
          //                     <div className="col-2">
          //                       <h6 className="text-start fs-5 fw-bold">
          //                         {v?.nrvValue > 0 ? v?.nrvValue : ""}
          //                       </h6>
          //                     </div>
          //                   </div>
          //                 );
          //               })}

          //               {
          //                 Boolean(chartDetails?.proximatesDetails?.vitamins?.length) && <>
          //                   <hr className="m-0 bottom-line" />
          //               <div className="row align-item-center">
          //                 <h6 className="col-2">Vitamins</h6>
          //                 <h6 className="col-2">Unit</h6>
          //                 <h6 className="col-2">Per 100g</h6>
          //                 <h6 className="col-2">
          //                   per {chartDetails?.totalQuantity}{" "}
          //                 </h6>
          //                 <h6 className="col-2">serving </h6>
          //                 <h6 className="col-2">NRV </h6>
          //               </div>
          //               <hr className="m-0 bottom-line" />
          //                 </>
          //               }

          //               {chartDetails?.proximatesDetails?.vitamins?.map((v) => {
          //                 return (
          //                   <div className="row  pt-3">
          //                     <div className="col-2 text-start">
          //                       <h6 className=" fs-5 fw-bold">
          //                         {v?.link ? (
          //                           <Link to={v?.link} target="_blank">
          //                             {v.name}
          //                           </Link>
          //                         ) : (
          //                           <span>{v.name}</span>
          //                         )}
          //                       </h6>
          //                     </div>
          //                     <div className="col-2">
          //                       <p className="fs-5">{v?.unit}</p>
          //                     </div>
          //                     <div className="col-2">
          //                       <p className="fs-5">
          //                         {v?.hundredValue?.toString()?.split(".")
          //                           ?.length > 1
          //                           ? Number(v?.hundredValue)?.toFixed(2)
          //                           : v?.hundredValue}
          //                       </p>
          //                     </div>
          //                     <div className="col-2">
          //                       <h6 className="text-start fs-5 fw-bold">
          //                         {v?.value?.toString()?.split(".")?.length > 1
          //                           ? Number(v?.value)?.toFixed(2)
          //                           : v?.value}
          //                       </h6>
          //                     </div>
          //                     <div className="col-2">
          //                       <h6 className="text-start fs-5 fw-bold">
          //                         {v?.nrvValue > 0 ? v?.nrvPercentage + "%" : ""}
          //                       </h6>
          //                     </div>
          //                     <div className="col-2">
          //                       <h6 className="text-start fs-5 fw-bold">
          //                         {v?.nrvValue > 0 ? v?.nrvValue : ""}
          //                       </h6>
          //                     </div>
          //                   </div>
          //                 );
          //               })}
          //               {
          //                 Boolean(chartDetails?.proximatesDetails?.minerals?.length) && <>
          //                  <hr className="m-0 bottom-line" />
          //               <div className="row align-item-center">
          //                 <h6 className="col-2">Minerals</h6>
          //                 <h6 className="col-2">Unit</h6>
          //                 <h6 className="col-2">Per 100g</h6>
          //                 <h6 className="col-2">
          //                   per {chartDetails?.totalQuantity}{" "}
          //                 </h6>
          //                 <h6 className="col-2">serving </h6>
          //                 <h6 className="col-2">NRV </h6>
          //               </div>
          //               <hr className="m-0 bottom-line" />
          //                 </>
          //               }

          //               {chartDetails?.proximatesDetails?.minerals?.map((v) => {
          //                 return (
          //                   <div className="row  pt-3">
          //                     <div className="col-2 text-start">
          //                       <h6 className=" fs-5 fw-bold">
          //                         {v?.link ? (
          //                           <Link to={v?.link} target="_blank">
          //                             {v.name}
          //                           </Link>
          //                         ) : (
          //                           <span>{v.name}</span>
          //                         )}
          //                       </h6>
          //                     </div>
          //                     <div className="col-2">
          //                       <p className="fs-5">{v?.unit}</p>
          //                     </div>
          //                     <div className="col-2">
          //                       <h6 className="text-start fs-5 fw-bold">
          //                         {v?.value?.toString()?.split(".")?.length > 1
          //                           ? Number(v?.value)?.toFixed(2)
          //                           : v?.value}
          //                       </h6>
          //                     </div>
          //                     <div className="col-2">
          //                       <h6 className="text-start fs-5 fw-bold">
          //                         {v?.value?.toString()?.split(".")?.length > 1
          //                           ? Number(v?.value)?.toFixed(2)
          //                           : v?.value}
          //                       </h6>
          //                     </div>
          //                     <div className="col-2">
          //                       <h6 className="text-start fs-5 fw-bold">
          //                         {v?.nrvValue > 0 ? v?.nrvPercentage + "%" : ""}
          //                       </h6>
          //                     </div>
          //                     <div className="col-2">
          //                       <h6 className="text-start fs-5 fw-bold">
          //                         {v?.nrvValue > 0 ? v?.nrvValue : ""}
          //                       </h6>
          //                     </div>
          //                   </div>
          //                 );
          //               })}
          //             </div>

          //             <p
          //               className=" text-dark m-0 card-bottom p-10 pt-3"
          //               style={{ borderTop: "3px solid black" }}
          //             >
          //               * % values are based on a 2000
          //               calories diet. Your daily values may be higher or lower
          //               depending on your energy needs.
          //             </p>
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //   )
          <div className="col-xxl-4  col-xl-4 col-lg-6 mt-3 ">
            {/* <div class="card  mb-0">
            <div class="card-body   p-0">
              
              <div className="modal-header-list position-relative ">
                <h1
                  class="modal-title-list  mb-0 py-3 "
                  id="exampleModalLabel"
                >
                  Nutritional Information
                  <button
                    type="button"
                    className="position-absolute rounded-circle  end-0 me-2"
                  >
                    {" "}
                    <i
                      onClick={()=> setToggle(false)}
                      className="bi bi-x"
                    ></i>
                  </button>
                </h1>
              </div>

              <div class=" p-3 " style={{ border: "1px solid #000" }}>
                {Boolean(chartDetails?.proximatesDetails?.nutrients?.length) && (
                  <div
                    className=" heading d-flex justify-content-around align-items-center"
                    style={{ borderBottom: "9px solid black" }}
                  >
                    <h6 className="p-1">Serving size </h6>
                    <h6 className="p-12">
                      {" "}
                      (
                      {chartDetails?.servingSize?.data
                        ? chartDetails?.servingSize?.data
                        : chartDetails?.servingSize}
                      )
                    </h6>
                  </div>
                )}

                <div
                  className=" heading d-flex justify-content-around align-items-center "
                  style={{ borderBottom: "5px solid black" }}
                >
                  <h6 className="p-17 fw-bold">Calories per serving</h6>
                  <h1 className="p-17 fw-boldest">{chartDetails?.caloriePerServing}</h1>
                </div>

               {
                Boolean(chartDetails?.proximatesDetails?.nutrients?.length) && <>
                 <div className="table-reponsive px-2">
                  <table className="table-sm header-fixed">
                    <thead style={{borderBottom : '2px solid #000'}}>
                      <tr>
                        <th>Nutrients</th>
                        <th>Unit</th>
                        <th>per 100g</th>
                        <th>per {chartDetails?.totalQuantity}</th>
                        <th>serving</th>
                        <th>NRV</th>
                      </tr>
                    </thead>
                      <tbody>
                      {Boolean(chartDetails?.proximatesDetails?.nutrients?.length) &&
                    chartDetails?.proximatesDetails?.nutrients
                      ?.filter((v) => v.value !== 0)
                      ?.map((v) => {
                        return (
                        <tr>
                          <td>{v?.link ? (
                                  <Link to={v?.link} target="_blank">
                                    {v.name}
                                  </Link>
                                ) : (
                                  <span>{v.name}</span>
                                )}</td>
                          <td className="fw-bold">{v.unit}</td>
                          <td className="fw-bold">{v.hundredValue
                                  ? v.hundredValue
                                    ?.toString()
                                    ?.split(".")?.length > 1
                                    ? Number(v.hundredValue)?.toFixed(2)
                                    : v.hundredValue
                                  : v?.value
                                    ? v?.value?.toString()?.split(".")
                                      ?.length > 1
                                      ? Number(v.value)?.toFixed(2)
                                      : v?.value
                                    : ""}</td>
                                    {Boolean(v?.hasOwnProperty('nrvPercentage') ) && (
                                      <>
                          <td className="fw-bold">{v?.value?.toFixed(2)}</td>
                          <td className="fw-bold">{v?.nrvValue > 0
                            ? v?.nrvPercentage + "%"
                            : ""}</td>
                          <td className="fw-bold">{v?.nrvValue > 0 ? v?.nrvValue : ""}</td>
                          </>
                          )}
                        </tr>
                         );
                        })}
                      </tbody>
                      </table>
                </div>
               </>
               }
                {/* <div className="disable-scroller_chart">
                  {Boolean(chartDetails?.proximatesDetails?.nutrients?.length) &&
                    chartDetails?.proximatesDetails?.nutrients
                      ?.filter((v) => v.value !== 0)
                      ?.map((v) => {
                        return (
                          <div className="row gx-0 pt-3">
                            <div
                              className={`col-2 text-start`}
                            >
                              <h6 className=" fs-5 fw-bold">
                                {v?.link ? (
                                  <Link to={v?.link} target="_blank">
                                    {v.name}
                                  </Link>
                                ) : (
                                  <span>{v.name}</span>
                                )}
                              </h6>
                            </div>
                            <div className={`col-2`}>
                              <p className="fs-5">{v.unit}</p>
                            </div>
                            <div className={`col-2 `}>
                              <h6 className="text-start fs-5 fw-bold">
                                {v.hundredValue
                                  ? v.hundredValue
                                    ?.toString()
                                    ?.split(".")?.length > 1
                                    ? Number(v.hundredValue)?.toFixed(2)
                                    : v.hundredValue
                                  : v?.value
                                    ? v?.value?.toString()?.split(".")
                                      ?.length > 1
                                      ? Number(v.value)?.toFixed(2)
                                      : v?.value
                                    : ""}
                              </h6>
                            </div>
                            {Boolean(v?.hasOwnProperty('nrvPercentage') ) && (
                              <>
                                <div className="col-2">
                                  <h6 className="text-start fs-5 fw-bold">
                                    {v?.value?.toFixed(2)}
                                  </h6>
                                </div>
                                <div className="col-2">
                                  <h6 className="text-start fs-5 fw-bold">
                                    {v?.nrvValue > 0
                                      ? v?.nrvPercentage + "%"
                                      : ""}
                                  </h6>
                                </div>
                                <div className="col-2">
                                  <h6 className="text-start fs-5 fw-bold">
                                    {v?.nrvValue > 0 ? v?.nrvValue : ""}
                                  </h6>
                                </div>
                              </>
                            )}
                          </div>
                        );
                      })}
                     
                </div> */}
            {/* <p
                  className=" text-dark m-0 card-bottom p-10 pt-3"
                  style={{ borderTop: "3px solid black" }}
                >
                  * % values are based on a 2000 calories diet. Your daily values may be higher or lower depending on your energy needs.
                </p>
              </div>
            </div> */}
            {/* </div> */}
            <RecipeChart
              chartDetails={chartDetails}
              handleCloseChart={() => handleCloseChart()}
            />
          </div>
        )}
      </div>

      {/* ////////////////////? */}

      {/* modal  */}

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content pb-4">
            <div className="modal-header border-bottom-0 pb-0">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Send an invitation
              </h1>
              <button
                type=""
                className=" border-0 bg-transparent"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {" "}
                <img src={CloseBtn} />
              </button>
            </div>
            <div className="modal-body">
              <p className="fw-bold pb-3">
                You can invite your friends and <br /> colleagues to share
                recipes with you
              </p>

              <label className="pb-2">Enter email and click to invite </label>
              <input
                type="text"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                class="form-control"
                placeholder="Enter email id here..."
                aria-label="Email"
                aria-describedby="basic-addon1"
              />
              <img className="modal-image" src={ModalImage} />
            </div>

            <div className=" mx-auto">
              <button
                type="button"
                className="invite-button"
                onClick={handleInvite}
              >
                Send invite
              </button>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default SharedRecipes;
