import firebase from "firebase/compat/app";
import axios from "axios";
import { initializeApp } from "firebase/app";
import "firebase/compat/auth";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { API_BASE_URL } from "./BaseUrl";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_apiKey,
    authDomain: process.env.REACT_APP_authDomain,
    projectId: process.env.REACT_APP_projectId,
    storageBucket: process.env.REACT_APP_storageBucket,
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
    appId: process.env.REACT_APP_appId,
    measurementId: process.env.REACT_APP_measurementId,
 
};

firebase.initializeApp(firebaseConfig);

export default firebase;

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  prompt: "select_account"
});
const provider1 = new firebase.auth.OAuthProvider('apple.com');

// export const signInWithApple=async()=> {
//   // const auth = firebase.auth();
//   // const provider = new firebase.auth.OAuthProvider('apple.com');
//   const result = await signInWithPopup(auth,provider1);

//   console.log(result.user); // logged-in Apple user
// } 
export const signInWithApple = (email,role,planName) => {
  return signInWithPopup(auth, provider1)
    .then((result) => {
      console.log(result, "resulttttttttttttt");
      return axios
        .post(`${API_BASE_URL}/users/logInAndsignUp`, {
          token: result.user.accessToken,
          loginType: "gmail",
          email:email,
          accountType: role ? "business" :"personal",
          planName
        })
        .then((data) => {
          console.log(data, "daatttttttttttttttt");
          if (data) {
            return data;
          } 
          else {
            return {status : -1}
          }
        });
    })
    .catch((error) => {
      // console.log(error,"errrrrrrrrrrrrr  ");
    });
};


export const signInWithGoogle = (email,role,planName) => {
  return signInWithPopup(auth, provider)
    .then((result) => {
      console.log(result, "resulttttttttttttt");
      return axios
        .post(`${API_BASE_URL}/users/logInAndsignUp`, {
          token: result.user.accessToken,
          loginType: "gmail",
          email:email,
          accountType: role ? "business" :"personal",
          planName
        })
        .then((data) => {
          console.log(data, "daatttttttttttttttt");
          if (data) {
            return data;
          }
          else {
            return {status : -1}
          }
        }).catch(err=>{
          console.log(err,'ye hai error1')
        })
    })
    .catch((error) => {
      console.log(error,'ye hai error')
      // console.log(error,"errrrrrrrrrrrrr  ");
    });
};



