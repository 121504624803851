import React, {useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import AppLayout from "../../AppLayout/AppLayout";
import { addUserSitesApi, getAllUserSites, updateUserSitesById,switchSite } from "../../Api/Apis";
import { nanoid } from "nanoid";
import CloseBtn from "../../Assets/Images/Group 4292.svg";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import Default from "../Default";
import { UserContext } from "../../Contexts/Contexts";
import { WhiteLoader } from "../Loader/Loader";
import { addSiteValidate } from "../../Utils/Validations";

const MyBusinessSites = () => {
  const { userProfileDetails, setUserProfileDetails } = useContext(UserContext);
  const userDetails = userProfileDetails?.data?.data
  const navigate = useNavigate();
  const [siteId, setSiteId] = useState();
  const [switchMySite, setSwitchMySite] = useState({ loading: false, id: null })
  const [toEditSite,setToEditSite] = useState(null)
  // const [singleSiteData,setSingleSiteData]=useState()
  const { values, handleChange, handleSubmit,setValues,touched,errors,handleBlur } = useFormik({
    initialValues: {
      name: "",
      nameManager: "",
      mailManager: "",
      address:""
    },
    validationSchema:addSiteValidate,
    onSubmit: async (val) => {
      console.log(val, "valuessseeddd");
      let payload;
      if(siteId){
        payload = {
    
              id:siteId,
              siteName: val.name,
              managerName: val.nameManager,
              managerMail: val.mailManager,
              address:val.address
        };
      }else{
        payload = {
          data: [
            {
              siteName: val.name,
              managerName: val.nameManager,
              managerMail: val.mailManager,
              address:val.address
            },
          ],
        };
      }
     
      try {
        const res = siteId ? await updateUserSitesById(payload) :  await addUserSitesApi(payload);
        if (res.status === 200 || res.status === 201) {
          toast.success(res.data.message);
          document.getElementById("addModal").click();
          fetchUserSites();
          setSiteId();
          setToEditSite(null)
          setValues({
            name: "",
            nameManager: "",
            mailManager: "",
            address:""
          })
        }
        console.log(res, "reoososossosososos");
      } catch (err) {
        console.log(err);
        toast.error(err?.response?.data?.message);
        document.getElementById("addModal").click();
      }
    },
  });
  const [siteData, setSiteData] = useState([
    // {
    //   // siteId: nanoid(),
    //   siteName: "",
    //   managerName: "",
    //   managerMail: "",
    // },
  ]);
  const fetchUserSites = async () => {
    try {
      const res = await getAllUserSites();
      if (res.status === 200 || res.status === 201) {
        if (res.data.data) {
          setSiteData(res?.data?.data);
        }
      }
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(singleSiteData,"sitedatatata")
  const handleEditSite = async(id) => {
    console.log(id);
    setSiteId(id);
    let data = siteData?.filter(l=>l.id == id);
    setToEditSite(data?.[0])
    // setSingleSiteData(data?.[0])
    setValues({
      name: data?.[0]?.siteName,
      nameManager:data?.[0]?.managerName,
      mailManager: data?.[0]?.managerMail,
      address: data?.[0]?.address,
    })
  };

  const handleReset =()=>{
    setValues({
      name: "",
      nameManager: "",
      mailManager: "",
      address:"",
    })
    setSiteId()
    setToEditSite(null)
  }
  console.log(siteData, "ASDFDSAFDSF");
  useEffect(() => {
    fetchUserSites();
  }, []);

  const handleSwitchSite = async (siteId) => {
    setSwitchMySite({ loading: true, id: siteId })
    try {
      const res = await switchSite({ siteId });
      if (res.status === 200 && res?.data?.data) {
        toast.success(res.data.message);
        setUserProfileDetails((v) => ({ ...v, data: res?.data }))
        setSwitchMySite({ loading: false, id: null })
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  }


  return (
    <AppLayout>
      <div className="d-flex justify-content-between pt-4 mb-3 position-relative">
        {/* <p className="browse">Browse all the ingredients and recipes shared with you</p> */}
        <div className=" d-flex justify-content-between align-items-center my-community-heading  ">
          <h2 className="fw-bolder ps-3 p-20">Add sites to your business</h2>
          <div className="d-flex gap-2">
         <button
          type="button"
          className="Continue-button p-14 pt-3"
          data-bs-target="#addModal"
          data-bs-toggle="modal"
        >
          Add Sites
        </button>
        {/* {userDetails?.siteId!==0 && <button
          type="button"
          className="Continue-button"
          onClick={() => userDetails?.siteId !== 0 && !switchMySite.loading && handleSwitchSite(0)}
          style={{ cursor: `${userDetails?.siteId === 0 ? "default" : "pointer"}` }}
        >
          Switch Account
        </button>}  */}
          </div>
        
        </div>
      </div>
      <div className=" mt-3">
        <div className="food-div my-site-bussiness border overflow-auto">
          <div className="row gx-0" >
            <div className="col-lg-5 col-md-4 col-12 mx-auto px-lg-5 px-md-2">
              {
              Boolean(siteData?.length) ?
                siteData?.map((v, i) => {
                  return (
                    <div class="mb-5">
                      <div className="edit-site float-end d-flex gap-2 px-lg-5">
                     <div
                        className=""
                        style={{ cursor: "pointer" }}
                        onClick={() => handleEditSite(v.id)}
                        data-bs-target="#addModal"
                        data-bs-toggle="modal"
                      >
                        <i class="bi bi-pen-fill pe-2"></i>
                        <span>Edit Site</span>
                      </div>
                      <div
                        onClick={() => userDetails?.siteId !== v?.id && !switchMySite.loading && handleSwitchSite(v?.id)}
                        className=""
                         style={{ cursor: `${userDetails?.siteId === v?.id ? "default" : "pointer"}` }}>
                          {switchMySite.loading && switchMySite.id === v.id ? <WhiteLoader color="red" /> : <span className={`${userDetails?.siteId === v?.id && "ingredients w-auto"}`}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-brilliance" viewBox="0 0 16 16">
                            <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16M1 8a7 7 0 0 0 7 7 3.5 3.5 0 1 0 0-7 3.5 3.5 0 1 1 0-7 7 7 0 0 0-7 7" />
                            </svg>
                            <span className="px-2">{userDetails?.siteId === v?.id ? "Active" : "Switch"} </span>
                            </span>}
                      </div>
                      </div>
                      <label
                        for="exampleFormControlInput1"
                        class="form-label p-14"
                      >
                        Site {i + 1}
                      </label>
                      {v?.isDefault && <span  className="mx-2 ingredients w-auto edit-site">
                         Default
                        </span>} 
                      <input
                        type="text"
                        class="form-control mb-3"
                        id="exampleFormControlInput1"
                        value={v?.siteName}
                        placeholder="Enter site name"
                        disabled
                      />
                      <input
                        type="text"
                        class="form-control mb-3"
                        id="exampleFormControlInput1"
                        placeholder="Enter site manager name"
                        value={v?.managerName}
                        disabled
                      />
                      <input
                        type="email"
                        class="form-control mb-3"
                        id="exampleFormControlInput1"
                        placeholder="Enter Site manager email"
                        value={v.managerMail}
                        disabled
                      />
                      <input
                        type="text"
                        class="form-control mb-3"
                        id="exampleFormControlInput1"
                        value={v?.address}
                        placeholder="Enter site address"
                        disabled
                      />
                    </div>
                  );
                })
              :null
              }
              {/* <div class="mb-5">
                <label for="exampleFormControlInput1" class="form-label p-14">
                  Sites 1
                </label>
                <input
                  type="text"
                  class="form-control mb-3"
                  id="exampleFormControlInput1"
                  placeholder="Enter site name"
                  disabled
                />
                <input
                  type="text"
                  class="form-control mb-3"
                  id="exampleFormControlInput1"
                  placeholder="Enter site manager name"
                  disabled
                />
                <input
                  type="email"
                  class="form-control mb-3"
                  id="exampleFormControlInput1"
                  placeholder="Enter Site manager email"
                  disabled
                />
              </div>
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label p-14">
                  Sites 2
                </label>
                <input
                  type="text"
                  class="form-control mb-3"
                  id="exampleFormControlInput1"
                  placeholder="Enter site name"
                  disabled
                />
                <input
                  type="text"
                  class="form-control mb-3"
                  id="exampleFormControlInput1"
                  placeholder="Enter site manager name"
                  disabled
                />
                <input
                  type="email"
                  class="form-control mb-3"
                  id="exampleFormControlInput1"
                  placeholder="Enter Site manager email"
                  disabled
                />
              </div>
            </div>
            <div className="col-lg-5 col-md-4 col-12 mx-auto px-lg-5 px-md-2">
              <div class="mb-4">
                <label for="exampleFormControlInput1" class="form-label p-14">
                  Sites 3
                </label>
                <input
                  type="text"
                  class="form-control mb-3"
                  id="exampleFormControlInput1"
                  placeholder="Enter site name"
                  disabled
                />
                <input
                  type="text"
                  class="form-control mb-3"
                  id="exampleFormControlInput1"
                  placeholder="Enter site manager name"
                  disabled
                />
                <input
                  type="email"
                  class="form-control mb-3"
                  id="exampleFormControlInput1"
                  placeholder="Enter Site manager email"
                  disabled
                />
              </div> */}
              {/* <button type="button" className="Continue-button p-14 pt-3">
                Continue
              </button> */}
            </div>
          </div>
        </div>
      </div>

      {/* modal start here  */}

      <div
        className="modal fade"
        id="addModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        {console.log(errors,touched)}
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content pb-4">
            <div className="modal-header border-bottom-0 pb-0">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
               {siteId ? "Update" :"Add"} Site
              </h1>
              <button
                type=""
                className=" border-0 bg-transparent"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleReset}
              >
                {" "}
                <img src={CloseBtn} />
              </button>
            </div>
            <div className="modal-body">
              <div class="mb-5">
                <label for="exampleFormControlInput1" class="form-label p-14">
                {!siteId && "Enter a new site"} 
                </label>
                <input
                  type="text"
                  class="form-control mb-3"
                  id="exampleFormControlInput1"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="name"
                  placeholder="Enter site name"
                  // disabled
                />
                {touched.name && errors.name && <p className="text-danger">{errors?.name}</p>}
                <input
                  type="text"
                  class="form-control mb-3"
                  id="exampleFormControlInput1"
                  placeholder="Enter site manager name"
                  value={values.nameManager}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="nameManager"
                />
                {touched.nameManager && errors.nameManager && <p className="text-danger">{errors?.nameManager}</p>}
                <input
                  type="email"
                  class="form-control mb-3"
                  id="exampleFormControlInput1"
                  placeholder="Enter Site manager email"
                  value={values.mailManager}
                  onChange={(e)=>!toEditSite?.isDefault && handleChange(e)}
                  onBlur={(e)=>!toEditSite?.isDefault && handleBlur(e)}
                  name="mailManager"
                  disabled={toEditSite?.isDefault}
                />
                {touched.mailManager && errors.mailManager && <p className="text-danger">{errors?.mailManager}</p>}
                <input
                  type="text"
                  class="form-control mb-3"
                  id="exampleFormControlInput1"
                  placeholder="Enter Site address"
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="address"
                />
                   {touched.address && errors.address && <p className="text-danger">{errors?.address}</p>}
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="Continue-button p-14 pt-3"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default MyBusinessSites;
