import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext, UserContext } from "../../../Contexts/Contexts";
import { useFormik } from "formik";
import firebase, { signInWithApple } from "../../../Utils/Firebase";
import { globalHandleChange, setToken } from "../../../Utils/HelperFuntions";
import { WhiteLoader } from "../../../Utils/Loaders";
import { logInFormikValidation } from "../../../Utils/Validations";
import { signInWithGoogle } from "../../../Utils/Firebase";
import toast from "react-hot-toast";
import { checkCredsApi, getAllPlanApi, getBusinessPlansApi } from "../../../Api/Apis";
import PhoneInput from "react-phone-input-2";
import AccountDeletedByAdminModal from "../../Common/AccountDeletedByAdminModal";


const LogIn = () => {
  const [loader, setLoader] = useState(false);
  const { setIsLoggedIn } = useContext(UserContext);
  const [isChecked,setIsChecked]=useState(false);
  const { verificationId, setVerificationId } = useContext(AuthContext);
  const [businessPlans,setBusinessPlans] = useState([])
  // const [mobCode,setMobCode] = useState('+93')
  // console.log(x, "got auth context...", verificationId, setVerificationId)
  const navigate = useNavigate();

  const logInFormik = useFormik({
    initialValues: {
      phone: "",
      email: "",
      verificationId: "",
    },
    validationSchema: logInFormikValidation,
    onSubmit: async(values) => {
      // let phoneNumber = String(mobCode)+ logInFormik.values.phone ; 
      setLoader(true);
      let phoneNumber = "+"+ logInFormik.values.phone ; 
      let check = await checkCredsApi(phoneNumber);
      if(check && check?.status === 201 && !Boolean(check?.data?.status)){        
        document.getElementById('accountDeletedModal').click()
        setLoader(false)
        return;
        
      }
      if(check?.data && !Boolean(check?.data?.status)){
        const phoneNumberWithPrefix = `${phoneNumber}`;


        if(!window.recaptchaVerifier){
          window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: (response) => {
              // reCAPTCHA callback
              // console.log(response, "recaptcha passed...")
            },
          }
        );
      }
        await firebase
          .auth()
          .signInWithPhoneNumber(phoneNumberWithPrefix, window.recaptchaVerifier,{accountType:"buisness"})
          .then((confirmationResult) => {
            logInFormik.setFieldValue(
              "verificationId",
              confirmationResult.verificationId
            );
            setVerificationId(confirmationResult.verificationId)
            // setTimeout(() => {
              navigate("/log-in-otp", {
                state: {
                  email: logInFormik.values.email,
                  phone: phoneNumber,
                  verificationId: confirmationResult.verificationId,
                },
              });
            // }, 100);
          })
          .catch((error) => {
            // Handle error
            let errorMsg = {...error}
            if(errorMsg.code === 'auth/invalid-phone-number'){
              toast.error('Invalid Phone Number!!')
            }else if(errorMsg.code === "auth/too-many-requests"){
              toast.error('Too Many Attempts, Try Later!')
            }else{
              toast.error('Something Went Wrong!!')
            }
            setLoader(false)
            console.error("Error sending verification code:", errorMsg);
          });
      }else{
        toast.error('User not registerd!');
        setLoader(false);
        logInFormik.resetForm()
      }
      
    },
  });

  const handleCheck =()=>{
    setIsChecked(!isChecked)
  }
  // const handleChange = (e) => {
  //   const inputChar = e.target.value;

  //   if (/^[0-9\b]*$/.test(inputChar)) {
  //     logInFormik.setFieldValue('phone',inputChar)
  //   }
  // };
  const handleChange = (value,dialCode) => {
    // let code = "+"+dialCode
    // setMobCode(code)
    logInFormik.setFieldValue('phone',value)
  };
  const appleLogin =async()=>{
    const res = await signInWithApple();
    if(res?.status === -1){
      document.getElementById('accountDeletedModal').click()
    }
    else if(res?.data?.token){
      setToken(res?.data?.token);
      setIsLoggedIn(true);
      navigate("/");
      setLoader(false);
    }
  }
  const googleLogin = async () => {
    // if(!isChecked) return;
    const res = await signInWithGoogle();
    if(res?.status === -1){
      document.getElementById('accountDeletedModal').click()
    }
    else if(res?.data?.token){
      setToken(res?.data?.token);
      setIsLoggedIn(true);
      navigate("/");
      setLoader(false);
    }
  
  };

  const handleGetPlans = async() =>{
    try{

      const res = await getBusinessPlansApi()
    if(res?.status == 200){
      setBusinessPlans(res?.data?.data)
    }
  }catch(err){
    console.log(err)
  }
  }

  const handleBusinessPlans =(planName,planType)=>{
    document.getElementById("exampleModalsub").click()
    navigate("/sign-up",{state:{role:"business",planName,planType}});
   }
  const handlePersonal = () => {
    document.getElementById("exampleModal").click();
    navigate("/sign-up");
  };

  console.log(isChecked, "phonephonephone");

  return (
    <>
      <div className="container-fliud mask">
        <div className="row ms-0 me-0">
          <div className="col-xl-7 col-lg-6 text-center">
            <div className="img_logo">
              <img src={process.env.PUBLIC_URL + "/assets/images/logo.svg"} />
              <img
                src={process.env.PUBLIC_URL + "/assets/images/tcf.svg"}
                className="tcf_logo"
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="login-welcome mask2">
              <h2 className="text-center">Login</h2>
              <h1 className="text-center mb-5">Welcome</h1>
              <label htmlFor="number" className="form-label mt-4">
                Enter phone number
              </label>
              {/* <div class="input-group">
    <span class="input-group-text p-0 w-25">
      <select className="form-select border-0" name=""id="" onChange={(e)=>setMobCode(e.target.value)}>
                {allCountryCode.map(el=>{
                  return (
                    <option value={el.mobileCode}>{`${el.code} (${el.mobileCode})`}</option>
                  )
                })
                  } 
      </select>
    </span>
              <input
                className="form-control"
                type="phone"
                name="phone"
                value={logInFormik.values.phone}
                // onChange={(e) => {
                //   globalHandleChange(e, logInFormik);
                // }}
                onChange={(e)=>handleChange(e)}
                id="phone"
                placeholder="Your phone number"
              />
  </div> */}
              <div>
                <PhoneInput
                  country={"gb"}
                  // disabled={toggle}
                  value={logInFormik.values.phone}
                  inputClass="phone-input"
                  onChange={(value, country, e, formattedValue) => {
                    handleChange(value, country.dialCode);
                  }}
                  placeholder="Your phone number"
                />
              </div>

              {Boolean(
                logInFormik.touched.phone && logInFormik.errors.phone
              ) && (
                <p className="text-danger mt-2">{logInFormik.errors.phone}</p>
              )}
              <div className="d-flex align-items-baseline mt-4">
                <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                  onClick={handleCheck}
                  className="me-2"
                />
                <label for="vehicle1">
                  <small>
                    By continuing you accept our{" "}
                    <Link
                      to="https://thecaloriefinder.com/privacy-policy/"
                      target="_blank"
                    >
                      {" "}
                      Privacy Policy
                    </Link>{" "}
                    and{" "}
                    <Link
                      to="https://thecaloriefinder.com/term-and-conditions/"
                      target="_blank"
                    >
                      {" "}
                      Term of Use
                    </Link>{" "}
                  </small>
                </label>
              </div>
              <button
                type="button"
                className="btn_custome mt-4 bg-gray"
                style={!isChecked ? {background:"#80808080"} :{}}
                disabled={!isChecked}
                onClick={logInFormik.handleSubmit}
              >
                {loader ? (
                  <>
                    <WhiteLoader />
                  </>
                ) : (
                  "Continue"
                )}
              </button>
              <div class="login_or mt-4">or</div>
              <div className="d-flex justify-content-center mt-4">
                <span className="brand_logo me-5">
                  <a href="#">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/apple.png"}
                      alt="apple"
                      width="40"
                      height="40"
                      onClick={appleLogin}
                    />
                    {/* <AppleSignIn /> */}
                  </a>
                </span>
                <span className="bg-white brand_logo">
                  <a href="#">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/google.png"}
                      alt="google"
                      width="24"
                      height="24"
                      onClick={googleLogin}
                    />
                    {/* <GoogleSignIn /> */}
                  </a>
                </span>
              </div>
              <p className="dont_account mt-4" onClick={()=>handleGetPlans()}>
                Don't have an account yet?{" "}
                <p
                  href="#exampleModal"
                  style={{ color: "#ff93ab", cursor: "pointer" }}
                  data-bs-toggle="modal"
                >
                  {" "}
                  Sign up.
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div id="recaptcha-container"></div> */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content model-radius">
            <div class="modal-body account-type">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/logo.svg"}
                width="114"
              />
              <h1 className="mt-4">
                Which type of <br /> <span> account</span> are <br /> you
                creating?
              </h1>

              <button
                className="btn_custome mt-4 btn-per-bus"
                onClick={handlePersonal}
              >
                Personal
              </button>
              <p className="mt-4 or-pupop">Or</p>
              <button
                className="btn_custome mt-2 btn-per-bus"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalsub"
              >
                Business
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*Subscriptions modal popup code start here. */}
      <div
        class="modal fade"
        id="exampleModalsub"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content model-subs">
            <div class="modal-body">
              <h3 className="text-center text-black">Subscriptions</h3>
              <img
                src={process.env.PUBLIC_URL + "/assets/images/Group.svg"}
                alt=""
              />
              <h1 className="mt-5 text-center">Choose Your Business Plan</h1>
              <h3 className="business_plane">Start your 15 days free trial</h3>
              <h1 className="plane-pop-title mt-5">Business</h1>

              <div className="row">
              {businessPlans.length && businessPlans.map((obj,index)=>{
                return <div
                  className="col-md-6 mb-4"
                  style={{ cursor: "pointer" }}
                  onClick={()=>handleBusinessPlans(obj?.product_data?.name, obj?.product_data?.metadata?.planType)}
                  key={index}
                >
                  <div className="Business_silver mt-3 platinium" style={{height:"110px"}}>

                      <div>

                      
                      <h3 style={{ color: obj?.product_data?.colors?.primary ? obj?.product_data?.colors?.primary : "#42c442" }}>{obj?.product_data?.name}</h3>
                      </div>
                      <div>
                      <p style={{ color: obj?.product_data?.colors?.primary ? obj?.product_data?.colors?.primary : "#42c442", borderColor: obj?.product_data?.colors?.primary ? obj?.product_data?.colors?.primary : "#42c442", backgroundColor: obj?.product_data?.colors?.primary ? obj?.product_data?.colors?.secondary : "#42c442" }}>
                        {obj?.amount ? `${obj?.currencySymbol} ${obj?.amount}/${obj?.interval_count > 1 ?obj?.interval_count + " ":""}${obj?.interval}` :"Free"}
                      </p>
                      </div>
                    <div className="mt-2 upto30">
                      {/* Suitable for businesses up to 3 sites up to 30
                      analysis/day */}
                      {obj?.product_data?.description}
                    </div>
                    </div>
                </div>})}
                {/* <div
                  className="col-md-6"
                  style={{ cursor: "pointer" }}
                  onClick={handleBusinessPlans}
                >
                  <div className="Business_silver  mt-3 platinium">
                    <div>
                      <h3>Business Platinium</h3>
                    </div>
                    <div>
                      <p>£ 600/year</p>
                    </div>
                    <div className="mt-2 upto30">
                      Suitable for businesses up to 20 sites up to 220
                      analysis/day
                    </div>
                  </div>
                </div> */}
                {/* <div
                  className="col-md-6"
                  style={{ cursor: "pointer" }}
                  onClick={handleBusinessPlans}
                >
                  <div className="Business_silver  mt-3 gold">
                    <div>
                      <h3>Business Gold</h3>
                    </div>
                    <div>
                      <p>£ 379/year</p>
                    </div>
                    <div className="mt-2 upto30">
                      Suitable for businesses up to 7 sites up to 70
                      analysis/day
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6"
                  style={{ cursor: "pointer" }}
                  onClick={handleBusinessPlans}
                >
                  <div className="Business_silver  mt-3 unlimited">
                    <div>
                      <h3>Business Unlimited</h3>
                    </div>
                    <div>
                      <p>£ 999/year</p>
                    </div>
                    <div className="mt-2 upto30">
                      Suitable for businesses up to 50 sites up to 500
                      analysis/day
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AccountDeletedByAdminModal/>
      <button className="d-none" id="accountDeletedModal" data-bs-target="#deleteModal" data-bs-toggle="modal">
        show
      </button>
    </>
  );
};

export default LogIn;
