import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import firebase, { signInWithApple, signInWithGoogle } from "../../../Utils/Firebase";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { WhiteLoader } from "../../../Utils/Loaders";
import {globalHandleChange,  setToken } from "../../../Utils/HelperFuntions";
import { AuthContext, UserContext } from "../../../Contexts/Contexts";
import { signUpFormikValidation } from "../../../Utils/Validations";
import { checkCredsApi } from "../../../Api/Apis";
import { toast } from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import AccountDeletedByAdminModal from "../../Common/AccountDeletedByAdminModal";


const SignUp = () => {
  const [loader, setLoader] = useState(false);
  const { setIsLoggedIn } = useContext(UserContext);
  const { verificationId, setVerificationId } = useContext(AuthContext);
  // const [mobCode,setMobCode] = useState('+44')
  const [isChecked,setIsChecked]=useState(false);
  // console.log(x, "got auth context...", verificationId, setVerificationId)

  const location = useLocation()
  console.log(location,"lolcacadf")
  const navigate = useNavigate();

  const signUpFormik = useFormik({
    initialValues: {
      phone: "",
      email: "",
      verificationId: "",
    },
    validationSchema: signUpFormikValidation,
    onSubmit: async(values) => {
      setLoader(true);
      let phoneNumber = "+"+ signUpFormik.values.phone ; 
      const phoneNumberWithPrefix = `${phoneNumber}`;
      // if(await checkCreds(phoneNumberWithPrefix) ){

      let check = await checkCredsApi(phoneNumber,values?.email);
      if(check?.data && Boolean(check?.data?.status)){
        if(!window.recaptchaVerifier){
          window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: (response) => {
              // reCAPTCHA callback
              // console.log(response, "recaptcha passed...")
            },
          }
        );
      }
        await firebase
          .auth()
          .signInWithPhoneNumber(phoneNumberWithPrefix, window.recaptchaVerifier,{accountType:"buisness"})
          .then((confirmationResult) => {
            signUpFormik.setFieldValue(
              "verificationId",
              confirmationResult.verificationId
            );
            setVerificationId(confirmationResult.verificationId)

            // setTimeout(() => {
              navigate("/sign-up-otp", {
                state: {
                  role:location?.state?.role,
                  email: signUpFormik.values.email?.toLowerCase(),
                  phone: phoneNumber,
                  verificationId: confirmationResult.verificationId,
                  planType:location?.state?.planType
                },
              });
            // }, 100);
          })
          .catch((error) => {
            // Handle error
            let errorMsg = {...error}
            if(errorMsg.code === 'auth/invalid-phone-number'){
              toast.error('Invalid Phone Number!!')
            }else{
              toast.error('Something Went Wrong!!')
            }
            setLoader(false)
            console.error("Error sending verification code:",error, errorMsg);
          });
      }else{
        toast.error('User already registerd!');
        setLoader(false);
        signUpFormik.resetForm()
      }
      
    },
  });

  const checkCreds =async(data)=>{
    const res = await checkCredsApi(data.phone , data.email);
    // console.log(res,"RSFDD")
    if(res.status === 200){
      return Boolean(res?.data?.status)
    }
  }

  // const handleChange = (e) => {
    // const inputChar = e.target.value;

    // if (/^[0-9\b]*$/.test(inputChar)) {
    //   signUpFormik.setFieldValue('phone',inputChar)
    // }
  // };

  const handleChange = (value,dialCode) => {
    // let code = "+"+dialCode
    // setMobCode(code)
    signUpFormik.setFieldValue('phone',value)
  };

  const googleLogin = async () => {
    const res = await signInWithGoogle(signUpFormik.values.email,location?.state?.role,location?.state?.planType);
    if(res?.status === -1){
      document.getElementById('accountDeletedModal').click()
    }
    else if(res?.data?.token){

      setToken(res?.data?.token);
      setIsLoggedIn(true);
      // location?.state?.role === "business" ? navigate("/add-sites") : navigate("/");
      // if(location?.state?.role === "business"){
      if(res?.data?.data?.accountType === "business"){
        navigate("/add-sites")
        if(res?.data?.message==='User Register Successfully'){
          alert(`Your 15 days trial is successfully activated.`)
        }
      }else{
        navigate("/edit-profile", {state:{"newUser":true}})
      }
      setLoader(false);
    }
  
  };
  const applleLogin = async () => {
    const res = await signInWithApple(signUpFormik.values.email,location?.state?.role,location?.state?.planType);
    if(res?.status === -1){
      document.getElementById('accountDeletedModal').click()
    }
    else if(res?.data?.token){

      setToken(res?.data?.token);
      setIsLoggedIn(true);
      if(location?.state?.role === "business"){
        if(res?.data?.message==='User Register Successfully'){
          alert(`Your 15 days trial successfully is activated.`)
        }
        navigate("/add-sites")
      }else{
        navigate("/edit-profile", {state:{"newUser":true}})
      }
      setLoader(false);
    }
  
  };
  const handleCheck =()=>{
    setIsChecked(!isChecked)
  }

   const handleBusinessPlans =()=>{
    console.log("clickkced")
   }
  // const handleSendCode = () => {
  //   const phoneNumberWithPrefix = `${signUpFormik.values.phone}`; // Modify the country prefix as needed

  //   const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
  //     size: 'invisible',
  //     callback: (response) => {
  //       // reCAPTCHA callback
  //       // console.log(response, "recaptcha passed...")
  //     },
  //   });

  //   firebase
  //     .auth()
  //     .signInWithPhoneNumber(phoneNumberWithPrefix, appVerifier)
  //     .then((confirmationResult) => {
  //       setVerificationId(confirmationResult.verificationId);
  //     })
  //     .catch((error) => {
  //       // Handle error
  //       console.error('Error sending verification code:', error);
  //     });
  // };

  console.log(signUpFormik.values, "<><><><>");

  return (
    <>
      <div className="container-fliud mask">
        <div className="row ms-0 me-0">
          <div className="col-xl-7 col-lg-6 text-center">
            <div className="img_logo">
              <img src={process.env.PUBLIC_URL + "/assets/images/logo.svg"} />
              <img
                src={process.env.PUBLIC_URL + "/assets/images/tcf.svg"}
                className="tcf_logo"
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="login-welcome mask2">
              <h2 className="text-center">Sign Up</h2>
              {/* <div>
            <h4>
              Country Code
              <select
                name=""
                id=""
                onChange={(e)=>setMobCode(e.target.value)}
                // style={{ border: "none", fontWeight: "700" }}
              >
                {allCountryCode.map(el=>{
                  return (

                    <option value={el.mobileCode}>{`${el.code} (${el.mobileCode})`}</option>
                  )
                })
                  }
 
              </select>
            </h4>
          </div> */}
              <label htmlFor="number" className="form-label mt-4">
                Enter phone number
              </label>
              <div >

              <PhoneInput
                  country={"gb"}
                  // disabled={toggle}
                  value={signUpFormik.values.phone}
                  inputClass="phone-input"
                  onChange={(value, country, e,formattedValue) => { handleChange(value,country.dialCode)}}
                  placeholder="Your phone number"
                  />
                  </div>
              {/* <input
                className="form-control"
                type="phone"
                name="phone"
                value={signUpFormik.values.phone}
                // onChange={(e) => {
                //   globalHandleChange(e, signUpFormik);
                // }}
                onChange={(e) => {
                  handleChange(e);
                }}
                id="phone"
                placeholder="Your phone number"
              /> */}
              {Boolean(
                signUpFormik.touched.phone && signUpFormik.errors.phone
              ) && (
                <p className="text-danger mt-2">{signUpFormik.errors.phone}</p>
              )}
              <label htmlFor="email" className="form-label mt-4">
                Enter Email address
              </label>
              <input
                className="form-control"
                type="email"
                name="email"
                id="email"
                value={signUpFormik.values.email}
                onChange={(e) => {
                  globalHandleChange(e, signUpFormik);
                }}
                placeholder="Your email address"
              />
              {Boolean(
                signUpFormik.touched.email && signUpFormik.errors.email
              ) && (
                <p className="text-danger mt-2">{signUpFormik.errors.email}</p>
              )}
              <div className="d-flex align-items-baseline mt-4">
                <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  onClick={handleCheck}
                  value="Bike"
                  className="me-2"
                />
                <label for="vehicle1">
                  <small>
                    By continuing you accept our <Link to="https://thecaloriefinder.com/privacy-policy/" target="_blank"> Privacy Policy</Link>{" "}
                    and <Link to="https://thecaloriefinder.com/term-and-conditions/" target="_blank">Term of Use</Link>{" "}
                  </small>
                </label>
              </div>
              <button
                type="button"
                className="btn_custome mt-4 "
                disabled={!isChecked}
                style={!isChecked ? {background:"#80808080"} :{}}
                onClick={() => {
                  signUpFormik.handleSubmit();
                }}
              >
                {loader ? (
                  <>
                    <WhiteLoader />
                  </>
                ) : (
                  "Register"
                )}
              </button>
              {/* <button type='button' className="btn_custome mt-4" onClick={handleSendCode}>{loader ? (<><WhiteLoader /></>) : "Register 2"}</button> */}

              <div class="login_or mt-4">or</div>

              <div className="d-flex justify-content-center mt-4">
                <span className="brand_logo me-5">
                  <a href="#"></a>
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/apple.png"}
                    alt="apple"
                    width="40"
                    height="40"
                    onClick={applleLogin}
                  />
                </span>
                <span className="brand_logo bg-white">
                  <a
                    href="#"
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/google.png"}
                      alt="google"
                      width="24"
                      height="24"
                      onClick={googleLogin}
                    />
                    {/* <GoogleSignIn/> */}
                  </a>
                </span>
              </div>
              <p className="dont_account mt-4">
                Already have an account? <Link to="/log-in">Login</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div id="recaptcha-container"></div> */}

      {/* modal popup code start here. */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content model-radius">
            <div class="modal-body account-type">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/logo.svg"}
                width="114"
              />
              <h1 className="mt-4">
                Which type of <br /> <span> account</span> are <br /> you
                creating?
              </h1>

              <button
                className="btn_custome mt-4 btn-per-bus"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalsub"
              >
                Personal
              </button>
              <p className="mt-4 or-pupop">Or</p>
              <button className="btn_custome mt-2 btn-per-bus">Business</button>
            </div>
          </div>
        </div>
      </div>

      {/*Subscriptions modal popup code start here. */}
      <div
        class="modal fade"
        id="exampleModalsub"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content model-subs">
            <div class="modal-body">
              <h3 className="text-center text-black">Subscriptions</h3>
              <img
                src={process.env.PUBLIC_URL + "/assets/images/Group.svg"}
                alt=""
              />
              <h1 className="mt-5 text-center">Choose Your Business Plan</h1>
              <h3 className="business_plane">Start your 15 days free trial</h3>
              <h1 className="plane-pop-title mt-5">Business</h1>

              <div className="row">
                <div className="col-md-6" style={{cursor:"pointer"}} onClick={handleBusinessPlans}>
                  <div className="Business_silver mt-3" >
                    <div>
                      <h3  >Business silver</h3>
                    </div>
                    <div>
                      <p>£ 200/year</p>
                    </div>
                    <div className="mt-2 upto30">
                      Suitable for businesses up to 3 sites up to 30
                      analysis/day
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="Business_silver  mt-3 platinium">
                    <div>
                      <h3>Business Platinium</h3>
                    </div>
                    <div>
                      <p>£ 600/year</p>
                    </div>
                    <div className="mt-2 upto30">
                      Suitable for businesses up to 20 sites up to 220
                      analysis/day
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="Business_silver  mt-3 gold">
                    <div>
                      <h3>Business Gold</h3>
                    </div>
                    <div>
                      <p>£ 379/year</p>
                    </div>
                    <div className="mt-2 upto30">
                      Suitable for businesses up to 7 sites up to 70
                      analysis/day
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="Business_silver  mt-3 unlimited">
                    <div>
                      <h3>Business Unlimited</h3>
                    </div>
                    <div>
                      <p>£ 999/year</p>
                    </div>
                    <div className="mt-2 upto30">
                      Suitable for businesses up to 50 sites up to 500
                      analysis/day
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AccountDeletedByAdminModal/>
      <button className="d-none" id="accountDeletedModal" data-bs-target="#deleteModal" data-bs-toggle="modal">
        show
      </button>
    </>
  );
};

export default SignUp;
