import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { BUCKET_URL } from "../../Utils/BaseUrl";
import ProfilePicDemo from "../../Assets/Images/Group 10775.png";
import IngredientChartModal from './IngredientChartModal';
import { chartFooterText, getChartNutrientsValue } from '../../Utils/HelperFuntions';

const RecipeChart = (props) => {
  const { chartDetails, handleCloseChart} = props
  let isRecipeChart = Boolean(!chartDetails?.ingredientStatus)
  let chartData = { ...chartDetails, proximatesDetails: chartDetails?.proximatesDetails || chartDetails?.proximates }
  const [ingredientId,setIngredientId] = useState()
  return (
    <div class="card  mb-0">

      <div class="card-body   p-0">
        {isRecipeChart && <div className="modal_chart_list mx-2 bg-white mb-3 ">
          <i
            onClick={handleCloseChart}
            class="bi bi-x position-absolute me-3 border rounded-circle mt-1 px-1 bg-dark text-white end-0"
          ></i>
          <div className="p-3 border-bottom">
            {chartData?.servingSize ? <p className="p-14 mb-2 fw-bolder">Portion size : <span>{chartData?.servingSize + " g"}</span></p>
              : <p className="p-14 mb-2 fw-bolder">No of Portion : <span>{chartData?.noOfPortion}</span></p>}
            {chartData?.note &&
              <div className="p-14 mb-0  d-flex justify-content-between">

                <span className='fw-bolder' style={{ width: "10%" }}> Note: </span>
                <span style={{ width: "90%" }} dangerouslySetInnerHTML={{ __html: chartData?.note.replace(/\n/g, '<br />') }} />
              </div>}

          </div>
          {Boolean(chartData?.ingredientDetails?.length) &&
            chartData?.ingredientDetails?.map((v) => {
              console.log(v, "vallluuueeee");
              return (
                <div className="row mb-4 mt-4">
                  <div className="col-auto">
                    <img
                      src={BUCKET_URL + "/" + v.frontImage}
                      style={{
                        width: "28px",
                        height: "28px",
                        borderRadius: "50%",
                      }}
                      onError={(e) => {
                        e.target.src = ProfilePicDemo;
                        e.error = null;
                      }}
                    />
                  </div>
                  <div className="col-7">
                    <h6 className=" p-14 fw-bold"> {v.name}</h6>
                    {/* <p className="p-10 m-0">Note: {v.notes}</p> */}
                    {v?.notes && <div className="p-10 m-0  d-flex">

                    <span className='fw-bold' style={{ width: "15%" }}> Note: </span>
                    <span style={{ width: "85%" }} dangerouslySetInnerHTML={{ __html: v?.notes.replace(/\n/g, '<br />') }} />
                    </div>}
                  </div>
                  <div className="col-auto">
                    <p className="m-0 p-12">
                      {`${v?.quantity} ${v?.quantityUnit || "g"}`}
                      { }
                    </p>
                  </div>

                  <div className="col-auto">
                      <i
                        class="bi bi-caret-right icon-color"
                        type="button"
                        data-bs-target="#ingredientModal"
                        data-bs-toggle="modal"
                        onClick={()=>setIngredientId(v.id)}
                      />
                  </div>
                </div>
              );
            })}
        </div>}
        <div className="modal-header-list position-relative ">
          <h1
            class="modal-title-list  mb-0 py-3 "
            id="exampleModalLabel"
          >
            Nutritional Information
            <button
              type="button"
              className="position-absolute rounded-circle  end-0 me-2"
            >
              {" "}
              <i
                onClick={() => handleCloseChart()}
                className="bi bi-x"
              ></i>
            </button>
          </h1>
        </div>

        <div class=" py-3 " style={{ border: "1px solid #000" }}>
          {isRecipeChart && Boolean(chartData?.proximatesDetails?.nutrients?.length) && (
            <div
              className=" heading d-flex justify-content-around align-items-center"
              style={{ borderBottom: "9px solid black" }}
            >
              <h6 className="p-1">Serving size </h6>
              <h6 className="p-12">
                {" "}
                (
                {/* {chartData?.servingSize?.data
                        ? chartData?.servingSize?.data
                        : chartData?.servingSize} */}
                {/* {chartData?.totalQuantity} */}
                {chartData?.totalServingSize}
                )
              </h6>
            </div>
          )}

          <div
            className=" heading d-flex justify-content-around align-items-center "
            style={{ borderBottom: "5px solid black" }}
          >
            <h6 className="p-17 fw-bold">Calories per serving</h6>
            <h1 className="p-17 fw-boldest">{parseInt(chartData?.caloriePerServing)}</h1>
          </div>

          {
            Boolean(chartData?.proximatesDetails?.nutrients?.length) && <>
              <div className="table-reponsive ">
                {/* <table className={`table-sm ${isRecipeChart ? "header-fixed" : "recipe-table"}  `}> */}
                <table className={`table-sm ${isRecipeChart ? "header-fixed" : "recipe-table"}  `}>
                  <thead style={{ borderBottom: '2px solid #000' }}>
                    <tr>
                      <th>Nutrients</th>
                      <th className='text-center'>Unit</th>
                      <th>per 100g</th>

                      {isRecipeChart && <>
                        <th>per {chartData?.totalQuantity}</th>
                        <th>serving</th>
                        <th>NRV</th></>
                      }
                    </tr>
                  </thead>
                  <tbody className='table-body'>
                    {Boolean(chartData?.proximatesDetails?.nutrients?.length) &&
                      chartData?.proximatesDetails?.nutrients
                        ?.filter((v) => v.value !== 0)
                        ?.map((v) => {
                          return (
                            <tr>
                              <td className='p-0'>{v?.link ? (
                                <Link to={v?.link} target="_blank" style={{
                                  wordWrap: "normal"
                                }}>
                                  {v.name}
                                </Link>
                              ) : (
                                <span>{v.name}</span>
                              )}</td>
                              <td className="fw-bold" align='center'>{v.unit}</td>
                              <td className="fw-bold">{getChartNutrientsValue(v?.hundredValue,v?.value,v?.name)}</td>
                              {Boolean(v?.hasOwnProperty('nrvPercentage')) && (
                                <>
                                  {isRecipeChart && <td className="fw-bold">{getChartNutrientsValue("",v?.value,v?.name)}</td>}
                                  {isRecipeChart && <td className="fw-bold">{v?.nrvValue > 0
                                    ? v?.nrvPercentage + "%"
                                    : ""}</td>}
                                  {isRecipeChart && <td className="fw-bold">{v?.nrvValue > 0 ? v?.nrvValue : ""}</td>}
                                </>
                              )}
                            </tr>
                          );
                        })}

                    {
                      !isRecipeChart && Boolean(chartData?.proximatesDetails?.minerals?.length) && <>
                        <div className="table-reponsive ">
                          <table className={`table-sm ${isRecipeChart ? "header-fixed" : "recipe-table"}  `}>
                            <thead style={{ borderBottom: '2px solid #000', borderTop: '2px solid #000', marginTop: '5px' }}>
                              <tr>
                                <th>Minerals</th>
                                <th className='text-center'>Unit</th>
                                <th>per 100g</th>

                                {isRecipeChart && <>
                                  <th>per {chartData?.totalQuantity}</th>
                                  <th>serving</th>
                                  <th>NRV</th></>
                                }
                              </tr>
                            </thead>
                            <tbody style={{overFlow: 'hidden'}}>
                              {Boolean(chartData?.proximatesDetails?.minerals?.length) &&
                                chartData?.proximatesDetails?.minerals
                                  ?.filter((v) => v.value !== 0)
                                  ?.map((v) => {
                                    return (
                                      <tr>
                                        <td className='p-0'>{v?.link ? (
                                          <Link to={v?.link} target="_blank" style={{
                                            wordWrap: "normal"
                                          }}>
                                            {v.name}
                                          </Link>
                                        ) : (
                                          <span>{v.name}</span>
                                        )}</td>
                                        <td className="fw-bold" align='center'>{v.unit}</td>
                                        <td className="fw-bold">{getChartNutrientsValue(v?.hundredValue,v?.value,v?.name)}</td>
                                        {Boolean(v?.hasOwnProperty('nrvPercentage')) && (
                                          <>
                                            {isRecipeChart && <td className="fw-bold">{getChartNutrientsValue("",v?.value,v?.name)}</td>}
                                            {isRecipeChart && <td className="fw-bold">{v?.nrvValue > 0
                                              ? v?.nrvPercentage + "%"
                                              : ""}</td>}
                                            {isRecipeChart && <td className="fw-bold">{v?.nrvValue > 0 ? v?.nrvValue : ""}</td>}
                                          </>
                                        )}
                                      </tr>
                                    );
                                  })}

                              {
                                !isRecipeChart && Boolean(chartData?.proximatesDetails?.vitamins?.length) && <>
                                  <div className="table-reponsive ">
                                    <table className={`table-sm ${isRecipeChart ? "header-fixed" : "recipe-table"}  `}>
                                      <thead style={{ borderBottom: '2px solid #000', borderTop: '2px solid #000', marginTop: '5px' }}>
                                        <tr>
                                          <th>Vitamins</th>
                                          <th className='text-center'>Unit</th>
                                          <th>per 100g</th>

                                          {isRecipeChart && <>
                                            <th>per {chartData?.totalQuantity}</th>
                                            <th>serving</th>
                                            <th>NRV</th></>
                                          }
                                        </tr>
                                      </thead>
                                      <tbody style={{overFlow: 'hidden'}}>
                                        {Boolean(chartData?.proximatesDetails?.vitamins?.length) &&
                                          chartData?.proximatesDetails?.vitamins
                                            ?.filter((v) => v.value !== 0)
                                            ?.map((v) => {
                                              return (
                                                <tr>
                                                  <td className='p-0'>{v?.link ? (
                                                    <Link to={v?.link} target="_blank" style={{
                                                      wordWrap: "normal"
                                                    }}>
                                                      {v.name}
                                                    </Link>
                                                  ) : (
                                                    <span>{v.name}</span>
                                                  )}</td>
                                                  <td className="fw-bold" align='center'>{v.unit}</td>
                                                  <td className="fw-bold">{getChartNutrientsValue(v?.hundredValue,v?.value,v?.name)}</td>
                                                  {Boolean(v?.hasOwnProperty('nrvPercentage')) && (
                                                    <>
                                                      {isRecipeChart && <td className="fw-bold">{getChartNutrientsValue("",v?.value,v?.name)}</td>}
                                                      {isRecipeChart && <td className="fw-bold">{v?.nrvValue > 0
                                                        ? v?.nrvPercentage + "%"
                                                        : ""}</td>}
                                                      {isRecipeChart && <td className="fw-bold">{v?.nrvValue > 0 ? v?.nrvValue : ""}</td>}
                                                    </>
                                                  )}
                                                </tr>
                                              );
                                            })}


                                      </tbody>
                                    </table>
                                  </div>
                                </>
                              }


                            </tbody>
                          </table>
                        </div>
                      </>
                    }


                  </tbody>
                </table>
              </div>
            </>
          }
          {/* {
            !isRecipeChart && Boolean(chartData?.proximatesDetails?.minerals?.length) && <>
              <div className="table-reponsive ">
                <table className={`table-sm ${isRecipeChart ? "header-fixed" : "recipe-table"}  `}>
                  <thead style={{ borderBottom: '2px solid #000' }}>
                    <tr>
                      <th>Minerals</th>
                      <th className='text-center'>Unit</th>
                      <th>per 100g</th>

                      {isRecipeChart && <>
                        <th>per {chartData?.totalQuantity}</th>
                        <th>serving</th>
                        <th>NRV</th></>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {Boolean(chartData?.proximatesDetails?.minerals?.length) &&
                      chartData?.proximatesDetails?.minerals
                        ?.filter((v) => v.value !== 0)
                        ?.map((v) => {
                          return (
                            <tr>
                              <td className='p-0'>{v?.link ? (
                                <Link to={v?.link} target="_blank" style={{
                                  wordWrap: "normal"
                                }}>
                                  {v.name}
                                </Link>
                              ) : (
                                <span>{v.name}</span>
                              )}</td>
                              <td className="fw-bold" align='center'>{v.unit}</td>
                              <td className="fw-bold">{v.hundredValue
                                ? v.hundredValue
                                  ?.toString()
                                  ?.split(".")?.length > 1
                                  ? Number(v.hundredValue)?.toFixed(2)
                                  : v.hundredValue
                                : v?.value
                                  ? v?.value?.toString()?.split(".")
                                    ?.length > 1
                                    ? Number(v.value)?.toFixed(2)
                                    : v?.value
                                  : ""}</td>
                              {Boolean(v?.hasOwnProperty('nrvPercentage')) && (
                                <>
                                  {isRecipeChart && <td className="fw-bold">{v?.value?.toFixed(2)}</td>}
                                  {isRecipeChart && <td className="fw-bold">{v?.nrvValue > 0
                                    ? v?.nrvPercentage + "%"
                                    : ""}</td>}
                                  {isRecipeChart && <td className="fw-bold">{v?.nrvValue > 0 ? v?.nrvValue : ""}</td>}
                                </>
                              )}
                            </tr>
                          );
                        })}

                        
                  </tbody>
                </table>
              </div>
            </>
          } */}
          {/* {
            !isRecipeChart && Boolean(chartData?.proximatesDetails?.vitamins?.length) && <>
              <div className="table-reponsive ">
                <table className={`table-sm ${isRecipeChart ? "header-fixed" : "recipe-table"}  `}>
                  <thead style={{ borderBottom: '2px solid #000' }}>
                    <tr>
                      <th>Vitamins</th>
                      <th className='text-center'>Unit</th>
                      <th>per 100g</th>

                      {isRecipeChart && <>
                        <th>per {chartData?.totalQuantity}</th>
                        <th>serving</th>
                        <th>NRV</th></>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {Boolean(chartData?.proximatesDetails?.vitamins?.length) &&
                      chartData?.proximatesDetails?.vitamins
                        ?.filter((v) => v.value !== 0)
                        ?.map((v) => {
                          return (
                            <tr>
                              <td className='p-0'>{v?.link ? (
                                <Link to={v?.link} target="_blank" style={{
                                  wordWrap: "normal"
                                }}>
                                  {v.name}
                                </Link>
                              ) : (
                                <span>{v.name}</span>
                              )}</td>
                              <td className="fw-bold" align='center'>{v.unit}</td>
                              <td className="fw-bold">{v.hundredValue
                                ? v.hundredValue
                                  ?.toString()
                                  ?.split(".")?.length > 1
                                  ? Number(v.hundredValue)?.toFixed(2)
                                  : v.hundredValue
                                : v?.value
                                  ? v?.value?.toString()?.split(".")
                                    ?.length > 1
                                    ? Number(v.value)?.toFixed(2)
                                    : v?.value
                                  : ""}</td>
                              {Boolean(v?.hasOwnProperty('nrvPercentage')) && (
                                <>
                                  {isRecipeChart && <td className="fw-bold">{v?.value?.toFixed(2)}</td>}
                                  {isRecipeChart && <td className="fw-bold">{v?.nrvValue > 0
                                    ? v?.nrvPercentage + "%"
                                    : ""}</td>}
                                  {isRecipeChart && <td className="fw-bold">{v?.nrvValue > 0 ? v?.nrvValue : ""}</td>}
                                </>
                              )}
                            </tr>
                          );
                        })}

                        
                  </tbody>
                </table>
              </div>
            </>
          } */}
          {/* <div className="disable-scroller_chart">
                  {Boolean(chartData?.proximatesDetails?.nutrients?.length) &&
                    chartData?.proximatesDetails?.nutrients
                      ?.filter((v) => v.value !== 0)
                      ?.map((v) => {
                        return (
                          <div className="row gx-0 pt-3">
                            <div
                              className={`col-2 text-start`}
                            >
                              <h6 className=" fs-5 fw-bold">
                                {v?.link ? (
                                  <Link to={v?.link} target="_blank">
                                    {v.name}
                                  </Link>
                                ) : (
                                  <span>{v.name}</span>
                                )}
                              </h6>
                            </div>
                            <div className={`col-2`}>
                              <p className="fs-5">{v.unit}</p>
                            </div>
                            <div className={`col-2 `}>
                              <h6 className="text-start fs-5 fw-bold">
                                {v.hundredValue
                                  ? v.hundredValue
                                    ?.toString()
                                    ?.split(".")?.length > 1
                                    ? Number(v.hundredValue)?.toFixed(2)
                                    : v.hundredValue
                                  : v?.value
                                    ? v?.value?.toString()?.split(".")
                                      ?.length > 1
                                      ? Number(v.value)?.toFixed(2)
                                      : v?.value
                                    : ""}
                              </h6>
                            </div>
                            {Boolean(v?.hasOwnProperty('nrvPercentage') ) && (
                              <>
                                <div className="col-2">
                                  <h6 className="text-start fs-5 fw-bold">
                                    {v?.value?.toFixed(2)}
                                  </h6>
                                </div>
                                <div className="col-2">
                                  <h6 className="text-start fs-5 fw-bold">
                                    {v?.nrvValue > 0
                                      ? v?.nrvPercentage + "%"
                                      : ""}
                                  </h6>
                                </div>
                                <div className="col-2">
                                  <h6 className="text-start fs-5 fw-bold">
                                    {v?.nrvValue > 0 ? v?.nrvValue : ""}
                                  </h6>
                                </div>
                              </>
                            )}
                          </div>
                        );
                      })}
                     
                </div> */}
          <p
            className=" text-dark m-0 card-bottom p-10 pt-3 p-1"
            style={{ borderTop: "3px solid black" }}
          >
            {/* % values are based on a 2000 calories diet. Your daily values may be higher or lower depending on your energy needs.<br />
            A ratio value of 1 is used for volume to weight conversion during some analysis.<br />
            Some of the ingredients and/or recipes on this database are Crown copyright data drawn from CoFID and licensed under the Open Government Licence v3.0. */}
            {chartFooterText()}
          </p>
        </div>
      </div>
      <IngredientChartModal ingredientId={ingredientId} setIngredientId={setIngredientId}/>

    </div>
  )
}

export default RecipeChart