import React, { useEffect, useState } from 'react'
import { UserContext } from './Contexts'
import { isAuth } from '../Utils/HelperFuntions'
import { getUserDetailsApi } from '../Api/Apis'
import { getToken } from '../Utils/HelperFuntions'

const UserContextState = (props) => {

   const [userProfileDetails, setUserProfileDetails] = useState({ data: null, loading: true })
   const [isLoggedIn, setIsLoggedIn] = useState(Boolean(getToken()))
   const [searchData,setSearchData] =  useState([]);
   const [pCount,setPCount]=useState(1)
   const [searchInpt,setSearchInpt] = useState()
   const [showTrialAlert,setShowTrialAlert] = useState(false)
   // console.log(isAuth(), "token is here at user context")
   const token = isAuth()

   const fetchUserDetails = async () => {
      try {
         const res = await getUserDetailsApi()
         if (res?.status === 200) {
            setUserProfileDetails((v) => ({ ...v, data: res?.data }))
            let onBusinessTrial = res?.data?.data?.onBusinessTrial
            if(onBusinessTrial!==null && onBusinessTrial!==undefined){
               setShowTrialAlert(onBusinessTrial)
            }
         }
      } catch (error) {
         console.log(error)
      }
   }

   useEffect(() => {
      if (isLoggedIn === true || token) {
         fetchUserDetails()
      }
   }, [token, isLoggedIn])

   return (
      <UserContext.Provider
         value={{
            userProfileDetails,
            setUserProfileDetails,
            fetchUserDetails,
            setIsLoggedIn,
            searchData,
            setSearchData,
            setPCount,
            pCount,
            setSearchInpt,
            searchInpt,
            setShowTrialAlert,
            showTrialAlert
         }}
      >
         {props.children}
      </UserContext.Provider>
   )
}

export default UserContextState