import React from 'react'
import Sidebar from '../AppLayout/Sidebar'

const About = () => {
  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="container-fliud">
          <div className='row'>
            <h2 className=''>adfhjahhdslfjsjdklfgjklsdfklsjkldf</h2>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
