import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { getAllIngridientsById } from '../../Api/Apis'
import ProfilePicDemo from "../../Assets/Images/Group 10775.png"
import { assignProfilePicBucketUrl } from '../../Utils/HelperFuntions'
import AppLayout from '../../AppLayout/AppLayout'

const ViewIngredients = () => {
   const urlParam = useParams()

   const [ingredients, setIngredients] = useState({ data: null, loading: true })
   const [images, setImages] = useState([])

   const fetchIngredients = async () => {
      try {
         const res = await getAllIngridientsById(urlParam?.id)
         if (res?.status === 200) {
            console.log(res?.data, "resposne")
            setIngredients((v) => ({ ...v, data: res?.data, loading: false }))
         }
      } catch (error) {
         setIngredients((v) => ({ ...v, loading: false }))
         console.log(error)
      }
   }

   useEffect(() => {
      fetchIngredients()
   }, [])

   console.log(ingredients, "000000000000000000000000000000")


   return (
      <AppLayout>
         <div className="row pt-4">
            <div className="col-md-4">
               <h3>Image Thumbnail</h3>
               <div className="d-flex justify-content-between after-upload-img">
                  <div className="me-3">
                     <img src={`${assignProfilePicBucketUrl(ingredients?.data?.data?.thumbnailImage)}`} width="44" alt=""
                        onError={(e) => {
                           e.target.src = ProfilePicDemo
                           e.error = null
                        }}
                     />
                  </div>
               </div>
               <div className="d-flex justify-content-between">
                  <label className="upload">
                     Upload Here
                     <input
                        type="file"
                        name="thumbnailImage"
                        accept="image/*"
                        style={{ display: "none" }}
                     />
                  </label>
                  <p>upto 11MB</p>
               </div>
               <div className="position-relative mt-2">
                  <input
                     className="form-control"
                     type="text"
                     name="name"
                     value={ingredients?.data?.data?.name}
                     id="name"
                     placeholder="Food Label Information"
                  />
                  <label htmlFor="name" className="input-label">
                     Name
                  </label>
               </div>

               <div className="position-relative mt-4">
                  <input
                     className="form-control"
                     type="text"
                     name="ingredientUnit"
                     value={ingredients?.data?.data?.ingredientUnit}
                     id="name"
                     placeholder="100g"
                  />
                  <label htmlFor="name" className="input-label">
                     Information per 100g
                  </label>
               </div>

               <div className="d-flex justify-content-between mt-3">
                  <label className="upload">
                     Upload front image here
                     <input
                        type="file"
                        name="frontImage"
                        accept="image/*"
                        style={{ display: "none" }}
                     />
                  </label>
                  <p>upto 11MB</p>
               </div>

               <div className="d-flex justify-content-between">
                  <label className="upload">
                     Upload back image here
                     <input
                        type="file"
                        name="backImage"
                        accept="image/*"
                        style={{ display: "none" }}
                     />
                  </label>
                  <p>upto 11MB</p>
               </div>


               <div className="d-flex justify-content-between after-upload-img">
                  <div className="me-3">
                     <img src={`${assignProfilePicBucketUrl(ingredients?.data?.data?.frontImage)}`} width="44" alt=""
                        onError={(e) => {
                           e.target.src = ProfilePicDemo
                           e.error = null
                        }}
                     />
                  </div>
                  <div style={{ textAlign: "left", width: "100%" }}>
                     <h4>{ingredients?.data?.data?.name || "Image Name"}</h4>
                     <h5>{ingredients?.data?.data?.size || "Image Size in"} MB</h5>
                  </div>
                  <div>
                     <i className='bi bi-x' style={{ "cursor": "pointer" }}></i>
                  </div>
               </div>

               <div className="d-flex justify-content-between after-upload-img mt-3">
                  <div className="me-3">
                     <img src={`${assignProfilePicBucketUrl(ingredients?.data?.data?.backImage)}`} width="44" alt=""
                        onError={(e) => {
                           e.target.src = ProfilePicDemo
                           e.error = null
                        }}
                     />
                  </div>
                  <div style={{ textAlign: "left", width: "100%" }}>
                     <h4>{ingredients?.data?.data?.name || "Image Name"}</h4>
                     <h5>{ingredients?.data?.data?.size || "Image Size in"} MB</h5>
                  </div>
                  <div>
                     <i className='bi bi-x' style={{ "cursor": "pointer" }}></i>
                  </div>
               </div>

               <div className="position-relative mt-4">
                  <input
                     className="form-control"
                     type="text"
                     name="caloriePerUnit"
                     value={ingredients?.data?.data?.caloriePerUnit}
                     id="name"
                     placeholder="142g"
                  />
                  <label htmlFor="name" className="input-label">
                     Calories per 100g
                  </label>
               </div>

               <div className="position-relative mt-4">
                  <textarea name="" className="form-control" id="" cols="100" rows="3">
                     * Persent Daily Values are based on a 2,000 calorie diet. Your Daily Values may be higher or lower depending on your energy needs.
                  </textarea>
                  <label htmlFor="name" className="input-label">
                     Calories per 100g
                  </label>
               </div>

               <label className="form-label mt-3" htmlFor="paste">Refrence/proof (paste link here)</label>
               <div className="d-flex align-items-center">
                  <input className="form-control me-3" type="text" name="" id="paste " placeholder="Enter link here" />
                  <span className="plus-sy">+</span>
               </div>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-4">
               <label htmlFor='referenceImages' className="upload-photog">
                  Uploaded photographs
               </label>
               {/* <input
                  id={"referenceImages"}
                  type="file"
                  name="referenceImages"
                  accept="image/*"
                  multiple
                  style={{ display: "none" }}
               /> */}

               {
                  ingredients?.data?.data?.referenceImages && ingredients?.data?.data?.referenceImages?.map((el, i) => {
                     console.log(el, "mappppppppppppppppppppppppppp")
                     return (
                        <React.Fragment key={`key-${i}`}>
                           <div className="d-flex justify-content-between after-upload-img mt-3">
                              <div className="me-3">
                                 <img src={assignProfilePicBucketUrl(el)} width="44" alt="" />
                              </div>
                              <div style={{ textAlign: "left", width: "100%" }}>
                                 <h4>{el?.file}</h4>
                                 <h5>{((el?.size / 1024) / 1024).toFixed(2)} MB</h5>
                              </div>
                              <div>
                                 <i className='bi bi-x' style={{ "cursor": "pointer" }}></i>
                              </div>
                           </div>
                        </React.Fragment>
                     )
                  })
               }

               <div className="table-responsive" style={{ height: "470px", overflowY: "auto" }}>
                  <table className="table table-borderlessj">
                     <tbody>
                        <tr>
                           <th>Proximates <i className="bi bi-arrow-down"></i></th>
                           <th></th>
                           <th></th>
                        </tr>
                        <tr>
                           <td>Nutrient</td>
                           <td></td>
                           <td>per 100g</td>
                        </tr>
                        <tr>
                           <td>Energy</td>
                           <td><b>KJ</b></td>
                           <td><b>295</b></td>
                        </tr>
                        <tr>
                           <td></td>
                           <td><b>KCAL</b></td>
                           <td><b>71</b></td>
                        </tr>
                        <tr>
                           <td>Fat of which</td>
                           <td><b>g</b></td>
                           <td><b>g</b></td>
                        </tr>
                        <tr>
                           <td>Saturates</td>
                           <td><b>g</b></td>
                           <td><b>4.6|</b></td>
                        </tr>
                        <tr>
                           <td>Carbohydrate Of which</td>
                           <td><b>g</b></td>
                           <td><b>2.9</b></td>
                        </tr>
                        <tr>
                           <td>Sugars</td>
                           <td><b>g</b></td>
                           <td><b>2.2</b></td>
                        </tr>
                     </tbody>
                  </table>
               </div>
               <button className="btn_custome mt-1 otp-submit" data-bs-toggle="modal" data-bs-target="#exampleModal">Back</button>
            </div>
            <div className="col-md-2"></div>
         </div>
      </AppLayout>
   )
}

export default ViewIngredients