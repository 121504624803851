import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../Pages/Home/Home'
import About from '../Pages/About'
import AddSites from '../Pages/MyBusinessSites/AddSites'
import LogIn from '../Pages/Account/LogIn/LogIn'
import PublicRoute from '../Routings/PublicRoute'
import SignUp from '../Pages/Account/SignUp/SignUp'
import AuthContextState from '../Contexts/AuthContextState'
import SignUpOtp from '../Pages/Account/SignUp/SignUpOtp/SignUpOtp'
import PrivateRoute from '../Routings/PrivateRoute'
import LogInOtp from '../Pages/Account/LogIn/LogInOtp/LogInOtp'
import UiRouter from '../ui-updates'
import MyRecipes from '../Pages/MyRecipes/MyRecipes'
import UserProfile from '../Pages/UserProfile/UserProfile'
import { Toaster } from 'react-hot-toast'
import SharedRecipes from '../Pages/SharedRecipes/SharedRecipes'
import MyCommunity from '../Pages/MyCommunity/MyCommunity'
import MyBusinessSites from '../Pages/MyBusinessSites/MyBusinessSites'
import AddIngredients from '../Pages/AddIngredients/AddIngredients'
import ViewIngredients from '../Pages/ViewIngredients/ViewIngredients'
import ErrorPage from '../Pages/ErrorPage/ErrorPage'
import ViewRecipe from '../Pages/MyRecipes/ViewRecipe'
import SavedRecipe from '../Pages/SavedRecipe/SavedRecipe'
import Default from '../Pages/Default'
import UpgradePlan from '../Pages/UpgradePlan/UpgradePlan'
import MySharedRecipe from '../Pages/MySharedRecipe/MySharedRecipe'
import UnsubscribeEmail from '../Pages/UnsubscribeNotification/UnsubscribeEmail'
import ManageSites from '../Pages/UserSites/manageSites'

const MainRouter = () => {
   return (
      <>
         <Routes>

            <Route path="/log-in" element={<AuthContextState><PublicRoute><LogIn /></PublicRoute></AuthContextState>} />
            <Route path="/log-in-otp" element={<AuthContextState><PublicRoute><LogInOtp /></PublicRoute></AuthContextState>} />
            <Route path="/sign-up" element={<AuthContextState><PublicRoute><SignUp /></PublicRoute></AuthContextState>} />
            <Route path="/sign-up-otp" element={<AuthContextState><PublicRoute><SignUpOtp /></PublicRoute></AuthContextState>} />

            <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route path="/add-ingredients" element={<PrivateRoute><AddIngredients /></PrivateRoute>} />
            <Route path="/view-ingredients/:id" element={<PrivateRoute><ViewIngredients /></PrivateRoute>} />
            <Route path="/about" element={<About />} />
            <Route path="/my-business-sites" element={<PrivateRoute><MyBusinessSites /></PrivateRoute>} />
            <Route path="/add-sites" element={<PrivateRoute><AddSites /></PrivateRoute>} />
            <Route path="/manage-sites" element={<PrivateRoute><ManageSites /></PrivateRoute>} />
            <Route path="/my-recipes" element={<PrivateRoute><MyRecipes /></PrivateRoute>} />
            <Route path="/saved-recipes" element={<PrivateRoute><SavedRecipe /></PrivateRoute>} />
            <Route path="/view-recipes" element={<PrivateRoute><ViewRecipe/></PrivateRoute>} />
            <Route path="/edit-profile" element={<PrivateRoute><UserProfile /></PrivateRoute>} />
            <Route path="/shared-recipes" element={<PrivateRoute><SharedRecipes /></PrivateRoute>} />
            <Route path="/myshared-recipes" element={<PrivateRoute><MySharedRecipe /></PrivateRoute>} />
            <Route path="/communityshared-recipes" element={<PrivateRoute><MySharedRecipe /></PrivateRoute>} />
            <Route path="/my-community" element={<PrivateRoute><MyCommunity /></PrivateRoute>} />
            <Route path="/upgrade-plan" element={<PrivateRoute><UpgradePlan /></PrivateRoute>} />
            <Route path="/unsubscribe-email" element={<UnsubscribeEmail />} />
            <Route path="*" element={<ErrorPage/>} />
            <Route path="/default" element={<Default/>} />
            {/* UI-Route */}
            <Route path="/ui-pages" element={<UiRouter />} />

         </Routes>
         <Toaster />
         <div id="recaptcha-container"></div>
      </>
   )
}

export default MainRouter