import React, { useContext, useEffect, useState } from 'react'
import OTPInput from 'react-otp-input';
import { resendOtpWithFirebase, setToken } from '../../../../Utils/HelperFuntions';
import { logInApi } from '../../../../Api/Apis';
import { AuthContext, UserContext } from '../../../../Contexts/Contexts';
import { useLocation, useNavigate } from 'react-router';
import firebase from "../../../../Utils/Firebase"
import { WhiteLoader } from '../../../../Utils/Loaders';
import { toast } from 'react-hot-toast';

const LogInOtp = () => {

   const TimerSecondsValue = 30

   const { verificationId, setVerificationId } = useContext(AuthContext)
   const { setIsLoggedIn } = useContext(UserContext)

   const [code, setCode] = useState('');
   const [loader, setLoader] = useState(false);
   const [timer, setTimer] = useState(0);
   const [isWrongOtp, setIsWrongOtp] = useState(false);

   const historyState = useLocation()
   const navigate = useNavigate()

   // console.log(historyState, "history state at otp", verificationId)

   const handleSetCode = (e) => {
      setCode(e)
   }

   const handleVerifyCode = async (e) => {
      e.preventDefault()
      console.log("first")
      setLoader(true)
      const credential = firebase.auth.PhoneAuthProvider.credential(historyState?.state?.verificationId, code);
      setIsWrongOtp(true)

      console.log(credential,"AFDFDSAFDSAFDSFD")
      await firebase
         .auth()
         .signInWithCredential(credential)
         .then((userCredential) => {
            // User successfully authenticated

            const user = userCredential.user;
            // setToken(user?._delegate?.accessToken)
            console.log(user, "credential...........")
            console.log('User authenticated:', user?.multiFactor?.user?.accessToken);
            console.log('User authenticated:', user?._delegate?.accessToken);
            handleLogIn({ token: user?.multiFactor?.user?.accessToken, loginType: "manual", accountType: "personal" })
         })
         .catch((error) => {
            console.log("inside catchhhhhhhhhhhhhhhhh", error)
            toast.error("Invalid OTP", { id: "001" })
            setLoader(false)
            setIsWrongOtp(true)
         });
   }

   const handleLogIn = async (payload) => {
      try {
         const res = await logInApi(payload)
         if (res?.status === 200) {
            setToken(res?.data?.token)
            setIsLoggedIn(true)
            navigate("/")
            setLoader(false)
         }
      } catch (error) {
         console.log(error, "this is hereeeeeeeeeeeeeeeeeeeeeeeee")
      }
   }

   const handleResendOTP = () => {
      resendOtpWithFirebase(historyState?.state?.phone)
      setTimer(TimerSecondsValue);
   };

   const setTimeOnFirstMount = () => {
      setTimer(TimerSecondsValue)
   }

   useEffect(() => {
      if (timer === 0) {
         setTimeOnFirstMount()
      }
   }, [])

   useEffect(() => {
      let intervalId;
      if (timer > 0) {
         intervalId = setInterval(() => {
            setTimer(timer - 1);
         }, 1000);
      }

      return () => {
         clearInterval(intervalId);
      };
   }, [timer]);

   return (
      <>
         <div className="container-fliud mask">
            <div className="row ms-0 me-0">
               <div className="col-xl-7 col-lg-6 text-center">
                  <div className="img_logo">
                     <img src={process.env.PUBLIC_URL + "/assets/images/logo.svg"} />
                     <img
                        alt=''
                        src={process.env.PUBLIC_URL + "/assets/images/tcf.svg"}
                        className="tcf_logo"
                     />
                  </div>
               </div>
               <div className="col-xl-5 col-lg-6">
                  <form onSubmit={handleVerifyCode}>
                     <div className="login-welcome otp-welcome mask2">
                        <h2 className="text-center">Verify your OTP</h2>
                        <p className="text-center mt-5">
                           We will send you a <b> One Time Password </b><br /> on this mobile number.
                        </p>
                        <p className="text-center mt-2">
                           {/* Send OTP in +<b>44******58</b> */}
                           Send OTP to <b>{historyState?.state?.phone}</b>
                        </p>
                        <label htmlFor="full" className="form-label mt-5">
                           Verification Code
                        </label>
                        <OTPInput
                           // value={otp}
                           numInputs={6}
                           containerStyle={`otp-field`}
                           shouldAutoFocus
                           inputStyle={{
                              margin: "10px",
                              width: "2.375rem",
                              height: "2.375rem",
                              borderRadius: "0.313rem",
                              textAlign: "center",
                              border: "1px solid rgba(0,0,0,0.3)",
                              display: "flex",
                              justifyContent: "space-around",
                           }}
                           errorStyle={{ border: "solid 1px red" }}
                           // hasErrored={(code.length <= 5) ? true : false}
                           hasErrored={isWrongOtp}
                           value={code}
                           onChange={(e) => handleSetCode(e)}

                           isInputNum
                           // renderSeparator={<span>-</span>}
                           renderInput={(props) => <input {...props} />}
                        />
                        <p className="dont_rev_otp mt-4 text-align-left">
                           Didn't receive the OTP?{" "}
                           {timer === 0 ? (
                              // <button onClick={handleResendOTP} className="btn_custome mt-4 otp-submit">Resend OTP</button>
                              <a className="ms-3" onClick={handleResendOTP} style={{ "cursor": "pointer" }}>Resend OTP</a>
                           ) : (
                              <a className="ms-3">Resend OTP in {timer} seconds</a>
                           )}
                        </p>

                        <button
                           className="btn_custome mt-4 otp-submit"
                           type="submit"
                        // onClick={(e) => { handleVerifyCode(e) }}
                        // disabled={Boolean(((timer > 0) && (code.length <= 5)) ? true : false) ? true : false}
                        >
                           {loader ? (<><WhiteLoader /></>) : "Submit"}
                        </button>
                     </div>
                  </form>

               </div>
            </div>
         </div>
         <div id="recaptcha-container"></div>
      </>
   )
}

export default LogInOtp