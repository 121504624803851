import React, { useEffect, useState } from "react";
import CloseBtn from "../../Assets/Images/Group 4292.svg";
import ModalImage from "../../Assets/Images/Group 3611 (1).svg";
import AppLayout from "../../AppLayout/AppLayout";
import {
  acceptInviteApi,
  getAllInvitePeople,
  getAllMyCommunityMembers,
  getPendingInvitesApi,
  inviteUsers,
  removeFromCommunityApi,
} from "../../Api/Apis";
import { toast } from "react-hot-toast";
import { BUCKET_URL } from "../../Utils/BaseUrl";
import ProfilePicDemo from "../../Assets/Images/Group 10773.png";
import defualtPic from "../../Assets/Images/person.png";
import { isValidEmail } from "../../Utils/HelperFuntions";
import { WhiteLoader } from "../../Utils/Loaders";
const MyCommunity = () => {
  const [email, setEmail] = useState("");
  const [allMembers, setAllMembers] = useState();
  const [isChecked, setIsChecked] = useState(true);
  const [invitedMember, setInvitedMember] = useState([]);
  const [removeId, setRemoveId] = useState();
  const [pendingInvites, setPendingInvites] = useState([]);
  const [showError,setShowError] = useState(false)
  const [loader,setLoader] = useState(false)
  const handleInvite = async () => {
    if (email && isValidEmail(email)) {
      try {
        setLoader(true)
        const res = await inviteUsers({ email: email.toLowerCase() });
        if (res.status === 200 || res.status === 201) {
          if(res.data.status){
            toast.success(res.data.message);
          }else{
            toast.error(res.data.message);
          }
          document.getElementById("exampleModal").click();
          setEmail("");
          getAllMyCommunity();
        }
        setLoader(false)
      } catch (err) {
        console.log(err);
        setLoader(false)

      }
    }else{
      setShowError(true)
    }
  };

  const handleDeleteMember = async (id) => {
    console.log(id);
  };
  const getAllMyCommunity = async () => {
    try {
      const res = await getAllMyCommunityMembers();
      if (res.status === 200 || res.status === 201) {
        if (isChecked) {
          setAllMembers(res.data?.allAcceptedInvites);
        } else {
          setAllMembers(res.data?.allPendingInvites);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const allInvitations = async () => {
    try {
      const res = await getAllInvitePeople();
      if (res.status === 200 || res.status === 201) {
        setInvitedMember(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleRemoveFromCommunity = (id) => {
    setRemoveId(id);
  };
  const handleRemoveUser = async () => {
    try {
      const res = await removeFromCommunityApi({ memberUserId: removeId });
      if (res.status === 200 || res.status === 201) {
        toast.success(res.data.message);
        document.getElementById("confirmModal").click();
        getAllMyCommunity();
        allInvitations();
        getAllPendingInvitations();
      }

    } catch (err) {
      console.log(err);
    }
  };
  const handleRadio = (name) => {
    if (name === "first") {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  };

  const getAllPendingInvitations = async () => {
    try {
      const res = await getPendingInvitesApi();
      console.log(res, "respoinddiie");
      if (res.status === 200 || res.status === 201) {
        setPendingInvites(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAccept = async (data, status) => {
    try {
      const res = await acceptInviteApi({
        email: data.email,
        inviteCode: data.inviteCode,
        status: status,
      });
      console.log(res, "resposneieiiendsifsdf");
      if (res.status === 200 || res.status === 201) {
        allInvitations();
        getAllPendingInvitations();
        toast.success(res.data.message);
        document.getElementById("inviteModal").click();
      }
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    allInvitations();
    getAllPendingInvitations();
  }, []);
  useEffect(() => {
    getAllMyCommunity();
  }, [isChecked]);
  return (
    <AppLayout>
      <div className="d-flex justify-content-between pt-4 mb-3 position-relative">
        {/* <p className="browse">Browse all the ingredients and recipes shared with you</p> */}
        <div className=" d-flex justify-content-between align-items-center my-community-heading  ">
          <h2 className="fw-bolder ps-3">
            My Community{" "}
            {pendingInvites?.length >= 1 && (
              <span
                className="invitess"
                data-bs-target="#inviteModal"
                data-bs-toggle="modal"
              >
                {pendingInvites?.length} invites
              </span>
            )}
          </h2>
          <div className="">
            <h3
              className="ingredient mb-0  "
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <span className="me-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 20.908 17.431"
                  fill="#FD3468"
                >
                  <g
                    id="Group_4201"
                    data-name="Group 4201"
                    transform="translate(-4978.529 140.166)"
                  >
                    <path
                      id="Path_10694"
                      data-name="Path 10694"
                      d="M5309.268,40.676a17.426,17.426,0,0,1-1.514,1.063,17.3,17.3,0,0,1-1.711.751.9.9,0,0,1-.168.035,7.213,7.213,0,0,0-4.324-5.676c.133-.181.255-.358.39-.525a.163.163,0,0,1,.152-.011,3.2,3.2,0,0,0,1.415.4,2.992,2.992,0,0,0,1.706-.449,5.265,5.265,0,0,0,.684-.511.242.242,0,0,1,.32-.044,5.559,5.559,0,0,1,2.913,3.943c.048.245.056.5.086.746.01.079.033.157.05.235Z"
                      transform="translate(-309.831 -168.654)"
                    />
                    <path
                      id="Path_10695"
                      data-name="Path 10695"
                      d="M4978.529,40.548c.032-.247.057-.5.1-.741a5.56,5.56,0,0,1,2.972-4.191c.146-.08.219-.017.314.07a3.5,3.5,0,0,0,1.671.881,2.968,2.968,0,0,0,2.045-.311c.146-.077.221-.067.306.076a4.93,4.93,0,0,0,.323.434,7.307,7.307,0,0,0-2.919,2.264,7.217,7.217,0,0,0-1.409,3.435c-.332-.118-.65-.218-.959-.345a8.143,8.143,0,0,1-2.308-1.436,1.5,1.5,0,0,0-.132-.094Z"
                      transform="translate(0 -168.568)"
                    />
                    <path
                      id="Path_10696"
                      data-name="Path 10696"
                      d="M5085.68,83.028a6.316,6.316,0,0,1,2.161,1.692,6.4,6.4,0,0,1,1.489,3.987.319.319,0,0,1-.095.221,9.6,9.6,0,0,1-11.755.761c-.326-.22-.626-.478-.942-.714a.309.309,0,0,1-.136-.293,6.4,6.4,0,0,1,3.526-5.6c.136-.075.205-.027.3.058a3.994,3.994,0,0,0,2.186,1.093,3.422,3.422,0,0,0,2.335-.52C5085.067,83.514,5085.359,83.266,5085.68,83.028Z"
                      transform="translate(-93.876 -214.079)"
                    />
                    <path
                      id="Path_10697"
                      data-name="Path 10697"
                      d="M5150.493-109.2a2.711,2.711,0,0,1-1.723-.963,5.4,5.4,0,0,1-1.567-3.681,3.517,3.517,0,0,1,2.662-3.483,3.56,3.56,0,0,1,4.439,2.887,4.486,4.486,0,0,1-.377,2.5,5.052,5.052,0,0,1-1.667,2.222A2.7,2.7,0,0,1,5150.493-109.2Z"
                      transform="translate(-161.786 -21.797)"
                    />
                    <path
                      id="Path_10698"
                      data-name="Path 10698"
                      d="M5044.9-133.225a1.994,1.994,0,0,1-2.145.107,3.949,3.949,0,0,1-1.563-1.729,4.558,4.558,0,0,1-.549-2.21,3.118,3.118,0,0,1,2.857-2.965,3.08,3.08,0,0,1,2.532.978.709.709,0,0,1,.054.061.224.224,0,0,1,.021.053,4.211,4.211,0,0,0-1.652,2.616A5.336,5.336,0,0,0,5044.9-133.225Z"
                      transform="translate(-59.579 -0.13)"
                    />
                    <path
                      id="Path_10699"
                      data-name="Path 10699"
                      d="M5295.65-133.334c.9-2.195.723-4.148-1.209-5.747a2.887,2.887,0,0,1,1.092-.817,3.107,3.107,0,0,1,4.318,2.234,3.779,3.779,0,0,1-.3,2.267,4.121,4.121,0,0,1-1.745,2.139A2.008,2.008,0,0,1,5295.65-133.334Z"
                      transform="translate(-303.012 0)"
                    />
                  </g>
                </svg>
              </span>{" "}
              Invite
            </h3>
          </div>
        </div>
      </div>
      {/* modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content pb-4">
            <div className="modal-header border-bottom-0 pb-0">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Send an invitation
              </h1>
              <button
                type=""
                className=" border-0 bg-transparent"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {" "}
                <img src={CloseBtn} />
              </button>
            </div>
            <div className="modal-body">
              <p className="fw-bold pb-3">
              You can invite your friends and <br /> colleagues to share recipes with you
              </p>

              <label className="pb-2">Enter email and click to invite</label>
              <input
                type="text"
                value={email}
                onChange={(e) => {
                  setShowError(false)
                  setEmail(e.target.value);
                }}
                class="form-control"
                placeholder="Enter email id here..."
                aria-label="Email"
                aria-describedby="basic-addon1"
              />
              <img className="modal-image" src={ModalImage} />
              {(email.length && !isValidEmail(email) || showError) ? <div className="text-danger">Invalid Email</div> : null}
            </div>
            <div className=" mx-auto">
              <button
                type="button"
                className="invite-button"
                onClick={handleInvite}
                disabled={loader}
              >
                {loader ? <WhiteLoader/> :"Send invite"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className=" mt-3">
          <div className="food-div my-community border ">
            <div className="d-flex mt-3  mb-5">
              <div
                className="form-check mx-5"
                onClick={() => handleRadio("first")}
              >
                <input
                  className="form-check-input me-4"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  // value={checked}
                  checked={isChecked}
                  // checked
                />
                <label className="form-check-label" for="flexRadioDefault1">
                  Active Members
                </label>
              </div>
              <div className="form-check" onClick={() => handleRadio("second")}>
                <input
                  className="form-check-input me-4"
                  type="radio"
                  name="flexRadioDefault"
                  // value={checked}
                  checked={!isChecked}
                  id="flexRadioDefault2"
                />
                <label className="form-check-label" for="flexRadioDefault2">
                  Pending Members
                </label>
              </div>
            </div>
            <div className="Card-details px-4 row ">
              {Boolean(allMembers?.length) ? (
                allMembers?.map((v) => {
                  console.log(v, "ASDFATGERGVAV");
                  return (
                    <div
                      className="Card   col-md-4 col-12 rounded p-4 mb-5  "
                      style={{ boxShadow: "0px 0px 6px 1px #5A71D01B" }}
                    >
                      <div className="row gx-0 align-items-center ">
                        <div
                          className="col-2 me-2"
                          style={{ height: "38px", width: "38px" }}
                        >
                          <img
                            src={v?.userDetails?.profilePic ? BUCKET_URL + "/" + v?.userDetails?.profilePic : defualtPic}
                            className=" rounded-circle"
                            alt="..."
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                            }}
                            onError={(e) => {
                              e.target.src = ProfilePicDemo;
                              e.error = null;
                            }}
                          />
                        </div>
                        <div className="col-9 ps-2">
                          <h3 className="m-0 p-14 ">
                            {v?.userDetails?.firstName}
                          </h3>
                          <p className="m-0 p-14">{v?.email}</p>
                        </div>
                        <div
                          className="col-auto"
                          onClick={() => handleDeleteMember(v.id)}
                        >
                          <i
                            className="bi bi-trash3 h2"
                            data-bs-toggle="modal"
                            data-bs-target="#confirmModal"
                            onClick={() =>
                              handleRemoveFromCommunity(v.memberUserId)
                            }
                          ></i>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-center">No data found</div>
              )}

              {/* <div
                className="Card  col-md-5 col-12 rounded p-4 mb-5   mx-auto"
                style={{ boxShadow: "0px 0px 6px 1px #5A71D01B" }}
              >
                <div className="row gx-0 ">
                  <div
                    className="col-2 me-2"
                    style={{ height: "38px", width: "38px" }}
                  >
                    <img
                      src="./assets/img/avatars/profiles/avatar-1.jpg"
                      className=" rounded-circle"
                      alt="..."
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div className="col-9 ps-2">
                    <h3 className="m-0 p-14 ">James</h3>
                    <p className="m-0 p-14">james@gmail.com</p>
                  </div>
                  <div className="col-auto">
                    <i className="bi bi-trash3 h1"></i>
                  </div>
                </div>
              </div>
              <div
                className="Card  col-md-5 col-12 rounded p-4  mb-5   mx-auto "
                style={{ boxShadow: "0px 0px 6px 1px #5A71D01B" }}
              >
                <div className="row gx-0 ">
                  <div
                    className="col-2 me-2"
                    style={{ height: "38px", width: "38px" }}
                  >
                    <img
                      src="./assets/img/avatars/profiles/avatar-1.jpg"
                      className=" rounded-circle"
                      alt="..."
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div className="col-9 ps-2">
                    <h3 className="m-0 p-14 ">James</h3>
                    <p className="m-0 p-14">james@gmail.com</p>
                  </div>
                  <div className="col-auto">
                    <i className="bi bi-trash3 h1"></i>
                  </div>
                </div>
              </div>
              <div
                className="Card  col-md-5 col-12 rounded p-4   mb-5   mx-auto"
                style={{ boxShadow: "0px 0px 6px 1px #5A71D01B" }}
              >
                <div className="row gx-0 ">
                  <div
                    className="col-2 me-2"
                    style={{ height: "38px", width: "38px" }}
                  >
                    <img
                      src="./assets/img/avatars/profiles/avatar-1.jpg"
                      className=" rounded-circle"
                      alt="..."
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div className="col-9 ps-2">
                    <h3 className="m-0 p-14 ">James</h3>
                    <p className="m-0 p-14">james@gmail.com</p>
                  </div>
                  <div className="col-auto">
                    <i className="bi bi-trash3 h1"></i>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* confrim modal  */}

      <div
        className="modal fade"
        id="confirmModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content pb-4">
            <div className="modal-header border-bottom-0 pb-0">
              <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
              <img className="modal-image-1" />
              <button
                type=""
                className=" border-0 bg-transparent"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {" "}
                <img src={CloseBtn} />
              </button>
            </div>
            <div className="modal-body px-5 pt-3 py-0">
              <h1 className="modal-title  fw-bold p-16  pt-5 fs-5 text-center">
                Are you sure want to remove this user from the community ??
              </h1>
            </div>

            <div className=" mx-auto">
              <button
                className="btn_custome mt-4 modal-submit me-5 "
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                No
              </button>
              <button
                className="btn_custome mt-4 modal-submit"
                onClick={handleRemoveUser}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* invite modal  */}

      <div
        className="modal fade"
        id="inviteModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content pb-4">
            <div className="modal-header border-bottom-0 pb-0">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Pending Invitations
              </h1>
              <button
                type=""
                className=" border-0 bg-transparent"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {" "}
                <img src={CloseBtn} />
              </button>
            </div>
            <div className="modal-body">
              {Boolean(pendingInvites?.length) &&
                pendingInvites?.map((v) => {
                  return (
                    <div
                      className="Card  col-md-12 col-12 rounded p-4  mb-5   mx-auto "
                      style={{ boxShadow: "0px 0px 6px 1px #5A71D01B" }}
                    >
                      <div className="row gx-0 ">
                        <div
                          className="col-2 me-2"
                          style={{ height: "38px", width: "38px" }}
                        >
                          <img
                            // src= "./assets/img/avatars/profiles/avatar-1.jpg"
                            src={v?.userDetails?.profilePic ? BUCKET_URL + "/" + v?.userDetails?.profilePic : defualtPic}
                            onError={(e) => {
                              e.target.src = defualtPic;
                              e.error = null;
                            }}
                            className=" rounded-circle"
                            alt="..."
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="col-9 ps-2">
                          <h3 className="m-0 p-14 ">
                            {v?.userDetails?.firstName}
                          </h3>
                          <p className="m-0 p-14">{v?.userDetails?.email}</p>
                        </div>
                        <div
                          style={{ margin: "0px 15px" }}
                          className="col-auto"
                        >
                          <i
                            onClick={() => handleAccept(v, "accepted")}
                            style={{ cursor: "pointer" }}
                            class="bi bi-check-circle h1"
                          ></i>
                        </div>
                        <div className="col-auto">
                          <i
                            onClick={() => handleAccept(v, "rejected")}
                            style={{ cursor: "pointer" }}
                            class="bi bi-x-circle h1"
                          ></i>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default MyCommunity;
