import React, { useContext } from 'react'
import { UserContext } from '../../Contexts/Contexts'

export default function OperatingAlert() {
   const {userProfileDetails } = useContext(UserContext);
   const userDetails = userProfileDetails?.data?.data
   return (
      <>
         {userDetails?.currentSite && !(userDetails?.id==userDetails?.currentSite?.userId && userDetails?.currentSite?.isDefault) && <div class="alert alert-danger fade show" role="alert">
           Operating business site {userDetails?.currentSite?.siteName} as manager
         </div>}
      </>

   )
}
