import React from 'react'
import AppLayout from '../AppLayout/AppLayout';
import default_image from "../Assets/Images/Group 10773.png"
const Default = (props) => {
    return (
        <>
            {/* <AppLayout> */}
                <div className="d-flex justify-content-between pt-4 mb-3  
                ">
                    <div className="all-svg">
                        <h3
                            className=" mb-0"
                            style={{ cursor: "pointer" }}
                        >
                          {props?.name}
                        </h3>

                    </div>
                    {/* <div>
                        <h4>
                            Sort by
                            <select
                                name=""
                                id=""
                                style={{ border: "none", fontWeight: "700" }}
                            >
                                <option value="">Newest</option>
                                <option value="">Oldest</option>
                            </select>
                        </h4>
                    </div> */}
                </div>
                <div className='main_class d-flex justify-content-center align-item-center'>
                    <div className='content_center m-auto text-center'>
                        <img src={default_image} />
                        <h1 className='p-26'>
                        {props?.name} {props.name === "Ingredients" ? "are" :"is"} <span className='fw-bolder'>Empty</span>
                        </h1>
                        {props.showSubHeading && <h6 className='p-16'>{props.subHeading}</h6>}
                    </div>

                </div>
               

                

            {/* </AppLayout> */}
        </>
    )
}

export default Default