import React, { useEffect, useState,useContext } from "react";
import Sidebar from "../../AppLayout/Sidebar";
import MainSearch from "../../AppLayout/MainSearch";
import { allSharedRecipeApi, getMySharedRecipeApi, getRecipieByIdApi } from "../../Api/Apis";
import Default from "../Default";
import ProfilePicDemo from '../../Assets/Images/Group 10775.png';
import { BUCKET_URL } from "../../Utils/BaseUrl";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { ThemeSpinner } from "../../Utils/Loaders";
import RecipeChart from "../Common/RecipeChart";
import BusinessPlanTrailAlert from "../Common/BusinessPlanTrailAlert";
import { UserContext } from "../../Contexts/Contexts";
import OperatingAlert from "../Common/OperatingAlert";

const MySharedRecipe = () => {
  const [recipeData, setRecipeData] = useState([]);
  const [sortData, setSortData] = useState([]);
  const [saved, setSaved] = useState();
  const [toggle,setToggle]=useState(false);
  const [borderId, setBorderId] = useState();
  const [chartDetails, setChartDetails] = useState();
  const [loader, setLoader] = useState(false)

  const { userProfileDetails } = useContext(UserContext);


  const location = useLocation()
  const navigate = useNavigate()

  const LIMIT = 30

  const getMySharedRecipeDetails = async () => {
    try {
      setLoader(true)
      const res = await getMySharedRecipeApi("",0,LIMIT);
      if (res.status === 200 || res.status === 201) {
        setRecipeData(res.data.data);
      }
      setLoader(false)
    } catch (err) {
      setLoader(false)
      console.log(err);
    }
  };

  const getCommunitySharedRecipe = async () =>{
    try {
      setLoader(true)
      const res = await allSharedRecipeApi("",0,LIMIT);
      if (res.status === 200 || res.status === 201) {
        setRecipeData(res.data.data);
      }
      setLoader(false)
    } catch (err) {
      setLoader(false)
      console.log(err);
    }
  }

  const getSortedData = (name) => {
    if (name === "new") {
      let d = recipeData?.map((v) => {
        return v;
      });
      const sortedData = d?.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setSortData(sortedData);
    } else {
      let d = recipeData?.map((v) => {
        return v;
      });

      const sortedData = d?.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
      // return sortedData;
      setSortData(sortedData);
    }
  };

  const handleChartView = () => {
    setChartDetails();
    // setBorderId()
    setToggle(false)
  };

  const handleCloseChart = () => {
    setChartDetails();
    // setBorderId()
    setToggle(false)
  };

  const handleViewRecipeChart = async (id) => {
    document.getElementById(`${borderId}`)?.removeAttribute("class");
    document.getElementById(`${borderId}`)?.setAttribute("class", `food-div`);
    setBorderId(`card${id}`);
    // document
    //   .getElementById(`card${id}`)
    //   .setAttribute("class", ` borderCard food-div`);
    try {
      const res = await getRecipieByIdApi(id);
      if (res.status === 200 || res.status === 201) {
        setChartDetails(res?.data?.data);
        setSaved(res?.data?.data?.isSaved);
        setToggle(true)
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSorting = (e) => {
    if (e.target.value === "new") {
      getSortedData("new");
    } else {
      getSortedData("old");
    }
  };

  useEffect(() => {
    if(location.pathname === '/myshared-recipes')
    getMySharedRecipeDetails();
  else
  getCommunitySharedRecipe();
  }, [location]);

  useEffect(() => {
    getSortedData("new");
  }, [recipeData]);

  return (
    <div>
      {console.log(location.pathname,'ye hai pathname')}
      <Sidebar />
      <div class="main-content mask content-mask" style={{paddingTop:'0rem'}}>
      <div className="sticky-header" style={{paddingTop:'1.5rem'}}> 
      {userProfileDetails?.data?.data?.onBusinessTrial && <BusinessPlanTrailAlert/>}
      <OperatingAlert/>
        <MainSearch />
        </div>
        <div className="d-flex justify-content-between pt-4 mb-3 position-relative">
          <div>     
          </div>
          <div>
            <h4>
              Sort by
              <select
                name=""
                className="ms-2"
                id=""
                onChange={handleSorting}
                style={{ border: "none", fontWeight: "700" }}
              >
                <option value="new">Newest</option>
                <option value="old">Oldest</option>
              </select>
            </h4>
          </div>
        </div>
        <div className="row">

        <div className={`col-xl-${toggle ? 7 : 12} col-lg-${toggle ? 6 : 12
                  } disable-scroller`}>
                     <div className="row">

                     <div className="container-fluid">
          <div className="row"> 
            <div className="d-flex justify-content-between  flex-wrap">
              <ul
                className="nav nav-tabs"
                id="pills-tab"
                role="tablist"
                style={{ zIndex: "1" }}
              >
                <NavLink
                  className="nav-link me-4"
                  exact
                  
                  activeClassName="active"
                  to="/communityshared-recipes"
                  
                  >
                  By Community
                </NavLink>

                <NavLink
                  className="nav-link"
                  exact
                  
                  activeClassName="active"
                  to="/myshared-recipes"
                  
                >
                  By Me
                </NavLink>
              </ul>
              <div style={{ display: "-webkit-inline-box" }}></div>
            </div>
            </div>
            </div>
        {Boolean(sortData?.length) ? (
          sortData?.map((v) => {
            return (
             
                <div className={`col-xl-${toggle ? 6 : 4} col-lg-${toggle ? 12 : 6
                } mt-3`}>
                  <div className="food-div" id={`card${v.id}`}>
                  <div className="image-div ratio ratio-4x3" style={{cursor:"pointer"}}
                  onClick={()=> navigate("/view-recipes", { state: { id:v?.recipeId} })}
                  >
                    <img
                      src={BUCKET_URL + "/" + v?.recipeDetails?.thumbnailImage  }
                      width="100%"
                      alt=""
                      onError={(e) => {
                        e.target.src = ProfilePicDemo;
                        e.error = null;
                      }}
                    />
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <p className="ingredients">Recipe</p>

                      {/* <p className="shared-green">
                        <i class="bi bi-dot shared"></i> Shared By {v?.userDetails?.firstName + " "+ v?.userDetails?.lastName}
                      </p> */}
                      <small
                        style={{ color: "#ff0000", fontWeight: "600" }}
                        className=" d-flex flex-column"
                      >
                        <p className="m-0 p-10">
                          <span> Created on:</span> <span>{(v.updatedAt)?.split("T")[0]?.split("-")?.reverse()?.join("/")}</span>
                        </p>
                        <p className="m-0 p-10">
                          <span> Created by:</span>{" "}
                          <span>
                            {v?.userDetails?.userName}
                            {/* {v?.userDetails?.firstName +
                              " " +
                              v?.userDetails?.lastName} */}
                          </span>
                        </p>
                      </small>
                    </div>
                    {/* <small style={{ color: "#FBAD06" }}>
                      Created on :   {v.createdAt?.split("T")[0]?.split("-").reverse().join("/")}
                    </small> */}


                    <h1 style={{minHeight:"60px"}}>{v.recipeDetails?.name}</h1>
                    <button   onClick={() => { 
                      // handleViewRecipeChart(v.recipeDetails?.id)
                      navigate("/view-recipes", { state: { id:v?.recipeId} })
                    }
                      } className="mt-3 btn-view-chart w-100">
                      View Chart
                    </button>
                  </div>
                </div>
            
            );
          })
        ) : 
        
        loader?<div className="text-center"><ThemeSpinner/></div>:
        
        (
          <Default name={location.pathname === '/myshared-recipes'?"My Shared Recipes":"Shared Recipes"} subHeading="Start sharing your recipes" showSubHeading={location.pathname ===  '/myshared-recipes'?true:false}/>
        )}
        </div>
        </div>
        {chartDetails && (
            <div className="col-xl-4 col-lg-6 mt-3">
              {/* <div class="card  mb-0">
                <div class="card-body  position-relative  p-0">
                  <div className="modal_chart_list mx-2   ">
                    <i
                      onClick={handleChartView}
                      class="bi bi-x position-absolute me-3 border rounded-circle mt-1 px-1 bg-dark text-white end-0"
                    ></i>
                    {Boolean(chartDetails?.ingredientDetails?.length) &&
                      chartDetails?.ingredientDetails?.map((v) => {
                        console.log(v, "vallluuueeee");
                        return (
                          <div className="row mb-4 mt-4">
                            <div className="col-auto">
                              <img
                                src={BUCKET_URL + "/" + v.frontImage}
                                style={{
                                  width: "28px",
                                  height: "28px",
                                  borderRadius: "50%",
                                }}
                                onError={(e) => {
                                  e.target.src = ProfilePicDemo;
                                  e.error = null;
                                }}
                              />
                            </div>
                            <div className="col-7">
                              <h6 className=" p-14 fw-bold"> {v.name}</h6>
                              <p className="p-10 m-0">Note: {v.notes}</p>
                            </div>
                            <div className="col-auto">
                              <p className="m-0 p-12">
                                {v?.quantity + " " + v?.quantityUnit}
                              </p>
                            </div>

                            <div className="col-auto">
                              <i
                                class="bi bi-caret-right icon-color"
                                type="button"
                              ></i>
                            </div>
                          </div>
                        );
                      })}
                   
                  </div>
                  <div className="modal-header-list position-relative w-100">
                    <h1
                      class="modal-title-list  mb-0 py-3 "
                      id="exampleModalLabel"
                    >
                      Nutritional Information
                    </h1>
                  </div>

                  <div class=" p-3 " style={{ border: "1px solid #000" }}>
                    <div
                      className=" heading d-flex justify-content-around align-items-center"
                      style={{ borderBottom: "9px solid black" }}
                    >
                      <h6 className="p-1">Serving size </h6>
                      <h6 className="p-12">
                        (
                        {chartDetails?.servingSize?.data
                          ? chartDetails?.servingSize?.data
                          : chartDetails?.servingSize}
                        )
                      </h6>
                    </div>
                    <div
                      className=" heading d-flex justify-content-around align-items-center "
                      style={{ borderBottom: "5px solid black" }}
                    >
                      <h6 className="p-17 fw-bold">Calories per serving</h6>
                      <h1 className="p-17 fw-boldest">
                        {chartDetails?.caloriePerServing}
                      </h1>
                    </div>
                   {
                    Boolean(chartDetails?.proximatesDetails?.nutrients?.length) && <>
                       <div className="row align-item-center">
                      <h6 className="col-2">Nutrients</h6>
                      <h6 className="col-2">Unit</h6>
                      <h6 className="col-2">Per 100g</h6>
                      <h6 className="col-2">
                        per {chartDetails?.totalQuantity}{" "}
                      </h6>
                      <h6 className="col-2">serving </h6>
                      <h6 className="col-2">NRV </h6>
                    </div>
                    <hr className="m-0 bottom-line" />
                    </>
                   }
                 
                    <div className="disable-scroller_chart">
                      {console.log(chartDetails, "cjradkede")}
                      {chartDetails?.proximatesDetails?.nutrients?.map((v) => {
                        return (
                          <div className="row  pt-3">
                            <div className="col-2 text-start">
                              <h6 className=" fs-5 fw-bold">
                                {v?.link ? (
                                  <Link to={v?.link} target="_blank">
                                    {v.name}
                                  </Link>
                                ) : (
                                  <span>{v.name}</span>
                                )}
                              </h6>
                            </div>
                            <div className="col-2">
                              <p className="fs-5">{v?.unit}</p>
                            </div>
                            <div className="col-2">
                              <h6 className="text-start fs-5 fw-bold">
                                {v?.hundredValue?.toString()?.split(".")
                                  ?.length > 1
                                  ? Number(v?.hundredValue)?.toFixed(2)
                                  : v?.hundredValue}
                              </h6>
                            </div>
                            <div className="col-2">
                              <h6 className="text-start fs-5 fw-bold">
                                {v?.value?.toString()?.split(".")?.length > 1
                                  ? Number(v?.value)?.toFixed(2)
                                  : v?.value}
                              </h6>
                            </div>
                            <div className="col-2">
                              <h6 className="text-start fs-5 fw-bold">
                                {v?.nrvValue > 0 ? v?.nrvPercentage + "%" : ""}
                              </h6>
                            </div>
                            <div className="col-2">
                              <h6 className="text-start fs-5 fw-bold">
                                {v?.nrvValue > 0 ? v?.nrvValue : ""}
                              </h6>
                            </div>
                          </div>
                        );
                      })}

                      {
                        Boolean(chartDetails?.proximatesDetails?.vitamins?.length) && <>
                          <hr className="m-0 bottom-line" />
                      <div className="row align-item-center">
                        <h6 className="col-2">Vitamins</h6>
                        <h6 className="col-2">Unit</h6>
                        <h6 className="col-2">Per 100g</h6>
                        <h6 className="col-2">
                          per {chartDetails?.totalQuantity}{" "}
                        </h6>
                        <h6 className="col-2">serving </h6>
                        <h6 className="col-2">NRV </h6>
                      </div>
                      <hr className="m-0 bottom-line" />
                        </>
                      }
                    
                      {chartDetails?.proximatesDetails?.vitamins?.map((v) => {
                        return (
                          <div className="row  pt-3">
                            <div className="col-2 text-start">
                              <h6 className=" fs-5 fw-bold">
                                {v?.link ? (
                                  <Link to={v?.link} target="_blank">
                                    {v.name}
                                  </Link>
                                ) : (
                                  <span>{v.name}</span>
                                )}
                              </h6>
                            </div>
                            <div className="col-2">
                              <p className="fs-5">{v?.unit}</p>
                            </div>
                            <div className="col-2">
                              <p className="fs-5">
                                {v?.hundredValue?.toString()?.split(".")
                                  ?.length > 1
                                  ? Number(v?.hundredValue)?.toFixed(2)
                                  : v?.hundredValue}
                              </p>
                            </div>
                            <div className="col-2">
                              <h6 className="text-start fs-5 fw-bold">
                                {v?.value?.toString()?.split(".")?.length > 1
                                  ? Number(v?.value)?.toFixed(2)
                                  : v?.value}
                              </h6>
                            </div>
                            <div className="col-2">
                              <h6 className="text-start fs-5 fw-bold">
                                {v?.nrvValue > 0 ? v?.nrvPercentage + "%" : ""}
                              </h6>
                            </div>
                            <div className="col-2">
                              <h6 className="text-start fs-5 fw-bold">
                                {v?.nrvValue > 0 ? v?.nrvValue : ""}
                              </h6>
                            </div>
                          </div>
                        );
                      })}
                      {
                        Boolean(chartDetails?.proximatesDetails?.minerals?.length) && <>
                         <hr className="m-0 bottom-line" />
                      <div className="row align-item-center">
                        <h6 className="col-2">Minerals</h6>
                        <h6 className="col-2">Unit</h6>
                        <h6 className="col-2">Per 100g</h6>
                        <h6 className="col-2">
                          per {chartDetails?.totalQuantity}{" "}
                        </h6>
                        <h6 className="col-2">serving </h6>
                        <h6 className="col-2">NRV </h6>
                      </div>
                      <hr className="m-0 bottom-line" />
                        </>
                      }
                     
                      {chartDetails?.proximatesDetails?.minerals?.map((v) => {
                        return (
                          <div className="row  pt-3">
                            <div className="col-2 text-start">
                              <h6 className=" fs-5 fw-bold">
                                {v?.link ? (
                                  <Link to={v?.link} target="_blank">
                                    {v.name}
                                  </Link>
                                ) : (
                                  <span>{v.name}</span>
                                )}
                              </h6>
                            </div>
                            <div className="col-2">
                              <p className="fs-5">{v?.unit}</p>
                            </div>
                            <div className="col-2">
                              <h6 className="text-start fs-5 fw-bold">
                                {v?.value?.toString()?.split(".")?.length > 1
                                  ? Number(v?.value)?.toFixed(2)
                                  : v?.value}
                              </h6>
                            </div>
                            <div className="col-2">
                              <h6 className="text-start fs-5 fw-bold">
                                {v?.value?.toString()?.split(".")?.length > 1
                                  ? Number(v?.value)?.toFixed(2)
                                  : v?.value}
                              </h6>
                            </div>
                            <div className="col-2">
                              <h6 className="text-start fs-5 fw-bold">
                                {v?.nrvValue > 0 ? v?.nrvPercentage + "%" : ""}
                              </h6>
                            </div>
                            <div className="col-2">
                              <h6 className="text-start fs-5 fw-bold">
                                {v?.nrvValue > 0 ? v?.nrvValue : ""}
                              </h6>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <p
                      className=" text-dark m-0 card-bottom p-10 pt-3"
                      style={{ borderTop: "3px solid black" }}
                    >
                      * % values are based on a 2000
                      calories diet. Your daily values may be higher or lower
                      depending on your energy needs.
                    </p>
                  </div>
                </div>
              </div> */}
              <RecipeChart chartDetails={chartDetails} handleCloseChart={()=>handleCloseChart()}/>
            </div>
          )}
      </div>
      
          </div>
    </div>
  );
};

export default MySharedRecipe;
